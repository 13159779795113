/* eslint-disable react/no-deprecated */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
import React, { Component, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import * as actions from '../../providers/actions';
import { initialState } from './initialState';
import IntlContext from '../IntlContext';
import { setObserver } from '../../../services/error_handler';
import { getCookie } from '../../helpers';

const associateActions = (component, actions) => {
  const associatedActions = {};
  Object.keys(actions).forEach((key) => {
    if (typeof actions[key] === 'function') {
      associatedActions[key] = actions[key].bind(component);
    }
    if (typeof actions[key] === 'object') {
      associatedActions[key] = associateActions(component, actions[key]);
    }
  });
  return associatedActions;
};

function setClaim(data) {
  this.state.claim.data = data;
}

function setUser(data) {
  this.state.userAxa.data = data;
}

function setUserIsCompleted(data) {
  this.state.userAxa.isCompleted = data;
}

function setMenu(data) {
  this.state.menu.type = data;
}

function getClaim() {
  const { data } = this.state.claim;
  return data;
}

function isUserCompleted() {
  const user = getCookie('userAxa');
  return !!user;
}

function getMenu() {
  const { type } = this.state.menu;
  return type;
}

function getSub() {
  const { sub } = this.state.user.data.userInfo;
  return sub;
}

function getSubFormated() {
  const { sub } = this.state.user.data.userInfo;
  return sub.replace('auth0|', '');
}

function isUserLogged() {
  return this.state.user.logged;
}

function getEmail() {
  const { email } = this.state.user.data.userInfo;
  return email;
}

function getUser() {
  const { data } = this.state.userAxa;
  return data;
}

export class StateContextParent extends Component {
  constructor(props) {
    super(props);
    if (!this.props.initialState) this.state = initialState();
    else this.state = this.props.initialState;
    this.actions = associateActions(this, actions);
    this.state.modal.modalRef = React.createRef();
    this.utils = {
      getSub: getSub.bind(this),
      getSubFormated: getSubFormated.bind(this),
      isUserLogged: isUserLogged.bind(this),
      getEmail: getEmail.bind(this),
      getUser: getUser.bind(this),
      setUser: setUser.bind(this),
      getMenu: getMenu.bind(this),
      setMenu: setMenu.bind(this),
      setUserIsCompleted: setUserIsCompleted.bind(this),
      isUserCompleted: isUserCompleted.bind(this),
      setClaim: setClaim.bind(this),
      getClaim: getClaim.bind(this),
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  componentWillMount() {
    setObserver(this);
    if (!this.props.initialState) {
      this.actions.user.getUserFromCookie();
    }
  }

  render() {
    const value = {
      state: this.state,
      actions: this.actions,
      utils: this.utils,
    };
    return (
      <StateContext.Provider value={value}>
        {this.props.children}
      </StateContext.Provider>
    );
  }
}

const IntlStateContextParent = (props) => {
  const intlContext = useContext(IntlContext);
  return <StateContextParent intlContext={intlContext} {...props} />;
};

export const StateContext = React.createContext({
  actions,
  state: initialState(),
  utils: {
    getSub,
    getSubFormated,
    isUserLogged,
    getEmail,
    getUser,
    setUser,
    getMenu,
    setMenu,
    setUserIsCompleted,
    isUserCompleted,
    setClaim,
    getClaim,
  },
});

export default withRouter(IntlStateContextParent);
