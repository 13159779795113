export {
  VALIDATION_REQUIRED,
  LABEL_PT,
  LABEL_ES,
  LABEL_EN,
  LABEL_FR,
  LABEL_DE,
  LABEL_IT,
  VALIDATION_MUST_BE_THAN_18,
  VALIDATION_VALID_DATE,
} from '../../shared/constants/locale';

export const TITLE = {
  en_US: 'Register for the Doctor Please! Service',
  it_IT: 'Registrati al servizio Doctor Please!',
  pt_BR: 'Registrar para o serviço do Doctor Please!',
  es_ES: 'Regístrese con  nuestra aplicación Doctor Please!',
  de_DE: 'Registrieren Sie sich für den Doctor Please! Service',
  fr_FR: 'Créer mon compte pour accéder au service Doctor Please!',
};

export const FORM_TITLE = {
  en_US: "Let's start with the basics.",
  it_IT: 'Iniziamo dalle basi',
  pt_BR: 'Vamos começar com o básico.',
  es_ES: 'Empecemos con lo básico',
  de_DE: 'Persönliche Daten',
  fr_FR: "Commençons par l'essentiel",
};

export const FIRSTNAME_FIELD = {
  en_US: 'First Name',
  it_IT: 'Nome',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
  de_DE: 'Vorname',
  fr_FR: 'Prénom',
};

export const LASTNAME_FIELD = {
  en_US: 'Last Name',
  it_IT: 'Cognome',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellidos',
  de_DE: 'Nachname',
  fr_FR: 'Nom',
};

export const EMAILADDRESS_FIELD = {
  en_US: 'Email Address',
  it_IT: 'Indirizzo Email',
  pt_BR: 'Endereço de e-mail',
  es_ES: 'Dirección de correo electrónico',
  de_DE: 'Email Adresse',
  fr_FR: 'Adresse e-mail',
};

export const DATEOFBIRTH_FIELD = {
  en_US: 'Date of Birth',
  it_IT: 'Data di nascita',
  pt_BR: 'Data de nascimento',
  es_ES: 'Fecha de nacimiento',
  de_DE: 'Geburtsdatum',
  fr_FR: 'Date de naissance',
};

export const GENDER_FIELD = {
  en_US: 'Gender',
  it_IT: 'Sesso',
  pt_BR: 'Gênero',
  es_ES: 'Género',
  de_DE: 'Geschlecht',
  fr_FR: 'Civilité',
};

export const LANGUAGE_FIELD = {
  en_US: 'Language',
  it_IT: 'Lingua',
  pt_BR: 'Idioma',
  es_ES: 'idioma',
  de_DE: 'Sprache',
  fr_FR: 'Langue',
};

export const COUNTRY_RESIDENCE_FIELD = {
  en_US: 'Country of Residence',
  it_IT: 'Paese di residenza',
  pt_BR: 'País de residência',
  es_ES: 'País de residencia',
  de_DE: 'Heimatland',
  fr_FR: 'Pays de résidence',
};

export const COUNTRY_CODE_FIELD = {
  en_US: 'Country Code',
  it_IT: 'Prefisso Internazionale',
  pt_BR: 'Código do país',
  es_ES: 'Código del país',
  de_DE: 'Ländesvorwahl',
  fr_FR: 'Indicatif pays',
};

export const TELEPHONE_NUMBER_FIELD = {
  en_US: 'Telephone Number',
  it_IT: 'Recapito telefonico',
  pt_BR: 'Número de telefone',
  es_ES: 'Número de teléfono',
  de_DE: 'Telefonnummer',
  fr_FR: 'Numéro de téléphone',
};

export const ACCESS_CODE_FIELD = {
  en_US: 'Access Code',
  it_IT: 'Codice di accesso',
  pt_BR: 'Código de acesso',
  es_ES: 'Código de acceso',
  de_DE: 'Zugangs Code',
  fr_FR: "Code d'accès",
};

export const TERMS_AND_CONDITION_0 = {
  en_US: 'I have read and agree to the ',
  it_IT: 'Ho letto e compreso i ',
  pt_BR: 'Li e estou de acordo com os ',
  es_ES: 'He leído y acepto los ',
  de_DE: 'Ich habe die AVB gelesen und stimme diesen zu ',
  fr_FR: "J'ai lu et accepte les ",
};

export const TERMS_AND_CONDITION_1 = {
  en_US: 'Terms and conditions.',
  it_IT: 'Termini e condizioni del servizio.',
  pt_BR: 'Termos e condições.',
  es_ES: 'Términos y condiciones.',
  de_DE: 'AVB.',
  fr_FR: "Conditions d'utilisation.",
};

export const PRIVACY_NOTICE_0 = {
  en_US:
    'I hereby consent to the processing of my health data in accordance with the ',
  it_IT:
    "Acconsento al trattamento dei miei dati sanitari in conformità con l'informativa sulla ",
  pt_BR:
    'Eu concordo com o processamento dos meus dados de saúde de acordo com o ',
  es_ES:
    'I hereby consent to the processing of my health data in accordance with the ',
  de_DE:
    'Ich erkläre mich mit der Verarbeitung meiner Gesundheitsdaten, gemäß des Datenschutzhinweises, einverstanden. ',
  fr_FR: 'Je consent au traitement de mes données de santé conformément à la ',
};

export const PRIVACY_NOTICE_1 = {
  en_US: 'Privacy Notice.',
  it_IT: 'Privacy.',
  pt_BR: 'Aviso de Privacidade.',
  es_ES: 'Privacy Notice.',
  de_DE: 'Meiner Gesundheitsdaten.',
  fr_FR: 'Politique de confidentialité.',
};

export const REGISTER_BUTTON = {
  en_US: 'Register',
  it_IT: 'REGISTRATI',
  pt_BR: 'Registrar',
  es_ES: 'Registrarse',
  de_DE: 'Registrieren',
  fr_FR: "S'ENREGISTRER",
};

export const MALE_LABEL = {
  en_US: 'Male',
  it_IT: 'Uomo',
  pt_BR: 'Masculino',
  es_ES: 'Hombre',
  de_DE: 'Männlich',
  fr_FR: 'Homme',
};

export const FEMALE_LABEL = {
  en_US: 'Female',
  it_IT: 'Donna',
  pt_BR: 'Feminino',
  es_ES: 'Mujer',
  de_DE: 'Weiblich',
  fr_FR: 'Femme',
};

//pending
export const VALIDATE_ACCESS_CODE = {
  en_US:
    'There is an error with your registration. Please contact customer services sending a mail to ',
  it_IT:
    "C'è un errore nella registrazione. Si prega di contattare il servizio clienti inviando una mail a ",
  pt_BR:
    'Existe um erro no seu registro. Entre em contato com o atendimento ao cliente enviando um email para o seguinte endereço: ',
  es_ES:
    'Hay un error en su registro. Por favor, póngase en contacto con atención al cliente enviando un mail a ',
  de_DE:
    'Es liegt ein Fehler bei Ihrer Registrierung vor. Bitte wenden Sie sich an den Kundendienst und senden Sie eine E-Mail an ',
  fr_FR:
    "Il y a une erreur avec votre inscription. Veuillez contacter le service client en envoyant un e-mail à l'adresse suivante: ",
};

//pending
export const EMAIL_ALREADY_EXISTS_TITLE_MESSAGE = {
  en_US: 'Email Already Exists',
  it_IT: 'Email Already Exists',
  pt_BR: 'Email Already Exists',
  es_ES: 'Email Already Exists',
  de_DE: 'Email Already Exists',
  fr_FR: 'Email Already Exists',
};

export const EMAIL_ALREADY_EXISTS = {
  en_US: 'Already exists an account linked to this email, please logout and register with another email address.',
  it_IT: 'È già esistente un account con questo indirizzo email, si prega di disconnettersi e registrarsi con un altro indirizzo email',
  pt_BR: 'Já existe uma conta registrada com este e-mail, por favor, saia e registre-se com outro endereço de e-mail.',
  es_ES: 'Ya existe una cuenta vinculada a este correo electrónico, cierre la sesión y regístrese con otra dirección de correo electrónico.',
  de_DE: 'Es besteht bereits ein Konto mit dieser Email Adresse, Bitte melden Sie sich ab und registrieren Sie sich mit einer anderen E-Mail-Adresse.',
  fr_FR: 'Cette adresse email est déjà utilisée sur Doctor Please!, veuillez vous déconnecter et vous inscrire avec une autre adresse e-mail.',
};

export const REGISTRATION_CONFIRMATION_TITLE = {
  en_US: 'Doctor Please!',
  it_IT: 'Doctor Please!',
  pt_BR: 'Doctor Please!',
  es_ES: 'Doctor Please!',
  de_DE: 'Doctor Please!',
  fr_FR: 'Doctor Please!',
};

export const REGISTRATION_CONFIRMATION_MESSAGE = {
  en_US: 'Your Doctor Please! account has been activated.',
  it_IT: 'Il tuo account Doctor Please! È stato attivato.',
  pt_BR: 'Sua conta do Doctor Please! foi ativada.',
  es_ES: 'Su cuenta de Doctor Please! Ha sido activada.',
  de_DE: 'Ihr Doctor Please! Konto wurde aktiviert.',
  fr_FR: 'Votre compte Doctor please! a été activé.',
};

export const SELECT_A_COUNTRY = {
  es_ES: 'Seleccione un país',
  en_US: 'Select a country',
  pt_BR: 'Selecione um pais',
  fr_FR: 'Choisissez un pays',
  de_DE: 'Wählen Sie ein Land aus',
  it_IT: 'Seleziona un Paese',
};

export const WHERE_TO_FIND_ACCESS_CODE = {
  es_ES: '¿Dónde encontrarlo?',
  en_US: 'Where to find it?',
  pt_BR: 'Onde encontrá-lo?',
  fr_FR: 'Où le trouver ?',
  de_DE: 'Wo ist es zu finden?',
  it_IT: 'Dove trovarlo?',
};

export const WHERE_TO_FIND_ACCESS_CODE_DESCRIPTION = {
  en_US: 'Please refer to the communication on your assistance service ',
  it_IT: 'Si prega di fare riferimento alla comunicazione sul proprio servizio di assistenza ',
  pt_BR: 'Por favor, consulte a comunicação sobre o seu serviço de assistência ',
  es_ES: 'Consulte la comunicación sobre su servicio de asistencia ',
  de_DE: 'Bitte beachten Sie die Mitteilung zu Ihrem Assistance-Service ',
  fr_FR: "Veuillez vous référer à la communication sur votre service d’assistance ",
};