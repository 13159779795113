import React, { Suspense, useState, memo } from 'react';
import { makeStyles, Grid, IconButton, Collapse } from '@material-ui/core';
import { Create, Close } from '@material-ui/icons';
import { RadioButton } from '../../../components';
import * as translations from '../intl';
import { useTranslation } from '../../../shared/hooks';
import { language } from '../../../globalConstants';
import { partner_id } from '../../../globalConstants';
import { partner_name } from '../../../globalConstants';
import { country_code } from '../../../globalConstants';
import { instance_env } from '../../../globalConstants';

const styles = makeStyles((theme) => ({
  container: {
    width: '100%',
    border: '1px solid rgba(245, 245, 245, 1)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '0px 23px',
    display: 'flex',
    background: 'rgba(245, 245, 245, 1)',
    marginBottom: 20,
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 10px',
    },
  },
  ageText: {
    color: theme.palette.text.main,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    fontFamily: 'Source Sans Pro !important',
    padding: '0px 30px',
  },
  editButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const PatientForm = React.lazy(() => import('./PatientForm'));

function PatientCard({ payload, type }) {
  const [CL, FM] = ['CL', 'FM'];
  const [showForm, setShowForm] = useState();
  const { locale } = useTranslation({
    translationsFile: translations,
  });
  const classes = styles();

  const {
    PREFFIX_AGE,
    PREFFIX_CLIENT,
    SON_OPTION,
    DAUGHTER_OPTION,
    OTHER_OPTION,
  } = locale;

  const preffixFM = {
    son: SON_OPTION,
    daughter: DAUGHTER_OPTION,
    other: OTHER_OPTION,
  };

  const mountName = (payload) => {
    if (type === CL) {
      return `${PREFFIX_CLIENT}${payload.name}`;
    } else if (type === FM) {
      return `${preffixFM[payload.relationType]}, ${payload.name}`;
    }
    return payload.name;
  };

  const handleEdit = () => {
    setShowForm((showForm) => !showForm);

    let type = '';

    if(window.location.href.includes('phone')) {
      type = 'phone call';
    } else {
      type = 'video call';
    }

    window.dataLayer.push({
        'subsidiary_name':'axa_partners',
        'business_unit':'customer_and_solutions',
        'business_line':'travel_solutions',
        'digital_business_process':'assistance_request',
        'global_digital_asset':'doctor_please',
        'partner_id':partner_id,
        'partner_name':partner_name,
        'country':country_code,
        'language':language,
        'instance_environment':instance_env,
        'user_logged':'true',
        'page_name':'amend doctor please',
        'funnel_step':'1',
        'step_name':'register doctor please',
        'appointment_type':type,
        'event':'pageview',
    });
  };

  const handleSubmit = () => {
    setShowForm(false);
  };
    
    

  return (
    <Grid container className={classes.container}>
      <Grid item xs={10} md={10}>
        <RadioButton
          name="clientData"
          label=""
          options={[{ label: mountName(payload), value: payload.id }]}
        />
      </Grid>
      <Grid item xs={2} md={2} className={classes.editButton}>
        <IconButton aria-label="edit" color="secondary" onClick={handleEdit}>
          {!showForm ? <Create /> : <Close />}
        </IconButton>
      </Grid>
      <Grid item xs={12} md={12}>
        <p className={classes.ageText}>
          {payload.age} {PREFFIX_AGE}
        </p>
      </Grid>
      <Collapse in={showForm}>
        <Grid item xs={12} md={12}>
          <Suspense fallback={<></>}>
            <PatientForm
              payload={payload}
              type={type}
              handleClose={handleSubmit}
              haveDelete={type === FM ? true : false}
            />
          </Suspense>
        </Grid>
      </Collapse>
    </Grid>
  );
}

export default memo(PatientCard);
