import { createStyles, makeStyles } from '@material-ui/core/styles';
// import stylesRoot from '../../assets/styles';

export default makeStyles(
  (theme) =>
    createStyles({
      // ...stylesRoot,
      title: {
        userSelect: 'none',
        fontWeight: 700,
        fontSize: 12,
        [theme.breakpoints.up('xs')]: {
          fontSize: 12,
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: 12,
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 14,
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 16,
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: 18,
        },
      },
      titleRadio: {
        userSelect: 'none',
        fontWeight: 700,
        fontSize: 12,
        color: theme.palette.text.main,
        [theme.breakpoints.up('xs')]: {
          fontSize: 14,
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: 14,
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 14,
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 14,
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: 16,
        },
      },
      titleBlue: {
        color: theme.palette.primary.main,
      },
      importantNotchedOutline: {
        border: '3px solid #f0f0f0!important',
      },
      notchedOutline: {
        border: '3px solid #f0f0f0',
      },
      radioVerticalRoot: {
        paddingRight: 5,
      },
      radioMarginLeft: {
        marginLeft: 15,
        [theme.breakpoints.up('sm')]: {
          marginLeft: 20,
        },
        [theme.breakpoints.up('lg')]: {
          marginLeft: 40,
        },
        [theme.breakpoints.up('xl')]: {
          marginLeft: 60,
        },
      },
      horizontallyMarginTop: {
        [theme.breakpoints.up('md')]: {
          marginTop: 18,
        },
        [theme.breakpoints.up('lg')]: {
          marginTop: 25,
        },
        [theme.breakpoints.up('xl')]: {
          marginTop: 20,
        },
      },
    }),
  { index: 1 }
);
