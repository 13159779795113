import PublicoHeadlineBoldTTF from './PublicoHeadline-Bold.ttf';
import PublicoHeadlineLightTTF from './PublicoHeadline-Light.ttf';
import PublicoHeadlineMediumTTF from './PublicoHeadline-Medium.ttf';

import SourceSansProBoldTTF from './SourceSansPro-Bold.ttf';
import SourceSansProLightTTF from './SourceSansPro-Light.ttf';
import SourceSansProMediumTTF from './SourceSansPro-Regular.ttf';

const unicodeRange =
  'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';

export const PublicoHeadlineBold = {
  fontFamily: 'Publico Headline',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Publico Headline'),
    local('PublicoHeadline-Bold'),
    url(${PublicoHeadlineBoldTTF}) format('truetype')
  `,
  unicodeRange,
};

export const PublicoHeadlineLight = {
  fontFamily: 'Publico Headline',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 200,
  src: `
    local('Publico Headline'),
    local('PublicoHeadline-Book'),
    url(${PublicoHeadlineLightTTF}) format('truetype')
  `,
  unicodeRange,
};

export const PublicoHeadlineMedium = {
  fontFamily: 'Publico Headline',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Publico Headline'),
    local('PublicoHeadline-Medium'),
    url(${PublicoHeadlineMediumTTF}) format('truetype')
  `,
  unicodeRange,
};

export const SourceSansProBold = {
  fontFamily: 'Source Sans Pro',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('SourceSansPro'),
    local('SourceSansPro-Bold'),
    url(${SourceSansProBoldTTF}) format('truetype')
  `,
};

export const SourceSansProLight = {
  fontFamily: 'Source Sans Pro',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 200,
  src: `
    local('SourceSansPro'),
    local('SourceSansPro-Book'),
    url(${SourceSansProLightTTF}) format('truetype')
  `,
};

export const SourceSansProMedium = {
  fontFamily: 'Source Sans Pro',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('SourceSansPro'),
    local('SourceSansPro-Medium'),
    url(${SourceSansProMediumTTF}) format('truetype')
  `,
};
