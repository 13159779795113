import React, {
  useMemo,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Container,
  FormHelperText,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import {
  AttachFile,
  Description,
  PictureAsPdf,
  PublishOutlined,
} from '@material-ui/icons';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as translations from './intl';
import {
  AutoComplete,
  Button,
  DarkTitle,
  InputMask,
  TextArea,
  ConfirmationModal,
} from '../../components';
import SelectAppointment from './components/SelectAppointment';
import { useTranslation } from '../../shared/hooks';
import { maskedCellphone } from '../../shared/constants/masks';
import { GeneralContext } from '../../shared/contexts/GeneralContext';
import { getCookie } from '../../shared/helpers';
import api from '../../services';

const styles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Publico Headline !important',
    fontSize: '36px !important',
    lineHeight: '60px !important',
    color: theme.palette.text.main,
    paddingBottom: 42,
    paddingTop: 42,
  },
  subTitle: {
    fontFamily: 'Publico Headline !important',
    fontSize: '25px',
    lineHeight: '30px',
    letterSpacing: '0.8px',
    padding: '15px 50px',
    color: theme.palette.text.main,
  },
  countryText: {
    fontFamily: 'Publico Headline !important',
    fontSize: '15px',
    letterSpacing: '0.8px',
    padding: '0px 50px',
    color: theme.palette.text.main,
  },
  containerPaper: {
    [theme.breakpoints.down('md')]: {
      padding: '0px 40px !important',
    },
    padding: '0px 90px !important',
    marginBottom: 20,
  },
  paperContent: {
    width: '100%',
  },
  container: {
    marginBottom: 96,
  },
  containerInfo: {
    width: '100%',
    border: '1px solid #fff',
    backgroundColor: theme.palette.primary.main,
    padding: '20px 80px',
    display: 'flex',
    marginBottom: 15,
    alignItems: 'flex-end',
  },
  containerForm: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px',
    },
    padding: '0px 80px',
    display: 'flex',
    background: '#fff',
    marginLeft: 0,
    marginBottom: 15,
    alignItems: 'flex-end',
    '& .MuiDropzoneArea-textContainer': {
      marginTop: 20,
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  containerFormTelephone: {
    width: '100%',
    padding: '0px 80px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px',
    },
    display: 'flex',
    background: '#fff',
    marginLeft: 0,
    marginBottom: 15,
    alignItems: 'flex-start',
  },
  nameText: {
    color: 'white',
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Source Sans Pro !important',
    fontWeight: '700',
    marginBottom: 3,
  },
  ageText: {
    color: 'white',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: 3,
    fontFamily: 'Source Sans Pro !important',
  },
  subText: {
    marginBottom: 2,
  },
  previewItem: {
    wordWrap: 'break-word',
    border: '1px solid #999999',
    boxSizing: 'border-box',
    borderRadius: '3px',
    marginTop: 10,
    marginLeft: 10,
    '& img': {
      height: 100,
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100% !important',
    },
  },
  containerItem: {
    // border: '1px grey solid',
  },
  imageUpload: {
    maxWidth: 150,
    maxHeight: 100,
  },
  dropzoneText: {
    fontFamily: 'Source Sans Pro',
    fontSize: 14,
    fontWeight: 600,
  },
  dropzoneArea: {},
  paddingFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '25px 60px',
    borderTop: '1px solid rgba(229, 229, 229, 1)',
  },
}));

function Request() {
  const classes = styles();
  const history = useHistory();
  const {
    fetchClientAndDependents,
    client,
    dependents,
    fetchAmosCountry,
    country,
    countryDdi,
    amosToken,
    amosClient,
    setIsLoading,
  } = useContext(GeneralContext);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [timezone, setTimezone] = useState([]);
  const [fileError] = useState({
    show: false,
    error: '',
  });
  const { type, id, clientType } = useParams();
  const { locale, idiomAmos } = useTranslation({
    translationsFile: translations,
  });
  const [CL, FM] = ['CL', 'FM'];
  const idiom = idiomAmos;
  const [selectedClient, setSelectedClient] = useState(null);
  const [fileScanResult, setFileScanResult] = useState(false);
  const isVideo = useMemo(() => type === 'video', [type]);
  const isClient = useMemo(() => clientType === CL, [clientType]);
  const fullURL = getCookie('fullURL');

  const {
    LABEL_ES,
    LABEL_EN,
    LABEL_PT,
    LABEL_FR,
    LABEL_DE,
    LABEL_IT,
    LABEL_FI,
    LABEL_DA,
    LABEL_SV,
    TITLE_VIDEO,
    TITLE_PHONE,
    SUBTITLE_REQUEST,
    PREFFIX_CLIENT,
    PREFFIX_AGE,
    SON_OPTION,
    DAUGHTER_OPTION,
    OTHER_OPTION,
    TITLE_NOW_WHERE_YOU_BE,
    COUNTRY_TEXT,
    COUNTRY_FIELD,
    TELEPHONE_NUMBER_FIELD,
    COUNTRY_CODE_FIELD,
    UPLOAD_IMAGE_TITLE,
    DROPZONE_TEXT,
    DROPZONE_PREVIEW_TEXT,
    APPOINTMENT_TIME_SECTION,
    SUBMIT_BUTTON,
    BACK_BUTTON,
    TYPE_CONSULTATION_FIELD,
    REASON_FIELD,
    LANGUAGE_FIELD,
    TIMEZONE_FIELD,
    VALIDATION_REQUIRED,
    APPOINTMENT_BOOKED_TITLE,
    APPOINTMENT_BOOKED_SUBTITLE_VIDEO,
    APPOINTMENT_BOOKED_SUBTITLE_PHONE,
    TYPE_CONSULTATION,
  } = locale;

  const [textMessage, setPopUpTextMessage] = useState();
  const [popupTitile, setPopUpTitile] = useState();

  const preffixFM = {
    son: SON_OPTION,
    daughter: DAUGHTER_OPTION,
    other: OTHER_OPTION,
  };

  useEffect(() => {
    if (dependents === null && client === null) {
      fetchClientAndDependents();
    } else {
      if (isClient) setSelectedClient(client);
      else {
        const [dependent] = dependents.filter((el) => el.id === id);
        setSelectedClient(dependent);
      }
    }
  }, [dependents, client]);

  const fetchTimezone = async () => {
    try {
      const { data } = await api.amosServices.getTimezone(idiomAmos);
      setTimezone(
        data.timezones.map((el) => {
          return { label: el.value, value: el.key };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!timezone?.length) {
      fetchTimezone();
    }
  }, []);

  useEffect(() => {
    fetchAmosCountry(idiom);
  }, [idiom]);

  const mountName = useCallback(() => {
    if (clientType === CL && selectedClient) {
      return `${PREFFIX_CLIENT}${selectedClient.name}`;
    } else if (clientType === FM && selectedClient) {
      return `${preffixFM[selectedClient.relationType]}, ${
        selectedClient.name
      }`;
    }
    return selectedClient?.name;
  }, [selectedClient]);

  const handleAdd = async (newFile) => {
    let sizeFiles = 0;
    const { file } = newFile[0];

    let scanResult;

    try {
      scanResult = await api.amosServices.getVblock(file);
      console.log('Scan result:', scanResult);
    } catch (error) {
      console.error('Error during file upload:', error);
      return;
    }

    setFileScanResult(scanResult);

    filesList.forEach((item) => {
      sizeFiles += item.file.size;
    });
    sizeFiles += file.size;

    if (sizeFiles > 8000000) {
      console.error('Total file size exceeds 8MB');
      return;
    }

    if (scanResult.ok) {
      setFilesList([...filesList, ...newFile]);
    } else {
      alert(`File Contains Virus: ${scanResult.virus || 'Unknown'}`);
    }
  };

  const optionTypeOfConsultation = [
    {
      label: TYPE_CONSULTATION,
      value: 'general',
    },
  ];

  const handleDelete = (file) => {
    const {
      file: { lastModified },
    } = file;

    const newArr = filesList.filter(
      (item) => item.file.lastModified !== lastModified
    );
    setFilesList([...newArr]);
  };

  const handlePreviewIcon = (fileObject) => {
    let { type } = fileObject.file;
    const iconProps = {
      className: classes.uploadIcon,
    };
    const typeImage = type.split('/');
    if (typeImage[0] === 'image') {
      type = 'image';
    }
    switch (type) {
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <Description {...iconProps} />;
      case 'application/pdf':
        return <PictureAsPdf {...iconProps} />;
      case 'image':
        return <img src={fileObject.data} alt="file" width="100px" />;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return <Description {...iconProps} />;
      default:
        return <AttachFile {...iconProps} />;
    }
  };

  const validationSchema = Yup.object().shape({
    countryResidence: Yup.string().required(VALIDATION_REQUIRED),
    countryCode: Yup.string().required(VALIDATION_REQUIRED),
    cellphone: Yup.string().required(VALIDATION_REQUIRED),
    typeConsultation: Yup.string().required(VALIDATION_REQUIRED),
    reason: Yup.string().required(VALIDATION_REQUIRED),
    language: Yup.string().required(VALIDATION_REQUIRED),
    timezone: Yup.string().required(VALIDATION_REQUIRED),
    appointment: Yup.string().when('countryResidence', {
      is: () => isVideo,
      then: Yup.string().required(VALIDATION_REQUIRED),
      otherwise: Yup.string().notRequired(VALIDATION_REQUIRED),
    }),
  });

  const initialValues = useMemo(
    () => ({
      countryResidence: '',
      countryCode: selectedClient?.phonePrefix,
      cellphone: selectedClient?.phone,
      typeConsultation: '',
      reason: '',
      language: selectedClient?.language,
      timezone: '',
      appointment: '',
    }),
    [selectedClient]
  );

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      let appointment;
      if (isVideo) appointment = JSON.parse(values.appointment);

      const formData = new FormData();
      formData.append('language', values.language);
      if (isVideo) formData.append('callReason', values.reason);
      formData.append('country', values.countryResidence);
      formData.append('phonePrefix', values.countryCode);
      formData.append('phone', values.cellphone);
      formData.append('name', selectedClient.name);
      formData.append('email', selectedClient.email);
      formData.append('surname', selectedClient.surname);
      formData.append('timeZone', values.timezone);
      formData.append('age', selectedClient.age);
      if (clientType === FM && selectedClient?.id) {
        formData.append('dependentId', selectedClient?.id);
      }

      formData.append('specialty', values.typeConsultation);
      formData.append('birthDate', selectedClient.birthDate);
      formData.append('clientId', amosClient);
      formData.append('clientToken', amosToken);
      formData.append('languageEmail', values.language);
      formData.append('fullURL', fullURL);
      if (isVideo) {
        formData.append('date', appointment?.date);
        formData.append('time', appointment?.time);
      }

      if (!isVideo) formData.append('channel', 'phone');
      formData.append('consultation', values.reason);

      if (filesList.length > 0) {
        filesList.map((item) => formData.append('files', item.file));
      } else {
        formData.append('files', []);
      }

      // if (isVideo) await api.amosServices.requestVideoCall(formData);

      // if (!isVideo) await api.amosServices.requestPhoneCall(formData);

      try {
        let doctor_response;
        if (isVideo) {
          await api.amosServices.requestVideoCall(formData);
          setPopUpTextMessage(APPOINTMENT_BOOKED_SUBTITLE_VIDEO);
          setPopUpTitile(APPOINTMENT_BOOKED_TITLE);
        } else {
          doctor_response = await api.amosServices.requestPhoneCall(
            formData,
            values.countryResidence
          );
          if (
            typeof doctor_response === 'string' &&
            values.countryResidence === 'SG'
          ) {
            setPopUpTextMessage(doctor_response);
            setPopUpTitile('Call Back request failed!!');
          } else {
            setPopUpTextMessage(APPOINTMENT_BOOKED_SUBTITLE_PHONE);
            setPopUpTitile(APPOINTMENT_BOOKED_TITLE);
          }
        }
      } catch (error) {
        return error;
      }

      setIsLoading(false);
      setOpenConfirm(true);

      window.dataLayer.push({
        element_displayed: 'popup',
        element_name: 'appointment booked',
        event: 'display',
      });

      window.scrollTo(0, 0);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <ConfirmationModal
        open={openConfirm}
        title={popupTitile}
        text={textMessage}
        handleAction={() => {
          history.push(`/?type=${type}`);

          window.dataLayer.push({
            zone: `${type}` + ' appointment booked',
            element_clicked: 'ok',
            event: 'navigation',
          });
          window.dataLayer.push({
            form_name: 'appointment ' + `${type}`,
            event: 'form_success',
          });
          window.dataLayer.push({
            zone: 'booking appointment',
            claim_id: '',
            appointment_type: '' + `${type}` + ' call',
            service_category: 'health',
            event: 'completed_journey',
          });

          setOpenConfirm(false);
        }}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        okButton
      />
      <Container maxWidth="lg" className={classes.container}>
        <Grid item md={12}>
          <p className={classes.title}>{isVideo ? TITLE_VIDEO : TITLE_PHONE}</p>
        </Grid>
        <Grid item md={12} justifyContent="center" className="d-flex">
          <Paper className={classes.paperContent}>
            <p className={classes.subTitle}>{SUBTITLE_REQUEST}</p>
            {selectedClient && (
              <>
                <Grid container className={classes.containerInfo}>
                  <Grid item md={12}>
                    <p className={classes.nameText}>{mountName()}</p>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <p className={classes.ageText}>
                      {selectedClient.age} {PREFFIX_AGE}
                    </p>
                  </Grid>
                </Grid>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  {({ values }) => (
                    <Form>
                      <Grid item md={12}>
                        <p className={classes.subTitle}>
                          {TITLE_NOW_WHERE_YOU_BE}
                        </p>
                        <p className={classes.countryText}>{COUNTRY_TEXT}</p>
                      </Grid>
                      <Grid container className={classes.containerForm}>
                        <Grid item xs={12} md={6}>
                          <AutoComplete
                            name="countryResidence"
                            showPlaceholder
                            label={COUNTRY_FIELD}
                            options={country}
                          />
                        </Grid>
                      </Grid>
                      <LineGrey />
                      <Grid item md={12}>
                        <p className={classes.subTitle}>{SUBTITLE_REQUEST}</p>
                      </Grid>

                      <Grid
                        container
                        className={classes.containerFormTelephone}
                        spacing={2}
                      >
                        <Grid item sm={12} md={6}>
                          <Grid item xs={12} md={12}>
                            <DarkTitle label={TELEPHONE_NUMBER_FIELD} />
                          </Grid>
                          <Grid
                            container
                            className="d-flex align-items-center"
                            spacing={2}
                          >
                            <Grid item xs={3} md={3}>
                              <AutoComplete
                                name="countryCode"
                                showPlaceholder
                                placeholder="+00"
                                options={countryDdi}
                              />
                            </Grid>
                            <Grid item xs={9} md={9}>
                              <InputMask
                                name="cellphone"
                                mask={maskedCellphone}
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <p className={classes.subText}>
                                {COUNTRY_CODE_FIELD}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoComplete
                            name="typeConsultation"
                            showPlaceholder
                            label={TYPE_CONSULTATION_FIELD}
                            options={optionTypeOfConsultation}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <TextArea
                            name="reason"
                            label={REASON_FIELD}
                            maxLength={800}
                            externalTitle
                          />
                        </Grid>
                        {!isVideo && (
                          <>
                            <Grid item xs={12} md={6}>
                              <AutoComplete
                                name="language"
                                showPlaceholder
                                label={LANGUAGE_FIELD}
                                options={[
                                  { label: LABEL_ES, value: 'es' },
                                  { label: LABEL_EN, value: 'en' },
                                  { label: LABEL_PT, value: 'pt' },
                                  { label: LABEL_FR, value: 'fr' },
                                  { label: LABEL_DE, value: 'de' },
                                  { label: LABEL_IT, value: 'it' },
                                  { label: LABEL_FI, value: 'fi' },
                                  { label: LABEL_DA, value: 'da' },
                                  { label: LABEL_SV, value: 'sv' },
                                ]}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <AutoComplete
                                name="timezone"
                                showPlaceholder
                                label={TIMEZONE_FIELD}
                                options={timezone}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>

                      <LineGrey />

                      <Grid item md={12}>
                        <p className={classes.subTitle}>{UPLOAD_IMAGE_TITLE}</p>
                      </Grid>
                      <Grid container className={classes.containerForm}>
                        <Grid item md={12} xs={12} style={{ zIndex: 0 }}>
                          <DropzoneAreaBase
                            color="primary"
                            name="files"
                            className={{
                              root: classes.dropzoneArea,
                            }}
                            Icon={PublishOutlined}
                            fileObjects={filesList}
                            onAdd={handleAdd}
                            onDelete={handleDelete}
                            showAlerts={['error']}
                            dropzoneParagraphClass={classes.dropzoneText}
                            acceptedFiles={['image/*', '.pdf']}
                            dropzoneText={DROPZONE_TEXT}
                            filesLimit={5}
                            maxFileSize={8000000}
                            showPreviews
                            showFileNamesInPreview
                            previewGridClasses={{
                              item: classes.previewItem,
                              image: classes.imageUpload,
                              container: classes.containerItem,
                            }}
                            previewGridProps={{
                              item: {
                                md: 2,
                              },
                              container: {
                                spacing: 2,
                              },
                            }}
                            showPreviewsInDropzone={false}
                            previewText={DROPZONE_PREVIEW_TEXT}
                            getPreviewIcon={handlePreviewIcon}
                          />
                          {fileError.show && (
                            <FormHelperText error>
                              {fileError.error}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>

                      {isVideo && (
                        <>
                          <LineGrey />

                          <Grid item md={12}>
                            <p className={classes.subTitle}>
                              {APPOINTMENT_TIME_SECTION}
                            </p>
                          </Grid>

                          <Grid
                            container
                            className={classes.containerForm}
                            spacing={3}
                          >
                            <Grid item xs={12} md={6}>
                              <AutoComplete
                                name="language"
                                showPlaceholder
                                label={LANGUAGE_FIELD}
                                options={[
                                  { label: LABEL_ES, value: 'es' },
                                  { label: LABEL_EN, value: 'en' },
                                  { label: LABEL_PT, value: 'pt' },
                                  { label: LABEL_FR, value: 'fr' },
                                  { label: LABEL_DE, value: 'de' },
                                  { label: LABEL_IT, value: 'it' },
                                ]}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <AutoComplete
                                name="timezone"
                                showPlaceholder
                                label={TIMEZONE_FIELD}
                                options={timezone}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <SelectAppointment
                                label={APPOINTMENT_TIME_SECTION}
                                options={timezone}
                                values={values}
                                name="appointment"
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}

                      <Grid container className={classes.paddingFooter}>
                        <Button
                          text={BACK_BUTTON}
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            history.push(
                              `/patient-selection?type=${type}&clientType=${clientType}&clientUuid=${id}`
                            );
                          }}
                        />
                        <Button
                          type="submit"
                          text={SUBMIT_BUTTON}
                          variant="contained"
                          color="primary"
                        />
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </Paper>
        </Grid>
      </Container>
    </>
  );
}

const LineGrey = styled.div`
  height: 1px;
  background-color: rgba(204, 204, 204, 1);
`;

export default Request;
