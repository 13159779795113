/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ReactNode, useEffect, useState } from 'react';

import IntlContext from '../contexts/IntlContext';
import translate from '../helpers/translate';
import getCountryPrefix from '../helpers/getCountryPrefix';
import { en_US } from '../constants/idioms';
import { getCookie, setCookie } from '../helpers/cookies';

type Props = {
  children: ReactNode;
  initialState: any;
};

type Translations = { [key: string]: any };

const IntlParent = ({ children, initialState }: Props) => {
  const [idiom, _setIdiom] = useState(getCookie('idiom') || en_US);
  const translated = (translations: Translations) =>
    translate(translations, idiom);
  const getCountry = () => getCountryPrefix(idiom);

  const setIdiom = (newIdiom: string) => {
    _setIdiom(newIdiom);
    setCookie('idiom', newIdiom);
  };

  const idiomForApi = (): string => (idiom ? idiom.replace('_', '-') : idiom);

  const twoDigitsIdiom = (): string => idiom.substring(0, 2);

  const getIdiom = (): string => idiom;

  useEffect(() => {
    if (initialState && initialState.defaultLanguage) {
      setIdiom(initialState.defaultLanguage);
    } else {
      const idiomFromCookie = getCookie('idiom');
      if (idiomFromCookie) {
        setIdiom(idiomFromCookie);
      } else {
        setCookie('idiom', idiom);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idiom]);

  return (
    <IntlContext.Provider
      value={{
        translate: translated,
        getCountryPrefix: getCountry,
        setIdiom,
        // @ts-ignore
        idiomForApi,
        // @ts-ignore
        twoDigitsIdiom,
        // @ts-ignore
        getIdiom,
        idiom,
      }}
    >
      {children}
    </IntlContext.Provider>
  );
};

export default IntlParent;
