export const COOKIES = {
    es_ES: 'Política de cookies',
    en_US: 'Cookies Policy',
    pt_BR: 'Política de Cookies',
    fr_FR: 'Politique concernant les Cookies',
    de_DE: 'Cookie-Richtlinie',
    it_IT: 'Politica dei cookie',
  };
  
  export const CLOSE = {
    es_ES: 'Cerrar',
    en_US: 'Close',
    pt_BR: 'Fechar',
    fr_FR: 'Fermer',
    de_DE: 'Nah dran',
    it_IT: 'Vicino',
  };
  