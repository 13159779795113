export {
  VALIDATION_REQUIRED,
  LABEL_PT,
  LABEL_ES,
  LABEL_EN,
  LABEL_FR,
  LABEL_DE,
  LABEL_IT,
  TITLE_VIDEO,
  TITLE_PHONE,
  PREFFIX_CLIENT,
  PREFFIX_AGE,
  SON_OPTION,
  DAUGHTER_OPTION,
  OTHER_OPTION,
  VALIDATION_MUST_BE_UNDER_18,
  VALIDATION_MUST_BE_THAN_18,
  VALIDATION_VALID_DATE,
} from '../../shared/constants/locale';

export const SUBTITLE_NEED_HELP = {
  en_US: 'Who needs help today?',
  it_IT: 'Chi ha bisogno di aiuto oggi?',
  pt_BR: 'Quem precisa de ajuda hoje?',
  es_ES: '¿Quién necesita ayuda hoy?',
  de_DE: 'Wem dürfen wir heute helfen?',
  fr_FR: 'Pour qui est la consultation ?',
};

export const TEXT = {
  en_US: 'From the 1st January 2025, the Telemedicine Service provided by American Express is changing to a different provider. After that date, if you need to speak to a doctor whilst on a trip, you can continue to use your Telemedicine Service.\n However, you will need to click <a href="https://amex.eclaims.europe-assistance.com" target="_blank">here</a> from 1st January to login / create an account.',
  it_IT: 'Dal 1° gennaio 2025, il Servizio di Telemedicina fornito da American Express sara’ trasferito ad un altro fornitore. Dopo questa data, se hai bisogno di parlare con un medico durante un viaggio, puoi continuare ad utilizzare il tuo Servizio di Telemedicina. Tuttavia, dal 1° Gennaio dovrai fare clic <a href="https://amex.eclaims.europe-assistance.com" target="_blank">qui</a> per accedere / creare un account.',
  pt_BR: 'A partir de 1 de janeiro de 2025, o Serviço de Telemedicina prestado pela American Express vai mudar para um fornecedor diferente.  Após essa data, se precisar de falar com um médico durante uma viagem, pode continuar a utilizar o seu Serviço de Telemedicina. No entanto, a partir de 1 de janeiro, terá de clicar <a href="https://amex.eclaims.europe-assistance.com" target="_blank">aqui</a> para iniciar sessão/criar uma conta.',
  es_ES: 'Desde el 1 de enero de 2025, el Servicio de Telemedicina prestado por American Express cambiará de proveedor. Después de esa fecha, si necesitas hablar con un médico durante un viaje, podrás seguir utilizando el Servicio de Telemedicina. No obstante, debes desde el 1 de enero hacer clic <a href="https://amex.eclaims.europe-assistance.com" target="_blank">aqui</a>  para iniciar sesión o crear una cuenta.',
  de_DE: 'Ab dem 1. Januar 2025 wechselt der Telemedizindienst von American Express zu einem anderen Anbieter. Wenn Sie nach diesem Datum während einer Reise mit einem Arzt sprechen müssen, können Sie weiterhin Ihren Telemedizindienst nutzen. Ab dem 1. Januar 2025 müssen Sie jedoch <a href="https://amex.eclaims.europe-assistance.com" target="_blank">hier</a> klicken, um sich einzuloggen / ein Konto zu erstellen.',
  fr_FR: 'A partir du 1er janvier 2025, le service de téléconsultation médicale fourni par American Express change de partenaire. A compter de cette date, si vous avez besoin de parler à un médecin lors de votre voyage, vous pourrez continuer à le faire grâce à Telemedicine Service, inclus dans votre Carte. Cependant vous devrez cliquer <a href="https://amex.eclaims.europe-assistance.com" target="_blank">ici</a> à partir du 1er janvier pour vous connecter / créer un compte.',
};

export const NEXT_BUTTON = {
  en_US: 'NEXT',
  it_IT: 'AVANTI',
  pt_BR: 'PRÓXIMO',
  es_ES: 'SIGUIENTE',
  de_DE: 'WEITER',
  fr_FR: 'SUIVANT',
};

export const BACK_BUTTON = {
  en_US: 'BACK',
  it_IT: 'PRECEDENTE',
  pt_BR: 'VOLTAR',
  es_ES: 'ATRÁS',
  de_DE: 'ZURÜCK',
  fr_FR: 'RETOUR',
};

export const FAMILY_MEMBER = {
  en_US: 'Family members',
  it_IT: 'Famigliare',
  pt_BR: 'Membros da família',
  es_ES: 'Miembros de la familia',
  de_DE: 'Familienmitglieder',
  fr_FR: 'Bénéficiaires',
};
/* Patient Form Intl */

export const FIRSTNAME_FIELD = {
  en_US: 'First Name',
  it_IT: 'Nome',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
  de_DE: 'Vorname',
  fr_FR: 'Prénom',
};

export const LASTNAME_FIELD = {
  en_US: 'Last Name',
  it_IT: 'Cognome',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellidos',
  de_DE: 'Nachname',
  fr_FR: 'Nom',
};

export const EMAILADDRESS_FIELD = {
  en_US: 'Email Address',
  it_IT: 'Indirizzo Email',
  pt_BR: 'Endereço de e-mail',
  es_ES: 'Dirección de correo electrónico',
  de_DE: 'Email Adresse',
  fr_FR: 'Adresse e-mail',
};

export const DATEOFBIRTH_FIELD = {
  en_US: 'Date of Birth',
  it_IT: 'Data di nascita',
  pt_BR: 'Data de nascimento',
  es_ES: 'Fecha de nacimiento',
  de_DE: 'Geburtsdatum',
  fr_FR: 'Date de naissance',
};

export const GENDER_FIELD = {
  en_US: 'Gender',
  it_IT: 'Sesso',
  pt_BR: 'Gênero',
  es_ES: 'Género',
  de_DE: 'Geschlecht',
  fr_FR: 'Civilité',
};

export const LANGUAGE_FIELD = {
  en_US: 'Language',
  it_IT: 'Lingua',
  pt_BR: 'Idioma',
  es_ES: 'idioma',
  de_DE: 'Sprache',
  fr_FR: 'Langue',
};

export const COUNTRY_RESIDENCE_FIELD = {
  en_US: 'Country of Residence',
  it_IT: 'Paese di residenza',
  pt_BR: 'País de residência',
  es_ES: 'País de residencia',
  de_DE: 'Heimatland',
  fr_FR: 'Pays de résidence',
};

export const COUNTRY_CODE_FIELD = {
  en_US: 'Country Code',
  it_IT: 'Prefisso Internazionale',
  pt_BR: 'Código do país',
  es_ES: 'Código del país',
  de_DE: 'Ländesvorwahl',
  fr_FR: 'Indicatif pays',
};

export const TELEPHONE_NUMBER_FIELD = {
  en_US: 'Telephone Number',
  it_IT: 'Recapito telefonico',
  pt_BR: 'Número de telefone',
  es_ES: 'Número de teléfono',
  de_DE: 'Telefonnummer',
  fr_FR: 'Numéro de téléphone',
};

export const ACCESS_CODE_FIELD = {
  en_US: 'Access Code',
  it_IT: 'Codice di accesso',
  pt_BR: 'Código de acesso',
  es_ES: 'Código de acceso',
  de_DE: 'Zugangs Code',
  fr_FR: "Code d'accès",
};

export const PRIVACY_NOTICE_1 = {
  en_US: 'Privacy Notice.',
  it_IT: 'Privacy.',
  pt_BR: 'Aviso de Privacidade.',
  es_ES: 'Privacy Notice.',
  de_DE: 'Aviso de privacidad.',
  fr_FR: 'Politique de confidentialité.',
};

export const REGISTER_BUTTON = {
  en_US: 'Register',
  it_IT: 'REGISTRATI',
  pt_BR: 'Registrar',
  es_ES: 'Registrarse',
  de_DE: 'Registrieren',
  fr_FR: "S'ENREGISTRER",
};

export const MALE_LABEL = {
  en_US: 'Male',
  it_IT: 'Uomo',
  pt_BR: 'Masculino',
  es_ES: 'Hombre',
  de_DE: 'Männlich',
  fr_FR: 'Homme',
};

export const FEMALE_LABEL = {
  en_US: 'Female',
  it_IT: 'Donna',
  pt_BR: 'Feminino',
  es_ES: 'Mujer',
  de_DE: 'Weiblich',
  fr_FR: 'Femme',
};

export const SUBMIT_BUTTON = {
  en_US: 'SUBMIT',
  it_IT: 'INVIA',
  pt_BR: 'ENVIAR',
  es_ES: 'ENVIAR',
  de_DE: 'SENDEN',
  fr_FR: 'SOUMETTRE',
};

export const RELATION_TYPE_FIELD = {
  en_US: 'Relashionship',
  it_IT: 'Rapporto',
  pt_BR: 'Parentesco',
  es_ES: 'Relación',
  de_DE: 'Familienverhältnis',
  fr_FR: 'Lien de parenté',
};

export const ADD_NEW_BUTTON = {
  en_US: 'Add new',
  it_IT: 'Aggungi',
  pt_BR: 'Adicionar novo',
  es_ES: 'Agregar nuevo',
  de_DE: 'Hinzufügen',
  fr_FR: 'Ajouter',
};

export const REMOVE_BUTTON = {
  en_US: 'Remove',
  it_IT: 'rimuovere',
  pt_BR: 'remover',
  es_ES: 'retirar',
  de_DE: 'Abbrechen',
  fr_FR: 'Supprimer',
};

export const CONFIRMATION_TITLE = {
  es_ES: 'Confirmación',
  en_US: 'Confirmation',
  pt_BR: 'Confirmação',
  fr_FR: 'Confirmation',
  de_DE: 'Bestätigung',
  it_IT: 'Conferma',
};

export const SELECT_A_COUNTRY = {
  es_ES: 'Seleccione un país',
  en_US: 'Select a country',
  pt_BR: 'Selecione um pais',
  fr_FR: 'Choisissez un pays',
  de_DE: 'Wählen Sie ein Land aus',
  it_IT: 'Seleziona un Paese',
};
