export const INTRO = {
  es_ES: 'Introducción',
  en_US: 'Introduction',
  pt_BR: 'Introdução',
  fr_FR: 'Introduction',
  de_DE: 'Einleitung',
  it_IT: 'Introduzione',
};

export const INTRO_BODY = {
  es_ES: `La presente aplicación «Doctor Please!» (en adelante, la Aplicación») la ofrece Teladoc Health International, S.A. con oficinas en 252-260 Vía Augusta 08017 Barcelona, España (en adelante, «Teladoc» o «Nosotros»).\n
    Al utilizar esta Aplicación, así como nuestros servicios de teleconsulta (en adelante, los «Servicios»), usted (en adelante, el «Interesado», el «Paciente» o «Usted») deberá proporcionar información sobre sí mismo, incluida información sobre su estado de salud, que se considera información sensible (en conjunto, «sus Datos personales»).\n
    Este Aviso de privacidad describe cómo se pueden recopilar, utilizar y divulgar sus Datos personales y cómo puede acceder a ellos. Léalo detenidamente.\n
    En Teladoc estamos comprometidos con la protección y el respeto de su privacidad. Teladoc opera a nivel mundial y se compromete a cumplir plenamente todas las leyes y reglamentos aplicables de cualquier país o territorio, y especialmente el Reglamento General de Protección de Datos (UE) 2016/679 (en adelante, el «RGPD»).\n
    El responsable del tratamiento es Teladoc.`,
  en_US: `This “Doctor Please!” application (“**the App**”) is proposed by Teladoc Health International, SA with offices at 252-260 Via Augusta 08017 Barcelona Spain (hereinafter “Teladoc” or “We”).\n
    When using this App as well as our teleconsultation services (“the Services”), you (the “**Data Subject**”, “**the Patient**” or “**You**”) will be required to provide information about You, including information about Your health status which are considered as sensitive information (all together “Your Personal Data”).\n
    This Privacy notice describes how Your Personal Data may be collected, used, and disclosed, and how You can get access to Your Personal Data. Please review it carefully.\n
    At Teladoc We are committed to protecting and respecting Your privacy. Teladoc operates globally and is committed to full compliance with all applicable laws and regulations of any jurisdiction, and especially the General Data protection Regulation (EU) 206/679 (“the **GDPR**”).\n
    The data controller is Teladoc.`,
  pt_BR: `Este aplicativo “Doctor Please!” (“o Aplicativo”) é fornecido pela Teladoc Health International, SA com escritórios em 252-260 Via Augusta 08017 Barcelona, Espanha doravante “Teladoc” ou “Nós”). Ao usar este Aplicativo e os nossos serviços de teleconsulta (“os Serviços”), você (o “Titular dos dados”, “o Paciente” ou “Você”) será solicitado a fornecer informações sobre Você, incluindo informações sobre Seu status de saúde, que são consideradas informações sensíveis (em conjunto, “Seus Dados Pessoais”).\n
  Este Aviso de Privacidade descreve como são coletados, usados e divulgados seus dados pessoais e de que modo você pode acessar seus dados pessoais.\n
  Leia este documento com atenção.\n
  Na Teladoc, nós estamos comprometidos com a proteção e o respeito à sua privacidade. A Teladoc opera em todo o mundo e está comprometida com o cumprimento total de todas as leis e regulamentos aplicáveis de qualquer jurisdição e especialmente com o Regulamento Geral de Proteção de Dados (União Europeia) 206/679 (“o <200>GDPR</200>”).\n
  O controlador de dados é a Teladoc.`,
  fr_FR: `La présente application « Doctor Please! » («l’Application») est proposée par Teladoc Health International, SA, dont les bureaux sont sis 252-260 Via Augusta 08017 Barcelone, Espagne (ci-après « Teladoc » ou « Nous »).\n
  Lors de votre utilisation de cette Application ainsi que de nos services de téléconsultation (« les Services »), vous (la « Personne Concernée », « le Patient» ou «Vous ») devrez communiquer des informations Vous concernant, notamment des informations concernant Votre état de santé, qui sont considérées comme des informations sensibles (collectivement « Vos Données à Caractère Personnel »).\n
  La présente Déclaration de confidentialité décrit la manière dont Vos Données à Caractère Personnel pourront être collectées, utilisées et divulguées et la manière dont Vous pourrez avoir accès à Vos Données à Caractère Personnel. Veuillez la lire attentivement.\n
  Teladoc s'engage à protéger et à respecter Votre vie privée. Teladoc exerce ses activités dans le monde entier et s’engage à respecter strictement toutes les lois et réglementations applicables de toute juridiction et en particulier le Règlement Général sur la Protection des Données (UE) 206/679 (« le RGPD»).\n
  Le responsable du traitement est Teladoc.`,
  de_DE: `Diese „Doctor Please!“-App („die App“) wird von Teladoc Health International, SA mit Sitz in 252-260 Via Augusta, 08017 Barcelona, Spanien (im Folgenden „Teladoc“ oder „wir“) angeboten. Wenn Sie diese App sowie unsere Fernberatungsdienstleistungen („die Dienstleistungen“) nutzen, werden Sie (die „betroffene Person“, der Patient“ oder „Sie) aufgefordert, Informationen über sich bereitzustellen, unter anderem Informationen über Ihren Gesundheitszustand, die als sensible Daten gelten (zusammen „Ihre personenbezogenen Daten“).\n
  Diese Datenschutzerklärung beschreibt, wie Ihre personenbezogenen Daten erhoben, verwendet und weitergegeben werden können und wie Sie Zugang zu Ihren personenbezogenen Daten erhalten.Bitte lesen Sie diese Erklärung sorgfältig durch.\n
  Wir bei Teladoc legen Wert darauf, Ihre Privatsphäre zu schützen und zu wahren.\n
  Teladoc ist international tätig und verpflichtet sich zur vollständigen Einhaltung aller geltenden Gesetze und Vorschriften sämtlicher Rechtsordnungen und insbesondere der Datenschutz-Grundverordnung (EU) 206/679 („die DSGVO“).\n
  Teladoc ist der Datenverantwortliche.`,
  it_IT: `La presente app “Doctor Please!” (“l’App”) è proposta da Teladoc Health International SA, con sede in 252-260 Via Augusta 08017 Barcellona, Spagna (di seguito “Teladoc” o “Noi”).\n
  Quando si utilizza questa App e i nostri servizi di teleconsultazione (“i Servizi”), l’Interessato (“l’Interessato”, “il Paziente” o “Lei”) sarà tenuto a fornire informazioni su di sé, comprese le informazioni sullo stato di salute che sono considerate informazioni sensibili (insieme “I Dati Personali dell’Interessato”).\n
  La presente informativa sulla privacy descrive come possono essere raccolti, utilizzati e divulgati i Dati Personali dell’Interessato e come è possibile accedervi. Si prega di consultarla attentamente.\n
  Teladoc si impegna a proteggere e rispettare la sua privacy. Teladoc opera a livello globale e si impegna a rispettare appieno ogni legge e regolamento applicabile di qualsiasi giurisdizione, in particolare il Regolamento generale sulla protezione dei dati (UE) 206/679 (“il RGPD”). Il titolare del trattamento è Teladoc.`,
};

export const FUNDAMENTALS = {
  es_ES: 'Principios fundamentales',
  en_US: 'Fundamental Principles',
  pt_BR: 'Princípios fundamentais',
  fr_FR: 'Principes fondamentaux',
  de_DE: 'Grundsätze',
  it_IT: 'Principi fondamentali',
};

export const FUNDAMENTALS_BODY = {
  es_ES: `Las prácticas de privacidad de Teladoc cumplen con el RGPD, que incluye las siguientes protecciones:`,
  en_US: `Teladoc's privacy practices comply with the GDPR which includes the following protections:`,
  pt_BR: `As práticas de privacidade da Teladoc cumprem com o GDPR, que inclui as seguintes proteções:`,
  fr_FR: `Les pratiques de Teladoc en matière de protection des données sont conformes au RGPD, qui prévoit notamment les protections suivantes:`,
  de_DE: `Die Datenschutzmaßnahmen von Teladoc entsprechen der DSGVO, die die folgenden Schutzvorkehrungen vorsieht:`,
  it_IT: `Le pratiche in materia di riservatezza di Teladoc sono conformi al RGPD, che include le seguenti tutele:`,
};

export const FUNDAMENTALS_LIST = {
  es_ES: [
    `Tratar sus Datos personales de forma legítima, transparente y justa.`,
    `Limitar el uso de sus Datos personales con finalidades legítimas.`,
    `Limitar el tratamiento y almacenamiento de sus Datos personales al mínimo necesario.`,
    `Asegurarse de que el aviso de privacidad sea preciso y suficiente.`,
    `Mantener políticas de privacidad abiertas y transparentes.`,
    `Asumir la responsabilidad frente a Usted respecto al procesamiento de sus Datos personales.`,
    `Asegurarse de que su consentimiento sea informado y fácil de retirar.`,
    `Definir y proteger sus categorías de datos sensibles/especiales.`,
    `Asegurarse de que terceros (médicos externos) apliquen estándares de controles de privacidad similares o equivalentes cuando traten sus Datos personales en nuestro nombre.`,
    `No transferir sus Datos personales fuera de la UE a menos que el destinatario haya proporcionado las salvaguardas adecuadas aprobadas por el RGPD.`,
    `Ofrecerle el derecho a una información concisa, oportuna y completa con respecto a nuestro tratamiento de sus Datos personales.`,
    `Ofrecerle el derecho a rectificar datos personales incompletos, inexactos, innecesarios o excesivos.`,
    `Ofrecerle el derecho de oposición (cuando sea aplicable).`,
    `Asegurarnos de contar con los procedimientos para respaldar el ejercicio de cualquiera de los derechos de los interesados.`,
    `Aplicar medidas de seguridad, incluyendo el apoyo técnico y de procedimientos para la integridad, la confidencialidad y la disponibilidad.`,
    `Mantener la confidencialidad de sus Datos personales incluso después de que nuestra relación con Usted haya terminado.`,
  ],
  en_US: [
    `Processing Your Personal Data lawfully, transparently and fairly`,
    `Limiting Your Personal Data use to legitimate purposes`,
    `Limiting the processing and storage of Your Personal Data to the minimum necessary`,
    `Making sure that privacy notice is accurate and sufficient`,
    `Maintaining open and transparent privacy policies`,
    `Being accountable to You for processing Your Personal Data`,
    `Making sure Your consent is informed and easy to withdraw`,
    `Defining and protecting Your sensitive/special categories of data`,
    `Ensuring third parties (external doctors) apply similar or equivalent standards of privacy controls where they process Your Personal Data on our behalf`,
    `Not transferring Your Personal Data outside of the EU unless the recipient has provided appropriate safeguards approved by the GDPR.`,
    `Giving You the right to concise, timely, comprehensive information regarding our processing of Your Personal Data`,
    `Giving You the right to rectify incomplete, inaccurate, unnecessary or excessive personal data`,
    `Giving You the right to object (where applicable)`,
    `Making sure We have procedures to support Your exercising of any data subject rights`,
    `Applying security measures, including technical and procedural support for integrity, confidentiality and availability must be provided`,
    `Maintaining the confidentiality of Your Personal Data even after our relationship with You has terminated.`,
  ],
  pt_BR: [
    `Processamento dos seus dados pessoais de modo legal, transparente e justo,`,
    `Limitação do uso e das finalidades legítimas dos seus Dados Pessoais,`,
    `Limitação do processo e do armazenamento dos seus Dados Pessoais ao mínimo necessário,`,
    `Assegurar que o aviso de privacidade seja correto e suficiente,`,
    `Manter claras e transparentes as políticas de privacidade,`,
    `Prestar contas a você pelo processamento dos seus Dados Pessoais,`,
    `Fazer com que seu consentimento seja informado e de fácil retirada,`,
    `Definir e proteger suas categorias de dados sensíveis/especiais,`,
    `Assegurar que terceiros (médicos externos) utilizem normas similares ou equivalentes de controles de privacidade onde eles processarem Seus Dados Pessoais em nosso nome,`,
    `Não transferir seus Dados Pessoais para fora da União Europeia exceto se os destinatários tenham fornecido proteções adequadas aprovadas pelo GDPR,`,
    `Dar a você o direito a informações concisas, oportunas e abrangentes sobre nosso processamento dos seus Dados Pessoais,`,
    `Dar a você o direito de retificar dados pessoais incompletos, imprecisos, desnecessários ou excessivos,`,
    `Dar a você o direito à contestação (conforme aplicável),`,
    `Ter procedimentos para ajudar você a exercer quaisquer dos seus direitos de titular de dados,`,
    `Empregar medidas de segurança, incluindo suporte técnico e procedimental, para que sejam fornecidas integridade, confidencialidade e disponibilidade,`,
    `Manter a confidencialidade dos seus Dados Pessoais, mesmo depois que nosso relacionamento com você tenha terminado.`,
  ],
  fr_FR: [
    `Traiter Vos Données à Caractère Personnel de manière licite, équitable et transparente`,
    `Limiter l’utilisation de Vos Données à Caractère Personnel à des finalités légitimes`,
    `Limiter le traitement et la conservation de Vos Données à Caractère Personnel au minimum nécessaire`,
    `S’assurer que la déclaration de confidentialité est exacte et suffisante`,
    `Maintenir des politiques de protection des données ouvertes et transparentes`,
    `Assumer la responsabilité envers Vous du traitement de Vos Données à Caractère Personnel`,
    `S’assurer que Votre consentement est éclairé et peut être retiré facilement`,
    `Définir et protéger Vos données sensibles et les catégories particulières de données`,
    `S’assurer que les tiers (médecins extérieurs) qui traitent Vos Données à Caractère Personnel appliquent des normes similaires ou équivalentes en matière de contrôle de la protection des données.`,
    `Ne pas transférer Vos Données à Caractère Personnel en dehors de l’UE, à moins que le destinataire n’ait prouvé la mise en place de mesures de protection appropriées approuvées par le RGPD.`,
    `Vous accorder le droit d’obtenir des informations concises, à jour et complètes concernant le traitement de Vos Données à Caractère Personnel`,
    `Vous accorder le droit de rectifier les données à caractère personnel incomplètes, inexactes, inutiles ou excessives`,
    `Vous accorder le droit de vous opposer au traitement (le cas échéant)`,
    `S’assurer que Nous avons mis en place des procédures facilitant l’exercice de tout ou partie des droits dont Vous disposez en tant que personne concernée,`,
    `Adopter des mesures de sécurité, y compris une assistance technique et procédurale, pour garantir l’intégrité, la confidentialité et la disponibilité`,
    `Préserver la confidentialité de Vos Données à Caractère Personnel même après la fin de notre relation avec Vous.`,
  ],
  de_DE: [
    `Verarbeitung Ihrer personenbezogenen Daten auf rechtmäßige, transparente und faire Weise`,
    `Begrenzung der Nutzung Ihrer personenbezogenen Daten auf legitime Zwecke`,
    `Begrenzung der Verarbeitung und Speicherung Ihrer personenbezogenen Daten auf das erforderliche Mindestmaß`,
    `Gewährleistung der Richtigkeit und Hinlänglichkeit der Datenschutzerklärung`,
    `Beibehaltung von offenen und transparenten Datenschutzerklärungen`,
    `Rechenschaftspflicht Ihnen gegenüber für die Verarbeitung Ihrer personenbezogenen Daten`,
    `Gewährleistung, dass Sie Ihre Einwilligung in Kenntnis der Sachlage erteilen und die Einwilligung leicht widerrufbar ist`,
    `Definition und Schutz Ihrer sensiblen Daten/Ihrer besonderen Kategorien von Daten`,
    `Sicherstellung, dass Dritte (externe Ärzte) ähnliche oder gleichwertige Datenschutzstandards anwenden, wenn sie Ihre personenbezogenen Daten in unserem Auftrag verarbeiten`,
    `Keine Übermittlung Ihrer personenbezogenen Daten an Länder außerhalb der EU, es sei denn, der Empfänger hat angemessene und DSGVO-konforme Sicherheitsmaßnahmen getroffen.`,
    `Gewährung des Rechts auf präzise, zeitnahe und umfassende Auskunft über unsere Verarbeitung Ihrer personenbezogenen Daten`,
    `Gewährung des Rechts auf Berichtigung unvollständiger, falscher, nicht notwendiger oder zu umfassender personenbezogener Daten`,
    `Gewährung des Widerspruchsrechts (sofern anwendbar)`,
    `Einrichtung von hinreichenden Verfahren, damit Sie Ihre Rechte als betroffene Person ausüben können,`,
    `Anwendung von Sicherheitsmaßnahmen; unter anderem sind technische und administrative Unterstützung für die Integrität, die Vertraulichkeit und die Verfügbarkeit bereitzustellen`,
    `Kontinuierliche Gewährleistung der Vertraulichkeit Ihrer personenbezogenen Daten, auch nach Beendigung der Kundenbeziehung.`,
  ],
  it_IT: [
    `Trattamento legale, trasparente ed equo dei Dati Personali dell’Interessato`,
    `Limitazione dell’uso dei Dati Personali dell’Interessato per scopi legittimi`,
    `Limitazione del trattamento e dell’archiviazione dei Dati Personali dell’Interessato al minimo necessario`,
    `Verifica che l'informativa sulla privacy sia accurata e sufficiente`,
    `Definizioni aperte e trasparenti in materia di privacy`,
    `Responsabilità nei confronti dell’Interessato per il trattamento dei suoi Dati Personali`,
    `Verifica che il consenso dell’Interessato sia informato e facile da revocare`,
    `Definizione e protezione delle categorie sensibili/particolari di dati dell’Interessato`,
    `Garanzia che i terzi (medici esterni) applichino standard simili o equivalenti di controllo della privacy laddove trattino i Dati Personali dell’Interessato per nostro conto`,
    `Nessun trasferimento dei Dati Personali dell’Interessato al di fuori dell’UE a meno che il destinatario abbia fornito salvaguardie adeguate approvate dal RGPD.`,
    `L’Interessato ha il diritto di ricevere informazioni concise, tempestive e complete sul nostro trattamento dei suoi Dati Personali`,
    `L’Interessato ha il diritto di rettifica dei dati personali incompleti, inesatti, non necessari o eccessivi`,
    `L’Interessato ha il diritto di opposizione (ove applicabile)`,
    `Garanzia di disporre di procedure a sostegno dell’esercizio dei diritti degli interessati`,
    `Applicazione di misure di sicurezza, compreso il supporto tecnico e procedurale per l'integrità, la riservatezza e la disponibilità`,
    `Mantenimento della riservatezza dei Dati Personali dell’Interessato anche dopo il temine del rapporto con lo stesso.`,
  ],
};

export const PERSONAL_DATA = {
  es_ES: '¿Qué Datos personales se recopilan?',
  en_US: 'What Personal Data is collected?',
  pt_BR: 'Quais dados pessoais são coletados?',
  fr_FR: 'Quelles sont les Données à Caractère Personnel collectées?',
  de_DE: 'Welche personenbezogenen Daten werden erhoben?',
  it_IT: 'Quali Dati Personali vengono raccolti?',
};

export const PERSONAL_DATA_BODY = {
  es_ES: `«Datos personales» hace referencia a cualquier información relativa a una persona física identificada o identificable (en adelante, el «Interesado»); una persona física identificable es aquella que puede ser identificada, directa o indirectamente, en particular mediante un elemento identificador como un nombre, un número de identificación, datos de localización, un identificador online o uno o varios factores específicos de identidad física, fisiológica, genética, mental, económica, cultural o social de dicha persona física.\n
    «Datos relativos a la salud» o «Datos de salud» hace referencia a los Datos personales relacionados con la salud física o mental de una persona física, incluida la prestación de servicios de atención sanitaria, que revelen información sobre su estado de salud.\n
    Teladoc solo recopila Datos personales que sean pertinentes y necesarios para la prestación de los Servicios, incluido el funcionamiento del software.`,
  en_US: `“Personal Data” means any information relating to an identified or identifiable natural person (“data subject”); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.\n
    “Data concerning health” or “Health Data” means Personal Data related to the physical or mental health of a natural person, including the provision of health care services, which reveal information about his or her health status.\n
    Teladoc collects only Personal Data which is relevant and necessary for the provision of the Services including the operation of the software.`,
  pt_BR: `“Dados Pessoais” significa qualquer informação relacionada a uma pessoa física identificada ou identificável (“titular de dados”). Uma pessoa física é alguém que pode ser identificado, direta ou indiretamente, em especial por referência a um identificador como nome, número de identificação, dados de local, identificador on-line ou um ou mais fatores específicos de identidade física, fisiológica, genética, mental, econômica, cultural ou social dessa pessoa física.\n
    “Dados Relacionados à Saúde” ou “Dados de Saúde” referem-se a Dados Pessoais relacionados à saúde física ou mental da pessoa física, incluindo as prestações de serviços de assistência médica, que revelam informações sobre o status de saúde da pessoa.\n
    A Teladoc coleta somente Dados Pessoais que sejam relevantes e necessários para a prestação de Serviços, incluindo a operação do software.`,
  fr_FR: `« Données à Caractère Personnel » désigne toute information se rapportant à une personne physique identifiée ou identifiable (la « personne concernée ») ; une personne physique identifiable est une personne physique qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant, tel qu’un nom, un numéro d’identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale.\n
    « Données concernant la santé » ou « Données Médicales » désigne les Données à Caractère Personnel relatives à la santé physique ou mentale d’une personne physique, y compris la prestation de services de soins de santé, qui révèlent des informations sur l’état de santé de cette personne.\n
    Teladoc collecte uniquement des Données à Caractère Personnel qui sont pertinentes et nécessaires pour la prestation des Services, y compris l’utilisation du logiciel.`,
  de_DE: `„Personenbezogene Daten“ sind jegliche Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person („betroffene Person“) beziehen; eine identifizierbare natürliche Person ist eine Person, die direkt oder indirekt, insbesondere durch Bezugnahme auf eine Kennung wie einen Namen, eine Kennnummer, Standortdaten, eine Online-Kennung oder auf eines oder mehrere Merkmale, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.\n
    „Gesundheitsbezogene Daten“ oder „Gesundheitsdaten“ sind personenbezogene Daten, die die physische oder geistige Gesundheit einer natürlichen Person betreffen (einschließlich der Bereitstellung von Gesundheitsdiensten), die Informationen über den Gesundheitszustand der Person preisgeben.\n
    Teladoc erhebt nur personenbezogene Daten, die für die Bereitstellung der Dienstleistungen, einschließlich des Betriebs der Software, relevant und erforderlich sind.`,
  it_IT: `“Dato Personale”: qualsiasi informazione riguardante una persona fisica identificata o identificabile (“interessato”); si considera identificabile la persona fisica che può essere identificata, direttamente o indirettamente, con particolare riferimento a un identificativo come il nome, un numero di identificazione, dati relativi all'ubicazione, un identificativo online o a uno o più elementi caratteristici della sua identità fisica, fisiologica, genetica, psichica, economica, culturale o sociale.\n
    “Dati relativi alla salute” o “Dati Sanitari” indicano i Dati Personali attinenti alla salute fisica o mentale di una persona fisica, comprese le prestazion di servizi di assistenza sanitaria, che rivelano informazioni relative al suo stato di salute.\n
    Teladoc raccoglie solo Dati Personali rilevanti e necessari per la fornitura dei Servizi, inclusa la gestione del software.`,
};

export const PERSONAL_DATA_ACCESS = {
  es_ES:
    'Datos personales necesarios para acceder y registrarse en Doctor Please!',
  en_US: 'Personal data necessary to access and register to Doctor Please!',
  pt_BR: 'Dados pessoais necessários para acesso e registro no Doctor Please!',
  fr_FR:
    'Données à caractère personnel nécessaires pour s’inscrire sur Doctor Please! et y accéder',
  de_DE:
    'Personenbezogene Daten, die für den Zugriff auf bzw. die Registrierung bei Doctor Please! erforderlich sind',
  it_IT: 'Dati Personali necessari per accedere e registrarsi a Doctor Please!',
};

export const PERSONAL_DATA_ACCESS_BODY = {
  es_ES: `Cuando accede y se registra en Doctor Please!, recopilamos información de identificación y de contacto sobre Usted (nombre, apellidos, dirección de correo electrónico, fecha de nacimiento, sexo, país de residencia, número de teléfono, ubicación y su contraseña).\n
    Si no facilita esta información, no podrá acceder a la Aplicación y no podremos proporcionarle nuestros Servicios a través de la Aplicación.`,
  en_US: `When You access and register to Doctor Please!, We collect identification and contact information about You (first name, last name, e-mail address, date of birth, gender, country of residence, telephone number, location, and your password).\n
    Should You not provide this information, You will not be able to access the App and We will not be able to provide You our Services via the App.`,
  pt_BR: `Quando você acessa e se registra no Doctor Please!, coletamos informações de identificação e contato sobre você (nome, sobrenome, endereço de e-mail, data de nascimento, gênero, país de residência, número de telefone, localização e senha).\n
    Se não nos fornecer essas informações, você não poderá acessar o Aplicativo e nós não poderemos prestar a você nossos Serviços pelo Aplicativo.`,
  fr_FR: `Lorsque Vous-vous inscrivez sur Doctor Please! et y accédez, Nous recueillons des informations d’identification et de contact Vous concernant (prénom, nom de famille, adresse e-mail, date de naissance, sexe, pays de résidence, numéro de téléphone, lieu et mot de passe).\n
    Si Vous ne transmettez pas ces informations, Vous ne pourrez pas accéder à l’Application et Nous ne serons pas en mesure de Vous fournir nos Services via l’Application.`,
  de_DE: `Wenn Sie auf Doctor Please! zugreifen und sich registrieren, erheben wir Identifikations- und Kontaktdaten von Ihnen (Vorname, Nachname, E-Mail-Adresse, Geburtsdatum, Geschlecht, Wohnsitzland, Telefonnummer, Ort und Ihr Passwort).\n
    Wenn Sie diese Daten nicht angeben, können Sie nicht auf die App zugreifen, und wir können Ihnen unsere Dienstleistungen möglicherweise nicht über die App bereitstellen.`,
  it_IT: `Quando l’Interessato accede e si registra a Doctor Please!, raccogliamo informazioni di identificazione e contatto che lo riguardano (nome, cognome, indirizzo e-mail, data di nascita, sesso, Paese di residenza, numero di telefono, ubicazione e password).\n
  Se l’Interessato non fornisce queste informazioni non può accedere all’App e non saremo in grado di fornirgli i nostri Servizi tramite l'App.`,
};

export const PERSONAL_DATA_NECESSARY = {
  es_ES: `Datos personales necesarios para el uso de determinadas funcionalidades de Doctor Please!`,
  en_US: `Personal Data necessary for the use of certain functionalities of Doctor Please!`,
  pt_BR: `Dados pessoais necessários para uso de certas funcionalidades do Doctor Please!`,
  fr_FR: `Données à Caractère Personnel nécessaires à l’utilisation de certaines fonctionnalités de Doctor Please!`,
  de_DE: `Personenbezogene Daten, die für die Nutzung bestimmter Funktionen von Doctor Please! erforderlich sind`,
  it_IT: `Dati Personali necessari per l’utilizzo di alcune funzionalità di Doctor Please!`,
};

export const PERSONAL_DATA_NECESSARY_BODY = {
  es_ES: `Para permitirle disfrutar de determinadas funcionalidades de la Aplicación, podemos ofrecerle que nos envíe determinada información, como su geolocalización.\n
  La comunicación de su geolocalización no es obligatoria, pero si no nos la facilita, no podrá acceder a algunos de nuestros Servicios.`,
  en_US: `To allow You to benefit from certain functionalities of the App, we may offer You to send us certain information such as Your geolocation.\n
    The communication of Your geolocation is not compulsory, but if You do not transmit it to us some of our services will not be accessible to You.`,
  pt_BR: `Para que você se beneficie de certas funcionalidades do Aplicativo, podemos pedir a você que nos envie certas informações como sua geolocalização.\n
    A comunicação da sua geolocalização não é obrigatória, mas se você não nos informar isso, alguns dos nossos serviços não estarão acessíveis a você.`,
  fr_FR: `Pour Vous permettre de bénéficier de certaines fonctionnalités de l’Application, nous pourrons Vous proposer de nous envoyer certaines informations telles que vos données de géolocalisation.\n
    La communication de Votre géolocalisation n’est pas obligatoire, mais si Vous ne nous la transmettez pas, certains de nos services ne Vous seront pas accessibles.`,
  de_DE: `Damit Sie bestimmte Funktionen der App nutzen können, fordern wir Sie möglicherweise auf, uns bestimmte Informationen wie Ihren aktuellen Standort („Geolocation“) zu übermitteln.\n
    Sie müssen uns Ihren aktuellen Standort nicht übermitteln, aber wenn Sie dies nicht tun, sind einige unserer Dienste nicht für Sie verfügbar.`,
  it_IT: `Per consentire all’Interessato di beneficiare di alcune funzionalità dell’App, potremmo proporgli di inviarci alcune informazioni, quali la sua geolocalizzazione.\n
    La comunicazione della geolocalizzazione non è obbligatoria, ma se l’Interessato non ce la trasmette, alcuni dei nostri servizi non gli saranno accessibili.`,
};

export const PERSONAL_DATA_INHERENT = {
  es_ES: `Datos personales necesarios e inherentes al uso del servicio`,
  en_US: `Personal Data necessary and inherent to the use of the service`,
  pt_BR: `Dados pessoais necessários e inerentes ao uso do serviço`,
  fr_FR: `Données à Caractère Personnel nécessaires et inhérentes à l’utilisation du service`,
  de_DE: `Personenbezogene Daten, die für die Nutzung des Dienstes erforderlich und damit verbunden sind`,
  it_IT: `Dati Personali necessari e inerenti all'utilizzo del servizio`,
};

export const PERSONAL_DATA_INHERENT_BODY = {
  es_ES: `Además, recopilaremos, trataremos y potencialmente revelaremos la información necesaria e inherente a la ejecución de los Servicios, a saber:`,
  en_US: `In addition, We will collect, process and potentially disclose information that is necessary and inherent to the execution of the Services, namely:`,
  pt_BR: `Além disso, nós coletaremos, processaremos e possivelmente divulgaremos informações que sejam necessárias e inerentes à execução dos Serviços, especificamente:`,
  fr_FR: `Par ailleurs, Nous collecterons, traiterons et divulguerons éventuellement des informations nécessaires et inhérentes à l’exécution des Services, à savoir:`,
  de_DE: `Darüber hinaus werden wir Informationen erheben, verarbeiten und gegebenenfalls übermitteln, die für die Durchführung der Dienstleistungen erforderlich und damit verbunden sind. Dazu gehören:`,
  it_IT: `Inoltre, raccoglieremo, elaboreremo e potenzialmente divulgheremo le informazioni necessarie e inerenti all'esecuzione dei Servizi, vale a dire:`,
};

export const PERSONAL_DATA_INHERENT_LIST = {
  es_ES: [
    `Datos relativos a su salud.`,
    `Su ubicación.`,
    `Todas las comunicaciones entre Usted y Teladoc para la prestación del servicio de teleconsulta.`,
    `Datos relacionados con su ubicación (y más específicamente su geolocalización cuando nos haya facilitado su consentimiento para que recopilemos y tratemos esta información).`,
  ],
  en_US: [
    `Data concerning Your health`,
    `Your location`,
    `All of the exchanges between You and Teladoc for the provision of the teleconsultation service to You`,
    `Data related to Your location (and more particularly Your geolocation when You have accepted that We collect and process this information)`,
  ],
  pt_BR: [
    `Dados relativos à sua saúde,`,
    `Sua localização,`,
    `Todas as informações trocadas entre você e a Teladoc para prestação do serviço de teleconsulta a você,`,
    `Dados relacionados à sua saúde (e mais especificamente sua geolocalização quando você tiver aceitado nossa coleta e processamento dessas informações).`,
  ],
  fr_FR: [
    `Données concernant Votre santé,`,
    `Votre localisation`,
    `Tous les échanges entre Vous et Teladoc dans le cadre de la prestation du service de téléconsultation.`,
    `Données relatives à Votre localisation (et plus particulièrement Votre géolocalisation si Vous avez accepté que Nous collections et traitions cette information).`,
  ],
  de_DE: [
    `Gesundheitsbezogene Daten;`,
    `Ihr Standort;`,
    `Sämtliche Kommunikation zwischen Ihnen und Teladoc zur Bereitstellung der Fernberatungsdienstleistungen;`,
    `Standortbezogene Daten (und insbesondere Ihr aktueller Standort, wenn Sie eingewilligt haben, dass wir diese Daten erheben und verarbeiten dürfen).`,
  ],
  it_IT: [
    `Dati relativi alla salute dell’Interessato,`,
    `L’ubicazione dell’Interessato,`,
    `Tutte le comunicazioni tra l’Interessato e Teladoc per la fornitura del servizio di teleconsulenza all’Interessato stesso,`,
    `Dati relativi all’ubicazione dell’Interessato (e più in particolare la sua geolocalizzazione quando l’Interessato ha accettato la nostra raccolta ed elaborazione di queste informazioni).`,
  ],
};

export const PURPOSES = {
  es_ES: `Fines para los que se procesan sus datos personales y base legal`,
  en_US: `Purposes for which your personal data is processed and legal basis`,
  pt_BR: `Finalidades para as quais seus dados pessoais são processados e base legal`,
  fr_FR: `Finalités pour lesquelles vos données à caractère personnel sont traitées et fondement juridique`,
  de_DE: `Zwecke der Verarbeitung Ihrer personenbezogenen Daten und Rechtsgrundlage`,
  it_IT: `Finalità per le quali sono trattati i Dati Personali dell’Interessato e base giuridica`,
};

export const PURPOSES_BODY = {
  es_ES: `Teladoc recopila, procesa y potencialmente revela sus Datos personales como se indica a continuación con el propósito de prestarle los Servicios sujetos a su consentimiento informado (y consentimiento explícito con respecto a los Datos de salud). Si no facilita ese consentimiento, Teladoc no tendrá derecho a prestar los Servicios.\n
    Teladoc puede generar información desprovista de elementos identificativos, esto es, datos que no incluyen su nombre, dirección, fecha de nacimiento u otra información que pueda utilizarse para identificarle (en adelante, los «Datos anonimizados») a efectos de:`,
  en_US: `Teladoc collects, processes and potentially discloses Your Personal Data as listed below for the purposes of providing You the Services subject to Your informed consent (and explicit consent with regards to Health Data).Shall You not provide that consent, Teladoc will not be entitled to provide the Services.\n
    Teladoc may create de-identified information defined as data that does not include Your name, address, birth date, or other information that could be used to identify You (the “Anonymized data”) for the purposes of:`,
  pt_BR: `A Teladoc coleta, processa e potencialmente divulga seus Dados Pessoais conforme listado abaixo para fornecer a você os Serviços sujeitos aos seu consentimento informado (e consentimento explícito com relação aos Dados de Saúde). Se você não der esse consentimento, a Teladoc não terá o direito de prestar os Serviços.\n
    A Teladoc pode criar informações sem identificação definidas como dados que não incluem seu nome, endereço, data de nascimento ou outras informações que podem ser usadas para identificar Você (os “Dados anônimos”) para a finalidade de:`,
  fr_FR: `Teladoc collecte, traite et peut être amené à divulguer Vos Données à Caractère Personnel comme indiqué ci-dessous aux fins de Vous fournir les Services, sous réserve de Votre consentement éclairé (et de Votre consentement explicite pour ce qui est des Données Médicales).\n
    Si Vous ne donnez pas ce consentement, Teladoc ne sera pas en droit de fournir les Services.\n
    Teladoc pourra générer des informations dépersonnalisées, c’est-à-dire des données n’incluant pas Vos nom, adresse et date de naissance ni aucune autre information susceptible d’être utilisée pour Vous identifier (les «Données anonymisées») pour :`,
  de_DE: `Teladoc erhebt, verarbeitet und übermittelt gegebenenfalls wie unten aufgeführt Ihre personenbezogenen Daten zum Zweck der Bereitstellung der Dienstleistungen, für die Sie in Kenntnis der Sachlage Ihre Einwilligung erteilt haben (sowie Ihre ausdrückliche Einwilligung in Bezug auf Gesundheitsdaten).\n
    Sollten Sie Ihre Einwilligung nicht erteilen, darf Teladoc die Dienstleistungen nicht erbringen.\n
    Teladoc kann „entidentifizierte Daten“, also Daten, die nicht Ihren Namen, Ihre Adresse, Ihr Geburtsdatum oder andere Daten enthalten, die eine Identifizierung ermöglichen könnten („anonymisierte Daten“), für die folgenden Zwecke erzeugen:`,
  it_IT: `Teladoc raccoglie, elabora e potenzialmente divulga i Dati Personali dell’Interessato come di seguito indicato ai fini della fornitura dei Servizi oggetto del consenso informato (e consenso esplicito in relazione ai Dati Sanitari). Se l’Interessato non fornisce il consenso, Teladoc non ha diritto di fornire i Servizi.\n
    Teladoc potrebbe creare informazioni anonimizzate, ossia dati che non comprendono nome, indirizzo, data di nascita o altre informazioni che potrebbero essere utilizzate per identificare l’Interessato (i “Dati Anonimizzati”) al fine di:`,
};

export const PURPOSES_LIST = {
  es_ES: [
    `Revisar o evaluar el rendimiento de nuestros sistemas en la prestación de los Servicios.`,
    `Mejorar la calidad o la puntualidad de nuestros Servicios.`,
    `Investigación médica.`,
    `Demostrar la fiabilidad de nuestra gestión de la información.`,
  ],
  en_US: [
    `Reviewing or evaluating the performance of our systems in providing the Services`,
    `Improving the quality or timeliness of our Services`,
    `Medical research`,
    `Demonstration of the reliability of our information management`,
  ],
  pt_BR: [
    `Revisar ou avaliar o desempenho dos nossos sistemas na prestação de Serviço,`,
    `Melhorar a qualidade ou conveniência dos nossos Serviços,`,
    `Pesquisa médica,`,
    `Demonstração da confiabilidade da nossa gestão de informações.`,
  ],
  fr_FR: [
    `Examiner ou évaluer la performance de nos systèmes dans le cadre de la prestation des Services`,
    `Améliorer la qualité ou la rapidité de nos Services`,
    `Recherche médicale`,
    `Démonstration de la fiabilité de notre gestion des informations.`,
  ],
  de_DE: [
    `Überprüfung oder Bewertung der Leistungsfähigkeit unserer Systeme in Bezug auf die Bereitstellung der Dienstleistungen;`,
    `Verbesserung der Qualität und rechtzeitige Bereitstellung unserer Dienstleistungen;`,
    `Medizinische Forschung;`,
    `Nachweis der Verlässlichkeit unseres Informationsmanagements.`,
  ],
  it_IT: [
    `Rivedere o valutare le prestazioni dei nostri sistemi nella fornitura dei Servizi;`,
    `Migliorare la qualità o la tempestività dei nostri Servizi;`,
    `Ricerca medica;`,
    `Dimostrazione dell’affidabilità della nostra gestione delle informazioni.`,
  ],
};

export const DATA_RETENTION = {
  es_ES: `Conservación de los datos`,
  en_US: `Data Retention`,
  pt_BR: `Retenção de dados`,
  fr_FR: `Conservation des données`,
  de_DE: `Speicherung von Daten`,
  it_IT: `Conservazione dei dati`,
};

export const DATA_RETENTION_BODY = {
  es_ES: `Teladoc conservará sus Datos personales durante el tiempo que sea necesario para la prestación de los Servicios y, tras ello, durante los plazos legales exclusivamente a efectos de atender las posibles responsabilidades que puedan derivarse de las prestaciones de los Servicios.\n
    Al final de ese periodo de conservación, sus Datos personales se destruyen de forma segura o se anonimizan permanentemente de conformidad con las leyes y reglamentos de protección de datos. Esos datos anonimizados permanentemente dejan de ser Datos personales y Teladoc los conserva indefinidamente para las finalidades establecidas en la cláusula 4 anterior.`,
  en_US: `Teladoc will retain Your Personal Data as long as it is needed for the provision of the Services to You and after that, for the statutory periods for the only purpose of attending eventual responsibilities that might arise from the provisions of the Services\n
    At the end of that retention period, Your Personal Data is securely destroyed or permanently de-identified in accordance with Data Protection Laws and Regulations. Such permanently anonymized data is no longer Personal Data and is retained by Teladoc indefinitely for the purposes set out in clause 4, above.`,
  pt_BR: `A Teladoc reterá seus Dados Pessoais pelo tempo que for necessário para prestação de Serviços a você e, depois disso, pelos períodos legais somente para a finalidade de cumprimento de eventuais responsabilidades que podem surgir decorrentes da prestação de Serviços.\n
    Ao fim do período de retenção, seus Dados Pessoais serão destruídos de modo seguro ou serão tornados anônimos de modo permanente, de acordo com as Leis e Regulamentos de Proteção a Dados. Esses dados tornados anônimos permanentemente não são mais Dados Pessoais e são retidos indefinidamente pela Teladoc para as finalidades descritas na cláusula 4 acima.`,
  fr_FR: `Teladoc conservera Vos Données à Caractère Personnel tant qu’elles seront nécessaires pour Vous fournir les Services, puis pendant la période de conservation légale, exclusivement pour assumer les éventuelles responsabilités qui pourraient résulter de la prestation des Services.\n
    À l’expiration de cette période de conservation, Vos Données à Caractère Personnel seront détruites de manière sécurisée ou dépersonnalisées de manière permanente conformément aux lois et réglementations applicables en matière de protection des données.\n
    Les données ainsi anonymisées de manière permanente ne constitueront plus des Données à Caractère Personnel et seront conservées indéfiniment par Teladoc pour les finalités décrites à l’article 4, ci-dessus.`,
  de_DE: `Teladoc speichert Ihre Daten so lange, wie es für die Bereitstellung der Dienstleistungen an Sie erforderlich ist und anschließend für die Dauer der gesetzlichen Fristen, und zwar ausschließlich zum Zweck der Erfüllung etwaiger Verpflichtungen, die sich aus der Bereitstellung der Dienstleistungen ergeben könnten.\n
    Nach Ablauf dieses Speicherzeitraums werden Ihre personenbezogenen Daten sicher gelöscht oder dauerhaft entidentifiziert, jeweils im Einklang mit den Datenschutzgesetzen und -vorschriften. Solche dauerhaft anonymisierten Daten stellen keine personenbezogenen Daten mehr dar und werden von Teladoc auf unbestimmte Zeit für die in Abschnitt 4 beschriebenen Zwecke aufbewahrt.`,
  it_IT: `Teladoc conserverà i Dati Personali dell’Interessato per tutto il tempo necessario a fornire i Servizi all’Interessato stesso; successivamente li conserverà per i periodi previsti dalla legge, al solo fine di onorare eventuali responsabilità derivanti dalle prestazioni dei Servizi.\n
  Al termine di tale periodo di conservazione, i Dati Personali dell’Interessato sono distrutti in modo sicuro o anonimizzati permanentemente in conformità alle leggi e ai regolamenti in materia di protezione dei dati.\n
  Tali dati permanentemente anonimizzati non sono più Dati Personali e sono conservati da Teladoc a tempo indeterminato per le finalità di cui alla clausola 4 precedente.`,
};

export const ACCESS_PERSONAL_DATA = {
  es_ES: `Acceso a sus Datos personales`,
  en_US: `Access to Your Personal Data`,
  pt_BR: `Acesso aos seus Dados Pessoais`,
  fr_FR: `Accès à Vos Données à Caractère Personnel`,
  de_DE: `Zugang zu Ihren personenbezogenen Daten`,
  it_IT: `Accesso ai Dati Personali dell’Interessato`,
};

export const ACCESS_PERSONAL_DATA_BODY = {
  es_ES: `Nunca compartiremos sus datos personales para ningún fin que no sea estrictamente necesario para prestar los Servicios en su beneficio.\n
    Por esa razón, los médicos contratados por Teladoc podrán consultar y recibir sus Datos personales.\n
    Nuestra empresa de alojamiento también puede consultar sus Datos personales.\n
    Ponemos en su conocimiento que cualquier organismo público debidamente facultado o tribunal de cualquier país de ciudadanía de nuestros pacientes puede exigir a Teladoc la revelación de sus Datos personales, incluidos sus Datos de salud.`,
  en_US: `We will never share Your Personal Data for any purpose other than those strictly necessary for rendering the Services for Your benefit.\n
    For that reasons Your Personal Data will be accessed by or transferred to our doctors with whom Teladoc has contracted.\n
    Your Personal Data may also be accessed by our hosting company.\n
    You must be informed that Teladoc may be called upon to disclose Your Persona Data, including Your Health Data, by a duly empowered branch of Government or Court in any country in which our patients are citizen.`,
  pt_BR: `Nunca compartilharemos seus Dados Pessoais para qualquer finalidade além daquelas estritamente necessárias para prestação de Serviços em seu benefício.\n
    Por esses motivos, Seus Dados Pessoais serão acessados ou transferidos aos médicos com os quais a Teladoc tenha firmado um contrato.\n
    Seus Dados Pessoais também podem ser acessados pela nossa empresa de hospedagem de dados.\n
    Você deve ser informado que a Teladoc pode ser solicitada a divulgar seus Dados Pessoais, incluindo seus Dados de Saúde, a uma agência devidamente capacitada do Governo ou da Justiça em qualquer país em que nossos pacientes sejam cidadãos.`,
  fr_FR: `Nous ne transmettrons jamais Vos Données à Caractère Personnel à quelque fin que ce soit, à l’exception de celles strictement nécessaires à la prestation des Services en votre faveur.\n
    Aux fins de la prestation des Services, Vos Données à Caractère Personnel seront accessibles et transférées aux médecins avec lesquels Teladoc a conclu un contrat.\n
    Notre société d’hébergement aura également accès à Vos Données à Caractère Personnel.\n
    Veuillez noter que toute autorité gouvernementale dûment habilitée ou tout tribunal de tout pays dont nos patients sont des ressortissants pourra imposer à Teladoc de divulguer Vos Données à Caractère Personnel, y compris vos Données Médicales.`,
  de_DE: `Wir geben Ihre personenbezogenen Daten nur für Zwecke weiter, die für die Bereitstellung der Dienstleistungen zu Ihrem Nutzen unbedingt erforderlich sind.\n
    Hierfür gewähren wir unseren Ärzten, mit denen Teladoc ein Vertragsverhältnis hat, Zugriff auf Ihre personenbezogenen Daten oder übermitteln diese Daten an sie.\n
    Unserem Hosting-Unternehmen können wir ebenfalls Zugriff auf Ihre personenbezogenen Daten gewähren.\n
    Bitte beachten Sie, dass Teladoc von einer zuständigen Regierungsstelle oder einem Gericht eines Landes, in dem unsere Patienten Staatsangehörige sind, zur Offenlegung Ihrer personenbezogenen Daten, einschließlich Ihrer Gesundheitsdaten, verpflichtet werden kann.`,
  it_IT: `Non condivideremo mai i Dati Personali dell’Interessato per scopi diversi da quelli strettament necessari per la prestazione dei Servizi a vantaggio dell’Interessato.\n
    Per questo motivo, i Dati Personali dell’Interessato saranno accessibili o trasferiti ai medici di cui si avvale Teladoc.\n
    I Dati Personali dell’Interessato possono anche essere accessibili dalla nostra società di hosting.\n
    L’Interessato deve essere informato che Teladoc potrebbe dover divulgare i suoi Dati Personali, inclusi i Dati Sanitari, su richiesta di un’autorità amministrativa debitamente autorizzata o di un’autorità giurisdizionale dei Paesi di cui i pazienti sono cittadini.`,
};

export const INTERNATIONAL_TRANSFERS = {
  es_ES: `Transferencias internacionales de sus Datos personales`,
  en_US: `International Transfers of Your Personal Data`,
  pt_BR: `Transferências internas dos seus Dados Pessoais`,
  fr_FR: `Transferts internationaux de Vos Données à Caractère Personnel`,
  de_DE: `Übermittlung Ihrer personenbezogenen Daten ins Ausland`,
  it_IT: `Trasferimenti internazionali dei Dati Personali dell’Interessato`,
};

export const INTERNATIONAL_TRANSFERS_BODY = {
  es_ES: `Para proteger mejor sus datos personales, Teladoc utiliza centros de datos con sede en la UE.\n
    Dado que es necesario para la ejecución de los Servicios requeridos,  es probable que el médico se encuentre en los países donde viaja durante su consulta. El médico tendrá acceso desde su país a sus datos personales almacenados en nuestro centro de tratamiento situado en la Unión Europea. Esto significa que, en algunos casos, dependiendo de la ubicación del médico, sus datos personales se pueden consultar fuera de la Unión Europea. Se pone en su conocimiento, y Usted acepta, que el reglamento local de protección de datos aplicable al tratamiento de sus Datos personales en ese país puede no presentar un nivel de protección similar al otorgado por el reglamento aplicable en el país del que procede, incluido el RGPD.\n
    No obstante, insistimos en que los médicos que contratamos para prestar servicios de apoyo a Teladoc cumplen nuestra política y principios de privacidad, así como todas las leyes y reglamentos de protección de datos aplicables a nivel mundial.`,
  en_US: `In order to better protect Your Personal Data, Teladoc uses data centers based in EU.\n
    As this is necessary for the performance of the Services required, it is likely that the doctors will be located in the country or the region where You are located (while travelling) at the time of the consultation. Those doctors will accordingly be able to access to Your Personal Data from their location(s), via our data center in the EU.  This means that in some cases, (depending on Your location) Your Personal Data will be accessible from locations outside of the EU. You are informed and accept that the local data protection regulation applicable to the processing of Your Personal Data in that country may not present a level of protection similar to that granted by the regulation applicable in the country where You are from, including the GDPR.\n
    Nevertheless, We insist that doctors that We retain to provide support services to Teladoc adhere to our Privacy Policy and Principles as well as all globally applicable Data Protection Laws and Regulations.`,
  pt_BR: `Para melhor proteger seus dados pessoais, a Teladoc usa centros de dados baseados na UE.\n
  Como este procedimento é necessário, é provável que o médico esteja localizado nos países onde viaja durante a sua consulta. O médico terá então acesso, a partir do seu país, aos seus dados pessoais registados no nosso centro de dados situado na União Europeia. Isto significa que, em alguns casos, dependendo da localização do médico, os seus dados pessoais podem ser consultados fora da União Europeia. Você está ciente e aceita que o regulamento local de proteção de dados aplicável ao processamento dos seus Dados Pessoais nesse país pode não ter o nível de proteção semelhante ao concedido pelo regulamento aplicável no país de onde você é, incluindo o GDPR.\n
  Mesmo assim, insistimos com os médicos que nós contratamos para prestar serviços de suporte à Teladoc que cumpram com nossa Política e Princípios de Privacidade e com os Regulamentos e Leis de Proteção de Dados aplicáveis.`,
  fr_FR: `Pour mieux protéger vos données personnelles, Teladoc utilise des centres de données basés dans l’UE.\n
  Si cela s’avère nécessaire à l’exécution des Services requis, il est probable que le médecin soit situé dans les pays ou vous voyagez lors de votre consultation. Le médecin aura alors accès depuis son pays à vos données personnelles enregistrées dans notre centre de données situé dans l’Union Européenne. Cela signifie que, dans certains cas, selon la localisation du médecin, vos données personnelles sont consultables en dehors de l’Union Européenne. Vous êtes informé et acceptez que la réglementation locale en matière de protection des données applicable au traitement de Vos Données à Caractère Personnel dans ce pays ne garantisse pas nécessairement un niveau de protection similaire à celui garanti par la réglementation applicable dans le pays dans lequel Vous résidez, y compris le RGPD.\n 
  Cependant, Nous Vous assurons que les médecins auxquels Nous faisons appel pour fournir des services d’assistance à Teladoc respectent notre Politique et nos Principes en matière de protection des données ainsi que les lois et réglementations applicables en matière de protection des données dans le monde entier.`,
  de_DE: `Um Ihre persönlichen Daten besser zu schützen, nutzt Teladoc EU-Datenzentren.\n
  Wenn dies für die Durchführung der erforderlichen Dienstleistungen erforderlich ist, ist es wahrscheinlich, dass der Arzt in den Ländern ansässig ist, in die Sie bei Ihrer Konsultation reisen. Der Arzt erhält dann von seinem Land aus Zugang zu Ihren persönlichen Daten, die in unserem Datenzentrum in der Europäischen Union gespeichert sind. Das bedeutet, dass in einigen Fällen, je nach Aufenthaltsort des Arztes, Ihre persönlichen Daten außerhalb der Europäischen Union abrufbar sind. . Sie werden hiermit darüber informiert und akzeptieren, dass die örtlichen Datenschutzvorschriften, die in diesem Land für die Verarbeitung Ihrer personenbezogenen Daten gelten, möglicherweise nicht ein ähnlich hohes Schutzniveau gewährleisten wie die Vorschriften in Ihrem Herkunftsland, einschließlich der DSGVO.\n
  Dennoch verpflichten wir die Ärzte, die in unserem Auftrag Unterstützungsleistungen für Teladoc erbringen, sich an unsere Datenschutzrichtlinien und -grundsätze sowie an sämtliche international anwendbaren Datenschutzgesetze und -vorschriften zu halten. Teladoc nutzt Rechenzentren, die sich in der EU und in den USA befinden, um Ihre personenbezogenen Daten besser zu schützen.`,
  it_IT: `Per proteggere al meglio i vostri dati personali, Teladoc utilizza centri dati con sede nell'UE.\n
  Se necessario per l'esecuzione dei Servizi richiesti, è probabile che il medico si trovi nei paesi in cui si viaggia durante la consultazione. Il medico avrà quindi accesso dal suo paese ai tuoi dati personali memorizzati nel nostro centro dati situato nell'Unione Europea. Ciò significa che, in alcuni casi, a seconda della posizione del medico, i tuoi dati personali sono consultabili al di fuori dell'Unione Europea. L’Interessato è informato e accetta che la normativa locale in materia di protezione dei dati applicabile al trattamento dei suoi Dati Personali in tale Paese potrebbe non presentare un livello di protezione simile a quello concesso dalla normativa applicabile nel Paese di provenienza, incluso il RGPD.\n
  Ciononostante, insistiamo affinché i medici di cui ci avvaliamo per fornire servizi di supporto a Teladoc rispettino la nostra Informativa e i nostri Principi sulla privacy, nonché ogni legge e regolamento in materia di protezione dei dati applicabile a livello globale.`,
};

export const SECURITY_ENFORCEMENT = {
  es_ES: `Aplicación de la seguridad`,
  en_US: `Security Enforcement`,
  pt_BR: `Execução da segurança`,
  fr_FR: `Sécurité`,
  de_DE: `Durchsetzung der Sicherheitsvorkehrungen`,
  it_IT: `Applicazione delle misure di sicurezza`,
};

export const SECURITY_ENFORCEMENT_BODY = {
  es_ES: `Protegemos sus datos personales con controles de seguridad técnicos y organizativos probados y certificados Nuestro personal y los médicos externos reciben formación sobre nuestra política y principios de privacidad, así como todas las leyes de privacidad de datos aplicables a escala mundial.`,
  en_US: `We safeguard Your Personal Data with tested and certified technical and organisational security controls. We educate our staff and external doctors on our Privacy Policy and Principles as well as all globally applicable data privacy laws.`,
  pt_BR: `Protegemos seus Dados Pessoais com controles de segurança técnica e organizacional testados e certificados. Treinamos nossos pessoal e médicos internos em nossa Política e Princípios de Privacidade e, ainda, em leis de privacidade de dados aplicáveis em todo o mundo.`,
  fr_FR: `Nous protégeons Vos Données à Caractère Personnel au moyen de contrôles de sécurité techniques et organisationnels éprouvés et certifiés. Nous sensibilisons notre personnel et les médecins extérieurs à nos Politiques et à nos principes de protection des données ainsi qu’à toutes les lois applicables en matière de protection des données dans le monde entier.`,
  de_DE: `Wir schützen Ihre personenbezogenen Daten mit geprüften und zertifizierten technischen und organisatorischen Sicherheitskontrollmechanismen. Wir schulen unsere Mitarbeiter und externen Ärzte zu unseren Datenschutzrichtlinien und -grundsätzen sowie sämtlichen international geltenden Datenschutzgesetzen.`,
  it_IT: `Proteggiamo i Dati Personali dell’Interessato con controlli di sicurezza tecnici e organizzativi collaudati e certificati. Formiamo il nostro personale e i nostri medici in merito alla nostra Informativa e ai nostri Principi sulla privacy, nonché alla normativa in materia di riservatezza dei    dati applicabile a livello globale.`,
};

export const SUBJECT_RIGHTS = {
  es_ES: `Sus derechos como Interesado`,
  en_US: `Your data subject rights`,
  pt_BR: `Seus direitos de titular de dados`,
  fr_FR: `Les droits dont vous disposez en tant que personne concernée`,
  de_DE: `Ihre Rechte als betroffene Person`,
  it_IT: `I diritti dell’Interessato`,
};

export const SUBJECT_RIGHTS_BODY1 = {
  es_ES: `Nos esforzamos para que sus Datos personales sean precisos y estén actualizados; y los actualizaremos o revelaremos cuando Usted nos lo solicite.\n
    Usted debe comunicarnos las modificaciones, rectificaciones o adiciones a sus Datos personales para que Teladoc pueda modificarlos en consecuencia y mantenerlos actualizados.\n
    Puede acceder a los Datos personales que conservamos sobre Usted y corregirlos o eliminarlos si son incorrectos.\n
    Cuando es de aplicación el RGPD, le amparan una serie de derechos, a saber:`,
  en_US: `We strive to keep your Personal Data accurate and current; and We will update or disclose it to You whenever You request us to do so. You are responsible for communicating modifications, rectifications or additions to Your Personal Data in order that Teladoc may change it accordingly and keep it current.\n
    You are able to access Personal Data held about You, and can correct or delete it if it is inaccurate. When the GDPR is applicable, You have rights including:`,
  pt_BR: `Buscamos manter seus Dados Pessoais corretos e atualizados e atualizaremos ou divulgaremos esses dados a você sempre que Você nos solicitar. Você é responsável por informar modificações, retificações ou acréscimos aos seus Dados Pessoais para que a Teladoc possa alterá-los adequadamente e mantê-los atualizados.\n
    Você pode acessar os Dados Pessoais que mantemos sobre você e pode corrigi-los ou apagá-los se estiverem imprecisos. Quando o GDPR estiver em vigor, você tem direitos, incluindo:`,
  fr_FR: `Nous nous efforçons de conserver des Données à Caractère Personnel Vous concernant qui soient exactes et à jour, et Nous les mettrons à jour ou Vous les communiquerons si Vous nous en faites la demande.\n
    Il vous incombe de nous communiquer les modifications, rectifications ou ajouts de/à Vos Données à Caractère Personnel afin que Teladoc puisse les modifier en conséquence et les tenir à jour.\n
    Vous pouvez accéder aux Données à Caractère Personnel détenues à votre sujet et obtenir leur correction ou leur suppression si elles sont inexactes.\n
    Lorsque le RGPD s’applique, Vous disposez de certains droits, parmi lesquels :`,
  de_DE: `Wir sind bemüht, Ihre personenbezogenen Daten korrekt und aktuell zu halten, und wir werden sie jederzeit aktualisieren oder Ihnen gegenüber offenlegen, wenn Sie uns dazu auffordern. Es liegt in Ihrer Verantwortung, uns Änderungen, Berichtigungen oder Ergänzungen Ihrer personenbezogenen Daten mitzuteilen, damit Teladoc sie entsprechend ändern und aktuell halten kann.\n
    Wir geben Ihnen Auskunft über die personenbezogenen Daten, die wir über Sie haben, und Sie können die Daten berichtigen oder löschen lassen, wenn diese falsch sind. Sofern die DSGVO anwendbar ist, haben Sie unter anderem die folgenden Rechte:`,
  it_IT: `Ci impegniamo a mantenere accurati e aggiornati i Dati Personali dell’Interessato; li aggiorneremo o li divulgheremo ogni volta che l’Interessato ci chiede di farlo. L’Interessato è responsabile di comunicare modifiche, rettifiche o aggiunte ai propri Dati Personali affinché Teladoc possa modificarli di conseguenza e mantenerli aggiornati.\n
    L’Interessato può accedere ai propri Dati Personali conservati e può correggerli o cancellarli se risultano inesatti. Quando il RGPD è applicabile, l’Interessato ha diritti tra i quali:`,
};

export const SUBJECT_RIGHTS_BODY2 = {
  es_ES: `Obsérvese que los derechos de los interesados no se aplicarán a los datos anonimizados, según lo definido en el apartado 5 anterior.\n
    Puede ejercitar sus derechos en calidad de Interesado enviando un correo electrónico a <a href="mailto:lopd@teladochealth.com">lopd@teladochealth.com</a> con su nombre, el servicio utilizado y su número de teléfono, y adjuntando su documento de identificación o cualquier otro documento que nos permita identificarle.`,
  en_US: `Note that data subject rights would not be applicable to de-identified data as defined section 5 above.\n
    You may exercise Your data subject rights by emailing to <a href="mailto:lopd@teladochealth.com">lopd@teladochealth.com</a>, indicating Your name, what service was used and Your telephone number, and attaching Your Identification Card or any other document that permits us to identify You.`,
  pt_BR: `Considere que os direitos de titular de dados não serão aplicáveis aos dados sem identificação conforme definido na seção 5 acima.\n
    Você pode exercer seus direitos de titular de dados ao enviar um e-mail para <a href="mailto:lopd@teladochealth.com">lopd@teladochealth.com</a>, indicando seu nome, que serviço foi usado e seu número de telefone, e anexando seu documento de identidade ou qualquer outro documento que nos permita identificar você.`,
  fr_FR: `Veuillez noter que les droits de la personne concernée ne s’appliquent pas aux données dépersonnalisées visées à la section 5 ci-dessus.\n
    Vous pouvez exercer les droits dont Vous disposez en tant que personne concernée en adressant un e-mail à <a href="mailto:lopd@teladochealth.com">lopd@teladochealth.com</a>, en indiquant Votre nom, le service qui a été utilisé et Votre numéro de téléphone et en joignant Votre Carte d’identité ou tout autre document nous permettant de Vous identifier.`,
  de_DE: `Bitte beachten Sie, dass die Rechte betroffener Personen nicht auf entidentifizierte Daten (wie in Abschnitt 5 oben definiert) anwendbar sind.\n
    Sie können Ihre Rechte als betroffene Person ausüben, indem Sie eine E-Mail an <a href="mailto:lopd@teladochealth.com">lopd@teladochealth.com</a> unter Angabe Ihres Namens, der in Anspruch genommenen Dienstleistung und Ihrer Telefonnummer senden. Bitte fügen Sie auch Ihren Personalausweis oder ein anderes Dokument bei, durch das wir Sie identifizieren können.`,
  it_IT: `Si noti che i diritti degli interessati non sono applicabili ai dati anonimizzati come definiti nella precedente sezione 5.\n
    L’Interessato può esercitare i suoi diritti inviando una e-mail a <a href="mailto:lopd@teladochealth.com">lopd@teladochealth.com</a>, indicando nome, servizio utilizzato e il numero di telefono e allegando il proprio documento di identità o qualsiasi altro documento che consenta di identificarlo.`,
};

export const SUBJECT_RIGHTS_LIST = {
  es_ES: [
    `Derecho de acceso: derecho a solicitar a Teladoc copias de sus Datos personales.`,
    `Derecho de rectificación: derecho a solicitar a Teladoc que rectifique los Datos personales que Usted considere inexactos. También tiene derecho a solicitar a Teladoc que complete la información que estime incompleta.`,
    `Derecho a retirar su consentimiento para el tratamiento de sus Datos de salud.`,
    `Derecho de supresión: derecho a solicitar a Teladoc la eliminación de sus Datos personales en determinadas circunstancias.`,
    `Derecho a restringir el procesamiento: derecho a solicitar a Teladoc que restrinja el procesamiento de sus datos personales en determinadas circunstancias.`,
    `Derecho a oponerse al procesamiento: derecho a oponerse al procesamiento de sus datos personales en ciertas circunstancias`,
    `Derecho a la portabilidad de los datos: derecho a solicitar que Teladoc transfiera la información que Usted facilitó a otra organización, o a Usted, en determinadas circunstancias.`,
  ],
  en_US: [
    `Right of access: right to ask Teladoc for copies of Your Personal Data.`,
    `Right to rectification: right to ask Teladoc to rectify Personal Data You think is inaccurate. You also have the right to ask Teladoc to complete information You think is incomplete.`,
    `Right to withdraw Your consent to the processing of Your Health Data`,
    `Right to erasure: right to ask Teladoc to erase your Personal Data in certain circumstances.`,
    `Right to restriction of processing: right to ask Teladoc to restrict the processing of Your Personal Data in certain circumstances.`,
    `Right to object to processing: right to object to the processing of Your Personal Data in certain circumstances.`,
    `Right to data portability: right to ask that Teladoc transfers the information You gave to another organisation, or to You, in certain circumstances.`,
  ],
  pt_BR: [
    `Direito ao acesso: direito de pedir à Teladoc cópias dos seus Dados Pessoais.`,
    `Direito à retificação: direito a pedir à Teladoc para retificar os Dados Pessoais que você acha que estão imprecisos.`,
    `Você também tem o direito de pedir que a Teladoc complete as informações que você considera incompletas.`,
    `Direito a retirar seu consentimento ao processamento dos seus Dados de Saúde.`,
    `Direito à exclusão: direito de pedir à Teladoc para que apague seus Dados Pessoais em certas circunstâncias.`,
    `Direito à restrição do processamento: direito de pedir à Teladoc para que restrinja o processamento dos seus Dados Pessoais em certas circunstâncias.`,
    `Direito à contestação do processamento: direito de contestar o processamento dos seus Dados Pessoais em certas circunstâncias.`,
    `Direito à portabilidade dos dados: direito de pedir à Teladoc que transfira as informações que você deu a outra organização, ou a você, em certas circunstâncias.`,
  ],
  fr_FR: [
    `Droit d’accès : le droit de demander à Teladoc des copies de Vos Données à Caractère Personnel.`,
    `Droit de rectification : le droit de demander à Teladoc de rectifier des Données à Caractère Personnel que Vous pensez inexactes. Vous avez également le droit de demander à Teladoc de compléter des informations que Vous jugez incomplètes.`,
    `Droit de retirer Votre consentement au traitement de Vos Données Médicales.`,
    `Droit à l’effacement : le droit de demander à Teladoc d’effacer Vos Données à Caractère Personnel dans certaines circonstances.`,
    `Droit à la limitation du traitement : le droit de demander à Teladoc de limiter le traitement de Vos Données à Caractère Personnel dans certaines circonstances.`,
    `Droit d’opposition au traitement : le droit de Vous opposer au traitement de Vos Données à Caractère Personnel dans certaines circonstances.`,
    `Droit à la portabilité des données : le droit de demander à Teladoc de transférer les informations que Vous avez communiquées à une autre organisation ou à Vous-même, dans certaines circonstances.`,
  ],
  de_DE: [
    `Recht auf Auskunft: das Recht, von Teladoc Kopien Ihrer personenbezogenen Daten anzufordern.`,
    `Recht auf Berichtigung: das Recht, die Ihrer Auffassung nach falschen personenbezogenen Daten über Sie berichtigen zu lassen. Sie haben auch das Recht, Teladoc aufzufordern, die Ihrer Auffassung nach unvollständigen Daten zu vervollständigen.`,
    `Recht auf Widerruf Ihrer Einwilligung zur Verarbeitung Ihrer Gesundheitsdaten.`,
    `Recht auf Löschung: das Recht, Teladoc aufzufordern, Ihre personenbezogenen Daten unter bestimmten Umständen zu löschen.`,
    `Recht auf Einschränkung der Verarbeitung: das Recht, Teladoc aufzufordern, die Verarbeitung Ihrer personenbezogenen Daten unter bestimmten Umständen einzuschränken.`,
    `Recht auf Widerspruch gegen die Verarbeitung: das Recht, der Verarbeitung Ihrer personenbezogenen Daten unter bestimmten Umständen zu widersprechen`,
    `Recht auf Datenübertragbarkeit (Datenportabilität): das Recht, Teladoc aufzufordern, die von Ihnen bereitgestellten Daten unter bestimmten Umständen an eine andere Organisation oder an Sie zu übermitteln.`,
  ],
  it_IT: [
    `Diritto di accesso: diritto di chiedere a Teladoc copie dei propri Dati Personali.`,
    `Diritto di rettifica: diritto di chiedere a Teladoc di rettificare i Dati Personali che ritiene inesatti.`,
    `L’Interessato ha anche il diritto di chiedere a Teladoc di completare informazioni che ritiene incomplete.`,
    `Diritto di ritirare il consenso al trattamento dei suoi Dati Sanitari.`,
    `Diritto di cancellazione: diritto di chiedere a Teladoc di cancellare i suoi Dati Personali in determinate circostanze.`,
    `Diritto di limitazione del trattamento: diritto di chiedere a Teladoc di limitare il trattamento dei suoi Dati Personali in determinate circostanze.`,
    `Diritto di opposizione al trattamento: diritto di opposizione al trattamento dei suoi Dati Personali in determinate circostanze.`,
    `Diritto alla portabilità dei dati: diritto di chiedere a Teladoc di trasferire a un’altra azienda o all’Interessato le informazioni trasmesse dall’Interessato, in determinate circostanze.`,
  ],
};

export const CONTACT = {
  es_ES: `Contacto para obtener información adicional`,
  en_US: `Contact for further information`,
  pt_BR: `Contato para mais informações`,
  fr_FR: `Coordonnées pour obtenir des informations complémentaires`,
  de_DE: `Kontakt für weitere Informationen`,
  it_IT: `Contatto per ulteriori informazioni`,
};

export const CONTACT_BODY = {
  es_ES: `Si tiene alguna pregunta con respecto a este Aviso de privacidad, desea una copia del mismo, quiere presentar una reclamación o cree que sus derechos de privacidad han sido vulnerados, puede ponerse en contacto con el delegado de protección de datos en <a href="mailto:dataprotectionofficer@teladochealth.com">dataprotectionofficer@teladochealth.com</a>. No habrá represalias por presentar una queja o por ejercitar los derechos que amparan a los interesados. \n
    También tiene derecho a presentar una reclamación ante su autoridad de protección de datos local: <a href="https://www.aepd.es/es">https://www.aepd.es/es</a>.`,
  en_US: `If You have any questions regarding this Privacy notice, or would like a copy of this Privacy notice, or otherwise You want to file a complaint or You believe Your privacy rights have been violated, You may contact the Data Protection Officer at <a href="mailto:dataprotectionofficer@teladochealth.com">dataprotectionofficer@teladochealth.com</a>. There will be no retaliation for filing any complaint or exercising any subject rights.\n
    You also have the right to lodge a complaint with Your local data protection authority <a href="https://www.aepd.es/es">https://www.aepd.es/es</a>.`,
  pt_BR: `Se você tiver quaisquer perguntas relacionadas a este Aviso de Privacidade, ou gostaria de uma cópia deste Aviso de Privacidade, ou gostaria de fazer uma reclamação ou você acha que seus direitos de privacidade foram descumpridos, você pode entrar em contato com o Data Protection Officer (Diretor de Proteção de Dados) em <a href="mailto:dataprotectionofficer@teladochealth.com">dataprotectionofficer@teladochealth.com</a>. Não haverá retaliação por fazer uma reclamação ou exercer quaisquer dos seus direitos.\n
    Você também tem o direito de apresentar uma reclamação junto à autoridade local de proteção de dados, <a href="https://www.aepd.es/es">https://www.aepd.es/es</a>.`,
  fr_FR: `Si Vous avez des questions concernant la présente Déclaration de confidentialité, si vous souhaitez obtenir un exemplaire de la présente Déclaration de confidentialité, si Vous souhaitez formuler une réclamation ou si Vous estimez que Vos droits au respect de la vie privée ont été violés, Vous pouvez contacter le Délégué à la Protection des Données à l’adresse <a href="mailto:dataprotectionofficer@teladochealth.com">dataprotectionofficer@teladochealth.com</a> . Aucune mesure de rétorsion ne sera prise si vous formulez une réclamation ou exercez tout ou partie des droits dont vous disposez en tant que personne concernée.\n
    Vous êtes également en droit d’introduire une réclamation auprès de Votre autorité locale chargée de la protection des données <a href="https://www.aepd.es/es">https://www.aepd.es/es</a>.`,
  de_DE: `Falls Sie Fragen zu diesen Datenschutzhinweisen haben, eine Kopie dieser Datenschutzhinweise wünschen, Beschwerde einlegen wollen oder der Auffassung sind, dass Ihre Datenschutzrechte verletzt wurden, können Sie sich unter <a href="mailto:dataprotectionofficer@teladochealth.com">dataprotectionofficer@teladochealth.com</a> an den Datenschutzbeauftragten wenden. Die Einlegung einer Beschwerde oder die Ausübung von Rechten als betroffene Person hat keine repressiven Konsequenzen.\n
    Sie haben auch das Recht, unter <a href="https://www.aepd.es/es">https://www.aepd.es/es</a> eine Beschwerde bei Ihrer lokalen Datenschutzbehörde einzureichen.`,
  it_IT: `In caso di domande in merito alla presente Informativa sulla privacy, o se desidera una copia della stessa, o se altrimenti desidera presentare un reclamo o se ritiene che i propri diritti alla privacy siano stati violati, l’Interessato può contattare il Responsabile della protezione dei dati all’indirizzo <a href="mailto:dataprotectionofficer@teladochealth.com">dataprotectionofficer@teladochealth.com</a>. Non vi saranno ritorsioni per i reclami o l’esercizio di diritti da parte degli interessati.\n
    L’Interessato ha anche il diritto di presentare un reclamo presso l’autorità locale per la protezione dei dati <a href="https://www.aepd.es/es">https://www.aepd.es/es</a>.`,
};
