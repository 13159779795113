import React from 'react';
import IntlParent from './shared/providers/IntlParent';
import { StateContextParent } from './shared/contexts/StateContextParent/StateContextParent';
import { GeneralProvider } from './shared/contexts/GeneralContext';
import { initializeGoogleAnalytics } from './shared/helpers/analitycs';
import Routes from './routes';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  generalDiv: {
    backgroundColor: '#FAFAFA',
  },
}));

interface IApp {
  initialState?: object;
}

const App = ({ initialState }: IApp) => {
  initializeGoogleAnalytics();
  const classes = styles();
  return (
    <div className={classes.generalDiv}>
      <IntlParent initialState={{ initialState }}>
        <StateContextParent>
          <GeneralProvider>
            <Routes />
          </GeneralProvider>
        </StateContextParent>
      </IntlParent>
    </div>
  );
};

export default App;
