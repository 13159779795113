import React, { useState } from 'react';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';

import './index.css';

import styled from 'styled-components';

import { Fab } from '@material-ui/core';
import {
  CallEnd,
  Videocam,
  VideocamOff,
  Mic,
  MicOff,
} from '@material-ui/icons';

import { getCookie } from '../../../../shared/helpers';

const TokboxVideoCall = ({
  apiKey,
  sessionId,
  token,
  amosTeladocId,
  onEndCall,
}) => {
  const [error, setError] = useState({
    code: null,
    message: '',
    name: '',
    stack: null,
  });
  const [connection, setConnection] = useState('Connecting...');
  const [publishVideo, setPublishVideo] = useState(true);
  const [publishAudio, setPublishAudio] = useState(true);

  const tokboxApiKeyTest = getCookie('tokboxApiKeyTest');
  const tokboxSessionIdTest = getCookie('tokboxSessionIdTest');
  const tokboxTokenTest = getCookie('tokboxTokenTest');

  const onSessionError = (err) => {
    setError(err);
    setConnection('Connect failed');
  };

  const sessionEventHandlers = {
    sessionConnected: () => {
      setConnection('Connected');
    },
    sessionDisconnected: () => {
      setConnection('Disconnected');
    },
    sessionReconnected: () => {
      setConnection('Reconnected');
    },
    sessionReconnecting: () => {
      setConnection('Reconnecting');
    },
  };

  const publisherEventHandlers = {
    accessDenied: () => {
      setConnection('User denied access to media source');
    },
    streamCreated: () => {
      setConnection('Publisher stream created');
    },
    streamDestroyed: ({ reason }) => {
      setConnection(`Publisher stream destroyed because: ${reason}`);
    },
  };

  const subscriberEventHandlers = {
    videoEnabled: () => {
      setConnection('Subscriber video enabled');
    },
    videoDisabled: () => {
      setConnection('Subscriber video disabled');
    },
  };

  const onPublish = () => {
    setConnection('Publish success');
  };

  const onPublishError = (err) => {
    setError(err);
  };

  const onSubscribe = () => {
    setConnection('Subscribe success');
  };

  const onSubscribeError = (err) => {
    setError(err);
  };

  const toggleVideo = () => {
    setPublishVideo(!publishVideo);
  };

  const toggleAudio = () => {
    setPublishAudio(!publishAudio);
  };

  return (
    <>
      <TokboxContainer>
        <VideosContainer>
          <OTSession
            apiKey={tokboxApiKeyTest || apiKey}
            sessionId={tokboxSessionIdTest || sessionId}
            token={tokboxTokenTest || token}
            onError={onSessionError}
            eventHandlers={sessionEventHandlers}
          >
            <OTPublisher
              properties={{ publishVideo, publishAudio }}
              onPublish={onPublish}
              onError={onPublishError}
              eventHandlers={publisherEventHandlers}
            />
            <OTStreams>
              <OTSubscriber
                onSubscribe={onSubscribe}
                onError={onSubscribeError}
                eventHandlers={subscriberEventHandlers}
              />
            </OTStreams>
          </OTSession>
        </VideosContainer>
        <InvisibleLog>
          <p>
            <strong>Session Status: </strong>
            {connection}
          </p>
          {error && error.code ? (
            <p>
              <strong>{error.stack}</strong>
            </p>
          ) : null}
        </InvisibleLog>
        <ControlButtonsContainer>
          <ControlButton onClick={() => toggleAudio()}>
            {publishAudio ? <Mic /> : <MicOff />}
          </ControlButton>
          <ControlButton onClick={() => onEndCall(amosTeladocId)}>
            <CallEnd />
          </ControlButton>
          <ControlButton onClick={() => toggleVideo()}>
            {publishVideo ? <Videocam /> : <VideocamOff />}
          </ControlButton>
        </ControlButtonsContainer>
      </TokboxContainer>
    </>
  );
};

const TokboxContainer = styled.div`
  background-color: #d3d3d3;
  position: relative;
  z-index: 1000000;
`;

const VideosContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 90vh;
  background-color: #c3c3c3;
`;

const ControlButtonsContainer = styled.div`
  width: 216px;
  min-height: 10vh;
  margin: 10px auto;
`;

const ControlButton = styled(Fab)`
  margin: 8px;
`;

const InvisibleLog = styled.div`
  display: none;
  margin: 8px;
`;

export default TokboxVideoCall;
