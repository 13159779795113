import React from 'react';
import styled from 'styled-components';
import load from '../../assets/img/loading.svg';

const Loading = ({ active }: any) => {
  return <Load active={active} data-testid="loading" />;
};

const Load = styled.div<any>`
  display: ${(props) => (props.active ? 'block' : 'none')};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 1;
  background: url('${load}') no-repeat fixed center;
  background-color: rgb(255, 255, 255, 0.5);
  z-index: 999;
`;

export default Loading;
