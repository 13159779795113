export const TERMS_AND_CONDITIONS = {
  es_ES: 'Términos y condiciones del usuario',
  en_US: 'User Terms and Conditions',
  pt_BR: 'Termos de uso e condições',
  fr_FR: 'Conditions générales d’utilisation',
  de_DE: 'Allgemeine Geschäftsbedingungen',
  it_IT: 'Termini e Condizioni',
};

export const PRIVACY = {
  es_ES: 'Política de privacidad',
  en_US: 'Privacy Policy',
  pt_BR: 'Política de privacidade',
  fr_FR: 'Politique de confidentialité',
  de_DE: 'PRIVATSPHÄRE EINSTELLUNGEN',
  it_IT: 'Privacy',
};

export const COOKIES = {
  es_ES: 'Política de cookies',
  en_US: 'Cookies Policy',
  pt_BR: 'Política de Cookies',
  fr_FR: 'Politique concernant les Cookies',
  de_DE: 'Cookie-Richtlinie',
  it_IT: 'Politica dei cookie',
};

export const PRIVACY_POLICY = {
  es_ES: '©2022 AXA Todos los derechos reservados',
  en_US: '©2022 AXA All Rights Reserved',
  pt_BR: '©2022 AXA Todos os direitos reservados',
  fr_FR: '©2022 AXA Tous droits réservés',
  de_DE: '©2022 AXA Alle Rechte vorbehalten',
  it_IT: '©2022 AXA Tutti i diritti riservati',
};

export const COOKIE_SETTINGS = {
  es_ES: 'Cookie Settings',
  en_US: 'Cookie Settings',
  pt_BR: 'Cookie Settings',
  fr_FR: 'Cookie Settings',
  de_DE: 'Cookie Settings',
  it_IT: 'Cookie Settings',
};
