import { createTheme } from '@material-ui/core';

import {
  PublicoHeadlineBold,
  PublicoHeadlineLight,
  PublicoHeadlineMedium,
  SourceSansProBold,
  SourceSansProLight,
  SourceSansProMedium,
} from '../../assets/fonts';

export default createTheme({
  typography: {
    fontFamily: 'Source Sans Pro',
  },
  palette: {
    primary: {
      main: '#F05D23',
      secondary: 'white',
    },
    secondary: {
      main: '#C5D86D',
    },
    warning: {
      main: '#F07662',
    },
    text: {
      main: '#261C15',
    },
    error: {
      main: '#C6132D',
    },
    action: {
      hover: '#E5E5E5',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#F7F7F2 !important',
        },
        '@font-face': [
          PublicoHeadlineBold,
          PublicoHeadlineLight,
          PublicoHeadlineMedium,
          SourceSansProBold,
          SourceSansProLight,
          SourceSansProMedium,
        ],
      },
    },
    PrivateTabIndicator: {
      root: {
        backgroundColor: '#F07662 !important',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '0px',
        height: 48,
        fontFamily: 'Source Sans Pro',
        fontWeight: 700,
      },
      containedPrimary: {
        backgroundColor: '#F05D23',
        color: 'rgba(255, 255, 255, 1)',
      },
      containedDefault: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: 'rgba(51, 51, 51, 1)',
      },
      outlinedPrimary: {
        border: '1px solid #F05D23 !important',
        color: '#F05D23 !important',
      },
      outlinedDefault: {
        borderColor: 'rgba(255, 255, 255, 1) !important',
        color: 'rgba(255, 255, 255, 1) !important',
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: '0px !important',
      },
    },
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 320,
      sm: 576,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
});
