export const HOME = '/';
export const HOME_WITH_THEME = '/home/:theme';
export const HOME_WITH_THEME_LANG = '/home/:theme/:lang';
export const LETS_START = '/lets-start';
export const LOGIN = '/login';
export const LOGOUT = '/logoff';
export const LOGIN_CALLBACK = '/login-callback';
export const PATIENT_SELECTION = '/patient-selection';
export const REQUEST = '/request/:type/:clientType/:id';
export const REFRESH_SESSION = '/refresh_session';
