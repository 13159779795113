import React, { useRef, useEffect } from 'react';
import { Button } from '..';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from '../../shared/hooks';
import * as translations from './intl';
import * as privPolicy from './intl.privacy';
import styled from 'styled-components';

const styles = makeStyles(() => ({
  pt0: {
    paddingTop: '0',
  },
  pt1: {
    paddingTop: '1em',
  },
  pb1: {
    paddingBottom: '1em',
  },
}));

export default function PrivacyPolicy({ showPrivacy, handleShowPrivacy }) {
  const classes = styles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { locale: intl } = useTranslation({
    translationsFile: translations,
  });
  const { locale: privacy } = useTranslation({
    translationsFile: privPolicy,
  });
  const { PRIVACY, CLOSE } = intl;
  const {
    INTRO,
    INTRO_BODY,
    FUNDAMENTALS,
    FUNDAMENTALS_BODY,
    FUNDAMENTALS_LIST,
    PERSONAL_DATA,
    PERSONAL_DATA_BODY,
    PERSONAL_DATA_ACCESS,
    PERSONAL_DATA_ACCESS_BODY,
    PERSONAL_DATA_NECESSARY,
    PERSONAL_DATA_NECESSARY_BODY,
    PERSONAL_DATA_INHERENT,
    PERSONAL_DATA_INHERENT_BODY,
    PERSONAL_DATA_INHERENT_LIST,
    PURPOSES,
    PURPOSES_BODY,
    PURPOSES_LIST,
    DATA_RETENTION,
    DATA_RETENTION_BODY,
    ACCESS_PERSONAL_DATA,
    ACCESS_PERSONAL_DATA_BODY,
    INTERNATIONAL_TRANSFERS,
    INTERNATIONAL_TRANSFERS_BODY,
    SECURITY_ENFORCEMENT,
    SECURITY_ENFORCEMENT_BODY,
    SUBJECT_RIGHTS,
    SUBJECT_RIGHTS_BODY1,
    SUBJECT_RIGHTS_BODY2,
    SUBJECT_RIGHTS_LIST,
    CONTACT,
    CONTACT_BODY,
  } = privacy;

  const PrivacyElementRef = useRef(null);

  useEffect(() => {
    if (showPrivacy) {
      const { current: descriptionElement } = PrivacyElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [showPrivacy]);

  return (
    <div data-testid="privacy-policy">
      <Dialog
        onClose={handleShowPrivacy}
        open={showPrivacy}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        aria-labelledby="privacy-policy"
      >
        <DialogTitle id="privacy-policy" onClose={handleShowPrivacy}>
          {PRIVACY}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText ref={PrivacyElementRef} tabIndex={-1}>
            <OrderedList>
              <ListItem className={classes.pt0}>
                <strong>{INTRO}</strong>
                <br />
                {INTRO_BODY.split('\n').map((el, index) => (
                  <div key={index}>
                    {el}
                    <br />
                  </div>
                ))}
              </ListItem>

              <ListItem>
                <strong>{FUNDAMENTALS}</strong>
                <br />
                {FUNDAMENTALS_BODY}
                <ul>
                  {FUNDAMENTALS_LIST.map((el, index) => (
                    <li key={index}>{el}</li>
                  ))}
                </ul>
              </ListItem>

              <ListItem>
                <strong>{PERSONAL_DATA}</strong>
                <br />
                {PERSONAL_DATA_BODY.split('\n').map((el, index) => (
                  <div key={index}>
                    {el}
                    <br />
                  </div>
                ))}
                <OrderedList className={classes.pt1}>
                  <ListItem className={classes.pt0}>
                    <strong>{PERSONAL_DATA_ACCESS}</strong>
                    <br />
                    {PERSONAL_DATA_ACCESS_BODY.split('\n').map((el, index) => (
                      <div key={index}>
                        {el}
                        <br />
                      </div>
                    ))}
                  </ListItem>

                  <ListItem>
                    <strong>{PERSONAL_DATA_NECESSARY}</strong>
                    <br />
                    {PERSONAL_DATA_NECESSARY_BODY.split('\n').map(
                      (el, index) => (
                        <div key={index}>
                          {el}
                          <br />
                        </div>
                      )
                    )}
                  </ListItem>
                  <ListItem>
                    <strong>{PERSONAL_DATA_INHERENT}</strong>
                  </ListItem>
                  {PERSONAL_DATA_INHERENT_BODY}
                  <ul>
                    {PERSONAL_DATA_INHERENT_LIST.map((el, index) => (
                      <li key={index}>{el}</li>
                    ))}
                  </ul>
                </OrderedList>
              </ListItem>

              <ListItem>
                <strong>{PURPOSES}</strong>
                <br />
                {PURPOSES_BODY.split('\n').map((el, index) => (
                  <div key={index}>
                    {el}
                    <br />
                  </div>
                ))}
                <ul>
                  {PURPOSES_LIST.map((el, index) => (
                    <li key={index}>{el}</li>
                  ))}
                </ul>
              </ListItem>

              <ListItem>
                <strong>{DATA_RETENTION}</strong>
                <br />
                {DATA_RETENTION_BODY.split('\n').map((el, index) => (
                  <div key={index}>
                    {el}
                    <br />
                  </div>
                ))}
              </ListItem>

              <ListItem>
                <strong>{ACCESS_PERSONAL_DATA}</strong>
                <br />
                {ACCESS_PERSONAL_DATA_BODY.split('\n').map((el, index) => (
                  <div key={index}>
                    {el}
                    <br />
                  </div>
                ))}
              </ListItem>

              <ListItem>
                <strong>{INTERNATIONAL_TRANSFERS}</strong>
                <br />
                {INTERNATIONAL_TRANSFERS_BODY.split('\n').map((el, index) => (
                  <div key={index}>
                    {el}
                    <br />
                  </div>
                ))}
              </ListItem>

              <ListItem>
                <strong>{SECURITY_ENFORCEMENT}</strong>
                <br />
                {SECURITY_ENFORCEMENT_BODY}
              </ListItem>

              <ListItem>
                <strong>{SUBJECT_RIGHTS}</strong>
                <br />
                {SUBJECT_RIGHTS_BODY1.split('\n').map((el, index) => (
                  <div key={index}>
                    {el}
                    <br />
                  </div>
                ))}
                <ul className={classes.pb1}>
                  {SUBJECT_RIGHTS_LIST.map((el, index) => (
                    <li key={index}>{el}</li>
                  ))}
                </ul>
                {SUBJECT_RIGHTS_BODY2.split('\n').map((el, index) => (
                  <div key={index}>
                    <span dangerouslySetInnerHTML={{ __html: el }} />
                    <br />
                  </div>
                ))}
              </ListItem>

              <ListItem>
                <strong>{CONTACT}</strong>
                <br />
                {CONTACT_BODY.split('\n').map((el, index) => (
                  <div key={index}>
                    <span dangerouslySetInnerHTML={{ __html: el }} />
                    <br />
                  </div>
                ))}
              </ListItem>
            </OrderedList>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleShowPrivacy}
            color="primary"
            text={CLOSE}
          ></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const OrderedList = styled.ol`
  counter-reset: item;
`;

const ListItem = styled.li`
  padding-top: 2em;
  display: block;
  &:before {
    content: counters(item, '.') ' ';
    counter-increment: item;
  }
`;
