import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GeneralContext } from '../../shared/contexts/GeneralContext';

function LoginCallback() {
  const { loginAmos, isLogged, clearAmosToken } = useContext(GeneralContext);
  const history = useHistory();

  useEffect(() => {
    const userEmail=localStorage.getItem('userData');
    if(userEmail?.length > 0){
      localStorage.removeItem('userData');
    }
    
    if (isLogged) {
      clearAmosToken();
      loginAmos();
    }

    const oldPath = localStorage.getItem('oldPath');
    if (oldPath?.length > 0) {
      history.push(oldPath);
      localStorage.removeItem('oldPath');
    } else {
      history.push('/');
    }
  });
  return <></>;
}

export default LoginCallback;
