export {
  LABEL_PT,
  LABEL_ES,
  LABEL_EN,
  LABEL_FR,
  LABEL_DE,
  LABEL_IT,
} from '../../shared/constants/locale';

export const BACK_TO_TRAVEL_BUTTON = {
  es_ES: 'VOLVER AL MY TRIP COMPANION',
  en_US: 'BACK TO MY TRIP COMPANION',
  pt_BR: 'VOLTAR PARA MY TRIP COMPANION',
  fr_FR: 'RETOUR À MY TRIP COMPANION',
  de_DE: 'ZURÜCK ZUM MY TRIP COMPANION',
  it_IT: 'TORNA AL MY TRIP COMPANION',
};

export const BACK_TO_COVERFORYOU_BUTTON = {
  es_ES: 'VOLVER AL COVERFORYOU',
  en_US: 'BACK TO COVERFORYOU',
  pt_BR: 'VOLTAR PARA COVERFORYOU',
  fr_FR: 'RETOUR À COVERFORYOU',
  de_DE: 'ZURÜCK ZUM COVERFORYOU',
  it_IT: 'TORNA AL COVERFORYOU',
};

export const BACK_TO_CEDARTREE_BUTTON = {
  es_ES: 'VOLVER AL CEDARTREE',
  en_US: 'BACK TO CEDARTREE',
  pt_BR: 'VOLTAR PARA CEDARTREE',
  fr_FR: 'RETOUR À CEDARTREE',
  de_DE: 'ZURÜCK ZUM CEDARTREE',
  it_IT: 'TORNA AL CEDARTREE',
};

export const BACK_TO_OUTBACKER_BUTTON = {
  es_ES: 'VOLVER AL OUTBACKER',
  en_US: 'BACK TO OUTBACKER',
  pt_BR: 'VOLTAR PARA OUTBACKER',
  fr_FR: 'RETOUR À OUTBACKER',
  de_DE: 'ZURÜCK ZUM OUTBACKER',
  it_IT: 'TORNA AL OUTBACKER',
};

export const REQUEST_VIDEO_CONSULTATION_BR = {
  es_ES: 'CONSULTA DE VIDEO',
  en_US: 'VIDEO CONSULTATION',
  pt_BR: 'CONSULTA POR VÍDEO',
  fr_FR: 'CONSULTATION VIDEO',
  de_DE: 'ZUR VIDEO-BERATUNG',
  it_IT: 'VIDEOCONSULTO',
};

export const REQUEST_VIDEO_CONSULTATION = {
  es_ES: 'CONSULTA DE VIDEO',
  en_US: 'VIDEO CONSULTATION',
  pt_BR: 'CONSULTA POR VÍDEO',
  fr_FR: 'CONSULTATION VIDEO',
  de_DE: 'ZUR VIDEO-BERATUNG',
  it_IT: 'VIDEOCONSULTO',
};

export const REQUEST_PHONE_CALL_BR = {
  es_ES: 'LLAMADA TELEFÓNICA',
  en_US: 'CALL BACK REQUEST',
  pt_BR: 'LIGAÇÃO',
  fr_FR: 'DEMANDE DE RAPPEL',
  de_DE: 'ZUR TELEFONISCHEN BERATUNG',
  it_IT: 'CONSULTO TELEFONICO',
};

export const REQUEST_PHONE_CALL = {
  es_ES: 'LLAMADA TELEFÓNICA',
  en_US: 'CALL BACK REQUEST',
  pt_BR: 'LIGAÇÃO',
  fr_FR: 'DEMANDE DE RAPPEL',
  de_DE: 'ZUR TELEFONISCHEN BERATUNG',
  it_IT: 'CONSULTO TELEFONICO',
};

export const APPOINTMENTS_BR = {
  es_ES: 'SUS CITAS',
  en_US: 'APPOINTMENTS',
  pt_BR: 'CONSULTAS',
  fr_FR: 'MES RENDEZ-VOUS',
  de_DE: 'TERMINVEREINBARUNG',
  it_IT: 'APPUNTAMENTI',
};

export const APPOINTMENTS = {
  es_ES: 'SUS CITAS',
  en_US: 'APPOINTMENTS',
  pt_BR: 'CONSULTAS',
  fr_FR: 'MES RENDEZ-VOUS',
  de_DE: 'TERMINVEREINBARUNG',
  it_IT: 'APPUNTAMENTI',
};

export const TELECONSULTATION_BR = {
  es_ES: 'inicio',
  en_US: 'Home',
  pt_BR: 'Início',
  fr_FR: 'ACCUEIL',
  de_DE: 'Home',
  it_IT: 'Home',
};

export const TELECONSULTATION = {
  es_ES: 'inicio',
  en_US: 'Home',
  pt_BR: 'Início',
  fr_FR: 'ACCUEIL',
  de_DE: 'Home',
  it_IT: 'Home',
};
