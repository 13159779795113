import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavButton = styled(Button)`
  overflow-wrap: break-word;
  color: #00008f;
`;

export const DrawerButton = styled(NavButton)`
  justify-content: normal;
  color: '#00008F';
`;
