import React, { useEffect, useContext, useMemo } from 'react';
import {
  Banner,
  TermsAndPolicySession,
  Appointments,
  TokboxVideoCall,
  ConfirmEmailAlert,
} from './components';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import GlobalStyle from '../../styles/global';
import { GeneralContext } from '../../shared/contexts/GeneralContext';
import { useQuery, useTranslation } from '../../shared/hooks';
import { getCookie,setCookie,deleteCookie } from '../../shared/helpers/cookies';
import api from '../../services';

function Home() {
  const { setUserData, isLogged, setIsLoading, userData, client } =
    useContext(GeneralContext);
  const { theme, lang } = useParams();
  const { idiom, setIdiom } = useTranslation({});
  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  let pathLang = window.location.pathname.split('/')[2];

  const cleanAmosTeledocAuth = () => {
    sessionStorage.removeItem('amosTeladocApiKey');
    sessionStorage.removeItem('amosTeladocToken');
    sessionStorage.removeItem('amosTeladocSessionId');
  };

  setCookie(
    'fullURL',
    window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname
  );

  const convertLang = (keyLang) => {
    switch (keyLang) {
      case 'es':
        return 'es_ES';
      case 'pt' || 'pt_BR':
        return 'pt_BR';
      case 'fr':
        return 'fr_FR';
      case 'de':
        return 'de_DE';
      case 'it':
        return 'it_IT';
      case 'en' || 'en_GB':
        return 'en_US';
      default:
        return 'en_US';
    }
  };

  const handleEndCall = async (id) => {
    try {
      setIsLoading(true);
      const clientToken = getCookie('amosToken');
      const bodyObj = {
        clientToken,
        email: client.email,
        firstName: client.name,
        lastName: client.surname,
        language: client.language,
      };
      await api.amosServices.amosEndVideoCall(id, bodyObj);

      setIsLoading(false);
      cleanAmosTeledocAuth();
      history.push('/');
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useMemo(() => {
    if (pathLang) {
      if (pathLang.length == 2) {
        setIdiom(convertLang(pathLang));
      }
      return;
    }
    if (lang) {
      setIdiom(convertLang(lang));
      return;
    }
  }, [pathLang, lang]);

  useEffect(() => {
    localStorage.setItem('theme', theme ? theme : '');
  }, [theme]);

  const homeBehavior = () => {
    try {
      if (location.pathname.includes('/home')) {
        setUserData({});
        setCookie('userAuth', '{}');
        history.push('/login');
        return;
      }
      if(idiom!=undefined && idiom.length>=2){
        document.documentElement.lang=idiom.substr(0, 2);
      }
      let queryParam1 = 'lang'
      let queryParam2 = 'ui_locales'
      let lanquagecode1 = idiom.substr(0, 2);
      let lanquagecode2 = idiom.substr(0, 2) == 'pt'
          ? 'pt-PT'
          : idiom.substr(0, 2) == 'fr'
          ? 'fr-FR'
          : idiom.substr(0, 2);

      if (!isLogged && window.location.hostname == process.env.REACT_APP_DOMAIN_INSUREFOR){
        window.location.href = `/login?connection=PartnerSSO&${queryParam1}=${lanquagecode1}&${queryParam2}=${lanquagecode2}`;
      }
      else if(!isLogged && window.location.hostname == process.env.REACT_APP_DOMAIN_WWIIS){
        const redirectURL = getCookie('brandCookieURL')
        if(redirectURL){
          deleteCookie('brandCookieURL')
          window.location.href = redirectURL
        }
        else{
          window.location.href = `/login?connection=PartnerSSO&${queryParam1}=${lanquagecode1}&${queryParam2}=${lanquagecode2}`;
        }
      }
      else if(!isLogged){
        window.location.href = `/login?${queryParam1}=${lanquagecode1}&${queryParam2}=${lanquagecode2}`;
      }
      if(window.location.hostname == process.env.REACT_APP_DOMAIN_WWIIS) {
        const urlParams = new URLSearchParams(window.location.search);

        // Get query parameters
        const brand = urlParams.get('Brand');
        const policyNumber = urlParams.get('policyNumber');
        if(brand && policyNumber){
          window.location.href = `/login?connection=PartnerSSO&${queryParam1}=${lanquagecode1}&${queryParam2}=${lanquagecode2}`;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    homeBehavior();
  }, []);

  if (query.get('type')) window.scrollTo(0, 750);

  if (query.get('type') === 'videoCall') {
    const amosTeladocApiKey = getCookie('amosTeladocApiKey');
    const amosTeladocToken = getCookie('amosTeladocToken');
    const amosTeladocSessionId = getCookie('amosTeladocSessionId');
    const amosTeladocId = getCookie('amosTeladocId');

    return (
      <ContainerFullScreen>
        <TokboxVideoCall
          apiKey={amosTeladocApiKey}
          token={amosTeladocToken}
          sessionId={amosTeladocSessionId}
          amosTeladocId={amosTeladocId}
          onEndCall={handleEndCall}
        />
      </ContainerFullScreen>
    );
  }

  return (
    <>
      <GlobalStyle />
      {userData?.email_verified === false && <ConfirmEmailAlert />}
      {userData?.email_verified && (
        <>
          <Banner />
          <TermsAndPolicySession />
          <Appointments />
        </>
      )}
    </>
  );
}

export default Home;

const ContainerFullScreen = styled.div`
  background: #000;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
