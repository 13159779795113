import React, { useState } from 'react';
import { Button } from '..';
import { Grid, makeStyles } from '@material-ui/core';
import * as translations from './intl';
import { useTranslation } from '../../../../shared/hooks';
import { TermsAndConditions, PrivacyPolicy } from '../../../../components';

const styles = makeStyles((theme) => ({
  container: {
    height: '8em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1em 5em',
    background: theme.palette.primary.footerbackgroundcolor,
    color: theme.palette.text.main,
  },
  'term-button': {
    overflowWrap: 'break-word',
    width: '16em',
    color: 'white',
    border: '1px solid white',
    fontFamily: 'Source Sans Pro !important',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '18px',
    letterSpacing: '1px',
    textAlign: 'center',
    minWidth: '250px',
  },
  sourceSans: {
    fontFamily: 'Source Sans Pro',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
}));

export default function TermsAndPolicySession() {
  const classes = styles();
  const { locale: intl } = useTranslation({
    translationsFile: translations,
  });
  const { USER_TERMS, PRIVACY } = intl;
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const handleToggleTerms = () => {
    setShowTerms((prevShowTerms) => !prevShowTerms);
    if (!showTerms) {
      window.dataLayer.push({
        'zone': 'homepage',
        'element_clicked': 'user terms and policy',
        'event': 'navigation',
      });
    }
  };

  const handleTogglePrivacy = () => {
    setShowPrivacy((prevShowPrivacy) => !prevShowPrivacy);
    if (!showPrivacy) {
      window.dataLayer.push({
        'zone': 'homepage',
        'element_clicked': 'privacy policy',
        'event': 'navigation',
      });
    }
  };

  return (
    <>
      <TermsAndConditions
        showTerms={showTerms}
        handleShowTerms={handleToggleTerms}
      />
      <PrivacyPolicy
        showPrivacy={showPrivacy}
        handleShowPrivacy={handleTogglePrivacy}
      />
      <div className={classes.container} data-testid="terms-policy">
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <Button
              className={`${classes['term-button']} ${classes.sourceSans} ${classes.upperCase}`}
              text={USER_TERMS}
              variant="outlined"
              onClick={handleToggleTerms}
            />
          </Grid>
          <Grid item>
            <Button
              className={`${classes['term-button']} ${classes.sourceSans} ${classes.upperCase}`}
              text={PRIVACY}
              variant="outlined"
              onClick={handleTogglePrivacy}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
