import React, { useEffect, useRef } from 'react';
import { Button } from '..';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from '../../shared/hooks';
import * as translations from './intl';
import * as cookiesPolicy from './intl.cookies';
import styled from 'styled-components';
const styles = makeStyles(() => ({
  w25: {
    width: '25%',
  },
  w50: {
    width: '50%',
  },
}));

export default function CookiePolicyWwiis({
  showCookiesPolicyWwiis,
  handleShowCookiesPolicy,
}) {
  const classes = styles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { locale: intl } = useTranslation({
    translationsFile: translations,
  });
  const { locale: policy } = useTranslation({
    translationsFile: cookiesPolicy,
  });
  const TermsElementRef = useRef(null);

  useEffect(() => {
    if (showCookiesPolicyWwiis) {
      const { current: descriptionElement } = TermsElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [showCookiesPolicyWwiis]);

  const { COOKIES, CLOSE } = intl;

  const {
    MAIN_COOKIE_TEXT,
    HOW_COLLECTS_YOUR_PERSONAL_DATA_TITLE,
    HOW_COLLECTS_YOUR_PERSONAL_DATA_TEXT,
    WHAT_ARE_COOKIES_TITLE,
    WHAT_ARE_COOKIES_TEXT1,
    WHAT_ARE_COOKIES_TEXT2,
    WHAT_ARE_COOKIES_LIST,
    WHAT_ARE_COOKIES_TEXT3,
    WHAT_ARE_COOKIES_TEXT4,
    WHAT_ARE_COOKIES_TEXT5,
    TYPES_OF_COOKIES_USED_TITLE,
    TYPES_OF_COOKIES_USED_TEXT,
    TYPES_OF_COOKIES_USED_LIST,
    COOKIE_LIST_TITLE,
    COOKIE_LIST_TEXT1,
    COOKIE_TABLES_TITLE,
    FUNCTIONAL_TECHNICAL_COOKIES_TITLE,
    FUNCTIONAL_TECHNICAL_COOKIES_TEXT,
    TABLE_HEAD,
    ANALYTICS_COOKIES_TITLE,
    ANALYTICS_COOKIES_TEXT,
    MARKETING_COOKIES_TITLE,
    MARKETING_COOKIES_TEXT,
    WHAT_ARE_YOUR_RIGHTS_RELATED_TITLE,
    WHAT_ARE_YOUR_RIGHTS_RELATED_TEXT,
    COOKIE_SETTINGS_TITLE,
    COOKIE_SETTINGS_TEXT1,
    COOKIE_SETTINGS_TEXT2,
    COOKIE_SETTINGS_LIST,
    HOW_TO_CONTACT_TITLE,
    HOW_TO_CONTACT_TEXT,
    UPDATES_TITLE,
    UPDATES_TEXT,
    UPDATE_OF_PRESENT_POLICY_TITLE,
    UPDATE_OF_PRESENT_POLICY_TEXT,
    MORE_INFO_TITLE,
    FIRST_PARTY,
  } = policy;

  return (
    <Dialog
      onClose={handleShowCookiesPolicy}
      open={showCookiesPolicyWwiis}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      aria-labelledby="cookies-policy"
    >
      <DialogTitle id="cookies-policy" onClose={handleShowCookiesPolicy}>
        {COOKIES}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
            <ListItem>
              <p>{MAIN_COOKIE_TEXT}</p>
            </ListItem>
            <ListItem>
              <strong>{HOW_COLLECTS_YOUR_PERSONAL_DATA_TITLE}</strong>
              <p>{HOW_COLLECTS_YOUR_PERSONAL_DATA_TEXT}</p>
            </ListItem>
            <ListItem>
              <strong>{WHAT_ARE_COOKIES_TITLE}</strong>
              <br />
              <p>{WHAT_ARE_COOKIES_TEXT1}</p>
              <p>{WHAT_ARE_COOKIES_TEXT2}</p>
              <p>
                <ul>
                  {WHAT_ARE_COOKIES_LIST.map((el, index) => (
                    <li key={index}>{el}</li>
                  ))}
                </ul>
              </p>
              <p>{WHAT_ARE_COOKIES_TEXT3}</p>
              <p>{WHAT_ARE_COOKIES_TEXT4}</p>
              <p>{WHAT_ARE_COOKIES_TEXT5}</p>
            </ListItem>
            <ListItem>
              <strong>{TYPES_OF_COOKIES_USED_TITLE}</strong>
              <p>{TYPES_OF_COOKIES_USED_TEXT}</p>
              <p>
                <ul>
                  {TYPES_OF_COOKIES_USED_LIST.map((el, index) => (
                    <li key={index}>{el}</li>
                  ))}
                </ul>
              </p>
              <p>
                <strong>{COOKIE_LIST_TITLE}</strong>
              </p>
              <p>{COOKIE_LIST_TEXT1}</p>
              <p><strong>{COOKIE_TABLES_TITLE}</strong></p>
              <p>
                <strong>{FUNCTIONAL_TECHNICAL_COOKIES_TITLE}</strong>
              </p>
              <p>{FUNCTIONAL_TECHNICAL_COOKIES_TEXT}</p>
              <p>
                <Table>
                  <thead>
                    <tr>
                      <TH className={classes.w25}>{TABLE_HEAD[0]}</TH>
                      <TH className={classes.w50}>{TABLE_HEAD[1]}</TH>
                      <TH>{TABLE_HEAD[2]}</TH>
                      <TH>{TABLE_HEAD[3]}</TH>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <TD>{FIRST_PARTY}</TD>
                      <TD>
                        <span> These cookies enable the counting of the number of users accessing the application over time. </span>
                      </TD>
                      <TD>
                        <span>user</span>
                      </TD>
                      <TD>
                      <span>Browsing Session</span>
                      </TD>
                    </tr>
                    <tr>
                      <TD>{FIRST_PARTY}</TD>
                      <TD>
                        <span> This cookie is dropped once visitor received the information about cookies and closed the banner. It prevents the banner from popping up at every visit. </span>
                      </TD>
                      <TD>
                        <span>OptanonConsent</span>
                      </TD>
                      <TD>
                      <span>3 Months</span>
                      </TD>
                    </tr>
                    <tr>
                      <TD>{FIRST_PARTY}</TD>
                      <TD>
                        <span> This enables site owners to prevent cookies in each category from being set in the users’ browser, when consent is not given. </span>
                      </TD>
                      <TD>
                        <span>OptanonAlertBoxClosed</span>
                      </TD>
                      <TD>
                      <span>3 Months</span>
                      </TD>
                    </tr>
                    <tr>
                      <TD>{FIRST_PARTY}</TD>
                      <TD>
                        <span> These cookies collect statistical usage and telemetry information </span>
                      </TD>
                      <TD>
                        <span>session</span>
                      </TD>
                      <TD>
                      <span>A Few Seconds</span>
                      </TD>
                    </tr>
                    <tr>
                      <TD>{FIRST_PARTY}</TD>
                      <TD>
                        <span> This cookie is used to store the full URL of the current page. </span>
                      </TD>
                      <TD>
                        <span>fullURL</span>
                      </TD>
                      <TD>
                      <span>Browsing Session</span>
                      </TD>
                    </tr>
                    <tr>
                      <TD>{FIRST_PARTY}</TD>
                      <TD>
                        <span> This cookie allows us to recognize the language in which visitors have their browser configured to show relevant ads on other sites. </span>
                      </TD>
                      <TD>
                        <span>idiom</span>
                      </TD>
                      <TD>
                      <span>Browsing Session</span>
                      </TD>
                    </tr>
                  </tbody>
                </Table>
              </p>
              <p>
                <strong>{ANALYTICS_COOKIES_TITLE}</strong>
              </p>
              <p>{ANALYTICS_COOKIES_TEXT}</p>
              
              <p>
                <strong>{MARKETING_COOKIES_TITLE}</strong>
              </p>
              <p>{MARKETING_COOKIES_TEXT}</p>
              <p>
                <Table>
                  <thead>
                    <tr>
                      <TH className={classes.w25}>{TABLE_HEAD[0]}</TH>
                      <TH className={classes.w50}>{TABLE_HEAD[1]}</TH>
                      <TH>{TABLE_HEAD[2]}</TH>
                      <TH>{TABLE_HEAD[3]}</TH>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <TD>{FIRST_PARTY}</TD>
                      <TD>
                        <span> This cookie is used to calculate visitor, session and campaign data for analytics reports. </span>
                      </TD>
                      <TD>
                        <span>_ga</span>
                      </TD>
                      <TD>
                      <span>13 Months</span>
                      </TD>
                    </tr>
                    <tr>
                      <TD>{FIRST_PARTY}</TD>
                      <TD>
                        <span> This cookie enables a service to distinguish one website visitor from another. </span>
                      </TD>
                      <TD>
                        <span>_ga_xxxxxxx</span>
                      </TD>
                      <TD>
                      <span>13 Months</span>
                      </TD>
                    </tr>
                  </tbody>
                </Table>
              </p>
            </ListItem>
            <ListItem>
              <strong>{WHAT_ARE_YOUR_RIGHTS_RELATED_TITLE}</strong>
              <p>{WHAT_ARE_YOUR_RIGHTS_RELATED_TEXT}</p>
            </ListItem>
            <ListItem>
              <strong>{COOKIE_SETTINGS_TITLE}</strong>
              <p>{COOKIE_SETTINGS_TEXT1}</p>
              <p>{COOKIE_SETTINGS_TEXT2}</p>
              <p>
                <ul>
                  {COOKIE_SETTINGS_LIST.map((el, index) => (
                    <li key={index}>{el}</li>
                  ))}
                </ul>
              </p>
            </ListItem>
            <ListItem>
              <strong>{HOW_TO_CONTACT_TITLE}</strong>
              <p>{HOW_TO_CONTACT_TEXT}</p>
            </ListItem>
            <ListItem>
              <strong>{UPDATES_TITLE}</strong>
              <p>{UPDATES_TEXT}</p>
            </ListItem>
            <ListItem>
              <strong>{UPDATE_OF_PRESENT_POLICY_TITLE}</strong>
              <p>{UPDATE_OF_PRESENT_POLICY_TEXT}</p>
            </ListItem>
            <ListItem>
              <strong>{MORE_INFO_TITLE}</strong>
            </ListItem>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleShowCookiesPolicy}
          color="primary"
          text={CLOSE}
        ></Button>
      </DialogActions>
    </Dialog>
  );
}

const ListItem = styled.li`
  padding-top: 2em;
  display: block;
`;

const Table = styled.table`
  border: 1px solid #d7d7d7;
  width: 100%;
  border-radius: 5px;
`;

const TH = styled.th`
  padding: 0.5em;
  border: 1px solid #d7d7d7;
`;
const TD = styled.td`
  padding: 0.5em;
  font-size: 0.8em;
  border: 1px solid #d7d7d7;
  word-wrap: break-word;
`;
