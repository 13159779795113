export const CHANGE_PASSWORD = {
  en_US: 'Change Password',
  it_IT: 'Cambio password',
  pt_BR: 'Alterar senha',
  es_ES: 'Cambia la contraseña',
  de_DE: 'Passwort ändern',
  fr_FR: 'Changer de mot de passe',
};

export const LOGOUT = {
  en_US: 'Log out',
  it_IT: 'Log out',
  pt_BR: 'Sair',
  es_ES: 'CERRAR SESIÓN',
  de_DE: 'ABMELDEN',
  fr_FR: 'SE DÉCONNECTER',
};

//pending
export const PASSWORD_RESET_TEXT = {
  en_US: 'This action will send you an email in order to reset your password.',
  it_IT: "Questa azione ti invierà un'e-mail per reimpostare la tua password.",
  pt_BR: 'Esta ação irá enviar um e-mail para redefinição de sua senha.',
  es_ES:
    'Esta acción te enviará un correo electrónico para restablecer tu contraseña.',
  de_DE:
    'Diese Aktion sendet Ihnen eine E-Mail, um Ihr Passwort zurückzusetzen.',
  fr_FR: 'Vous recevrez un email afin de réinitialiser votre mot de passe.',
};
