import { errorHandler } from './error_handler';
import api from './api';
import Amos from './amos';
import User from './user';

// You should merge all imports inside this axaAPI object
const apiService = {
  amosServices: Amos(process.env, api, errorHandler),
  userServices: User(process.env, api, errorHandler),
};

export default apiService;
