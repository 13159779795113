export const APPOINTMENTS = {
  es_ES: 'Sus Citas',
  en_US: 'Appointments',
  pt_BR: 'Consultas',
  fr_FR: 'Mes Rendez-Vous',
  de_DE: 'Terminvereinbarung',
  it_IT: 'Appuntamenti',
};

export const VIDEO_CALLS = {
  es_ES: 'Video Consulta',
  en_US: 'Video Calls',
  pt_BR: 'Consultas Por Vídeo',
  fr_FR: 'Rendez-Vous Vidéo',
  de_DE: 'Video Beratung',
  it_IT: 'Videochiamate',
};

export const PHONE_CALLS = {
  es_ES: 'Llamadas telefónica',
  en_US: 'Phone Calls',
  pt_BR: 'Ligações',
  fr_FR: 'Rendez-vous téléphonique',
  de_DE: 'Telefonische Beratung',
  it_IT: 'Telefonate',
};

export const FULL_WIDTH_LABEL = {
  es_ES: 'full width tabs example',
  en_US: 'full width tabs example',
  pt_BR: 'full width tabs example',
  fr_FR: 'full width tabs example',
  de_DE: 'full width tabs example',
  it_IT: 'full width tabs example',
};
