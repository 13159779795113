import { useContext, useMemo } from 'react';
import IntlContext from '../contexts/IntlContext';

function useTranslation({
  translationsFile = {},
  //context = IntlContext,
  formValidation,
}: any) {
  const { translate, getIdiom, setIdiom, idiom } = useContext(IntlContext);
  const locale = translate(translationsFile);

  const formValidationSchema = useMemo(
    () => formValidation && formValidation(locale),
    [formValidation, locale]
  );

  const idiomAmos = useMemo(() => getIdiom().substr(0, 2), [getIdiom()]);

  return { locale, formValidationSchema, getIdiom, setIdiom, idiomAmos, idiom };
}

export default useTranslation;
