const user = () => ({
  logged: false,
  data: {},
});

const alert = () => ({
  message: '',
  type: 'error',
  show: false,
});

const modal = () => ({
  title: '',
  message: '',
  show: false,
});

const userAxa = () => ({
  isComplete: false,
  data: {},
});

const menu = () => ({
  type: 'LINKS_CARD_HOLDER',
});

const loading = () => ({
  isLoading: false,
});

const cardUser = () => ({
  cards: [],
});

const claim = () => ({
  data: [],
});

const confirm = () => ({
  message: '',
  title: '',
  show: false,
});

export const initialState = () => ({
  user: user(),
  alert: alert(),
  modal: modal(),
  userAxa: userAxa(),
  menu: menu(),
  loading: loading(),
  cardUser: cardUser(),
  claim: claim(),
  confirm: confirm(),
});
