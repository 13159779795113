import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { useTranslation } from '../../../../shared/hooks';
import { Button } from '../../../../components';
import * as translations from './intl';
import styles from './styles';
import IconWarning from '../../../../assets/icons/ico-alert.png';

function DialogTelemed({ open, handleClose, dialogConfig }) {
  const { locale: intl } = useTranslation({
    translationsFile: translations,
  });
  const classes = styles();

  const { LABEL_ACCEPT } = intl;
  return (
    <>
      <Dialog
        open={open}
        onClose={dialogConfig?.func ? dialogConfig?.func : handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.displayCenter}>
          {!dialogConfig?.title && (
            <img
              src={IconWarning}
              className={classes.iconImage}
              alt="warning icon"
            />
          )}
          {dialogConfig?.title && <h3>{dialogConfig.title}</h3>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.description}
          >
            {dialogConfig?.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.displayCenter}>
          <Button
            onClick={dialogConfig?.func ? dialogConfig?.func : handleClose}
            color="primary"
            text={LABEL_ACCEPT}
            className={classes.buttonAgree}
          ></Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogTelemed;
