import React, { useState , useContext } from 'react';
import './Popup.css'; // Import your custom CSS file
import Appstore from '../../../assets/images/Appstore.png';
import Playstore from '../../../assets/images/Playstore.png';
import { GeneralContext } from '../../../shared/contexts/GeneralContext';


const Popup = () => {
 const {
  ispopup,
  setIspopup,
} = useContext(GeneralContext);


  const closePopup = () => {
    setIspopup(false);
  };

 

  return (

    <div>

        {ispopup && (
          <div className="Popup-overlay">
            <div className="popupbox">
                <span className="popup-close" onClick={closePopup}>&times;</span>

                <div className="popup-title">Beneficiary is above 18 years old</div>
                <hr className="popup-line" />
                <div className="popup-description">

                            Due to European regulation beneficiaries above 18 years old covered under your policy must create their own account on Doctor Please mobile application using your access code, the application is available on Google Play Store and Apple Store.

                </div>

                <div className="popup-buttons">

                <a href="https://apps.apple.com/gb/app/doctor-please/id1488419347" target="_blank" rel="noopener noreferrer">

                <img src={Appstore} alt="Apple App Store" width="100" />

                </a>

                <a href="https://play.google.com/store/apps/details?id=com.advancemedical.axaassistance&hl=fr&pli=1" target="_blank" rel="noopener noreferrer">

                <img src={Playstore} alt="Google Play Store" width="100" />

                </a>

                </div>
                <hr className="popup-line" />
                <button className="popup-button" onClick={closePopup}>OK, GOT IT</button>
            </div>
          </div>
        )}

    </div>

    );

};

export default Popup;
