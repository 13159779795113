export const VALIDATION_REQUIRED = {
  es_ES: 'Falta el campo requerido',
  en_US: 'Field is missing',
  pt_BR: 'Campo está faltando',
  fr_FR: 'Le champ doit-être renseigné.',
  de_DE: 'Eingabe fehlt',
  it_IT: 'Campo vuoto',
};

export const VALIDATION_VALID_DATE = {
  es_ES: 'Por favor, introduzca una fecha válida.',
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  fr_FR: 'Veuillez entrer une date valide.',
  de_DE: 'Bitte gib ein korrektes Datum an.Eingabe fehlt',
  it_IT: 'Per favore, inserisci una data valida.',
};

export const VALIDATION_MUST_BE_UNDER_18 = {
  es_ES:
    'Los beneficiarios mayores de 18 años cubiertos por su contrato deben crear su propia cuenta Doctor Please usando su código de acceso.',
  en_US: 'Beneficiaries above 18 years old covered under your policy must create their own Doctor Please account using your access code.',
  pt_BR: 'Os beneficiários maiores de 18 anos cobertos pelo seu contrato devem criar sua própria conta Doctor Please usando seu código de acesso.',
  fr_FR:
    'Les bénéficiaires de plus de 18 ans couverts par votre contrat doivent créer leur propre compte Doctor Please à l\'aide de votre code daccès.',
  de_DE:
    'Begünstigte über 18 Jahre, die unter Ihre Police fallen, müssen mit Ihrem Zugangscode ein eigenes Doctor Please-Konto erstellen.',
  it_IT:
    'i beneficiari di età superiore ai 18 anni coperti dal tuo contratto devono creare il proprio account Doctor Please utilizzando il tuo codice di accesso.',
};

export const VALIDATION_MUST_BE_THAN_18 = {
  es_ES: 'Debes ser mayor de 18 años para registrarte.',
  en_US: 'You must be at least 18 years old in order to register',
  pt_BR: 'Você deve ter ao menos 18 anos para poder se registrar',
  fr_FR: 'Vous devez avoir au moins 18 ans pour vous inscrire',
  de_DE:
    'Sie müssen mindestens 18 Jahre alte sein, um sich registrieren zu können',
  it_IT: 'Devi avere almeno 18 anni per registrarti',
};

export const LABEL_PT = {
  es_ES: 'Portugués',
  en_US: 'Portuguese',
  pt_BR: 'Português',
  fr_FR: 'Portugais',
  de_DE: 'Portugiesisch',
  it_IT: 'Portoghese',
};

export const LABEL_ES = {
  es_ES: 'Español',
  en_US: 'Spanish',
  pt_BR: 'Espanhol',
  fr_FR: 'Espagnol',
  de_DE: 'Spanisch',
  it_IT: 'Spagnolo',
};

export const LABEL_EN = {
  es_ES: 'Inglés',
  en_US: 'English',
  pt_BR: 'Inglês',
  fr_FR: 'Anglais',
  de_DE: 'Englisch',
  it_IT: 'Inglese',
};

export const LABEL_FR = {
  es_ES: 'Francés',
  en_US: 'French',
  pt_BR: 'Francês',
  fr_FR: 'Français',
  de_DE: 'Französisch',
  it_IT: 'Francese',
};

export const LABEL_DE = {
  es_ES: 'Alemán',
  en_US: 'German',
  pt_BR: 'Alemão',
  fr_FR: 'Allemand',
  de_DE: 'Deutsch',
  it_IT: 'Tedesco',
};

export const LABEL_IT = {
  es_ES: 'Italiano',
  en_US: 'Italian',
  pt_BR: 'Italiano',
  fr_FR: 'Italien',
  de_DE: 'Italienisch',
  it_IT: 'Italiano',
};

export const LABEL_FI = {
  es_ES: 'Finlandés',
  en_US: 'Finnish',
  pt_BR: 'Findlandês',
  fr_FR: 'Finnois',
  de_DE: 'Finnisch',
  it_IT: 'Finlandese',
};

export const LABEL_DA = {
  es_ES: 'Danés',
  en_US: 'Danish',
  pt_BR: 'Dinamarquês',
  fr_FR: 'Danois',
  de_DE: 'Dänisch',
  it_IT: 'Danese',
};

export const LABEL_SV = {
  es_ES: 'Sueco',
  en_US: 'Swedish',
  pt_BR: 'Sueco',
  fr_FR: 'Suédois',
  de_DE: 'Schewedisch',
  it_IT: 'Svedese',
};

export const TITLE_VIDEO = {
  en_US: 'Request a video consultation',
  it_IT: 'videoconsulto',
  pt_BR: 'Solicitar uma consulta por vídeo',
  es_ES: 'Solicitar una consulta de video',
  de_DE: 'Fragen Sie jetzt eine Video-Beratung an',
  fr_FR: 'Prendre un rendez-vous vidéo',
};

export const TITLE_PHONE = {
  en_US: 'Request a phone call',
  it_IT: 'consulto telefonico',
  pt_BR: 'Solicitar uma ligação',
  es_ES: 'Solicitar una llamada telefónica',
  de_DE: 'Telefonische Beratung anfragen',
  fr_FR: 'Demander à être rappelé',
};

export const PREFFIX_CLIENT = {
  en_US: 'You, ',
  it_IT: 'You, ',
  pt_BR: 'Você, ',
  es_ES: 'Usted, ',
  de_DE: 'Sie, ',
  fr_FR: 'Vous, ',
};

export const PREFFIX_AGE = {
  en_US: ' year(s) old',
  it_IT: ' anni',
  pt_BR: ' ano(s)',
  es_ES: ' años',
  de_DE: ' Jahr(e)',
  fr_FR: ' an(s)',
};

export const SON_OPTION = {
  en_US: 'Son',
  it_IT: 'Figlio',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
  de_DE: 'Sohn',
  fr_FR: 'Fils',
};

export const DAUGHTER_OPTION = {
  en_US: 'Daughter',
  it_IT: 'Figlia',
  pt_BR: 'Filha',
  es_ES: 'Hija',
  de_DE: 'Tochter',
  fr_FR: 'Fille',
};

export const OTHER_OPTION = {
  en_US: 'Other',
  it_IT: 'Altro',
  pt_BR: 'Outro',
  es_ES: 'Otro',
  de_DE: 'Sonstige',
  fr_FR: 'Autre',
};
