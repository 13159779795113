/* eslint-disable react/require-default-props */
import React from 'react';
import {
  FormControl,
  Checkbox as CheckBoxUI,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { useField } from 'formik';

const Checkbox = ({ name, label, placeholder = '', ...props }) => {
  const [field, meta] = useField(name);

  return (
    <>
      <FormControl fullWidth margin="dense" error={!!meta.error}>
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <CheckBoxUI
              placeholder={placeholder}
              checked={meta.value}
              {...props}
              {...field}
            />
          }
          label={label}
        />

        {meta.touched && !!meta.error && (
          <FormHelperText>{meta?.error}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Checkbox;
