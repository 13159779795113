export const REQUEST_VIDEO_CALL_BUTTON = {
  es_ES: 'SOLICITAR UNA CONSULTA DE VIDEO',
  en_US: 'REQUEST A VIDEO CONSULTATION',
  pt_BR: 'SOLICITAR UMA CONSULTA POR VIDEO',
  fr_FR: 'PRENDRE UN RENDEZ-VOUS VIDÉO',
  de_DE: 'FRAGEN SIE JETZT EINE VIDEO-BERATUNG AN',
  it_IT: 'VIDEOCONSULTO',
};

export const ACCESS_CODE_TITLE = {
  en_US: 'Access Code',
  it_IT: 'Codice di accesso',
  pt_BR: 'Código de acesso',
  es_ES: 'Código de acceso',
  de_DE: 'Zugangs Code',
  fr_FR: "Code d'accès",
};

export const ACCESS_CODE_TEXT = {
  en_US:
    'An access code is required in order to access this service, please make sure you have well received the necessary access code.',
  es_ES:
    'Se requiere un código de acceso para acceder a este servicio, asegúrese de haber recibido bien el código de acceso necesario.',
  pt_BR:
    'É necessário um código de acesso para aceder a este serviço, por favor certifique-se de que recebeu bem o código de acesso necessário.',
  it_IT:
    'È necessario un codice di accesso per accedere a questo servizio, assicurati di aver ricevuto il codice di accesso necessario.',
  de_DE:
    'Ein Zugangscode ist erforderlich, um auf diesen Service zugreifen zu können, bitte stellen Sie sicher, dass Sie den erforderlichen Zugangscode gut erhalten haben.',
  fr_FR:
    'Un code d’accès est nécessaire pour accéder à ce service, veuillez vous assurer que vous avez bien reçu le code d’accès nécessaire.',
};

export const ACCESS_CODE_US_TITLE = {
  en_US: 'Access Code',
  it_IT: 'Codice di accesso',
  pt_BR: 'Código de acesso',
  es_ES: 'Código de acceso',
  de_DE: 'Zugangs Code',
  fr_FR: "Code d'accès",
};

export const ACCESS_CODE_US_TEXT = {
  en_US:
    'An access code is required to use the service. To request the access code, please reference the 24/7 phone number provided on your travel assistance documents and give us a call.',
  es_ES:
    'Se requiere un código de acceso para acceder a este servicio, asegúrese de haber recibido bien el código de acceso necesario.',
  pt_BR:
    'É necessário um código de acesso para aceder a este serviço, por favor certifique-se de que recebeu bem o código de acesso necessário.',
  it_IT:
    'È necessario un codice di accesso per accedere a questo servizio, assicurati di aver ricevuto il codice di accesso necessario.',
  de_DE:
    'Ein Zugangscode ist erforderlich, um auf diesen Service zugreifen zu können, bitte stellen Sie sicher, dass Sie den erforderlichen Zugangscode gut erhalten haben.',
  fr_FR:
    'Un code d’accès est nécessaire pour accéder à ce service, veuillez vous assurer que vous avez bien reçu le code d’accès nécessaire.',
};

export const REQUEST_PHONE_CALL_BUTTON = {
  es_ES: 'SOLICITAR UNA LLAMADA TELEFÓNICA',
  en_US: 'REQUEST A PHONE CALL',
  pt_BR: 'SOLICITAR UMA LIGAÇÃO',
  fr_FR: 'DEMANDER À ÊTRE RAPPELÉ',
  de_DE: 'TELEFONISCHE BERATUNG ANFRAGEN',
  it_IT: 'CONSULTO TELEFONICO',
};

export const DOCTOR_PLEASE = {
  es_ES: 'Doctor Please!',
  en_US: 'Doctor Please!',
  pt_BR: 'Doctor Please!',
  fr_FR: 'Doctor Please!',
  de_DE: 'Doctor Please!',
  it_IT: 'Doctor Please!',
};

export const POWERED_BY = {
  es_ES: 'Desarrollado por AXA',
  en_US: 'Powered by AXA',
  pt_BR: 'Distribuído por AXA',
  fr_FR: 'En partenariat avec AXA',
  de_DE: 'Powered by AXA',
  it_IT: 'Powered by AXA',
};

export const SPEAK = {
  es_ES:
    'Hable con un médico internacional para obtener apoyo médico. La prescripción dependerá de la necesidad clínica y de la normativa local.',
  en_US:
    'Speak to an international Physician for medical support. Prescription will depend on the clinical need and local regulations.',
  pt_BR:
    'Para obter apoio médico, contacte um médico internacional. A prescrição dependerá da necessidade clínica e dos regulamentos locais.',
  fr_FR:
    'Consultez un médecin international pour obtenir un soutien médical. La prescription dépendra des besoins cliniques et des réglementations locales.',
  de_DE:
    'Sprechen Sie mit einem internationalen Arzt für medizinische Unterstützung. Die Verschreibung hängt von der klinischen Notwendigkeit und den örtlichen Vorschriften ab.',
  it_IT:
    'Rivolgersi a un medico internazionale per assistenza medica. La prescrizione dipenderà dalle esigenze cliniche e dalle normative locali.',
};

export const SERVICE = {
  es_ES:
    'Servicio ofrecido por Teladoc Health quien actúa como intermediario de transferencia de datos para proporcionar asistencia médica. El servicio se entrega por teléfono o por video. En cualquier lugar y en cualquier momento.',
  en_US:
    'Service delivered by Teladoc Health, who acts as data controller to provide medical support. Service is delivered over the phone, or by video. Anywhere. Anytime.',
  pt_BR:
    'Serviço realizado por Teladoc Health, que age como controlador de dados para oferecer suporte médico. Serviço é realizado por telefone ou por vídeo. Em qualquer lugar. Em qualquer horário.',
  fr_FR:
    "Teladoc Health propose ce service en tant que responsable du traitement de vos données personnelles. Par téléphone, vidéo. N'importe quand, où que vous soyez.",
  de_DE:
    'Ein Service von Teladoc Health, einem Vermittler von medizinischer Beratung per Telefon oder Video. Jederzeit , überall.',
  it_IT:
    'Servizio fornito da Teladoc Health, che agisce in qualità di responsabile del trattamento dei dati per fornire assistenza medica. Il servizio viene fornito per telefono o tramite video. Ovunque. In qualsiasi momento.',
};

export const PLEASE_CONTACT = {
  es_ES:
    'Póngase en contacto por teléfono con AXA si se encuentra actualmente en el hospital o necesita atención médica de emergencia.',
  en_US:
    'Please contact AXA by phone if you are currently in hospital or require emergency medical assistance.',
  pt_BR:
    'Por favor entre em contato com AXA por telefone caso você esteja em um hospital ou necessite de assistência médica de emergência.',
  fr_FR:
    "Si vous êtes à l'hôpital ou en situation d'urgence, merci de contacter votre service d'assistance AXA par téléphone.",
  de_DE:
    'Bitte kontaktieren Sie die AXA falls Sie sich aktuell in einem Krankenhaus befinden oder Sie medizinische Soforthilfe benötigen.',
  it_IT:
    'In caso di ricovero o per emergenze mediche, contatta AXA via telefono.',
};
