export const USER_TERMS = {
  es_ES: 'Términos y condiciones del usuario',
  en_US: 'User Terms and Conditions',
  pt_BR: 'Termos de uso e condições',
  fr_FR: 'Conditions générales d’utilisation',
  de_DE: 'Allgemeine Geschäftsbedingungen',
  it_IT: 'Termini e Condizioni',
};

export const PRIVACY = {
  es_ES: 'Política de privacidad',
  en_US: 'Privacy Policy',
  pt_BR: 'Política de privacidade',
  fr_FR: 'Politique de confidentialité',
  de_DE: 'PRIVATSPHÄRE EINSTELLUNGEN',
  it_IT: 'Privacy',
};

export const CLOSE = {
  es_ES: 'Cerrar',
  en_US: 'Close',
  pt_BR: 'Fechar',
  fr_FR: 'Fermer',
  de_DE: 'Nah dran',
  it_IT: 'Vicino',
};
