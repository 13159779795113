/* 
  Modification made to include Gateway Sql Injector.
  Old cookies moved to sessionStorage and encrypted by override in Storage.prototype, using crypto-js.
*/
const arrayToSession = [
  'amosTeladocApiKey',
  'amosTeladocToken',
  'amosTeladocSessionId',
  'amosToken',
  'amosRefreshToken',
  'amosClient',
  'lastStatusVideoCall',
  'amosTeladocId',
  'appointmentCardId',
  'appointmentVideoPage',
  'appointmentcardId',
  'theme',
];

export const parseCookie = (name) => {
  return JSON.parse(decodeURIComponent(getCookie(name)));
};

function escapeCustom(s) {
  return s.replace(/\/([.*+?/\\^${}()|/\\[\]/\\/\\])/g, '\\$1');
}

export const getCookie = (name) => {
  // Condition to move old cookies
  if (!arrayToSession.includes(name)) {
    var match = document.cookie.match(
      RegExp('(?:^|;\\s*)' + escapeCustom(name) + '=([^;]*)')
    );
    return match ? decodeURI(match[1]) : null;
  }
  return sessionStorage.getItem(name);
};

export const setCookie = (name, value, days) => {
  // Condition to move old cookies
  if (!arrayToSession.includes(name)) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie =
      name + '=' + (encodeURI(value) || '') + expires + '; path=/';
  } else {
    sessionStorage.setItem(name, value);
  }
};

const noDeleteCookies = [' type', 'type', 'idiom', ' idiom'];

export const clearListCookies = () => {
  sessionStorage.clear();
  localStorage.clear();
  var cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var spcook = cookies[i].split('=');
    if (!noDeleteCookies.includes(spcook[0])) deleteCookie(spcook[0]);
  }
};
export const deleteCookie = (nome) => {
  var data = new Date(2010, 0, 1);
  data = data.toGMTString();
  document.cookie = nome + '=; expires=' + data + '; path=/';
}
export const clearAllCookies = () => {
  var cookies = document.cookie.split(';');
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split('.');
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path=';
      var p = window.location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
};
