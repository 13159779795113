/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/require-default-props */
import React from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { useField } from 'formik';

import styles from './styles';

const RadioButton = ({
  name,
  options,
  disabled,
  title,
  layoutHorizontally = false,
  blueTitle = false,
  noError = false,
  ...radioGroupProps
}) => {
  const [field, meta, fieldProps] = useField(name);
  const classes = styles();

  const handleChange = (e) => {
    fieldProps.setValue(e.target.value);
  };

  return (
    <FormControl component="fieldset" margin="dense">
      {title && (
        <Typography
          className={`${classes.title} ${blueTitle && classes.titleBlue}`}
        >
          {title}
        </Typography>
      )}
      <RadioGroup
        onChange={handleChange}
        aria-label={field.name}
        value={field.value}
        row={layoutHorizontally}
        className={
          layoutHorizontally ? classes.horizontallyMarginTop : undefined
        }
        {...field}
        {...radioGroupProps}
      >
        {options.map((option, index) => {
          const { label: lab, value } = option;

          return (
            <>
              <FormControlLabel
                data-testid={`${name}-${value}`}
                // eslint-disable-next-line react/no-array-index-key
                key={`${name}-radio-${index}`}
                id={`${name}-radio-${index}`}
                value={value}
                control={
                  <Radio
                    color="secondary"
                    key={`${index}-radio-${name}`}
                    classes={{
                      root: !layoutHorizontally
                        ? classes.radioVerticalRoot
                        : index > 0
                        ? classes.radioMarginLeft
                        : undefined,
                    }}
                  />
                }
                label={lab}
                disabled={disabled}
                classes={{ label: classes.titleRadio }}
              />
            </>
          );
        })}
      </RadioGroup>
      {meta?.touched && meta?.error && !noError && (
        <FormHelperText error>{meta?.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default RadioButton;
