export function closeConfirm() {
  const confirm = {
    ...this.state.confirm,
    show: false,
  };
  this.setState({ confirm });
}

export function showConfirm(message, title = '', confirmFn, denyFn) {
  const confirm = {
    message: message,
    title: title,
    confirmFunction: confirmFn,
    denyFunction: denyFn,
    show: true,
  };
  this.setState({ confirm });
}
