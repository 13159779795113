export {
  VALIDATION_REQUIRED,
  LABEL_PT,
  LABEL_ES,
  LABEL_EN,
  LABEL_FR,
  LABEL_DE,
  LABEL_IT,
  LABEL_FI,
  LABEL_DA,
  LABEL_SV,
  TITLE_VIDEO,
  TITLE_PHONE,
  PREFFIX_CLIENT,
  PREFFIX_AGE,
  SON_OPTION,
  DAUGHTER_OPTION,
  OTHER_OPTION,
} from '../../shared/constants/locale';

export const SUBTITLE_REQUEST = {
  en_US: 'Appointment Details',
  it_IT: 'Dettagli Appuntamento',
  pt_BR: 'Detalhes da consulta',
  es_ES: 'Detalles de su consulta',
  de_DE: 'Termin Details',
  fr_FR: 'Détails de votre rendez-vous',
};

export const TITLE_NOW_WHERE_YOU_BE = {
  en_US: 'Okay, we need to know where you will be.',
  it_IT: 'Ok, dobbiamo sapere dove sarai',
  pt_BR: 'OK, nós precisamos saber onde você estará.',
  es_ES: 'Ok, necesitamos saber dónde estarás.',
  de_DE: 'Wo befinden Sie sich?',
  fr_FR: 'Ou êtes-vous actuellement?',
};

export const COUNTRY_TEXT = {
  en_US: 'Different levels of service can be provided country by country, and prescription capabilities may vary. This information is needed to put you in contact with the most appropriate doctor available.',
  it_IT: 'I livelli di servizio possono essere diversi da Paese a Paese e le capacità di prescrizione possono variare. Queste informazioni sono necessarie per mettervi in contatto con il medico più appropriato disponibile.',
  pt_BR: 'Podem ser prestados diferentes níveis de serviço em cada país e as capacidades de prescrição podem variar. Esta informação é necessária para o colocar em contacto com o médico mais adequado disponível.',
  es_ES: 'Los niveles de servicio pueden variar de un país a otro, y la capacidad de prescripción puede variar. Esta información es necesaria para ponerle en contacto con el médico más adecuado disponible.',
  de_DE: 'Je nach Land werden unterschiedliche Leistungen angeboten, und die Verschreibungsmöglichkeiten können variieren. Diese Informationen werden benötigt, um Sie mit dem am besten geeigneten Arzt in Verbindung zu bringen.',
  fr_FR: "Différents niveaux de service peuvent être fournis d'un pays à l'autre et les possibilités de prescription peuvent varier. Ces informations sont nécessaires pour vous mettre en contact avec le médecin le plus approprié.",
};

export const UPLOAD_IMAGE_TITLE = {
  en_US: 'Securely Upload Up To 5 Pictures',
  it_IT: 'Carica in modo sicuro fino ad un max di 5 foto',
  pt_BR: 'Enviar com segurança até 5 fotos',
  es_ES: 'Suba de forma segura hasta 5 fotos',
  de_DE: 'Sie können bis zu 5 Fotos hochladen',
  fr_FR: "Chargez jusqu'à 5 photos de façon sécurisée",
};

export const COUNTRY_FIELD = {
  en_US: 'Country',
  it_IT: 'Paese',
  pt_BR: 'País',
  es_ES: 'País',
  de_DE: 'Land',
  fr_FR: 'Pays',
};

export const TELEPHONE_NUMBER_FIELD = {
  en_US: 'Telephone Number',
  it_IT: 'Recapito telefonico',
  pt_BR: 'Número de telefone',
  es_ES: 'Número de teléfono',
  de_DE: 'Telefonnummer',
  fr_FR: 'Numéro de téléphone',
};

export const COUNTRY_CODE_FIELD = {
  en_US: 'Country Code',
  it_IT: 'Prefisso Internazionale',
  pt_BR: 'Código do país',
  es_ES: 'Código del país',
  de_DE: 'Ländesvorwahl',
  fr_FR: 'Indicatif pays',
};

export const DROPZONE_TEXT = {
  en_US: 'UPLOAD FILE',
  it_IT: 'CARICARE UN FILE',
  pt_BR: 'SUBIR ARQUIVO',
  es_ES: 'SUBIR ARCHIVO',
  de_DE: 'DATEI HOCHLADEN',
  fr_FR: 'télécharger un fichier',
};

export const DROPZONE_PREVIEW_TEXT = {
  en_US: 'PREVIEW',
  it_IT: 'anteprima',
  pt_BR: 'visualizar',
  es_ES: 'avance',
  de_DE: 'Vorschau',
  fr_FR: 'Aperçu',
};

export const APPOINTMENT_TIME_SECTION = {
  en_US: 'Please Select An Appointment Time',
  it_IT: 'Seleziona l’orario dell’appuntamento',
  pt_BR: 'Por favor selecione uma consulta',
  es_ES: 'Seleccione La Hora de la Cita',
  de_DE: 'Bitte wählen Sie einen Termin',
  fr_FR: "Choisissez l'heure du rendez-vous",
};

export const SUBMIT_BUTTON = {
  en_US: 'SUBMIT',
  it_IT: 'INVIA',
  pt_BR: 'ENVIAR',
  es_ES: 'ENVIAR',
  de_DE: 'SENDEN',
  fr_FR: 'SOUMETTRE',
};

export const BACK_BUTTON = {
  en_US: 'BACK',
  it_IT: 'PRECEDENTE',
  pt_BR: 'VOLTAR',
  es_ES: 'ATRÁS',
  de_DE: 'ZURÜCK',
  fr_FR: 'RETOUR',
};

export const TYPE_CONSULTATION_FIELD = {
  en_US: 'Type of consultation',
  it_IT: 'Tipo di consulenza',
  pt_BR: 'Tipo de consulta',
  es_ES: 'Tipo de consulta',
  de_DE: 'Art der Beratung',
  fr_FR: 'Type de consultation',
};

export const TYPE_CONSULTATION = {
  es_ES: 'Consulta general',
  en_US: 'General Consultation',
  pt_BR: 'Consulta Geral',
  fr_FR: 'Consultation générale',
  de_DE: 'Allgemeinmedizin',
  it_IT: 'Medicina generale',
};

export const REASON_FIELD = {
  en_US: 'Reason for Appointment',
  it_IT: 'Motivo dell’appuntamento',
  pt_BR: 'Motivo da consulta',
  es_ES: 'Motivo de su consulta',
  de_DE: 'Grund der Terminvereinbarung',
  fr_FR: 'Motif du rendez-vous',
};

export const LANGUAGE_FIELD = {
  en_US: 'Language',
  it_IT: 'Lingua',
  pt_BR: 'Idioma',
  es_ES: 'idioma',
  de_DE: 'Sprache',
  fr_FR: 'Langue',
};

export const TIMEZONE_FIELD = {
  en_US: 'Timezone',
  it_IT: 'Fuso orario',
  pt_BR: 'Fuso horário',
  es_ES: 'Zona horaria',
  de_DE: 'Zeitzone',
  fr_FR: 'Fuseau horaire',
};

export const APPOINTMENT_BOOKED_TITLE = {
  en_US: 'Your appointment has been booked.',
  it_IT: 'Il tuo appuntamento è stato prenotato.',
  pt_BR: 'Sua consulta foi agendada.',
  es_ES: 'Detalles de la cita',
  de_DE: 'Ihr Termin wurde erfolgreich vereinbart',
  fr_FR: 'Votre rendez-vous est confirmé.',
};

export const APPOINTMENT_BOOKED_SUBTITLE_VIDEO = {
  en_US:
    'We have sent you an email with your appointment details. You can access your Doctor Please! Appointment up to 5 minutes before your appointment time.',
  it_IT:
    'Hai ricevuto una mail con i dettagli dell’appuntamento. Puoi collegarti 5minuti prima dello stesso su Doctor Please!',
  pt_BR:
    'Nós lhe enviamos um e-mail com os detalhes de sua consulta. Você pode acessar sua consulta com Doctor Please! até 5 minutos antes do horário de sua consulta. ',
  es_ES:
    'Le hemos enviado un correo electrónico con los detalles de su cita. Puede acceder a su consulta 5 minutos antes de la hora de su cita a través de su portal web.',
  de_DE:
    'Wir haben Ihnen eine Email, zu den Details des soeben vereinbarten Termins, gesendet. Sie können sich, ab 5 Minuten vor dem vereinbarten Termin, in Ihrem Nutzerprofil in den Termin einwählen.',
  fr_FR:
    "Nous vous avons envoyé un e-mail avec les détails de votre rendez-vous. Veuillez vous connecter au portail Doctor Please! 5 minutes avant l'heure de votre rendez-vous.",
};

export const APPOINTMENT_BOOKED_SUBTITLE_PHONE = {
  en_US: 'A doctor will call you back as soon as possible',
  it_IT: 'Un medico vi richiamerà il prima possibile',
  pt_BR: 'Um médico telefonar-lhe-á de volta o mais depressa possível',
  es_ES: 'Un médico le llamará lo antes posible',
  de_DE: 'Ein Arzt wird Sie so bald wie möglich zurückrufen.',
  fr_FR: 'Un médecin vous rappellera par téléphone dès que possible',
};
