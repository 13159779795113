type Translations = {
  [key: string]: any;
};

const translate = (translations: Translations, idiom: string): any => {
  const translated: Translations = {};
  const keys = Object.keys(translations);
  keys.forEach((key: string) => {
    translated[key] = translations[key][idiom];
  });
  return translated;
};

export default translate;
