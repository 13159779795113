import React, { memo } from 'react';
import moment from 'moment';
import { useTranslation } from '../../../../../../../shared/hooks';
import * as translations from '../../intl';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { Button } from '../../../../../../../components';

function JoinNow({
  videoId,
  gotTime,
  appointDateTime,
  onHandleStartVideoCall,
  status = '',
  onDetails = false,
}) {
  const { locale: intl } = useTranslation({
    translationsFile: translations,
  });
  const { TITLE_APPOINTMENT_JOIN_NOW } = intl;

  function getTimeDiff(start, end) {
    return moment.duration(
      moment(end, 'HH:mm:ss a').diff(moment(start, 'HH:mm:ss a'))
    );
  }

  function handleEnableJoinNow() {
    const dateTime = moment(appointDateTime, 'YYYY-MM-DD HH:mm');
    const appointDate = moment(dateTime).format('YYYY-MM-DD');
    const appointTime = moment(dateTime).format('HH:mm');

    const respDateTime = moment(gotTime, 'YYYY-MM-DD HH:mm');
    const respDate = moment(respDateTime).format('YYYY-MM-DD');
    const respTime = moment(respDateTime).format('HH:mm');

    const diff = getTimeDiff(respTime, appointTime);

    if (onDetails) {
      return (
        <Button
          variant="contained"
          onClick={() => onHandleStartVideoCall(videoId)}
          startIcon={<VideocamOutlinedIcon />}
          text={TITLE_APPOINTMENT_JOIN_NOW}
        ></Button>
      );
    }
    if (moment(respDate).isSame(appointDate) && diff.minutes() <= 5) {
      return (
        <Button
          color="primary"
          onClick={() => onHandleStartVideoCall(videoId)}
          startIcon={<VideocamOutlinedIcon />}
          text={TITLE_APPOINTMENT_JOIN_NOW}
        ></Button>
      );
    }
    return <>{status}</>;
  }
  return <>{handleEnableJoinNow()}</>;
}

export default memo(JoinNow);
