import React, { useRef } from 'react';
import {
  FormControl,
  TextFieldProps,
  Typography,
  OutlinedInput as MuiOutlinedInput,
  FormHelperText,
} from '@material-ui/core';
import { FastField, useField } from 'formik';
import { useFocusOnError } from '../../shared/hooks';
import styles from './styles';
// noCase here just to avoid breaking other (legacy) components. Should be removed on clean up.

const InputText = ({
  name,
  label,
  placeholder,
  inputProps,
  maxLength,
  className,
  lightBorder = true,
  startIcon,
  endIcon,
  labelGrey = false,
  labelMargin = false,
  ...props
}) => {
  const fieldRef = useRef();
  const classes = styles();
  const [, metaError] = useField(name);
  useFocusOnError(fieldRef, name, `form-field-${name}`);
  return (
    <div className={className}>
      <FormControl fullWidth variant="outlined" className={classes.input}>
        {label && (
          <Typography
            className={`${classes.title} ${labelGrey && classes.titleColor} ${
              labelMargin && classes.titleMargin
            }`}
          >
            {label}
          </Typography>
        )}
        <FastField name={name}>
          {({ field, meta }) => {
            return (
              <MuiOutlinedInput
                {...field}
                {...props}
                type="text"
                error={meta.touched && !!meta.error}
                id={`form-field-${name}`}
                placeholder={placeholder}
                inputProps={{
                  ...inputProps,
                  maxLength: maxLength || 75,
                  autoComplete: 'off',
                  form: {
                    autoComplete: 'off',
                  },
                }}
                ref={fieldRef}
                classes={{
                  root: lightBorder
                    ? classes.inputLightRoot
                    : classes.inputDarkRoot,
                  input: classes.inputOutlined,
                  notchedOutline: classes.notchedOutline,
                }}
                startAdornment={startIcon}
                endAdornment={endIcon}
              />
            );
          }}
        </FastField>
        {metaError.touched && metaError?.error && (
          <FormHelperText error>{metaError.error}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default InputText;
