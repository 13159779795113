export const TAB_TITLE_VIDEO = {
  es_ES: 'Video Consulta',
  en_US: 'Video consultation',
  pt_BR: 'Consulta por vídeo',
  fr_FR: 'Rendez-vous vidéo',
  de_DE: 'Video Beratung',
  it_IT: 'Videoconsulto',
};

export const TAB_TITLE_AUDIO = {
  es_ES: 'Llamadas telefónica',
  en_US: 'Phone Call',
  pt_BR: 'Ligações',
  fr_FR: 'Rendez-vous téléphonique',
  de_DE: 'Telefonische Beratung',
  it_IT: 'Telefonate',
};

export const TITLE_APPOINTMENT_JOIN_NOW = {
  es_ES: 'Iniciar video Consulta',
  en_US: 'START VIDEO CALL',
  pt_BR: 'Iniciar chamada por vídeo',
  fr_FR: 'DÉMARRER LA VIDÉO',
  de_DE: 'Video Beratung starten',
  it_IT: 'Inizia la videochiamata',
};
