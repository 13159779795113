import React, { useMemo, useContext, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import {
  InputText,
  InputMask,
  AutoComplete,
  DarkTitle,
  Button,
  ConfirmationModal,
  DatePicker,
} from '../../../components';
import { useTranslation } from '../../../shared/hooks';
import * as translations from '../intl';
import { maskedCellphone } from '../../../shared/constants/masks';
import { Formik, Form } from 'formik';
import { GeneralContext } from '../../../shared/contexts/GeneralContext';
import api from '../../../services';
import * as Yup from 'yup';
import Popup from './Popup';

const styles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Publico Headline !important',
    fontSize: '36px !important',
    lineHeight: '60px !important',
    color: theme.palette.text.main,
    paddingBottom: 42,
  },
  subTitle: {
    fontFamily: 'Publico Headline',
    fontSize: '25px',
    lineHeight: '30px',
    letterSpacing: '0.8px',
    padding: '10px 50px',
    color: theme.palette.text.main,
  },
  containerInput: {
    paddingTop: '44px !important',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  paperContent: {
    width: '100%',
  },
  container: {
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  linkCheckbox: {
    fontWeight: 700,
    fontFamily: 'Source Sans Pro !important',
    color: theme.palette.primary.main,
  },
  
  textCheckbox: {
    color: theme.palette.text.main,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Source Sans Pro !important',
    marginBottom: 0,
  },
  paddingFooterFlexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '25px 29px',
    borderTop: '1px solid rgba(229, 229, 229, 1)',
  },
  paddingFooterSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '25px 29px',
    borderTop: '1px solid rgba(229, 229, 229, 1)',
  },
}));

function PatientForm({ payload, type, handleClose, haveDelete = false }) {
  const {
    clientCode,
    amosToken,
    amosClient,
    country,
    countryDdi,
    refreshClientAndDependents,
    setRefreshClientAndDependents,
    setIsLoading,
    ispopup,
    setIspopup,
  } = useContext(GeneralContext);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [dependentId, setDepedentId] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { locale } = useTranslation({
    translationsFile: translations,
  });
  const classes = styles();
  const {
    LABEL_ES,
    LABEL_EN,
    LABEL_PT,
    LABEL_FR,
    LABEL_DE,
    LABEL_IT,
    VALIDATION_REQUIRED,
    FIRSTNAME_FIELD,
    LASTNAME_FIELD,
    EMAILADDRESS_FIELD,
    DATEOFBIRTH_FIELD,
    GENDER_FIELD,
    MALE_LABEL,
    FEMALE_LABEL,
    LANGUAGE_FIELD,
    COUNTRY_RESIDENCE_FIELD,
    SELECT_A_COUNTRY,
    COUNTRY_CODE_FIELD,
    TELEPHONE_NUMBER_FIELD,
    SUBMIT_BUTTON,
    RELATION_TYPE_FIELD,
    SON_OPTION,
    DAUGHTER_OPTION,
    OTHER_OPTION,
    REMOVE_BUTTON,
    VALIDATION_VALID_DATE,
    VALIDATION_MUST_BE_UNDER_18,
    VALIDATION_MUST_BE_THAN_18,
    CONFIRMATION_TITLE,
  } = locale;

  const genderOptions = useMemo(
    () => [
      {
        label: MALE_LABEL,
        value: 'male',
      },
      {
        label: FEMALE_LABEL,
        value: 'female',
      },
    ],
    []
  );

  const relationOptions = useMemo(
    () => [
      {
        label: SON_OPTION,
        value: 'son',
      },
      {
        label: DAUGHTER_OPTION,
        value: 'daughter',
      },
      {
        label: OTHER_OPTION,
        value: 'other',
      },
    ],
    []
  );

  const initialValues = useMemo(() => {
    if (type === 'CL')
      return {
        clientCode: payload.clientCode,
        firstName: payload.name,
        lastName: payload.surname,
        birthDate: payload.birthDate || null,
        gender: payload.gender,
        email: payload.email,
        countryCode: payload.phonePrefix,
        cellphone: payload.phone,
        countryResidence: payload.country,
        language: payload.language,
      };
    if (type === 'FM')
      return {
        relationType: payload.relationType,
        clientCode: payload.clientCode,
        firstName: payload.name,
        lastName: payload.surname,
        birthDate: payload.birthDate || null,
        gender: payload.gender,
        email: payload.email,
        countryCode: payload.phonePrefix,
        dependentCode: payload.dependentCode,
        cellphone: payload.phone,
        dependentId: payload.id,
        countryResidence: payload.country,
        language: payload.language,
      };
  }, [payload, type]);

  const emailInput = document.querySelector('[name="email"]')
  if(emailInput){
    emailInput.style.color = "rgba(0, 0, 0, 0.5)";
  }

if(window.location.hostname == process.env.REACT_APP_DOMAIN_INSUREFOR) {

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(VALIDATION_REQUIRED),
    lastName: Yup.string().required(VALIDATION_REQUIRED),
    // birthDate: Yup.date()
    //   .when([], {
    //     is: () => type === 'FM',
    //     then: Yup.date()
    //       .required(VALIDATION_REQUIRED)
    //       .min(new Date(1900, 0, 0), VALIDATION_VALID_DATE)
    //       .max(new Date(), VALIDATION_VALID_DATE)
    //       .typeError(VALIDATION_VALID_DATE)
    //       .test('age-validation-under', VALIDATION_MUST_BE_UNDER_18, (item) => {
    //         const year = new Date().getFullYear();
    //         const age = parseInt(year) - parseInt(moment(item).format('yyyy'));
    //         return age <= 18;
    //       }),
    //     otherwise: Yup.date()
    //       .required(VALIDATION_REQUIRED)
    //       .min(new Date(1900, 0, 0), VALIDATION_VALID_DATE)
    //       .max(new Date(), VALIDATION_VALID_DATE)
    //       .typeError(VALIDATION_VALID_DATE)
    //       .test('age-validation-older', VALIDATION_MUST_BE_THAN_18, (item) => {
    //         const year = new Date().getFullYear();
    //         const age = parseInt(year) - parseInt(moment(item).format('yyyy'));
    //         return age >= 18;
    //       }),
    //   })
    //   .nullable(),
    gender: Yup.string().required(VALIDATION_REQUIRED),
    countryResidence: Yup.string().required(VALIDATION_REQUIRED),
    relationType: Yup.string().when([], {
      is: () => type === 'FM',
      then: Yup.string().nullable().required(VALIDATION_REQUIRED),
      otherwise: Yup.string().nullable().notRequired(VALIDATION_REQUIRED),
    }),
    language: Yup.string().required(VALIDATION_REQUIRED),
    email: Yup.string().email().required(VALIDATION_REQUIRED),
    countryCode: Yup.string().required(VALIDATION_REQUIRED),
    cellphone: Yup.string().required(VALIDATION_REQUIRED),
  });

  const handleDeleteFM = async (id) => {
    try {
      setIsLoading(true);
      setOpenConfirm(false);
      const sendData = {
        clientToken: amosToken,
        clientId: amosClient,
      };

      await api.amosServices.deleteAmosDependets(sendData, id);
      setRefreshClientAndDependents(!refreshClientAndDependents);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      // const birthDate = new Date(values.birthDate);
      // const currentDate = new Date();
      // const age = currentDate - birthDate;
      //console.log(values.birthDate._i)
      // if (age >= 18) {
      //   setIspopup(true);
      // }

const birthDate = values.birthDate._i;
const currentDate = new Date();
const parsedBirthDate = new Date(birthDate);
const yearDiff = currentDate.getFullYear() - parsedBirthDate.getFullYear();
const monthDiff = currentDate.getMonth() - parsedBirthDate.getMonth();
const dayDiff = currentDate.getDate() - parsedBirthDate.getDate();
const isAgeAbove18 = yearDiff > 18 || (yearDiff === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)));

if (isAgeAbove18) {
     setIspopup(true);
 }
      if (type === 'CL') {
        const sendData = {
          clientCode: clientCode,
          clientToken: amosToken,
          name: values.firstName,
          surname: values.lastName,
          gender: values.gender,
          birthDate: moment(values.birthDate).format('yyyy-MM-DD'),
          country: values.countryResidence,
          language: values.language,
          email: values.email,
          phonePrefix: values.countryCode,
          phone: values.cellphone,
        };

        await api.amosServices.amosUpdateClient(sendData);
      }

      if (type === 'FM') {
        const sendData = {
          relationType: values.relationType,
          name: values.firstName,
          surname: values.lastName,
          gender: values.gender,
          birthDate: moment(values.birthDate).format('yyyy-MM-DD'),
          country: values.countryResidence,
          language: values.language,
          email: values.email,
          phonePrefix: values.countryCode,
          phone: values.cellphone,
          clientId: amosClient,
          clientToken: amosToken,
        };

        if (values.dependentId)
          await api.amosServices.putAmosDependets(sendData, values.dependentId);
        else await api.amosServices.postAmosDependets(sendData);
      }
      handleClose();
      setRefreshClientAndDependents(!refreshClientAndDependents);
      window.scrollTo(0, 0);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <>
    <Container className={classes.container}>
      <ConfirmationModal
        open={openConfirm}
        title={CONFIRMATION_TITLE}
        handleAction={() => {
          handleDeleteFM(dependentId);
        }}
        handleClose={() => {
          setOpenConfirm(false);
        }}
      />
      <Grid item md={12} justifyContent="center" className="d-flex">
        <Formik
          initialValues={initialValues}
          enabledRenitialized
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched }) => (
            <Form>
              <Container className={classes.containerInput}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputText name="firstName" label={FIRSTNAME_FIELD} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputText name="lastName" label={LASTNAME_FIELD} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <DatePicker name="birthDate" label={DATEOFBIRTH_FIELD} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <AutoComplete
                      name="gender"
                      label={GENDER_FIELD}
                      showPlaceholder
                      options={genderOptions}
                    />
                  </Grid>
                  {type == 'FM' && (
                    <Grid item xs={12} sm={12} md={6}>
                      <AutoComplete
                        name="relationType"
                        label={RELATION_TYPE_FIELD}
                        showPlaceholder
                        options={relationOptions}
                      />
                    </Grid>
                  )}
                  {type == 'FM' && (
                    <Grid item xs={12} sm={12} md={6}>
                      <InputText name="email" label={EMAILADDRESS_FIELD} />
                    </Grid>
                  )}
                  {type != 'FM' && (
                    <Grid item xs={12} sm={12} md={6}>
                      <InputText name="email" label={EMAILADDRESS_FIELD} disabled />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid item xs={12} sm={12} md={12}>
                      <DarkTitle label={TELEPHONE_NUMBER_FIELD} />
                    </Grid>
                    <Grid
                      container
                      className="d-flex align-items-center"
                      spacing={2}
                    >
                      <Grid item xs={5} md={3}>
                        <AutoComplete
                          name="countryCode"
                          showPlaceholder
                          placeholder="+00"
                          options={countryDdi}
                        />
                      </Grid>
                      <Grid item xs={7} md={9}>
                        <InputMask name="cellphone" mask={maskedCellphone} />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <p className={classes.subText}>{COUNTRY_CODE_FIELD}</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <AutoComplete
                      name="countryResidence"
                      showPlaceholder
                      placeholder={SELECT_A_COUNTRY}
                      label={COUNTRY_RESIDENCE_FIELD}
                      options={country}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <AutoComplete
                      name="language"
                      label={LANGUAGE_FIELD}
                      showPlaceholder
                      options={[
                        { label: LABEL_ES, value: 'es' },
                        { label: LABEL_EN, value: 'en' },
                        { label: LABEL_PT, value: 'pt' },
                        { label: LABEL_FR, value: 'fr' },
                        { label: LABEL_DE, value: 'de' },
                        { label: LABEL_IT, value: 'it' },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Container>
              <Grid
                container
                className={
                  haveDelete
                    ? classes.paddingFooterSpaceBetween
                    : classes.paddingFooterFlexEnd
                }
              >
                <Button
                  type="submit"
                  text={SUBMIT_BUTTON}
                  variant="contained"
                  color="primary"
                />
                {haveDelete && (
                  <Button
                    text={REMOVE_BUTTON}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setDepedentId(values.dependentId);
                      setOpenConfirm(true);
                    }}
                  />
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    
    </Container> {ispopup && (
                    <Popup/>
                    )}</>
  );
  } else {
    const validationSchema = Yup.object().shape({
      firstName: Yup.string().required(VALIDATION_REQUIRED),
      lastName: Yup.string().required(VALIDATION_REQUIRED),
      birthDate: Yup.date()
        .when([], {
          is: () => type === 'FM',
          then: Yup.date()
            .required(VALIDATION_REQUIRED)
            .min(new Date(1900, 0, 0), VALIDATION_VALID_DATE)
            .max(new Date(), VALIDATION_VALID_DATE)
            .typeError(VALIDATION_VALID_DATE)
            .test('age-validation-under', VALIDATION_MUST_BE_UNDER_18, (item) => {
              const year = new Date().getFullYear();
              const age = parseInt(year) - parseInt(moment(item).format('yyyy'));
              return age <= 18;
            }),
          otherwise: Yup.date()
            .required(VALIDATION_REQUIRED)
            .min(new Date(1900, 0, 0), VALIDATION_VALID_DATE)
            .max(new Date(), VALIDATION_VALID_DATE)
            .typeError(VALIDATION_VALID_DATE)
            .test('age-validation-older', VALIDATION_MUST_BE_THAN_18, (item) => {
              const year = new Date().getFullYear();
              const age = parseInt(year) - parseInt(moment(item).format('yyyy'));
              return age >= 18;
            }),
        })
        .nullable(),
      gender: Yup.string().required(VALIDATION_REQUIRED),
      countryResidence: Yup.string().required(VALIDATION_REQUIRED),
      relationType: Yup.string().when([], {
        is: () => type === 'FM',
        then: Yup.string().nullable().required(VALIDATION_REQUIRED),
        otherwise: Yup.string().nullable().notRequired(VALIDATION_REQUIRED),
      }),
      language: Yup.string().required(VALIDATION_REQUIRED),
      email: Yup.string().email().required(VALIDATION_REQUIRED),
      countryCode: Yup.string().required(VALIDATION_REQUIRED),
      cellphone: Yup.string().required(VALIDATION_REQUIRED),
    });
  
    const handleDeleteFM = async (id) => {
      try {
        setIsLoading(true);
        setOpenConfirm(false);
        const sendData = {
          clientToken: amosToken,
          clientId: amosClient,
        };
  
        await api.amosServices.deleteAmosDependets(sendData, id);
        setRefreshClientAndDependents(!refreshClientAndDependents);
      } catch (err) {
        setIsLoading(false);
      }
    };
  
    const handleSubmit = async (values) => {
      try {
        setIsLoading(true);
      
        if (type === 'CL') {
          const sendData = {
            clientCode: clientCode,
            clientToken: amosToken,
            name: values.firstName,
            surname: values.lastName,
            gender: values.gender,
            birthDate: moment(values.birthDate).format('yyyy-MM-DD'),
            country: values.countryResidence,
            language: values.language,
            email: values.email,
            phonePrefix: values.countryCode,
            phone: values.cellphone,
          };
  
          await api.amosServices.amosUpdateClient(sendData);
        }
  
        if (type === 'FM') {
          const sendData = {
            relationType: values.relationType,
            name: values.firstName,
            surname: values.lastName,
            gender: values.gender,
            birthDate: moment(values.birthDate).format('yyyy-MM-DD'),
            country: values.countryResidence,
            language: values.language,
            email: values.email,
            phonePrefix: values.countryCode,
            phone: values.cellphone,
            clientId: amosClient,
            clientToken: amosToken,
          };
  
          if (values.dependentId)
            await api.amosServices.putAmosDependets(sendData, values.dependentId);
          else await api.amosServices.postAmosDependets(sendData);
        }
        handleClose();
        setRefreshClientAndDependents(!refreshClientAndDependents);
        window.scrollTo(0, 0);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };
  
    return (
      <Container className={classes.container}>
        <ConfirmationModal
          open={openConfirm}
          title={CONFIRMATION_TITLE}
          handleAction={() => {
            handleDeleteFM(dependentId);
          }}
          handleClose={() => {
            setOpenConfirm(false);
          }}
        />
        <Grid item md={12} justifyContent="center" className="d-flex">
          <Formik
            initialValues={initialValues}
            enabledRenitialized
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, errors, touched }) => (
              <Form>
                <Container className={classes.containerInput}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                      <InputText name="firstName" label={FIRSTNAME_FIELD} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <InputText name="lastName" label={LASTNAME_FIELD} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <DatePicker name="birthDate" label={DATEOFBIRTH_FIELD} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <AutoComplete
                        name="gender"
                        label={GENDER_FIELD}
                        showPlaceholder
                        options={genderOptions}
                      />
                    </Grid>
                    {type == 'FM' && (
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoComplete
                          name="relationType"
                          label={RELATION_TYPE_FIELD}
                          showPlaceholder
                          options={relationOptions}
                        />
                      </Grid>
                    )}
                    {type == 'FM' && (
                      <Grid item xs={12} sm={12} md={6}>
                        <InputText name="email" label={EMAILADDRESS_FIELD} />
                      </Grid>
                    )}
                    {type != 'FM' && (
                      <Grid item xs={12} sm={12} md={6}>
                        <InputText name="email" label={EMAILADDRESS_FIELD} disabled />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid item xs={12} sm={12} md={12}>
                        <DarkTitle label={TELEPHONE_NUMBER_FIELD} />
                      </Grid>
                      <Grid
                        container
                        className="d-flex align-items-center"
                        spacing={2}
                      >
                        <Grid item xs={5} md={3}>
                          <AutoComplete
                            name="countryCode"
                            showPlaceholder
                            placeholder="+00"
                            options={countryDdi}
                          />
                        </Grid>
                        <Grid item xs={7} md={9}>
                          <InputMask name="cellphone" mask={maskedCellphone} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <p className={classes.subText}>{COUNTRY_CODE_FIELD}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <AutoComplete
                        name="countryResidence"
                        showPlaceholder
                        placeholder={SELECT_A_COUNTRY}
                        label={COUNTRY_RESIDENCE_FIELD}
                        options={country}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <AutoComplete
                        name="language"
                        label={LANGUAGE_FIELD}
                        showPlaceholder
                        options={[
                          { label: LABEL_ES, value: 'es' },
                          { label: LABEL_EN, value: 'en' },
                          { label: LABEL_PT, value: 'pt' },
                          { label: LABEL_FR, value: 'fr' },
                          { label: LABEL_DE, value: 'de' },
                          { label: LABEL_IT, value: 'it' },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Container>
                <Grid
                  container
                  className={
                    haveDelete
                      ? classes.paddingFooterSpaceBetween
                      : classes.paddingFooterFlexEnd
                  }
                >
                  <Button
                    type="submit"
                    text={SUBMIT_BUTTON}
                    variant="contained"
                    color="primary"
                  />
                  {haveDelete && (
                    <Button
                      text={REMOVE_BUTTON}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setDepedentId(values.dependentId);
                        setOpenConfirm(true);
                      }}
                    />
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      
      </Container>
    );
  }
}

export default PatientForm;
