import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) =>
    createStyles({
      title: {
        userSelect: 'none',
        fontWeight: 700,
        fontSize: 16,
        fontFamily: 'Source Sans Pro !important',
        color: theme.palette.text.main,
        [theme.breakpoints.up('xs')]: {
          fontSize: 16,
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: 16,
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 18,
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 20,
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: 20,
        },
      },
      titleColor: {
        color: '#908e88',
        fontWeight: 100,
      },
      notchedOutline: {
        border: '3px solid #f0f0f0',
      },
      select: {
        paddingTop: 0,
      },
      selectRoot: {
        border: '1px solid rgba(204, 204, 204, 1)',
        borderRadius: 0,
        backgroundColor: 'white',
      },
      selectOutlined: {
        padding: '13px 14px',
        fontFamily: 'Source Sans Pro !important',
      },
      inputOutlined: {
        padding: '13px 14px !important',
        fontFamily: 'Source Sans Pro !important',
      },
      selectIcon: {
        color: 'black',
        fontSize: 30,
        top: 'calc(50% - 14px)',
        [theme.breakpoints.up('sm')]: {
          fontSize: 40,
          top: 'calc(50% - 18px)',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 45,
          top: 'calc(50% - 20px)',
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: 50,
          top: 'calc(50% - 22px)',
        },
      },
      selectMenu: {
        maxHeight: '250px',
        fontFamily: 'Source Sans Pro !important',
      },
    }),
  { index: 1 }
);
