export const NAME = {
  es_ES: 'NOMBRE',
  en_US: 'NAME',
  pt_BR: 'NOME',
  fr_FR: 'NOM',
  de_DE: 'NAME',
  it_IT: 'NOME',
};

export const DATE = {
  es_ES: 'FECHA',
  en_US: 'DATE',
  pt_BR: 'DATA',
  fr_FR: 'DATE',
  de_DE: 'DATUM',
  it_IT: 'DATA',
};

export const STATUS = {
  es_ES: 'ESTADO',
  en_US: 'STATUS',
  pt_BR: 'STATUS',
  fr_FR: 'STATUT',
  de_DE: 'STATUS',
  it_IT: 'ETAT',
};

export const TITLE_APPOINTMENT_PENDING = {
  es_ES: 'Pendiente',
  en_US: 'Pending',
  pt_BR: 'Pendente',
  fr_FR: 'En attente',
  de_DE: 'Laufend',
  it_IT: 'In attesa di',
};

export const TITLE_APPOINTMENT_IN_PROGRESS = {
  es_ES: 'En curso',
  en_US: 'In progress',
  pt_BR: 'Em andamento',
  fr_FR: 'En cours',
  de_DE: 'In bearbeitung',
  it_IT: 'In corso',
};

export const TITLE_APPOINTMENT_DONE = {
  es_ES: 'Hecho',
  en_US: 'Done',
  pt_BR: 'Feito',
  fr_FR: 'Terminé',
  de_DE: 'OK',
  it_IT: 'Fatto',
};

export const TITLE_APPOINTMENT_CANCELED = {
  es_ES: 'Cancelar',
  en_US: 'Cancelled',
  pt_BR: 'Cancelado',
  fr_FR: 'Annulé',
  de_DE: 'Abgesagt',
  it_IT: 'Cancellare',
};

export const TITLE_APPOINTMENT_DETAILS = {
  es_ES: 'Detalles',
  en_US: 'Details',
  pt_BR: 'Detalhes',
  fr_FR: 'Détails',
  de_DE: 'EINZELHEITEN',
  it_IT: 'DETTAGLI',
};

export const FIRST_PAGE_LABEL = {
  es_ES: 'primera página',
  en_US: 'first page',
  pt_BR: 'primeira página',
  fr_FR: 'Première Page',
  de_DE: 'erste Seite',
  it_IT: 'prima pagina',
};

export const PREVIOUS_PAGE_LABEL = {
  es_ES: 'pagina anterior',
  en_US: 'previous page',
  pt_BR: 'página anterior',
  fr_FR: 'Page précédente',
  de_DE: 'vorherige Seite',
  it_IT: 'pagina precedente',
};

export const NEXT_PAGE_LABEL = {
  es_ES: 'siguiente página',
  en_US: 'next page',
  pt_BR: 'próxima página',
  fr_FR: 'Page suivante',
  de_DE: 'Nächste Seite',
  it_IT: 'pagina successiva',
};

export const LAST_PAGE_LABEL = {
  es_ES: 'última página',
  en_US: 'last page',
  pt_BR: 'última página',
  fr_FR: 'dernière page',
  de_DE: 'letzte Seite',
  it_IT: 'ultima pagina',
};

export const COSTUMIZED_TABLE_LABEL = {
  es_ES: 'customized table',
  en_US: 'customized table',
  pt_BR: 'customized table',
  fr_FR: 'customized table',
  de_DE: 'customized table',
  it_IT: 'customized table',
};

export const ROWS_PER_PAGE_LABEL = {
  es_ES: 'Filas por página',
  en_US: 'Rows per page',
  pt_BR: 'Linhas por página',
  fr_FR: 'lignes par page',
  de_DE: 'Zeilen pro Seite',
  it_IT: 'Righe per pagina',
};

export const TEXT_NOT_APPOINTMENT = {
  es_ES: 'Aún no tienes ninguna consulta',
  en_US: 'You have no cases yet.',
  pt_BR: 'Você ainda não tem nenhum caso.',
  fr_FR: 'Vous n’avez pas de rendez-vous pour l’instant.',
  de_DE: 'Es wurde noch kein Termin vereinbart',
  it_IT: 'Non hai ancora casi.',
};
