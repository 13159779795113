/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import axios, { AxiosInstance } from 'axios';
import { getCookie } from '../shared/helpers/cookies';

export type Env = any;
export type Api = AxiosInstance;
export type ErrorHandler = any;

const api: Api = axios.create({
  baseURL: '/api',
});

api.interceptors.request.use(
  function (config) {
    // @ts-ignore
    if (config.url.startsWith('/api')) {
      config.headers.Pragma = 'no-cache';
      config.headers['Cache-Control'] = 'no-cache';
      config.headers['X-Requested-With'] = 'XMLHttpRequest';
      config.headers.locale = getCookie('idiom');
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

export default api;
