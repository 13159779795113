export const TITLE_APPOINTMENT_PENDING = {
  es_ES: 'Pendiente',
  en_US: 'Pending',
  pt_BR: 'Pendente',
  fr_FR: 'En attente',
  de_DE: 'Laufend',
  it_IT: 'In attesa di',
};

export const TITLE_APPOINTMENT_IN_PROGRESS = {
  es_ES: 'En curso',
  en_US: 'In progress',
  pt_BR: 'Em andamento',
  fr_FR: 'En cours',
  de_DE: 'In bearbeitung',
  it_IT: 'In corso',
};

export const TITLE_APPOINTMENT_DONE = {
  es_ES: 'Hecho',
  en_US: 'Done',
  pt_BR: 'Feito',
  fr_FR: 'Terminé',
  de_DE: 'OK',
  it_IT: 'Fatto',
};

export const TITLE_APPOINTMENT_CANCELED = {
  es_ES: 'Cancelar',
  en_US: 'Cancelled',
  pt_BR: 'Cancelado',
  fr_FR: 'Annulé',
  de_DE: 'Abgesagt',
  it_IT: 'Cancellare',
};

export const TITLE_APPOINTMENT_DETAILS = {
  es_ES: 'Detalles',
  en_US: 'Details',
  pt_BR: 'Detalhes',
  fr_FR: 'Détails',
  de_DE: 'Einzelheiten',
  it_IT: 'Dettagli',
};

export const TITLE_NO_HAVE_DOCTOR_DEFAULT_ERROR = {
  es_ES: 'Error al intentar conectarse a la llamada.',
  en_US: 'Error when try to connect to the call.',
  pt_BR: 'Erro ao tentar conectar-se à chamada.',
  fr_FR: "Erreur lors de la tentative de connexion à l'appel.",
  de_DE: 'Fehler beim Versuch, eine Verbindung zum Anruf herzustellen.',
  it_IT: 'Errore durante il tentativo di connessione alla chiamata.',
};

export const TITLE_NO_HAVE_DOCTOR_MSG = {
  es_ES:
    'Su cita aún no se ha asociado con un Médico. Por favor, espere unos minutos e intente de nuevo.',
  en_US:
    'Your appointment is yet to be associated with a Doctor. Please wait and try again later.',
  pt_BR:
    'Sua consulta ainda não foi direcionada a um Médico. Por favor, aguarde alguns instantes e tente novamente.',
  fr_FR:
    "Votre rendez-vous n'est pas encore associé à un médecin. Veuillez réessayer plus tard.",
  de_DE:
    'Ihr Termin muss noch von einem Arzt bestätigt werden. Bitte warten Sie und versuchen Sie es später erneut.',
  it_IT:
    'Il tuo appuntamento deve ancora essere associato a un medico. Attendi e riprova più tardi.',
};

export const TITLE_APPOINTMENT_NOT_DUE_YET = {
  es_ES:
    'Todavía no es la hora de la consulta; por favor, verifica que te has conectado a la fecha y hora correcta.',
  en_US:
    'Appointment not due yet; please verify that you have connected at the correct date and time.',
  pt_BR:
    'Ainda não é o horário de sua consulta. Por favor verique se você conectou no dia e horário corretos.',
  fr_FR:
    "Votre rendez-vous n'aura pas lieu tout de suite, veuillez vérifier l'heure et la date de votre rendez-vous.",
  de_DE:
    'Termin noch nicht fällig; bitte vergewissern Sie sich, dass Sie sich zum richtigen Datum und zur richtigen Zeit angemeldet haben.',
  it_IT:
    "Appuntamento non ancora scaduto; verifica di esserti connesso alla data e all'ora corrette.",
};

export const NAME = {
  es_ES: 'NOMBRE',
  en_US: 'NAME',
  pt_BR: 'NOME',
  fr_FR: 'NOM',
  de_DE: 'NAME',
  it_IT: 'NOME',
};

export const DATE = {
  es_ES: 'FECHA Y HORA',
  en_US: 'DATE AND TIME',
  pt_BR: 'DATA E HORA',
  fr_FR: 'DATE ET HEURE',
  de_DE: 'DATUM UND ZEIT',
  it_IT: 'DATA E ORA',
};

export const TIME = {
  es_ES: 'HORA',
  en_US: 'TIME',
  pt_BR: 'HORÁRIO',
  fr_FR: 'HEURE',
  de_DE: 'ZEIT',
  it_IT: 'ORA',
};

export const STATUS = {
  es_ES: 'ESTADO',
  en_US: 'STATUS',
  pt_BR: 'STATUS',
  fr_FR: 'STATUT',
  de_DE: 'STATUS',
  it_IT: 'ETAT',
};

export const FIRST_PAGE_LABEL = {
  es_ES: 'primera página',
  en_US: 'first page',
  pt_BR: 'primeira página',
  fr_FR: 'Première Page',
  de_DE: 'erste Seite',
  it_IT: 'prima pagina',
};

export const PREVIOUS_PAGE_LABEL = {
  es_ES: 'pagina anterior',
  en_US: 'previous page',
  pt_BR: 'página anterior',
  fr_FR: 'Page précédente',
  de_DE: 'vorherige Seite',
  it_IT: 'pagina precedente',
};

export const NEXT_PAGE_LABEL = {
  es_ES: 'siguiente página',
  en_US: 'next page',
  pt_BR: 'próxima página',
  fr_FR: 'Page suivante',
  de_DE: 'Nächste Seite',
  it_IT: 'pagina successiva',
};

export const LAST_PAGE_LABEL = {
  es_ES: 'última página',
  en_US: 'last page',
  pt_BR: 'última página',
  fr_FR: 'dernière page',
  de_DE: 'letzte Seite',
  it_IT: 'ultima pagina',
};

export const COSTUMIZED_TABLE_LABEL = {
  es_ES: 'customized table',
  en_US: 'customized table',
  pt_BR: 'customized table',
  fr_FR: 'customized table',
  de_DE: 'customized table',
  it_IT: 'customized table',
};

export const ROWS_PER_PAGE_LABEL = {
  es_ES: 'Filas por página',
  en_US: 'Rows per page',
  pt_BR: 'Linhas por página',
  fr_FR: 'lignes par page',
  de_DE: 'Zeilen pro Seite',
  it_IT: 'Righe per pagina',
};

export const TEXT_NOT_APPOINTMENT = {
  es_ES: 'Aún no tienes ninguna consulta',
  en_US: 'You have no cases yet.',
  pt_BR: 'Você ainda não tem nenhum caso.',
  fr_FR: 'Vous n’avez pas de rendez-vous pour l’instant.',
  de_DE: 'Es wurde noch kein Termin vereinbart',
  it_IT: 'Non hai ancora casi.',
};
