export const BUTTON_YES = {
  en_US: 'Yes',
  it_IT: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
  de_DE: 'Ja',
  fr_FR: 'Oui',
};

export const BUTTON_NO = {
  en_US: 'No',
  it_IT: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
  de_DE: 'Nein',
  fr_FR: 'non',
};

export const BUTTON_OK = {
  en_US: 'Ok',
  it_IT: 'Ok',
  pt_BR: 'Ok',
  es_ES: 'Ok',
  de_DE: 'Ok',
  fr_FR: 'Ok',
};

export const CLOSE_LABEL = {
  en_US: 'Close',
  it_IT: 'Close',
  pt_BR: 'Close',
  es_ES: 'Close',
  de_DE: 'Close',
  fr_FR: 'Close',
};
