import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useHashFragment = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    hash === ''
      ? window.scrollTo(0, 0)
      : setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
  }, [pathname, hash, key]);
};

export default useHashFragment;
