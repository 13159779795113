import styled from 'styled-components';

export const Container = styled.div`
  width: 40em;
  padding: 2em;
  padding-top: 4em;
  padding-bottom: 4em;
`;
// background-image: linear-gradient(
//   to right,
//   rgba(255, 255, 255, 0),
//   rgba(255, 255, 255, 0.8)
// );