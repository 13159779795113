import React, { useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';

const GTM = process.env.REACT_APP_GTM;
    const tagManagerArgs = {
      gtmId: 'GTM-WFJR4NFR',
      auth:'',
      preview:'',
      cookiesWin:'',
      dataLayerName: 'dataLayer'
    };
    TagManager.initialize(tagManagerArgs);

export default GTM;