/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import './locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/pt';
import React, { useState, useRef, useEffect } from 'react';
import {
  FormControl,
  OutlinedInput as MuiOutlinedInput,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import { useField } from 'formik';
import {
  KeyboardDatePicker as DatePickerUI,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import styled from 'styled-components';
import { getCookie } from '../../shared/helpers/cookies';
import { useFocusOnError } from '../../shared/hooks';
import dateIcon from '../../assets/icons/ico-date.png';
import styles from './styles';

const TextField = (textFieldProps) => {
  const classes = styles();
  const icon = (
    <Icon
      src={dateIcon}
      alt="date-icon"
      onClick={() => textFieldProps.inputProps.setDtOpen(true)}
    />
  );

  return (
    <FormControl fullWidth variant="outlined">
      <MuiOutlinedInput
        {...textFieldProps}
        type="text"
        label=""
        classes={{
          root: classes.inputLightRoot,
          input: classes.inputOutlined,
          notchedOutline: classes.notchedOutline,
        }}
        startAdornment={icon}
        error={textFieldProps.inputProps.error}
        helperText={textFieldProps.inputProps.helperText}
        labelGrey={textFieldProps.inputProps.labelGrey}
      />
    </FormControl>
  );
};

const DatePicker = ({
  name,
  label,
  placeholder,
  value,
  disabled,
  disableIcon,
  min,
  max,
  labelGrey = false,
  ...props
}) => {
  const classes = styles();
  const [changed, setChanged] = useState(true);
  const [field, meta, fieldProps] = useField(name);
  const idiom = getCookie('idiom');
  const localeCalendar =
    idiom === 'es_ES' ? 'es' : idiom === 'en_US' ? 'en-gb' : 'pt';
  const [dtopen, setDtOpen] = useState(false);
  const handleChange = (e) => {
    if (!changed) setChanged(true);
    fieldProps.setValue(e ? moment(moment(e).format('YYYY-MM-DD')) : null);
  };
  const calCancelLabel =
    idiom === 'es_ES' ? 'Cancelar' : idiom === 'en_US' ? 'Cancel' : 'Cancelar';
  moment.locale(localeCalendar);

  useEffect(() => {
    if (field.value && changed) {
      handleChange(field.value);
      setChanged(false);
    }
  }, [field?.value]);

  const fieldRef = useRef();
  useFocusOnError(fieldRef, name, `form-field-${name}`);

  const controlCursor = () => {
    if (fieldRef?.current?.selectionStart === 0) {
      fieldRef.current.setSelectionRange(1, 1);
    } else {
      fieldRef.current.setSelectionRange(
        fieldRef?.current?.selectionStart,
        fieldRef?.current?.selectionStart
      );
    }
  };

  return (
    <>
      <InvisibleButton
        data-testid={`input-${name}`}
        type="button"
        onClick={() => handleChange(moment())}
      />
      {label && (
        <Typography
          className={`${classes.title} ${labelGrey && classes.titleColor}`}
        >
          {label}
        </Typography>
      )}
      <MuiPickersUtilsProvider locale={localeCalendar} utils={MomentUtils}>
        <DatePickerUI
          {...props}
          {...field}
          label={label}
          placeholder={placeholder}
          format="DD/MM/yyyy"
          value={field?.value}
          inputRef={fieldRef}
          autoOk
          maxDate={max}
          id={`form-field-${name}`}
          minDate={min || moment().year(1900).month(0).date(1)}
          disabled={disabled}
          onChange={handleChange}
          animateYearScrolling={false}
          onKeyPress={controlCursor}
          inputProps={{
            'data-testid': `input-datepicker-${name}`,
            labelGrey,
            setDtOpen,
            error: meta.touched && !!meta.error,
          }}
          TextFieldComponent={TextField}
          open={disableIcon ? false : dtopen}
          onClose={() => setDtOpen(false)}
          cancelLabel={calCancelLabel}
        />
      </MuiPickersUtilsProvider>
      {meta.touched && meta?.error && (
        <CustomHelperText error>{meta.error}</CustomHelperText>
      )}
    </>
  );
};

const CustomHelperText = styled(FormHelperText)`
  margin-left: 14px;
  margin-right: 14px;
`;

export const InvisibleButton = styled.button`
  display: none;
`;

export const Icon = styled.img`
  width: 24px;
`;
export default DatePicker;
