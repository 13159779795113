/* eslint-disable no-shadow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import styles from './styles';
import '../style.css';

const SelectList = ({
  className,
  title,
  value = [],
  setValue,
  options,
  name,
}) => {
  const classes = styles();

  const handleChange = (name, checked) => {
    if (checked) {
      setValue([...value, name]);
    } else {
      setValue(value.filter((valueName) => valueName !== name));
    }
  };

  return (
    <div className={className}>
      {title && <Typography className={classes.title}>{title}</Typography>}
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <FormGroup name={name}>
          {options.map((option, key) => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  checked={value.includes(option.value)}
                  onChange={({ target: { name, checked } }) => {
                    handleChange(name, checked);
                  }}
                  name={option.value}
                  color="primary"
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default SelectList;
