/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

const useFocusOnError = (fieldRef: any, name: any, id: any) => {
  const formik: any = useFormikContext();
  const prevSubmitCountRef = React.useRef(formik.submitCount);
  useEffect(() => {
    const firstErrorKey: any = Object.keys(formik.errors)[0];
    const auxArray: any = [];
    if (formik.errors && Array.isArray(formik.errors[firstErrorKey])) {
      formik.errors[firstErrorKey].map((el: any, index: number) => {
        if (formik.errors[firstErrorKey][index]) {
          const arrayErrorKey: any = Object.keys(
            formik.errors[firstErrorKey][index]
          )[0];
          auxArray.push(`${firstErrorKey}[${index}].${arrayErrorKey}`);
        }
        return true;
      });
    }
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      prevSubmitCountRef.current = formik.submitCount;
      if (auxArray.length > 0 && auxArray[0] === name)
        return document?.getElementById(id)?.focus();
      if (firstErrorKey === name) {
        return document?.getElementById(id)?.focus();
      }
    }
  }, [formik.submitCount, formik.isValid, name, id, formik.errors]);
};

export default useFocusOnError;
