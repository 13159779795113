import { getCookie } from '../shared/helpers';
let observer = {};

export const setObserver = (_observer) => {
  observer = _observer;
};

export const ALERT_EXPIRE_SESSION = {
  es_ES: 'Su sesión ha caducado',
  en_US: 'Your session has expired',
  pt_BR: 'Sua sessão expirou',
};

export const unauthorized = (error) => {
  // TODO: will be redirect to expired session page and logout execute.
  observer.actions.alert.showError(ALERT_EXPIRE_SESSION[getCookie('idiom')]);
  if (
    error &&
    error.response &&
    error.response.error_code === 'invalid_refresh_token'
  ) {
    window.location.href = '/login';
  } else {
    window.location.href = '/logoff';
  }
};

export const statusReaction = {
  401: unauthorized,
};

export const errorHandler = async (
  request,
  _requestType = null,
  _requestData = {},
  _countryResidence
) => {
  try {
    const response = await request;
    return response;
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      if (statusReaction[error.response.status]) {
        statusReaction[error.response.status](error);
      } else if (
        (error.response.status == '500' || error.response.status == '400') &&
        _requestType == 'requestPhoneCall' &&
        _countryResidence == 'SG' &&
        error.response.data.message ==
          '[AMOSConsultationService - create] Error at create consultation service. 440 - consultation not created. Service not allowed'
      ) {
        return 'Your service request cannot be created. If you are currently located in Singapore, please request a Video Consultation. If it is an emergency, please go to your nearest Emergency Department';
      } else throw error;
    } else {
      observer.actions.alert.showError('UNEXPECTED_ERROR_OCCURRED');
    }
  }
};
