export {
  LABEL_PT,
  LABEL_ES,
  LABEL_EN,
  LABEL_FR,
  LABEL_DE,
  LABEL_IT,
  LABEL_FI,
  LABEL_DA,
  LABEL_SV,
  PREFFIX_AGE,
} from '../../../../../../shared/constants/locale';

export const MESSAGE_CONFIRM_CANCEL_PHONE = {
  es_ES: '¿Seguro que quieres cancelar esta consulta?',
  en_US: 'Are you sure you want to cancel this appointment?',
  pt_BR: 'Tem certeza de que deseja cancelar este compromisso?',
  fr_FR: 'Êtes-vous sûr de vouloir annuler ce rendez-vous?',
  de_DE: 'Möchten Sie diesen Termin wirklich stornieren?',
  it_IT: 'Sei sicuro di voler annullare questo appuntamento?',
};

export const MESSAGE_APPOINTMENT_IS_CANCELLED = {
  es_ES: 'Su cita ha sido cancelada',
  en_US: 'Your appointment has been cancelled',
  pt_BR: 'Seu compromisso foi cancelado',
  fr_FR: 'Votre rendez-vous a été annulé',
  de_DE: 'Ihr Termin wurde abgesagt',
  it_IT: 'Il tuo appuntamento è stato cancellato',
};

export const MESSAGE_CONFIRM_CANCEL_VIDEO = {
  es_ES: '¿Seguro que quieres cancelar esta consulta?',
  en_US: 'Are you sure you want to cancel this appointment?',
  pt_BR: 'Tem certeza de que deseja cancelar este compromisso?',
  fr_FR: 'Êtes-vous sûr de vouloir annuler ce rendez-vous ?',
  de_DE: 'Möchten Sie diesen Termin wirklich stornieren?',
  it_IT: 'Sei sicuro di voler annullare questo appuntamento?',
};

export const CONFIRMATION_TITLE = {
  es_ES: 'Confirmación',
  en_US: 'Confirmation',
  pt_BR: 'Confirmação',
  fr_FR: 'Confirmation',
  de_DE: 'Bestätigung',
  it_IT: 'Conferma',
};

export const BACK_BUTTON = {
  es_ES: 'ATRÁS',
  en_US: 'BACK',
  pt_BR: 'VOLTAR',
  fr_FR: 'RETOUR',
  de_DE: 'ZURÜCK',
  it_IT: 'PRECEDENTE',
};

export const TITLE_APPOINTMENT_PENDING = {
  es_ES: 'Pendiente',
  en_US: 'Pending',
  pt_BR: 'Pendente',
  fr_FR: 'En attente',
  de_DE: 'laufend',
  it_IT: 'In attesa di',
};

export const TITLE_APPOINTMENT_IN_PROGRESS = {
  es_ES: 'En curso',
  en_US: 'In progress',
  pt_BR: 'Em andamento',
  fr_FR: 'En cours',
  de_DE: 'In bearbeitung',
  it_IT: 'In corso',
};

export const TITLE_APPOINTMENT_DONE = {
  es_ES: 'Hecho',
  en_US: 'Done',
  pt_BR: 'Feito',
  fr_FR: 'Terminé',
  de_DE: 'OK',
  it_IT: 'Erogato',
};

export const TITLE_APPOINTMENT_CANCELED = {
  es_ES: 'Cancelar',
  en_US: 'Cancelled',
  pt_BR: 'Cancelado',
  fr_FR: 'Annulé',
  de_DE: 'Abgesagt',
  it_IT: 'Cancellare',
};

export const TITLE_NO_HAVE_DOCTOR_DEFAULT_ERROR = {
  es_ES: 'Error al intentar conectarse a la llamada.',
  en_US: 'Error when try to connect to the call.',
  pt_BR: 'Erro ao tentar conectar-se à chamada.',
  fr_FR: "Erreur lors de la tentative de connexion à l'appel",
  de_DE: 'Fehler beim Versuch, eine Verbindung zum Anruf herzustellen',
  it_IT: 'Errore durante il tentativo di connessione alla chiamata',
};

export const TITLE_NO_HAVE_DOCTOR_MSG = {
  es_ES:
    'Su cita aún no se ha asociado con un Médico. Por favor, espere unos minutos e intente de nuevo.',
  en_US:
    'Your appointment is yet to be associated with a Doctor. Please wait and try again later.',
  pt_BR:
    'Sua consulta ainda não foi direcionada a um Médico. Por favor, aguarde alguns instantes e tente novamente.',
  fr_FR:
    "Votre rendez-vous n'est pas encore associé à un médecin. Veuillez réessayer plus tard.",
  de_DE:
    'Ihr Termin muss noch von einem Arzt bestätigt werden. Bitte warten Sie und versuchen Sie es später erneut.',
  it_IT:
    'Il tuo appuntamento deve ancora essere associato a un medico. Attendi e riprova più tardi.',
};

export const TITLE_APPOINTMENT_NOT_DUE_YET = {
  es_ES:
    'Todavía no es la hora de la consulta; por favor, verifica que te has conectado a la fecha y hora correcta.',
  en_US:
    'Appointment not due yet; please verify that you have connected at the correct date and time.',
  pt_BR:
    'Ainda não é o horário de sua consulta. Por favor verique se você conectou no dia e horário corretos.',
  fr_FR:
    "Votre rendez-vous n'aura pas lieu tout de suite, veuillez vérifier l'heure et la date de votre rendez-vous.",
  de_DE:
    'Termin noch nicht fällig; bitte vergewissern Sie sich, dass Sie sich zum richtigen Datum und zur richtigen Zeit angemeldet haben.',
  it_IT:
    "Appuntamento non ancora scaduto; verifica di esserti connesso alla data e all'ora corrette.",
};

export const CANCEL_APPOINTMENT = {
  es_ES: 'Cancelar cita',
  en_US: 'Cancel Appointment',
  pt_BR: 'Cancelar consulta',
  fr_FR: 'Annuler le rendez-vous',
  de_DE: 'Termin absagen',
  it_IT: 'Cancella appuntamento',
};

export const PHONE_CONSULTATION = {
  es_ES: 'Llamadas telefónica',
  en_US: 'Phone Consultation',
  pt_BR: 'Ligações',
  fr_FR: 'Rendez-vous téléphonique',
  de_DE: 'Telefonische Beratung',
  it_IT: 'Telefonate',
};

export const VIDEO_CONSULTATION = {
  es_ES: 'Video Consulta',
  en_US: 'Video consultation',
  pt_BR: 'Consulta por vídeo',
  fr_FR: 'Rendez-vous vidéo',
  de_DE: 'Video Beratung',
  it_IT: 'Videoconsulto',
};

export const LANGUAGE_LABEL = {
  es_ES: 'Idioma',
  en_US: 'Language',
  pt_BR: 'Idioma',
  fr_FR: 'Langue',
  de_DE: 'Sprache',
  it_IT: 'Lingua',
};

export const DATE_LABEL = {
  es_ES: 'Fecha',
  en_US: 'Date',
  pt_BR: 'Data',
  fr_FR: 'Date',
  de_DE: 'Datum',
  it_IT: 'Data',
};

export const DATE_TIME_LABEL = {
  es_ES: 'Fecha y hora',
  en_US: 'Date and time',
  pt_BR: 'Data e hora',
  fr_FR: 'Date et heure',
  de_DE: 'Datum und Zeit',
  it_IT: 'Data e ora',
};

export const TYPE_CONSULTATION = {
  es_ES: 'Tipo de consulta',
  en_US: 'Type of consultation',
  pt_BR: 'Tipo de consulta',
  fr_FR: 'Type de consultation',
  de_DE: 'Art der Beratung',
  it_IT: 'Tipo di consulenza',
};

export const TYPE_CONSULTATION_DATA = {
  es_ES: 'Consulta general',
  en_US: 'General Consultation',
  pt_BR: 'Consulta Geral',
  fr_FR: 'Consultation générale',
  de_DE: 'Allgemeinmedizin',
  it_IT: 'Medicina generale',
};

export const TELEPHONE_NUMBER = {
  es_ES: 'Número de teléfono',
  en_US: 'Telephone number',
  pt_BR: 'Número de telefone',
  fr_FR: 'Numéro de téléphone',
  de_DE: 'Telefonnummer',
  it_IT: 'Recapito telefonico',
};

export const REASON_APPOINTMENT = {
  es_ES: 'Motivo de su consulta',
  en_US: 'Reason for appointment',
  pt_BR: 'Motivo da consulta',
  fr_FR: 'Motif du rendez-vous',
  de_DE: 'Grund der Terminvereinbarung',
  it_IT: 'Motivo dell’appuntamento',
};

export const YOUR_FILES = {
  es_ES: 'Ficheros subido (s)',
  en_US: 'Uploaded File(s)',
  pt_BR: 'Arquivos enviados',
  fr_FR: 'Télécharger document(s)​',
  de_DE: 'Anhang',
  it_IT: 'Carica file(s)',
};

export const STATUS = {
  es_ES: 'Estado',
  en_US: 'Status',
  pt_BR: 'Status',
  fr_FR: 'Statut',
  de_DE: 'Status',
  it_IT: 'Status',
};

export const DOCTORS_COMMENTS = {
  es_ES: 'Comentarios del doctor',
  en_US: "Doctor's Comments",
  pt_BR: 'Comentários do(a) médico(a)',
  fr_FR: 'Commentaires du médecin',
  de_DE: 'Kommentar des Arztes',
  it_IT: 'Commenti del medico',
};

export const TITLE_TIMEZONE = {
  en_US: 'Timezone',
  it_IT: 'Fuso orario',
  pt_BR: 'Fuso horário',
  es_ES: 'Zona horaria',
  de_DE: 'Zeitzone',
  fr_FR: 'Fuseau horaire',
};
