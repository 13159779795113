import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableFooter,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import * as translations from './intl';
import { useTranslation } from '../../../../../../shared/hooks';
import { trativeFormatDateWithIdiom } from '../../../../../../shared/helpers';
import { language } from '../../../../../../globalConstants';
import { partner_id } from '../../../../../../globalConstants';
import { partner_name } from '../../../../../../globalConstants';
import { country_code } from '../../../../../../globalConstants';
import { instance_env } from '../../../../../../globalConstants';

const StyledTableCellWarning = withStyles(() => ({
  root: {
    borderBottom: 'none',
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.main,
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '1px',
    boxShadow: '0px 1px 0px #111B1D',
  },
  body: {
    color: theme.palette.text.main,
    fontSize: 16,
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 700,
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

export default function TabPhone({
  phoneList,
  setValue,
  setPhoneCallId,
  setTypeAppointment,
}) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, phoneList.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { locale: intl, idiom } = useTranslation({
    translationsFile: translations,
  });
  const {
    NAME,
    DATE,
    STATUS,
    TITLE_APPOINTMENT_PENDING,
    TITLE_APPOINTMENT_IN_PROGRESS,
    TITLE_APPOINTMENT_DONE,
    TITLE_APPOINTMENT_CANCELED,
    TITLE_APPOINTMENT_DETAILS,
    FIRST_PAGE_LABEL,
    PREVIOUS_PAGE_LABEL,
    NEXT_PAGE_LABEL,
    LAST_PAGE_LABEL,
    COSTUMIZED_TABLE_LABEL,
    ROWS_PER_PAGE_LABEL,
    TEXT_NOT_APPOINTMENT,
  } = intl;

  const onMakeStatus = (typeStatus) => {
    switch (typeStatus) {
      case 'done':
        return TITLE_APPOINTMENT_DONE;
      case 'pending':
        return TITLE_APPOINTMENT_PENDING;
      case 'canceled':
        return TITLE_APPOINTMENT_CANCELED;
      case 'in-progress':
        return TITLE_APPOINTMENT_IN_PROGRESS;
      default:
        return '';
    }
  };

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label={FIRST_PAGE_LABEL}
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label={PREVIOUS_PAGE_LABEL}
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label={NEXT_PAGE_LABEL}
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label={LAST_PAGE_LABEL}
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleDetails = (row, event) => {
    event.stopPropagation();
    setValue(2);
    setPhoneCallId(row.id);
    setTypeAppointment('phone');

    window.dataLayer.push({
        'subsidiary_name':'axa_partners',
        'business_unit':'customer_and_solutions',
        'business_line':'travel_solutions',
        'digital_business_process':'assistance_request',
        'global_digital_asset':'doctor_please',
        'partner_id':partner_id,
        'partner_name':partner_name,
        'country':country_code,
        'language':language,
        'instance_environment':instance_env,
        'user_logged':'true',
        'page_name':'appointments details',
        'appointment_type':'phone call',
        'appointment_status':row.status,
        'funnel_step':'4',
        'step_name':'appointment details',
        'event':'pageview',
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label={COSTUMIZED_TABLE_LABEL}>
        <TableHead>
          <TableRow>
            <StyledTableCell>{NAME}</StyledTableCell>
            <StyledTableCell align="left">{STATUS}</StyledTableCell>
            <StyledTableCell align="left">{DATE}</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? phoneList.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : phoneList
          ).map((row) => (
            <StyledTableRow
              key={row.id}
              onClick={(event) => {
                handleDetails(row, event);
              }}
            >
              <StyledTableCell component="th" scope="row">
                {` ${row.patient.name} ${row.patient.surname2}`}
              </StyledTableCell>
              <StyledTableCell align="left">
                {onMakeStatus(String(row.status).toLowerCase())}
              </StyledTableCell>
              <StyledTableCell align="left">
                {moment(row.date).format(trativeFormatDateWithIdiom(idiom))}
              </StyledTableCell>
              <StyledTableCell>
                <Button
                  color="primary"
                  endIcon={<ArrowForwardRoundedIcon />}
                  onClick={(event) => {
                    handleDetails(row, event);
                  }}
                >
                  {TITLE_APPOINTMENT_DETAILS.toUpperCase()}
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
          {emptyRows === 5 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <StyledTableCellWarning colSpan={5} className="d-flex">
                <div>{TEXT_NOT_APPOINTMENT}</div>
              </StyledTableCellWarning>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              // colSpan={3}
              count={phoneList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={ROWS_PER_PAGE_LABEL}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
