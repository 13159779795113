export default (env: any, api: any, errorHandler: any) => ({
  amosLogin: async () => {
    const url = `/api/v1/amos/clients/login`;
    return errorHandler(api.post(url), 'amosLogin', {});
  },
  amosCreate: async (payload: any) => {
    const url = `/api/v1/amos/clients`;
    return errorHandler(api.post(url, payload), 'amosCreate', { url, payload });
  },
  amosUpdateClient: async (payload: any) => {
    const url = `/api/v1/amos/clients`;
    return errorHandler(api.put(url, payload), 'amosUpdateClient', {
      url,
      payload,
    });
  },
  amosLogout: async (token: string) => {
    const url = `/api/v1/amos/clients/logout`;
    return errorHandler(
      api.post(url, { clientToken: token }),
      'amosLogout',
      {}
    );
  },
  amosRefreshToken: async (data: any) => {
    const url = `/api/v1/amos/clients/refresh-token`;
    return errorHandler(
      api.post(url, { clientRefreshToken: data }),
      'amosRefreshToken',
      { data }
    );
  },
  getFamilyMembers: async (token: string) => {
    const url = `/api/v1/amos/dependents?clientToken=${token}`;
    return errorHandler(api.get(url), 'getFamilyMembers', { token });
  },
  getCountries: async (idiomAmos: string) => {
    const url = `/api/v1/amos/countries?language=${idiomAmos}`;
    return errorHandler(api.get(url), 'getCountries', { idiomAmos });
  },
  getTime: async (timeZone: string) => {
    const url = `/api/v1/amos/time?timeZone=${timeZone}`;
    return errorHandler(api.get(url), 'getTime', { timeZone });
  },
  amosStartVideoCall: async (id: string, clientToken: string) => {
    const url = `/api/v1/amos/videocalls/${id}/start`;
    return errorHandler(api.post(url, clientToken), 'amosStartVideoCall', {
      id,
      clientToken,
    });
  },
  amosEndVideoCall: async (id: string, sendData: any) => {
    const url = `/api/v1/amos/videocalls/${id}/end`;
    return errorHandler(api.post(url, sendData), 'amosEndVideoCall', {
      id,
      sendData,
    });
  },
  postAmosDependets: async (sendData: any) => {
    const url = `/api/v1/amos/dependents/create`;
    return errorHandler(api.post(url, sendData), 'postAmosDependets', {
      sendData,
    });
  },
  putAmosDependets: async (sendData: any, dependentId: string) => {
    const url = `/api/v1/amos/dependents/${dependentId}`;
    return errorHandler(api.put(url, sendData), 'putAmosDependets', {
      sendData,
      dependentId,
    });
  },
  deleteAmosDependets: async (sendData: any, dependentId: string) => {
    const url = `/api/v1/amos/dependents/${dependentId}`;
    return errorHandler(
      api.delete(url, { data: sendData }),
      'deleteAmosDependets',
      { sendData, dependentId }
    );
  },
  getTimezone: async (idiomAmos: string) => {
    const url = `/api/v1/amos/timezones?language=${idiomAmos}`;
    return errorHandler(api.get(url), 'getTimezone', { idiomAmos });
  },
  getClientAndDependents: async (clientToken: string, clientId: string) => {
    const url = `/api/v1/amos/clients?clientToken=${clientToken}&clientId=${clientId}`;
    return errorHandler(api.get(url), 'getClientAndDependents', {
      clientToken,
      clientId,
    });
  },
  getAppointments: async (
    idiomAmos: string,
    timeZone: string,
    clientToken: string
  ) => {
    const url = `/api/v1/amos/videocalls-availability?timeZone=${timeZone}&clientToken=${clientToken}&language=${idiomAmos}`;
    return errorHandler(api.get(url), 'getAppointments', {
      idiomAmos,
      timeZone,
      clientToken,
    });
  },
  requestVideoCall: async (sendData: any) => {
    const url = `/api/v1/amos/videocalls/create`;
    return errorHandler(api.post(url, sendData), 'requestVideoCall', {
      sendData,
    });
  },
  requestPhoneCall: async (sendData: any, _countryofResidency = '') => {
    const url = `/api/v1/amos/consultations/create`;
    return errorHandler(
      api.post(url, sendData),
      'requestPhoneCall',
      {
        sendData,
      },
      _countryofResidency
    );
  },
  getRequestsVideo: async (clientToken: any, withFiles = false) => {
    const url = `/api/v1/amos/videocalls?clientToken=${clientToken}&files=${withFiles}`;
    return errorHandler(api.get(url), 'getRequestsVideo', {
      clientToken,
      withFiles,
    });
  },
  getRequestsPhone: async (clientToken: any, withFiles = false) => {
    const url = `/api/v1/amos/consultations?clientToken=${clientToken}&files=${withFiles}`;
    return errorHandler(api.get(url), 'getRequestsPhone', {
      clientToken,
      withFiles,
    });
  },
  getPhoneCall: async (phoneCallId: string, clientToken: string) => {
    const url = `/api/v1/amos/consultations/${phoneCallId}?clientToken=${clientToken}&files=true`;
    return errorHandler(api.get(url), 'getConsultation', {
      phoneCallId,
      clientToken,
    });
  },
  getVideocall: async (videocallId: string, clientToken: string) => {
    const url = `/api/v1/amos/videocalls/${videocallId}?clientToken=${clientToken}&files=true`;
    return errorHandler(api.get(url), 'getVideocall', {
      videocallId,
      clientToken,
    });
  },
  cancelPhoneCall: async (sendData: any, phoneCallId: string) => {
    const url = `/api/v1/amos/consultations/${phoneCallId}/cancel`;
    return errorHandler(api.post(url, sendData), 'cancelPhoneCall', {
      sendData,
      phoneCallId,
    });
  },
  cancelVideoCall: async (sendData: any, videocallId: string) => {
    const url = `/api/v1/amos/videocalls/${videocallId}/cancel`;
    return errorHandler(api.post(url, sendData), 'cancelVideoCall', {
      sendData,
      videocallId,
    });
  },
  getDoctor: async (doctorId: string) => {
    const url = `/api/v1/amos/doctors/${doctorId}`;
    return errorHandler(api.get(url), 'getDoctor', {
      doctorId,
    });
  },
  getVblock: async (file: File) => {
    const url = `/api/v1/amos/vblock/upload`;

    const formData = new FormData();

    formData.append('file', file);

    try {
      const response = api
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res: any) => res.data);
      console.log(' ppp => ', response);
      return response;
    } catch (error) {
      return errorHandler(error, 'requestVblock', { file });
    }
  },
});
