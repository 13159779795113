import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { useTranslation } from '../../../shared/hooks';
import { AutoComplete } from '../../../components';
import { trativeFormatDateWithIdiom } from '../../../shared/helpers';
import * as translations from '../intl';
import { GeneralContext } from '../../../shared/contexts/GeneralContext';

import api from '../../../services';

const SelectAppointment = ({ label, values }) => {
  const { getIdiom } = useTranslation({
    translationsFile: translations,
  });
  const { setIsLoading, amosToken } = useContext(GeneralContext);

  const [appointmentsAmos, setAppointmentsAmos] = useState([]);

  const fetchAppointments = async (timeZone, language) => {
    try {
      setIsLoading(true);
      const { data } = await api.amosServices.getAppointments(
        language,
        timeZone,
        amosToken
      );

      const options = data?.availability.map((el) => {
        const { date } = el;
        const daySlots = el?.daySlots.map((element) => {
          return {
            label: `${moment(date).format(
              trativeFormatDateWithIdiom(getIdiom())
            )} ${element.timeIni} - ${element.timeEnd}`,
            value: `{"date":"${date}", "time":"${element.timeIni}"}`,
          };
        });
        return daySlots;
      });

      setAppointmentsAmos([...options[0], ...options[1]]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (values?.timezone && values?.language) {
      fetchAppointments(values?.timezone, values?.language);
    }
  }, [values?.timezone, values?.language]);

  return (
    <>
      <AutoComplete
        label={label}
        options={appointmentsAmos}
        name="appointment"
      />
    </>
  );
};

export default SelectAppointment;
