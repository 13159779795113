import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTranslation } from '../../shared/hooks';
import * as translations from './intl';
import { Button } from '../../components';
import { withStyles } from '@material-ui/styles';

const styles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogBorder: {
    borderRadius: '0px !important',
  },
}));

const dialogStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(dialogStyles)((props) => {
  const { children, classes, onClose, closelabel, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label={closelabel}
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function ConfirmationModal({
  open,
  handleClose,
  handleAction,
  title = '',
  text = '',
  okButton = false,
}) {
  const classes = styles();
  const { locale: intl } = useTranslation({
    translationsFile: translations,
  });

  const { BUTTON_YES, BUTTON_NO, BUTTON_OK, CLOSE_LABEL } = intl;

  return (
    <div>
      <Dialog
        PaperProps={{
          className: classes.dialogBorder,
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle onClose={handleClose} closelabel={CLOSE_LABEL}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{ __html: text }} />
        </DialogContent>
        <DialogActions>
          {!okButton && (
            <>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="primary"
                text={BUTTON_NO}
              />
              <Button
                onClick={handleAction}
                variant="contained"
                color="primary"
                text={BUTTON_YES}
              />
            </>
          )}
          {okButton && (
            <>
              <Button
                onClick={handleAction}
                variant="contained"
                color="primary"
                text={BUTTON_OK}
              />
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmationModal;
