export const UPDATE_TITLE = {
  es_ES: 'ACTUALIZACIÓN DE LA PRESENTE POLÍTICA ',
  en_US: '',
  pt_BR: ' ',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};

export const UPDATE_TEXT = {
  es_ES: 'AXA Partners puede actualizar la presente política de cookies oportunamente en respuesta a cambios sobre las cookies descargadas al navegar el sitio web. La última actualización de la presente política de privacidad fue el 3 de octubre de 2023. ',
  en_US: '',
  pt_BR: ' ',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};

export const MAIN_COOKIE_TEXT = {
  es_ES: 'Una cookie es un archivo de datos pequeño (archivo de texto) que un sitio web, al ser visitado por un usuario, le pide a su buscador almacenar en su dispositivo para mantener información sobre usted, tal como sus preferencias de idioma o información. AXA Partners recopila y establece cookies “internas” durante su visita a este sitio web, según los fines descritos en la presente política.  ',
  en_US: 'A cookie is a small data file (text file) that a website, when visited by a user, asks your browser to store on your device to keep information about you, such as your language preferences or login information. AXA Partners collects and sets up ‘internal’ cookies during your visit of this website and pursuant to the purposes described in the present policy.',
  pt_BR: 'A cookie is a small data file (text file) that a website, when visited by a user, asks your browser to store on your device to keep information about you, such as your language preferences or login information. AXA Partners collects and sets up ‘internal’ cookies during your visit of this website and pursuant to the purposes described in the present policy.',
  fr_FR: 'Un cookie est un petit fichier de données (fichier texte) quun site internet, lorsquil est consulté par un utilisateur, demande à votre navigateur de stocker sur votre appareil afin de mémoriser des informations vous concernant, telles que vos préférences linguistiques ou informations de connexion. AXA Partners collecte des cookies lors de votre visite sur ce site et configure les cookies appelés cookies « internes » selon les finalités décrites dans la présente politique. ',
  de_DE: 'Ein Cookie ist eine kleine Datendatei (Textdatei), die eine Website beim Besuch eines Nutzers von Ihrem Browser auf Ihrem Gerät speichern lässt, um Informationen über Sie zu speichern. AXA Partners sammelt und speichert "interne" Cookies während Ihres Besuchs auf dieser website, gemäß den in diesen Informationen beschriebenen Zwecken. ',
  it_IT: 'A cookie is a small data file (text file) that a website, when visited by a user, asks your browser to store on your device to keep information about you, such as your language preferences or login information. AXA Partners collects and sets up ‘internal’ cookies during your visit of this website and pursuant to the purposes described in the present policy.',
};

export const HOW_COLLECTS_YOUR_PERSONAL_DATA_TITLE = {
  es_ES: '¿QUIÉNES SON LOS RESPONSABLES DE DATOS DE SUS DATOS PERSONALES?',
  en_US: 'WHO COLLECTS YOUR PERSONAL DATA ON THIS WEBSITE?',
  pt_BR: 'WHO COLLECTS YOUR PERSONAL DATA ON THIS WEBSITE?',
  fr_FR: 'QUI SONT LES RESPONSABLES DE TRAITEMENT ? ',
  de_DE: 'WER IST DER VERANTWORTLICHE STELLE FÜR DIE DATENVERARBEITUNG?',
  it_IT: 'WHO COLLECTS YOUR PERSONAL DATA ON THIS WEBSITE?',
};

export const HOW_COLLECTS_YOUR_PERSONAL_DATA_TEXT = {
  es_ES: 'Inter Partner Assistance SA, Sucursal en España, ubicada en calle Arequipa nº1, Edificio Mar de Cristal, planta 3ª, escaleras 2, 3 y 4 28043 de Madrid, actúa como responsable de datos independiente de sus datos personales (significa que determina los fines y medios del tratamiento de sus datos personales). En la presente política, el responsable de datos independiente se denomina "AXA Partners" o "Responsable de Datos" o "nosotros" o "nuestro". ',
  en_US: 'AXA Assistance UK Limited, a company under the laws of the United Kingdom, having its registered office at The Quadrangle, 106-118 Station Road, Redhill Surrey RH1 1 PR, United Kingdom, acts as an independent or joint data controller of the personal data processed in scope of this policy (it means that it determines the purposes and means of the processing of your personal data).  AXA Assistance UK Limited is referred to in this policy as "AXA Partners" or " Data Controller" or "we" or "us".',
  pt_BR: 'AXA Assistance UK Limited, a company under the laws of the United Kingdom, having its registered office at The Quadrangle, 106-118 Station Road, Redhill Surrey RH1 1 PR, United Kingdom, acts as an independent or joint data controller of the personal data processed in scope of this policy (it means that it determines the purposes and means of the processing of your personal data).  AXA Assistance UK Limited is referred to in this policy as "AXA Partners" or " Data Controller" or "we" or "us".',
  fr_FR: 'AXA ASSISTANCE FRANCE, société de droit français, ayant son siège social au 8/10, rue Paul Vaillant Couturier 92240 Malakoff, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro 311 338 339, agit en tant que Responsable de traitement de vos données à caractère personnel (c’est-à-dire qu’il détermine les finalités et les moyens du traitement de vos informations). Le responsable de traitement est désigné dans la présente Politique par AXA Partners ou le Responsable de traitement ou nous. ',
  de_DE: 'AXA Assistance Deutschland GmbH, Colonia-Allee 10-20, 51067 Köln, ein Unternehmen der AXA Gruppe, registriert am Amtsgericht Köln unter der Registernummer HRB88893, handelt als unabhängiger für die Verarbeitung Ihrer personenbezogenen Daten Verantwortlicher (d.H., er bestimmt die Zwecke und Mittel der Verarbeitung). Der unabhängige Datenverantwortliche wird in dieser Richtlinie als "AXA Partners" oder "Data Controller" oder "wir" oder "uns" bezeichnet.',
  it_IT: 'AXA Assistance UK Limited, a company under the laws of the United Kingdom, having its registered office at The Quadrangle, 106-118 Station Road, Redhill Surrey RH1 1 PR, United Kingdom, acts as an independent or joint data controller of the personal data processed in scope of this policy (it means that it determines the purposes and means of the processing of your personal data).  AXA Assistance UK Limited is referred to in this policy as "AXA Partners" or " Data Controller" or "we" or "us".',
};

export const WHAT_ARE_COOKIES_TITLE = {
  es_ES: '¿CÓMO ACEPTAR O RECHAZAR cookies?',
  en_US: 'HOW TO ACCEPT OR REFUSE COOKIES?',
  pt_BR: 'HOW TO ACCEPT OR REFUSE COOKIES?',
  fr_FR: 'COMMENT ACCEPTER OU REFUSER DE CONSENTIR AU DÉPÔT DE COOKIES ?',
  de_DE: 'WIE KANN ICH COOKIES AKZEPTIEREN ODER ABLEHNEN?',
  it_IT: 'HOW TO ACCEPT OR REFUSE COOKIES?',
};
export const WHAT_ARE_COOKIES_TEXT1 = {
  es_ES:
    'Un banner de cookie emerge desde su primera visita del sitio',
  en_US:
    'A cookie banner pops up as from your first visit of this website . This cookie banner enables you to, either:',
  pt_BR:
    'A cookie banner pops up as from your first visit of this website . This cookie banner enables you to, either:',
  fr_FR: 'Un bandeau apparaît la première fois que vous vous connectez sur ce site. Ce bandeau vous permet : ',
  de_DE:
    'Ab Ihrem ersten Besuch der Website porsche.de.axa.travel wird ein Cookie-Banner eingeblendet. Mit diesem Cookie-Banner können Sie entweder: ',
  it_IT:
    'A cookie banner pops up as from your first visit of this website . This cookie banner enables you to, either:',
};
export const WHAT_ARE_COOKIES_TEXT2 = {
  es_ES:
    'webhttps://esnt.claims.axa.travel/https://fit2trip.claims.axa.travel/.  Este banner de cookies le permite a usted: ',
  en_US:
    '',
  pt_BR:
    '',
  fr_FR: '',
  de_DE: '',
  it_IT:
    '',
};
export const WHAT_ARE_COOKIES_LIST = {
  es_ES: [
    'Aceptar todas las cookies opcionales que no son estrictamente necesarias para el correcto funcionamiento del sitio web, o ',
    'Rechazar todas las cookies que no son estrictamente necesarias para el correcto funcionamiento del sitio web, o  ',
    'Acceder al Centro de Preferencias de Cookies para configurar sus opciones en términos de consentimiento (lo que significa que puede seleccionar individualmente las cookies que acepta descargar mientras navega, aunque no son esenciales para el correcto funcionamiento del sitio web). ',
  ],
  en_US: [
    'Accept all optional cookies which are not strictly necessary for the proper functioning of the website, or',
    'Refuse all cookies that are not strictly necessary to the proper functioning of the website',
    'Access the Cookie Preference Center to set up your choices in terms of consent (meaning that you may select individually cookies you accept to drop while browsing event though they are not essential to the proper functioning of the website).',
  ],
  pt_BR: [
    'Accept all optional cookies which are not strictly necessary for the proper functioning of the website, or',
    'Refuse all cookies that are not strictly necessary to the proper functioning of the website',
    'Access the Cookie Preference Center to set up your choices in terms of consent (meaning that you may select individually cookies you accept to drop while browsing event though they are not essential to the proper functioning of the website).',
  ],
  fr_FR: [
    'de consentir au dépôt de tous les cookies optionnels qui ne sont pas strictement nécessaires au bon fonctionnement du site, ou  ',
    'de refuser le dépôt de tous les cookies optionnels, qui ne sont pas strictement nécessaires au bon fonctionnement du site, toutes catégories confondues, ou  ',
    'd’accéder à une interface de personnalisation de vos choix de consentement (cela signifie que vous pouvez choisir de consentir ou non au dépôt de chaque cookie qui nest pas indispensable au fonctionnement normal du site). ',
  ],
  de_DE: [
    'alle optionalen Cookies zu akzeptieren, die für das ordnungsgemäße Funktionieren der Website nicht unbedingt erforderlich sind, oder ',
    'alle Cookies abzulehnen, die für das ordnungsgemäße Funktionieren der Website nicht unbedingt erforderlich sind,  ',
    'auf das Cookie Preference Center zugreifen, um Ihre Wahl in Bezug auf die Zustimmung festzulegen (d. h. Sie können individuell auswählen, dass Sie Cookies ablehnen, auch wenn sie für das ordnungsgemäße Funktionieren der Website nicht unbedingt erforderlich sind). ',
  ],
  it_IT: [
    'Accept all optional cookies which are not strictly necessary for the proper functioning of the website, or',
    'Refuse all cookies that are not strictly necessary to the proper functioning of the website',
    'Access the Cookie Preference Center to set up your choices in terms of consent (meaning that you may select individually cookies you accept to drop while browsing event though they are not essential to the proper functioning of the website).',
  ],
};
export const WHAT_ARE_COOKIES_TEXT3 = {
  es_ES:
    'Guardaremos sus preferencias, ya sea consentimiento o rechazo de cookies, durante 3 meses. Vencido este plazo, el banner de cookies volverá a emerger para que usted vuelva a configurar sus preferencias. Usted puede ajustar la configuración de cookies en cualquier momento a través de ‘Centro de Preferencias de Cookies’ disponible en el pie de página. ',
  en_US:
    '',
  pt_BR: '',
  fr_FR: 'Nous conservons votre choix quel quil soit pendant une période de 6 mois. A lissue de cette période, nous vous demanderons de renouveler votre choix. Vous pouvez modifier vos préférences à tout moment en cliquant sur le centre de préférences des cookies accessible en bas de page. ',
  de_DE:
    'Wir werden Ihre Präferenzen, ob Zustimmung oder Ablehnung von Cookies, für 6 Monate speichern. Nach Ablauf dieses Zeitraums erscheint das Cookie-Banner, das Sie auffordert, Ihre Einstellungen erneut vorzunehmen. Sie können die Cookie-Einstellungen jederzeit über ‘Cookie Einstellungszentrum‘ anpassen. ',
  it_IT: '',
};
export const WHAT_ARE_COOKIES_TEXT4 = {
  es_ES:
    '',
  en_US:
    '',
  pt_BR: '',
  fr_FR: '',
  de_DE: '',
  it_IT: '',  
};
export const WHAT_ARE_COOKIES_TEXT5 = {
  es_ES:
    '',
  en_US:
    '',
  pt_BR: '',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};
export const TYPES_OF_COOKIES_USED_TITLE = {
  es_ES: '¿es obligatoria la descarga de cookies?',
  en_US: '',
  pt_BR: '',
  fr_FR: 'LE DÉPÔT DE COOKIES EST-IL OBLIGATOIRE ?',
  de_DE: 'IST DIE HINTERLEGUNG VON COOKIES OBLIGATORISCH?',
  it_IT: '',
};
export const TYPES_OF_COOKIES_USED_TEXT = {
  es_ES:
    'El carácter estrictamente necesario u opcional de cada categoría de cookies se indica en el mismo momento en que se le pide a usted otorgar (o denegar) el consentimiento, ya sea a través del banner de cookies en ocasión de su primera visita a nuestro sitio web, o a través de ‘Centro de Preferencias de Cookies’. El ‘Centro de Preferencias de Cookies’ permanece accesible en todo momento en el pie de página del sitio web, y también haciendo clic en ‘Personalizar mis preferencias’ en el banner de cookies. Tenga en cuenta que, al rechazar las cookies opcionales, algunas funciones, páginas y áreas del sitio web pueden no estar disponibles, por lo que no podemos responsabilizarnos. ',
  en_US:
    '',
  pt_BR: '',
  fr_FR: 'Le caractère nécessaire ou optionnel de chaque catégorie de cookies vous est indiqué au moment du recueil de votre consentement à leur dépôt dans la bannière cookies lors de votre première visite, ainsi que dans le Centre de Préférences Cookies. Le Centre de Préférences Cookies est accessible à tout moment en bas de page du site ainsi que via le bouton « Personnaliser mes choix » dans la bannière cookies. En cas de refus de certaines catégories de cookies optionnels, il se peut que certaines fonctionnalités, pages ou espaces du site internet ne soient pas accessibles ; ce dont nous ne saurions être responsables. Pour plus d’informations sur les cookies, vous pouvez consulter le site de la CNIL via le lien suivant : https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser.  ',
  de_DE: 'Der unbedingt notwendige oder fakultative Charakter jeder Kategorie von Cookies wird in dem Moment angegeben, in dem Sie aufgefordert werden, Ihre Zustimmung zu erteilen (oder zu verweigern), entweder über den Cookie-Banner bei Ihrem ersten Besuch auf unserer Website oder über das ‘Cookie Einstellungszentrum‘. Der ‘Cookie Einstellungszentrum‘ bleibt jederzeit in der Fußzeile der Website zugänglich, aber auch durch Anklicken von ´Meine Auswahl personalisieren´ im Cookie-Banner. Bitte beachten Sie, dass durch die Ablehnung von optionalen Cookies einige Funktionen, Seiten und Bereiche der Website möglicherweise nicht verfügbar sind, wofür wir nicht verantwortlich gemacht werden können. ',
  it_IT: '',
};
export const TYPES_OF_COOKIES_USED_LIST = {
  es_ES: [],
  en_US: [
    'We will store your preferences, whether consent or refusal of cookies, for 3 months. Once this period expires, you will see the cookie banner again when you next visit the site. You can adjust the cookie settings at any time via the Cookie Preference Center',
  ],
  pt_BR: [
    'We will store your preferences, whether consent or refusal of cookies, for 3 months. Once this period expires, you will see the cookie banner again when you next visit the site. You can adjust the cookie settings at any time via the Cookie Preference Center',
  ],
  fr_FR: [],
  de_DE: [],
  it_IT: [
    'We will store your preferences, whether consent or refusal of cookies, for 3 months. Once this period expires, you will see the cookie banner again when you next visit the site. You can adjust the cookie settings at any time via the Cookie Preference Center',
  ],
};
export const COOKIE_LIST_TITLE = {
  es_ES: '',
  en_US: 'IS COOKIE DEPOSIT MANDATORY',
  pt_BR: 'IS COOKIE DEPOSIT MANDATORY',
  fr_FR: '',
  de_DE: '',
  it_IT: 'IS COOKIE DEPOSIT MANDATORY',
};
//pending
export const COOKIE_LIST_TEXT1 = {
  es_ES:
    '',
  en_US:
    'The strictly necessary or optional character of each category of cookies is indicated at the very moment you are asked to grant (or deny) consent, either via the cookie banner when you first visit our website, or through the Cookie Preference Center. The Cookie Preference Center remains accessible at any time via a link the footer of the website but also by clicking ‘Personalize my choices’ in the cookie banner. Please note that by refusing optional cookies, some functions, pages and areas of the website may be unavailable or not operate effectively, for which we cannot be held responsible.',
  pt_BR:
    'The strictly necessary or optional character of each category of cookies is indicated at the very moment you are asked to grant (or deny) consent, either via the cookie banner when you first visit our website, or through the Cookie Preference Center. The Cookie Preference Center remains accessible at any time via a link the footer of the website but also by clicking ‘Personalize my choices’ in the cookie banner. Please note that by refusing optional cookies, some functions, pages and areas of the website may be unavailable or not operate effectively, for which we cannot be held responsible.',
  fr_FR: '',
  de_DE: '',
  it_IT:
    'The strictly necessary or optional character of each category of cookies is indicated at the very moment you are asked to grant (or deny) consent, either via the cookie banner when you first visit our website, or through the Cookie Preference Center. The Cookie Preference Center remains accessible at any time via a link the footer of the website but also by clicking ‘Personalize my choices’ in the cookie banner. Please note that by refusing optional cookies, some functions, pages and areas of the website may be unavailable or not operate effectively, for which we cannot be held responsible.',
};

export const COOKIE_TABLES_TITLE = {
  es_ES: '¿CUÁLES SON LAS CATEGORÍAS DE LAS COOKIES QUE SE DESCARGAN DURANTE LA NAVEGACIÓN EN NUESTRO SITIO WEB Y CUÁLES SON SUS FINALIDADES?',
  en_US: 'WHAT ARE THE CATEGORIES OF COOKIES BEING DROPPED WHILE YOU ARE BROWSING OUR WEBSITE AND WHAT ARE THEIR PURPOSES?',
  pt_BR: 'WHAT ARE THE CATEGORIES OF COOKIES BEING DROPPED WHILE YOU ARE BROWSING OUR WEBSITE AND WHAT ARE THEIR PURPOSES?',
  fr_FR: 'QUELLES SONT LES CATEGORIES DE COOKIES DEPOSES LORS DE VOTRE VISITE ET QUELLES EN SONT LES FINALITES ?',
  de_DE: 'WELCHES SIND DIE KATEGORIEN DER COOKIES, DIE WÄHREND IHRES BESUCHS AUF UNSERER WEBSITE ABGELEGT WERDEN, UND WELCHEN ZWECK ERFÜLLEN SIE?',
  it_IT: 'WHAT ARE THE CATEGORIES OF COOKIES BEING DROPPED WHILE YOU ARE BROWSING OUR WEBSITE AND WHAT ARE THEIR PURPOSES?',
};

//pending
export const FUNCTIONAL_TECHNICAL_COOKIES_TITLE = {
  es_ES: 'COOKIES FUNCIONALES Y TÉCNICAS (COOKIES ESTRICTAMENTE NECESARIAS) ',
  en_US: 'FUNCTIONAL AND TECHNICAL COOKIES (STRICTLY NECESSARY COOKIES)',
  pt_BR: 'FUNCTIONAL AND TECHNICAL COOKIES (STRICTLY NECESSARY COOKIES)',
  fr_FR: 'COOKIES FONCTIONNELS ET TECHNIQUES (COOKIES STRICTEMENT NÉCESSAIRES) ',
  de_DE: 'FUNKTIONELLE UND TECHNISCHE COOKIES  (UNBEDINGT NOTWENDIGE COOKIES) ',
  it_IT: 'FUNCTIONAL AND TECHNICAL COOKIES (STRICTLY NECESSARY COOKIES)',
};
//pending
export const FUNCTIONAL_TECHNICAL_COOKIES_TEXT = {
  es_ES:
    'Estas cookies son necesarias para que el sitio web funcione y no puedan apagarse en nuestros sistemas. Normalmente solo se fijan en respuesta a acciones que usted hace para solicitar servicios, tales como fijar sus preferencias de privacidad, inicio de sesión o completar formularios. Usted puede configurar su navegador para bloquear o recibir una alerta sobre estas cookies, pero en tal caso algunas partes del sitio web no funcionarán. ',
  en_US:
    'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions taken by you, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but this may impact the functionality and use of the website.',
  pt_BR:
    'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions taken by you, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but this may impact the functionality and use of the website.',
  fr_FR: 'Ces cookies sont nécessaires au fonctionnement du site internet et ne peuvent pas être désactivés dans nos systèmes. Ils sont généralement établis en tant que réponse à des actions que vous avez effectuées et qui constituent une demande de services, telles que la définition de vos préférences en matière de confidentialité, la connexion ou le remplissage de formulaires. Vous pouvez configurer votre navigateur afin de bloquer ou être informé de lexistence de ces cookies, mais certaines parties du site internet peuvent être affectées. ',
  de_DE: 'Diese Cookies sind für das Funktionieren der Website erforderlich und können in unseren Systemen nicht abgeschaltet werden. Sie werden in der Regel nur gesetzt, wenn Sie Dienste anfordern, z. B. wenn Sie Ihre Datenschutzeinstellungen festlegen, sich anmelden oder Formulare ausfüllen. Sie können Ihren Browser so einstellen, dass er diese Cookies blockiert oder Sie darauf hinweist, aber einige Teile der Website werden dann nicht funktionieren. ',
  it_IT:
    'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions taken by you, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but this may impact the functionality and use of the website.',
};
//pending
export const TABLE_HEAD = {
  es_ES: ['Sub-grupo de cookies', 'Cookies', 'Cookies utilizadas', 'Ciclo de vida '],
  en_US: ['Cookie Subgroup', 'Cookies', 'Cookies used', 'Lifepan'],
  pt_BR: ['Cookie Subgroup', 'Cookies', 'Cookies used', 'Lifepan'],
  fr_FR: ['Sous-groupe de cookies ', 'Cookies', 'Cookies utilisés', 'Durées de vie'],
  de_DE: ['Untergruppe Cookies', 'Cookie Beschreibung ', 'Eingesetzte Cookies', 'Lebensspanne'],
  it_IT: ['Cookie Subgroup', 'Cookies', 'Cookies used', 'Lifepan'],
};
//pending
export const FIRST_PARTY = {
  es_ES: 'Propia',
  en_US: 'First Party',
  pt_BR: 'First Party',
  fr_FR: 'Cookies internes',
  de_DE: 'Erstanbieter',
  it_IT: 'First Party',
};

export const FUNCTIONAL_COOKIES_NAME0 = {
  es_ES: 'Estas cookies permiten contar el número de usuarios que acceden a la aplicación a lo largo del tiempo. ',
  en_US: 'These cookies enable the counting of the number of users accessing the application over time.',
  pt_BR: 'These cookies enable the counting of the number of users accessing the application over time.',
  fr_FR: 'Ces cookies permettent de compter le nombre dutilisateurs accédant à lapplication au fil du temps. ',
  de_DE: 'Diese Cookies ermöglichen die Zählung der Anzahl der Benutzer, die im Laufe der Zeit auf die Anwendung zugreifen. ',
  it_IT: 'These cookies enable the counting of the number of users accessing the application over time.',
};

export const FUNCTIONAL_COOKIES_USED0 = {
  es_ES: 'user ',
  en_US: 'user',
  pt_BR: 'user',
  fr_FR: 'user',
  de_DE: 'user',
  it_IT: 'user',
};

export const FUNCTIONAL_COOKIES_LIFESPAN0 = {
  es_ES: 'Sesión de navegación ',
  en_US: 'Browsing Session',
  pt_BR: 'Browsing Session',
  fr_FR: 'Session de navigation ',
  de_DE: 'Browsersitzung ',
  it_IT: 'Browsing Session',
};

export const FUNCTIONAL_COOKIES_NAME1 = {
  es_ES: 'Estas cookies almacenan información relativa a las categorías de cookies depositadas en el sitio y a la prestación o retirada del consentimiento por parte de los usuarios para cada una de ellas. Esto permite impedir que se depositen cookies en ausencia de consentimiento. ',
  en_US: 'This cookie is dropped once visitor received the information about cookies and closed the banner. It prevents the banner from popping up at every visit. ',
  pt_BR: 'This cookie is dropped once visitor received the information about cookies and closed the banner. It prevents the banner from popping up at every visit. ',
  fr_FR: 'Ce cookie conserve les informations relatives aux catégories de cookies déposés sur le site et à la fourniture ou au retrait de consentement des utilisateurs. Cela permet dempêcher les cookies dêtre déposés en labsence de consentement.',
  de_DE: 'Der Cookie speichert Informationen über die Kategorien von Cookies, die die Website verwendet, und darüber, ob die Besucher ihre Zustimmung zur Verwendung der einzelnen Kategorien gegeben oder zurückgezogen haben. Auf diese Weise wird verhindert, dass Cookies gesetzt werden, wenn der Nutzer seine Zustimmung nicht erteilt hat. ',
  it_IT: 'This cookie is dropped once visitor received the information about cookies and closed the banner. It prevents the banner from popping up at every visit. ',
};

export const FUNCTIONAL_COOKIES_USED1 = {
  es_ES: 'OptanonConsent ',
  en_US: 'OptanonConsent',
  pt_BR: 'OptanonConsent',
  fr_FR: 'OptanonConsent ',
  de_DE: 'OptanonConsent ',
  it_IT: 'OptanonConsent',
};

export const FUNCTIONAL_COOKIES_LIFESPAN1 = {
  es_ES: '3 meses ',
  en_US: '3 Months',
  pt_BR: '3 Months',
  fr_FR: '3 mois ',
  de_DE: '3 Monate',
  it_IT: '3 Months',
};

export const FUNCTIONAL_COOKIES_NAME2 = {
  es_ES: 'Estas cookies se colocan una vez que los visitantes han recibido la información de la cookie y han cerrado el banner. Permiten que el sitio no muestre el banner cada vez que los visitantes se conectan. ',
  en_US: 'This enables site owners to prevent cookies in each category from being set in the users’ browser, when consent is not given.',
  pt_BR: 'This enables site owners to prevent cookies in each category from being set in the users’ browser, when consent is not given.',
  fr_FR: 'Ce cookie est placé une fois que les visiteurs ont reçu l’information relative aux cookies et fermé la bannière. Il permet au site de ne pas représenter la bannière à chaque connexion. ',
  de_DE: 'Der Cookie wird gesetzt, nachdem der Cookie-Hinweis eingeblendet und eine Auswahl getroffen wurde. Werden die Cookie-Hinweise aktiv geschlossen, wird der Cookie ebenfalls gesetzt. Es ermöglicht der Website, die Meldung nicht mehr als einmal an einen Nutzer zu senden. ',
  it_IT: 'This enables site owners to prevent cookies in each category from being set in the users’ browser, when consent is not given.',
};

export const FUNCTIONAL_COOKIES_USED2 = {
  es_ES: 'OptanonAlertBoxClosed ',
  en_US: 'OptanonAlertBoxClosed',
  pt_BR: 'OptanonAlertBoxClosed',
  fr_FR: 'OptanonAlertBoxClosed ',
  de_DE: 'OptanonAlertBoxClosed ',
  it_IT: 'OptanonAlertBoxClosed',
};

export const FUNCTIONAL_COOKIES_LIFESPAN2 = {
  es_ES: '3 meses ',
  en_US: '3 Months',
  pt_BR: '3 Months',
  fr_FR: '3 mois ',
  de_DE: '3 Monate',
  it_IT: '3 Months',
};

export const FUNCTIONAL_COOKIES_NAME3 = {
  es_ES: 'Estas cookies recopilan información estadística de uso y telemetría. ',
  en_US: 'These cookies collect statistical usage and telemetry information',
  pt_BR: 'These cookies collect statistical usage and telemetry information',
  fr_FR: 'Ces cookies collectent des informations statistiques dutilisation et de télémétrie ',
  de_DE: 'Diese Cookies sammeln statistische Nutzungs- und Telemetrieinformationen ',
  it_IT: 'These cookies collect statistical usage and telemetry information',
};

export const FUNCTIONAL_COOKIES_USED3= {
  es_ES: 'session ',
  en_US: 'session',
  pt_BR: 'session',
  fr_FR: 'session ',
  de_DE: 'session',
  it_IT: 'session',
};

export const FUNCTIONAL_COOKIES_LIFESPAN3 = {
  es_ES: 'Unos pocos segundos ',
  en_US: 'A Few Seconds',
  pt_BR: 'A Few Seconds',
  fr_FR: 'Quelques secondes ',
  de_DE: 'Ein paar Sekunden ',
  it_IT: 'A Few Seconds',
};

export const FUNCTIONAL_COOKIES_NAME4 = {
  es_ES: 'Esta cookie se utiliza para almacenar la URL completa de la página actual. ',
  en_US: 'This cookie is used to store the full URL of the current page.',
  pt_BR: 'This cookie is used to store the full URL of the current page.',
  fr_FR: 'Ce cookie est utilisé pour stocker lURL complète de la page actuelle. ',
  de_DE: 'Dieses Cookie wird verwendet, um die vollständige URL der aktuellen Seite zu speichern. ',
  it_IT: 'This cookie is used to store the full URL of the current page.',
};

export const FUNCTIONAL_COOKIES_USED4 = {
  es_ES: 'fullURL ',
  en_US: 'fullURL',
  pt_BR: 'fullURL',
  fr_FR: 'fullURL ',
  de_DE: 'fullURL ',
  it_IT: 'fullURL',
};

export const FUNCTIONAL_COOKIES_LIFESPAN4 = {
  es_ES: 'Sesión de navegación ',
  en_US: 'Browsing Session',
  pt_BR: 'Browsing Session',
  fr_FR: 'Session de navigation ',
  de_DE: 'Browsersitzung ',
  it_IT: 'Browsing Session',
};

export const FUNCTIONAL_COOKIES_NAME5 = {
  es_ES: 'Esta cookie nos permite reconocer el idioma en el que los visitantes tienen configurado su navegador para mostrar anuncios relevantes en otros sitios. ',
  en_US: 'This cookie allows us to recognize the language in which visitors have their browser configured to show relevant ads on other sites.',
  pt_BR: 'This cookie allows us to recognize the language in which visitors have their browser configured to show relevant ads on other sites.',
  fr_FR: 'Ce cookie nous permet de reconnaître la langue dans laquelle les visiteurs ont configuré leur navigateur pour afficher des publicités pertinentes sur dautres sites. ',
  de_DE: 'Mit diesem Cookie können wir die Sprache erkennen, in der Besucher ihren Browser so konfiguriert haben, dass relevante Anzeigen auf anderen Websites angezeigt werden. ',
  it_IT: 'This cookie allows us to recognize the language in which visitors have their browser configured to show relevant ads on other sites.',
};

export const FUNCTIONAL_COOKIES_USED5 = {
  es_ES: 'idiom ',
  en_US: 'idiom',
  pt_BR: 'idiom',
  fr_FR: 'idiom',
  de_DE: 'idiom',
  it_IT: 'idiom',
};

export const FUNCTIONAL_COOKIES_LIFESPAN5 = {
  es_ES: 'Sesión de navegación ',
  en_US: 'Browsing Session',
  pt_BR: 'Browsing Session',
  fr_FR: 'Session de navigation ',
  de_DE: 'Browsersitzung ',
  it_IT: 'Browsing Session',
};
//pending
export const ANALYTICS_COOKIES_TITLE = {
  es_ES: '',
  en_US: '',
  pt_BR: 'Analytics cookies (website traffic)',
  fr_FR: '',
  de_DE: '',
  it_IT: 'Cookies analitici (traffico website)',
};
//pending
export const ANALYTICS_COOKIES_TEXT = {
  es_ES:
    '',
  en_US:
    '',
  pt_BR: '',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};
//pending
export const MARKETING_COOKIES_TITLE = {
  es_ES: 'COOKIES DE MEDICIÓN DE AUDIENCIA (ANALÍTICAS) ',
  en_US: 'AUDIENCE MEASUREMENT COOKIES',
  pt_BR: 'AUDIENCE MEASUREMENT COOKIES',
  fr_FR: 'COOKIES D’ANALYSE ET DE MESURE D’AUDIENCE ',
  de_DE: 'COOKIES ZUR LEISTUNGSMESSUNG – LEISTUNGSBEZOGENE COOKIES ',
  it_IT: 'AUDIENCE MEASUREMENT COOKIES',
};
export const MARKETING_COOKIES_TEXT = {
  es_ES:
    'Estas cookies nos permiten conocer el número de visitas y fuentes de tráfico de modo que podemos medir y mejorar el desempeño de nuestro sitio web. Estas cookies nos permiten conocer qué páginas son las más y menos visitadas y ver cómo navegan los usuarios por el sitio web. Toda la información que recogen estas cookies se agrega. Si usted no permite estas cookies, no sabremos cuándo ha visitado nuestro sitio web y no podremos monitorear su desempeño. ',
  en_US:
    'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our website. They enable us to monitor which pages are the most and least popular, common actions taken and how visitors use website. All information these cookies collect is aggregated. If you do not allow these cookies we will not know when you have visited our website and will not be able to monitor its performance.',
  pt_BR:
    'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our website. They enable us to monitor which pages are the most and least popular, common actions taken and how visitors use website. All information these cookies collect is aggregated. If you do not allow these cookies we will not know when you have visited our website and will not be able to monitor its performance.',
  fr_FR: 'Ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic, afin de mesurer et d’améliorer les performances de notre site internet. Ils nous aident également à identifier les pages les plus / moins visitées et d’évaluer comment les visiteurs naviguent sur le site internet. Toutes les informations collectées par ces cookies sont agrégées. Si vous nacceptez pas ces cookies, AXA Partners ne sera pas informé de votre visite sur notre site. ',
  de_DE: 'Diese Cookies ermöglichen es uns, Besuche und Verkehrsquellen zu zählen, damit wir die Leistung unserer Website messen und verbessern können. So können wir feststellen, welche Seiten am beliebtesten und welche am unbeliebtesten sind, und sehen, wie sich die Besucher auf der Website bewegen. Alle Informationen, die diese Cookies sammeln, werden aggregiert. Wenn Sie diese Cookies nicht zulassen, wissen wir nicht, wann Sie unsere Website besucht haben und können ihre Leistung nicht überwachen. ',
  it_IT:
    'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our website. They enable us to monitor which pages are the most and least popular, common actions taken and how visitors use website. All information these cookies collect is aggregated. If you do not allow these cookies we will not know when you have visited our website and will not be able to monitor its performance.',
};

export const MARKETING_COOKIES_NAME0 = {
  es_ES: 'Esta cookie permite que un servicio distinga a un visitante del sitio web de otro. ',
  en_US: 'This cookie is used to calculate visitor, session and campaign data for analytics reports.',
  pt_BR: 'This cookie is used to calculate visitor, session and campaign data for analytics reports.',
  fr_FR: 'Ce cookie permet à un service de distinguer un visiteur dun site Web dun autre. ',
  de_DE: 'Dieses Cookie wird zur Berechnung von Besucher-, Sitzungs- und Kampagnendaten für Analyseberichte verwendet. ',
  it_IT: 'This cookie is used to calculate visitor, session and campaign data for analytics reports.',
};

export const MARKETING_COOKIES_USED0 = {
  es_ES: '_ga_xxxxxxx ',
  en_US: '_ga',
  pt_BR: '_ga',
  fr_FR: '_ga_xxxxxxx ',
  de_DE: '_ga',
  it_IT: '_ga',
};

export const MARKETING_COOKIES_LIFESPAN0 = {
  es_ES: '13 meses ',
  en_US: '13 Months',
  pt_BR: '13 Months',
  fr_FR: '13 mois ',
  de_DE: '13 Monate ',
  it_IT: '13 Months',
};

export const MARKETING_COOKIES_NAME1 = {
  es_ES: 'Esta cookie se utiliza para calcular datos de visitantes, sesiones y campañas para informes analíticos. ',
  en_US: 'This cookie enables a service to distinguish one website visitor from another.',
  pt_BR: 'This cookie enables a service to distinguish one website visitor from another.',
  fr_FR: 'Ce cookie est utilisé pour calculer les données des visiteurs, des sessions et des campagnes pour les rapports danalyse. ',
  de_DE: 'Dieses Cookie ermöglicht einem Dienst, einen Website-Besucher von einem anderen zu unterscheiden. ',
  it_IT: 'This cookie enables a service to distinguish one website visitor from another.',
};

export const MARKETING_COOKIES_USED1 = {
  es_ES: '_ga ',
  en_US: '_ga_xxxxxxx',
  pt_BR: '_ga_xxxxxxx',
  fr_FR: '_ga ',
  de_DE: '_ga_xxxxxxxxxx ',
  it_IT: '_ga_xxxxxxx',
};

export const MARKETING_COOKIES_LIFESPAN1 = {
  es_ES: '13 meses ',
  en_US: '13 Months',
  pt_BR: '13 Months',
  fr_FR: '13 mois ',
  de_DE: '13 Monate ',
  it_IT: '13 Months',
};


export const WHAT_ARE_YOUR_RIGHTS_RELATED_TITLE = {
  es_ES: '¿CUÁLES SON SUS DERECHOS RELATIVOS A SUS DATOS?',
  en_US: 'WHAT ARE YOUR RIGHTS RELATING TO YOUR DATA?',
  pt_BR: 'WHAT ARE YOUR RIGHTS RELATING TO YOUR DATA?',
  fr_FR: 'QUELS SONT VOS DROITS SUR VOS DONNEES A CARACTERE PERSONNEL ? ',
  de_DE: 'WELCHE RECHTE HABEN SIE IN BEZUG AUF IHRE DATEN?',
  it_IT: 'WHAT ARE YOUR RIGHTS RELATING TO YOUR DATA?',
};

export const WHAT_ARE_YOUR_RIGHTS_RELATED_TEXT = {
  es_ES: 'El depósito de cookies puede implicar el tratamiento de sus datos personales. En tal caso, y de acuerdo con los requisitos del RGPD, usted tiene derechos que puede ejercer. Consulte nuestro Política de Privacidad para consultar dichos derechos. Además, usted tiene derecho de retirar el consentimiento («baja») al depósito de cookies opcionales descargadas al descargar cookies opcionales. Usted puede actualizar su configuración de cookies en cualquier momento a través del centro de preferencias disponible aquí ‘Centro de Preferencias de Cookies’ disponible en el pie de página.  ',
  en_US: 'You may grant your consent (‘opt-in’) to the dropping of some cookies and update your preferences at any time for all or part of the optional cookies dropped via the Cookie Preference Center. The dropping of cookies may result in the processing of your personal data. In such a case, and according to UK GDPR and any other applicable data protection requirements, you have rights you can exercise in relation to this processing. Please refer to our Privacy Policy for more information.',
  pt_BR: 'You may grant your consent (‘opt-in’) to the dropping of some cookies and update your preferences at any time for all or part of the optional cookies dropped via the Cookie Preference Center. The dropping of cookies may result in the processing of your personal data. In such a case, and according to UK GDPR and any other applicable data protection requirements, you have rights you can exercise in relation to this processing. Please refer to our Privacy Policy for more information.',
  fr_FR: 'Il est possible que le dépôt de ces cookies implique un traitement de données à caractère personnel. Dans un tel cas, et conformément au RGPD, vous disposez de droits les concernant. Pour les connaître, veuillez-vous référer à la politique concernant les cookies disponible en bas de page. Par ailleurs, vous disposez du droit de retirer votre consentement (« opt-out ») au dépôt de cookies optionnels. Vous modifier vos préférences à tout moment pour tout ou partie des cookies déposés via le Centre de Préférence Cookies accessible en bas de page  ',
  de_DE: 'Erteilen Sie Ihre Zustimmung ("Opt-in") zur Hinterlegung einiger Cookies und aktualisieren Sie Ihre Präferenzen jederzeit oder für alle oder einen Teil der optionalen Cookies, die beim Surfen abgelegt werden ‘Cookie Einstellungszentrum‘. Darüber hinaus haben Sie das Recht, Ihre Zustimmung zur Hinterlegung von optionalen Cookies zu widerrufen ("Opt-out"), während Sie die optionalen Cookies ablegen. Sie können Ihre Cookie-Einstellungen jederzeit über das hier verfügbare Präferenzzentrum aktualisieren ‘Cookie Einstellungszentrum‘. ',
  it_IT: 'You may grant your consent (‘opt-in’) to the dropping of some cookies and update your preferences at any time for all or part of the optional cookies dropped via the Cookie Preference Center. The dropping of cookies may result in the processing of your personal data. In such a case, and according to UK GDPR and any other applicable data protection requirements, you have rights you can exercise in relation to this processing. Please refer to our Privacy Policy for more information.',
};

export const COOKIE_SETTINGS_TITLE = {
  es_ES: '',
  en_US: '',
  pt_BR: '',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};

export const COOKIE_SETTINGS_TEXT1 = {
  es_ES:
    '',
  en_US:
    '',
  pt_BR: '',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};
export const COOKIE_SETTINGS_TEXT2 = {
  es_ES:
    '',
  en_US:
    '',
  pt_BR: '',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
  };
export const COOKIE_SETTINGS_LIST = {
  es_ES: [],
  en_US: [],
  pt_BR: [],
  fr_FR: [],
  de_DE: [],
  it_IT: [],
};

export const HOW_TO_CONTACT_TITLE = {
  es_ES: '¿CÓMO CONTACTAR AL DPO PARA PRESENTAR UNA SOLICITUD O EJERCER SUS DERECHOS?',
  en_US: 'HOW TO CONTACT AXA PARTNERS REGARDING THIS COOKIE POLICY',
  pt_BR: 'HOW TO CONTACT AXA PARTNERS REGARDING THIS COOKIE POLICY',
  fr_FR: 'COMMENT CONTACTER LE DELEGUE A LA PROTECTION DES DONNEES (« DPD » OU « DPO ») POUR TOUTE QUESTION OU COMMENT EXERCER VOS DROITS ?',
  de_DE: 'WIE KÖNNEN SIE DEN DATENSCHUTZBEAUFTRAGTEN KONTAKTIEREN, UM ANFRAGEN ZU STELLEN ODER IHRE RECHTE GELTEND ZU MACHEN?',
  it_IT: 'HOW TO CONTACT AXA PARTNERS REGARDING THIS COOKIE POLICY',
};

export const HOW_TO_CONTACT_TEXT = {
  es_ES: 'Si tiene alguna pregunta, queja o comentario con respecto a este aviso informativo o para ejercer los derechos antes mencionados, por favor diríjase a través del correo electrónico a protecciondedatos@axa-assistance.es. Su solicitud se redirigirá en consecuencia al DPO correspondiente. Los socios de AXA pueden solicitarle información adicional para confirmar su identidad y/o ayudar a localizar los datos que está buscando. ',
  en_US: 'If you have any questions, complaints, or comments regarding this information notice, the processing it describes or to exercise the rights mentioned above, please contact us by email to dataprotectionenquiries@axa-assistance.co.uk. We may ask you for additional information to confirm your identity and/or to assist locating the data you are seeking.',
  pt_BR: 'If you have any questions, complaints, or comments regarding this information notice, the processing it describes or to exercise the rights mentioned above, please contact us by email to dataprotectionenquiries@axa-assistance.co.uk. We may ask you for additional information to confirm your identity and/or to assist locating the data you are seeking.',
  fr_FR: 'Pour toute question, réclamation ou commentaire concernant la présente Politique ou pour exercer vos droits, veuillez adresser votre demande à dpo.axapartnersfrance@axa-assistance.com. AXA Partners peut vous demander des renseignements complémentaires pour confirmer votre identité et / ou pour aider AXA Partners à trouver les données que vous recherchez. ',
  de_DE: 'Wenn Sie Fragen, Beschwerden oder Kommentare zu diesem Informationsblatt haben oder Ihre oben genannten Rechte wahrnehmen möchten, senden Sie bitte eine E-Mail an datenschutz@axa-assistance.de.  Ihre Anfrage wird dann entsprechend an den zuständigen Datenschutzbeauftragten weitergeleitet. AXA Partners kann Sie um zusätzliche Informationen bitten, um Ihre Identität zu bestätigen und/oder um Ihnen zu helfen, die Daten zu finden, die Sie suchen. ',
  it_IT: 'If you have any questions, complaints, or comments regarding this information notice, the processing it describes or to exercise the rights mentioned above, please contact us by email to dataprotectionenquiries@axa-assistance.co.uk. We may ask you for additional information to confirm your identity and/or to assist locating the data you are seeking.',
};

export const UPDATES_TITLE = {
  es_ES: '¿CÓMO PRESENTAR UNA RECLAMACIÓN ANTE LA AUTORIDAD DE CONTROL?',
  en_US: 'HOW TO MAKE A COMPLAINT TO A SUPERVISORY AUTHORITY?',
  pt_BR: 'HOW TO MAKE A COMPLAINT TO A SUPERVISORY AUTHORITY?',
  fr_FR: 'COMMENT EMETTRE UNE RECLAMATION AUPRES D’UNE AUTORITE DE CONTRÔLE ? ',
  de_DE: 'WIE KANN ICH EINE BESCHWERDE BEI EINER AUFSICHTSBEHÖRDE EINREICHEN?',
  it_IT: 'HOW TO MAKE A COMPLAINT TO A SUPERVISORY AUTHORITY?',
};
export const UPDATES_TEXT = {
  es_ES:
    'Usted tiene derecho a plantear sus dudas sobre cómo se están tratando sus datos personales ante una autoridad de control competente, en el Estado miembro de su residencia habitual, lugar de trabajo o lugar donde crea que se ha producido una supuesta vulneración de sus derechos. En España, la Autoridad de Protección de Datos es la Agencia Española de Protección de Datos, sita en la calle Jorge Juan nº6, 28001 Madrid. El listado de autoridades de protección de datos en la Unión Europea está disponible a través del siguiente enlace: https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm. Para países no pertenecientes a la UE, consulte el sitio web de su autoridad local de protección de datos. ',
  en_US:
    'You have the right to raise concerns about how your personal data is being processed with a competent supervisory authority, in the location of your habitual residence, place of work or place where you think an alleged infringement to your rights occurred. In the UK, the competent supervisory authority is the Information Commissioner’s Office (“The ICO”) - their website address is https://ico.org.uk/. The list of data protection authorities in the European Union is available through the following link: https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm. For non-EU countries, please refer to the website of your local data protection authority.',
  pt_BR:
    'You have the right to raise concerns about how your personal data is being processed with a competent supervisory authority, in the location of your habitual residence, place of work or place where you think an alleged infringement to your rights occurred. In the UK, the competent supervisory authority is the Information Commissioner’s Office (“The ICO”) - their website address is https://ico.org.uk/. The list of data protection authorities in the European Union is available through the following link: https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm. For non-EU countries, please refer to the website of your local data protection authority.',
  fr_FR: 'Vous avez le droit de faire part, à une autorité de contrôle compétente, en particulier dans lEtat membre de votre résidence habituelle, de votre lieu de travail ou du lieu où vous pensez qu’un manquement à vos droits sest produit, de toute préoccupation concernant la manière dont vos données à caractère personnel sont traitées. En France, l’autorité de protection des données à caractère personnel est la Commission nationale de l’informatique et des libertés, ou CNIL. Vous pouvez la contacter directement sur le site https://www.cnil.fr/fr/contacter-la-cnil-standard-et-permanences-telephoniques ou par courrier à l’adresse suivante : 3 place de Fontenoy – TSA 80715 – 75334 Paris Cedex 07. La liste des autorités de contrôle au sein de l’Union Européenne est disponible en cliquant sur le lien suivant : https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm. Si votre pays est situé hors Union Européenne, veuillez-vous référer au site internet de votre autorité de contrôle en matière de protection des données. ',
  de_DE: 'Sie haben das Recht, sich bei einer zuständigen Aufsichtsbehörde des Mitgliedstaats, in dem Sie Ihren gewöhnlichen Aufenthalt, Ihren Arbeitsplatz oder den Ort, an dem Sie glauben, dass Ihre Rechte verletzt wurden, über die Verarbeitung Ihrer personenbezogenen Daten zu beschweren. In Deutschland wenden Sie sich bitte an den jeweiligen Landesdatenschutzbeauftragten. Die zuständige Landesdatenschutzbeauftragte für den Betreiber der Webseite ist die Landesdatenschutzbeauftragte für Datenschutz und Informationsfreiheit NRW, Kavalleriestraße 2-4, 40213 Düsseldorf, E-Mail: poststelle@ldi.nrw.de. Die Liste der Datenschutzbehörden in der Europäischen Union finden Sie unter dem folgenden Link: https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm. Für Nicht-EU-Länder konsultieren Sie bitte die Website Ihrer örtlichen Datenschutzbehörde. ',
  it_IT:
    'You have the right to raise concerns about how your personal data is being processed with a competent supervisory authority, in the location of your habitual residence, place of work or place where you think an alleged infringement to your rights occurred. In the UK, the competent supervisory authority is the Information Commissioner’s Office (“The ICO”) - their website address is https://ico.org.uk/. The list of data protection authorities in the European Union is available through the following link: https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm. For non-EU countries, please refer to the website of your local data protection authority.',
};

export const UPDATE_OF_PRESENT_POLICY_TITLE = {
  es_ES: 'ACTUALIZACIÓN DE LA PRESENTE POLÍTICA ',
  en_US: 'UPDATE OF THE PRESENT POLICY',
  pt_BR: 'UPDATE OF THE PRESENT POLICY',
  fr_FR: 'MISE A JOUR DE LA PRESENTE POLITIQUE DE CONFIDENTIALITE ',
  de_DE: 'UPDATE DER AKTUELLEN RICHTLINIE',
  it_IT: 'UPDATE OF THE PRESENT POLICY',
};

export const UPDATE_OF_PRESENT_POLICY_TEXT = {
  es_ES: 'AXA Partners puede actualizar la presente política de cookies oportunamente en respuesta a cambios sobre las cookies descargadas al navegar el sitio web. La última actualización de la presente política de privacidad fue el 3 de octubre de 2023. ',
  en_US: 'AXA Partners may update this cookie policy from time to time in response to changes regarding cookies dropped while browsing this website. This cookie policy was last updated on October 2, 2023.',
  pt_BR: 'AXA Partners may update this cookie policy from time to time in response to changes regarding cookies dropped while browsing this website. This cookie policy was last updated on October 2, 2023.',
  fr_FR: 'AXA Partners peut mettre à jour cette politique en réponse à des changements relatifs au dépôt de cookies sur le site https://frnt.claims.axa.travel/. Cette politique a été mise à jour pour la dernière fois le 3 octobre 2023.  ',
  de_DE: 'AXA Partners kann diese Cookie-Richtlinie von Zeit zu Zeit aktualisieren, um auf Änderungen in Bezug auf Cookies zu reagieren, die beim Surfen auf der Website abgelegt werden. Diese Datenschutzrichtlinie wurde zuletzt am 03.10.2023 aktualisiert. ',
  it_IT: 'AXA Partners may update this cookie policy from time to time in response to changes regarding cookies dropped while browsing this website. This cookie policy was last updated on October 2, 2023.',
};

export const MORE_INFO_TITLE = {
  es_ES: '',
  en_US: '',
  pt_BR: '',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};
