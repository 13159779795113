import React, { useRef } from 'react';
import {
  TextField,
  FormControl,
  TextFieldProps,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { useField } from 'formik';
import { useFocusOnError } from '../../shared/hooks';
import styles from '../InputText/styles';

export type InputTextProps = {
  smallFont?: boolean;
  name: string;
  maxLength?: number;
  subTitle?: string;
  externalTitle?: boolean;
} & TextFieldProps;

const InputTextField = styled(TextField).attrs(() => ({
  maxLength: 75,
}))<any>`
  background-color: #fff;
  color: #016fd0;
  font-weight: bold;
  border: 3px solid #f0f0f0 !important;
  border-radius: 0px;
  border: none;
  padding: 4px;
  font-family: Source Sans Pro !important;

  &:focus,
  &:hover {
    border: none;
  }

  .Mui-focused {
    border: none;

    fieldset,
    fieldset:hover {
      border: none;
    }
  }

  .MuiInput-underline {
    &:hover {
      &:before,
      &:after {
        border: none;
      }
    }

    &:before,
    &:after {
      border: none;
    }
  }

  .MuiInputLabel-formControl {
    color: #6f6f6f;
    font-weight: bold;
    margin: 4px;
    font-size: 15px !important;
    text-transform: uppercase;
    padding-right: 2px;
    background-color: #fff;
    z-index: 0;

    &.Mui-focused {
      color: #000;
      font-size: 1rem;
    }
  }
  textarea {
    font-size: ${(props) =>
      props.smallFont ? '12px !important' : '15px !important'};
  }
`;

const TextArea = ({
  name,
  label,
  placeholder,
  innerRef,
  smallFont,
  maxLength,
  disabled,
  subTitle,
  externalTitle,
}: InputTextProps) => {
  const [field, meta, fieldProps] = useField(name);
  const classes = styles();

  const handleChange = (e: any) => {
    fieldProps.setValue(e.target.value);
  };

  const fieldRef = useRef();
  useFocusOnError(fieldRef, name, `form-field-${name}`);

  return (
    <FormControl fullWidth margin="dense">
      {externalTitle && (
        <>
          <Typography className={classes.title}>{label}</Typography>
          <CustomBoldText>{subTitle}</CustomBoldText>
        </>
      )}
      <InputTextField
        label={externalTitle ? '' : label}
        placeholder={placeholder}
        InputLabelProps={{ htmlFor: `form-field-${name}` }}
        id={`form-field-${name}`}
        value={field.value}
        onChange={handleChange}
        multiline
        rows={4}
        disabled={disabled}
        variant="outlined"
        ref={fieldRef}
        inputProps={{
          'data-testid': `input-${name}`,
          maxLength: maxLength || 500,
        }}
        smallFont={smallFont}
      />
      {meta?.touched && meta?.error && (
        <CustomFormHelperText error>{meta?.error}</CustomFormHelperText>
      )}
    </FormControl>
  );
};

export default TextArea;

const CustomBoldText = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

const CustomFormHelperText = styled(FormHelperText)`
  margin-left: 14px;
  margin-right: 14px;
`;
