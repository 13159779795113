import * as React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  outlined: {
    color: '#fff',
    border: 'solid 1px #fff',
    lineHeight: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px',
      lineHeight: '11px',
    },
  },
  regular: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px',
      lineHeight: '11px',
    },
    lineHeight: '16px',
  },
}));

export default function BasicButtons({
  variant,
  text,
  icon,
  onClick,
  ...props
}) {
  const classes = styles();

  return (
    <>
      {variant === 'outlined' ? (
        <Button
          className={classes.outlined}
          variant={variant}
          startIcon={icon}
          onClick={onClick}
          {...props}
        >
          {text}
        </Button>
      ) : (
        <Button
          className={classes.regular}
          variant={variant}
          startIcon={icon}
          onClick={onClick}
          {...props}
        >
          {text}
        </Button>
      )}
    </>
  );
}
