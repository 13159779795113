import React, { useContext, useState, useEffect  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Hidden } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Jumbotron from '../../../../assets/img/jumbotron_05.png';
import { Button } from '..';
import { useTranslation } from '../../../../shared/hooks';
import * as translations from './intl';
import { GeneralContext } from '../../../../shared/contexts/GeneralContext';
import CallIcon from '@material-ui/icons/Call';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { Container } from './styles';
import { ConfirmationModal } from '../../../../components';
import { partner_id, language, partner_name, country_code, instance_env } from '../../../../globalConstants';

const styles = makeStyles((theme) => ({
  bg: {
    height: '75vh',
  },
  content: {
    width: '420px',
  },
  subtitle_a: {
    fontFamily: 'Source Sans Pro',
    fontSize: '12px',
    letterSpacing: '2px',
    fontWeight: '700',
  },
  subtitle_b: {
    fontFamily: 'Publico Headline',
    fontSize: '16px',
    fontWeight: '700',
  },
  title: {
    fontFamily: 'Publico Headline',
    fontSize: '50px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px !important',
    },
  },
  topContainer: {

    width: '34em !important',
    textAlign: 'justify',

  },
  textColor: {
    color: 'white',
  },
  commonText: {
    fontFamily: 'Source Sans Pro',
    fontSize: '20px',
    fontWeight: '400',
    margin: '0 0 20px 0',
    lineHeight: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
      lineHeight: '14px !important',
    },
  },
  container: {
    color: theme.palette.text.main,
    backgroundImage: `url(${Jumbotron})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  responsiveContainer: {
    color: theme.palette.text.main,
    backgroundImage: `url(${Jumbotron})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '30em',
  },
  card: {
    marginTop: '-25em',
    marginBottom: '3em',
    marginLeft: '0.5em',
    marginRight: '0.5em',
    maxWidth: '40em',
    padding: '0em 2em 0em 2em',
  },
  mw40: {
    maxWidth: '40em',
  },
  mb2: {
    marginBottom: '1em',
  },
  sourceSans: {
    fontFamily: 'Source Sans Pro',
  },
  publicoHeadline: {
    fontFamily: 'Publico Headline',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
}));

export default function Banner() {
  const history = useHistory();
  const { userData, isLogged } = useContext(GeneralContext);
  const [OpenConfirm, setOpenConfirm] = useState(true);
  const classes = styles();
  const { locale: intl, idiom } = useTranslation({
    translationsFile: translations,
  });

  useEffect(() => {
    if (isLogged && !userData?.isRegisteredAmos && window.location.hostname !== process.env.REACT_APP_DOMAIN_AMEX) {
      setOpenConfirm(true);
      window.dataLayer.push({
        'element_displayed': 'popup',
        'element_name': 'access code',
        'event': 'display',
      });
    }
  }, [isLogged, userData, setOpenConfirm]);

  const {
    REQUEST_VIDEO_CALL_BUTTON,
    REQUEST_PHONE_CALL_BUTTON,
    DOCTOR_PLEASE,
    POWERED_BY,
    SERVICE,
    SPEAK,
    PLEASE_CONTACT,
    ACCESS_CODE_TITLE,
    ACCESS_CODE_TEXT,
    ACCESS_CODE_US_TITLE,
    ACCESS_CODE_US_TEXT,
  } = intl;
  const closeConfirmation = (elementClicked) => {
    setOpenConfirm(false);

    window.dataLayer.push({
      'zone':'popup - access code',
      'element_clicked':elementClicked,
      'event':'navigation',
    });
  };
  const handleButtonsBanner = async (path) => {

    window.dataLayer.push({
      'zone':'cover',
      'element_clicked':`${path}` + ' consultation',
      'service_category':'health',
      'event':'navigation',
    });
    if (isLogged && userData?.isRegisteredAmos) {
      history.push(`/patient-selection?type=${path}`);

      window.dataLayer.push({
          'subsidiary_name':'axa_partners',
          'business_unit':'customer_and_solutions',
          'business_line':'travel_solutions',
          'digital_business_process':'assistance_request',
          'global_digital_asset':'doctor_please',
          'partner_id':partner_id,
          'partner_name':partner_name,
          'country':country_code,
          'language':language,
          'instance_environment':instance_env,
          'user_logged':'true',
          'page_name':'patient selection',
          'funnel_step':'1',
          'step_name':'request appointment',
          'event':'pageview',
      });
    }

    if (isLogged && userData?.isRegisteredAmos === false) {
      history.push(`/lets-start?type=${path}`);

      window.dataLayer.push({
          'subsidiary_name':'axa_partners',
          'business_unit':'customer_and_solutions',
          'business_line':'travel_solutions',
          'digital_business_process':'assistance_request',
          'global_digital_asset':'doctor_please',
          'partner_id':partner_id,
          'partner_name':partner_name,
          'country':country_code,
          'language':language,
          'instance_environment':instance_env,
          'user_logged':'true',
          'page_name':'register doctor please',
          'funnel_step':'0.1',
          'step_name':'register doctor please',
          'event':'pageview',
      });
    }

    if (!isLogged) {
      history.push(`/login?lang=${idiom.substr(0, 2)}`);
    }
  };

  return (
    <div data-testid="banner">
      <Hidden only={['sm', 'xs']}>
        <div className={classes.container}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Container className={`${classes.topContainer}`}>
              <h1 className={`${classes.title} ${classes.textColor}`}>
                {DOCTOR_PLEASE}
              </h1>
              <p className={`${classes.subtitle_b} ${classes.textColor}`}>
                {POWERED_BY}
              </p>
              <p
                className={`${classes.commonText} ${classes.textColor}`}
              >
                {`${SPEAK}`}
              </p>
              <p
                className={`${classes.commonText} ${classes.textColor}`}
              >
                {`${SERVICE} ${PLEASE_CONTACT}`}
              </p>
              {isLogged && !userData?.isRegisteredAmos && (
              <>
              {window.location.hostname != process.env.REACT_APP_DOMAIN_AMEX && 
              window.location.hostname == process.env.REACT_APP_DOMAIN_GENERIC ||
              window.location.hostname == process.env.REACT_APP_DOMAIN_TRIPUK ||
              window.location.hostname == process.env.REACT_APP_DOMAIN_WWIIS ||
              window.location.hostname == process.env.REACT_APP_DOMAIN_INSUREFOR ? (
                <>
                <ConfirmationModal
                  open={OpenConfirm}
                  handleClose={() => closeConfirmation('cross')}
                  handleAction={() => closeConfirmation('ok')}
                  title={ACCESS_CODE_TITLE}
                  text={ACCESS_CODE_TEXT}
                  okButton
                />
                </>
              ) : (
              <>
              {window.location.hostname != process.env.REACT_APP_DOMAIN_AMEX && 
              window.location.hostname == process.env.REACT_APP_DOMAIN_US ||
              window.location.hostname == process.env.REACT_APP_DOMAIN_US_METLIFE ||
              window.location.hostname == process.env.REACT_APP_DOMAIN_US_CHUBB ||
              window.location.hostname == process.env.REACT_APP_DOMAIN_USTI ? (
                <>
                <ConfirmationModal
                  open={OpenConfirm}
                  handleClose={() => closeConfirmation('cross')}
                  handleAction={() => closeConfirmation('ok')}
                  title={ACCESS_CODE_US_TITLE}
                  text={ACCESS_CODE_US_TEXT}
                  okButton
                />
                </>
              ) : (
                <>
                {window.location.hostname == process.env.REACT_APP_DOMAIN_AMEX}
                <>
                </>
                </>
              )}
              </>
              )}
              </>
              )}
              {userData?.email_verified && (
                <>
                  <Grid item md={12} xs={12}>
                    {RequestVideoButton(
                      REQUEST_VIDEO_CALL_BUTTON,
                      handleButtonsBanner,
                      classes
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    {RequestPhoneButton(
                      REQUEST_PHONE_CALL_BUTTON,
                      handleButtonsBanner,
                      classes
                    )}
                  </Grid>
                </>
              )}
            </Container>
          </Grid>
        </div>
      </Hidden>
      <Hidden only={['xl', 'lg', 'md']}>
        <div className={classes.responsiveContainer}></div>
        <Grid container justifyContent="center">
          <Card className={classes.card}>
            <CardContent className={classes.mw40}>
              <h1 className={`${classes.title}`}>{DOCTOR_PLEASE}</h1>
              <p className={`${classes.subtitle_b}`}>{POWERED_BY}</p>
              <p
                className={`${classes.commonText}`}
              >
                {`${SPEAK}`}
              </p>
              <p
                className={`${classes.commonText}`}
              >
                {`${SERVICE} ${PLEASE_CONTACT}`}
              </p>
              {isLogged && !userData?.isRegisteredAmos && (
              <>
              {window.location.hostname != process.env.REACT_APP_DOMAIN_AMEX && 
              window.location.hostname == process.env.REACT_APP_DOMAIN_GENERIC ||
              window.location.hostname == process.env.REACT_APP_DOMAIN_TRIPUK ||
              window.location.hostname == process.env.REACT_APP_DOMAIN_WWIIS ||
              window.location.hostname == process.env.REACT_APP_DOMAIN_INSUREFOR ? (
                <>
                <ConfirmationModal
                  open={OpenConfirm}
                  handleClose={() => closeConfirmation('cross')}
                  handleAction={() => closeConfirmation('ok')}
                  title={ACCESS_CODE_TITLE}
                  text={ACCESS_CODE_TEXT}
                  okButton
                />
                </>
              ) : (
              <>
              {window.location.hostname != process.env.REACT_APP_DOMAIN_AMEX && 
              window.location.hostname == process.env.REACT_APP_DOMAIN_US ||
              window.location.hostname == process.env.REACT_APP_DOMAIN_US_METLIFE ||
              window.location.hostname == process.env.REACT_APP_DOMAIN_US_CHUBB ||
              window.location.hostname == process.env.REACT_APP_DOMAIN_USTI ? (
                <>
                <ConfirmationModal
                  open={OpenConfirm}
                  handleClose={() => closeConfirmation('cross')}
                  handleAction={() => closeConfirmation('ok')}
                  title={ACCESS_CODE_US_TITLE}
                  text={ACCESS_CODE_US_TEXT}
                  okButton
                />
                </>
              ) : (
                <>
                {window.location.hostname == process.env.REACT_APP_DOMAIN_AMEX}
                <>
                </>
                </>
              )}
              </>
              )}
              </>
              )}
              {userData?.email_verified && (
                <>
                  <Grid item md={12} xs={12}>
                    {RequestVideoButton(
                      REQUEST_VIDEO_CALL_BUTTON,
                      handleButtonsBanner,
                      classes
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    {RequestPhoneButton(
                      REQUEST_PHONE_CALL_BUTTON,
                      handleButtonsBanner,
                      classes
                    )}
                  </Grid>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Hidden>
    </div>
  );
}
function RequestPhoneButton(
  REQUEST_PHONE_CALL_BUTTON,
  handleButtonsBanner,
  classes
) {
  return (
    <Button
      text={REQUEST_PHONE_CALL_BUTTON}
      variant="contained"
      onClick={() => {
        handleButtonsBanner('phone');
      }}
      className={`${classes.sourceSans} ${classes.upperCase}`}
      icon={<CallIcon />}
    />
  );
}

function RequestVideoButton(
  REQUEST_VIDEO_CALL_BUTTON,
  handleButtonsBanner,
  classes
) {
  return (
    <Button
      text={REQUEST_VIDEO_CALL_BUTTON}
      variant="contained"
      color="primary"
      onClick={() => {
        handleButtonsBanner('video');
      }}
      icon={<VideocamOutlinedIcon />}
      className={`${classes.mb2} ${classes.sourceSans} ${classes.upperCase}`}
    />
  );
}
