export const USER_TERMS = {
  es_ES: 'Términos y condiciones del usuario',
  en_US: 'User Terms and Conditions',
  pt_BR: 'Termos e condições do usuário',
  fr_FR: 'Conditions d’utilisation',
  de_DE: 'Allgemeine Nutzungsbedingungen',
  it_IT: 'Condizioni generali di servizio',
};

export const CLOSE = {
  es_ES: 'Cerrar',
  en_US: 'Close',
  pt_BR: 'Fechar',
  fr_FR: 'Fermer',
  de_DE: 'Nah dran',
  it_IT: 'Vicino',
};

export const ACCEPT_TEXT = {
  es_ES: 'Aceptar',
  en_US: 'Accept',
  pt_BR: 'Aceitar',
  fr_FR: 'Accepter',
  de_DE: 'Akzeptieren',
  it_IT: 'Accettare',
};
