import { createContext } from 'react';

const IntlContext = createContext({
  translate: (translations) => translations,
  getCountryPrefix: () => {},
  setIdiom: (idiom) => {
    return idiom;
  },
  idiomForApi: () => '',
  twoDigitsIdiom: () => '',
  getIdiom: () => '',
  idiom: 'en_US',
});

export default IntlContext;
