import { getCookie } from '../../../shared/helpers/cookies';
import { language } from '../../../globalConstants';
import { partner_id } from '../../../globalConstants';
import { partner_name } from '../../../globalConstants';
import { country_code } from '../../../globalConstants';
import { instance_env } from '../../../globalConstants';
export { default as Banner } from './Banner';
export { default as Button } from '../../../components/Button';
export { default as TermsAndPolicySession } from './TermsAndPolicySession';
export { default as Appointments } from './Appointments';
export { default as DialogTelemed } from './DialogTelemed';
export { default as TokboxVideoCall } from './TokboxVideoCall';
export { default as ConfirmEmailAlert } from './ConfirmEmailAlert';

window.dataLayer.push({
    'subsidiary_name':'axa_partners',
    'business_unit':'customer_and_solutions',
    'business_line':'travel_solutions',
    'digital_business_process':'assistance_request',
    'global_digital_asset':'doctor_please',
    'partner_id':partner_id,
    'partner_name':partner_name,
    'country':country_code,
    'language':language,
    'instance_environment':instance_env,
    'user_logged':'true',
    'page_name':'homepage',
    'funnel_step':'0',
    'step_name':'homepage',
    'event':'pageview',
});
