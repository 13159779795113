export function closeAlert() {
  const alert = {
    ...this.state.alert,
    show: false,
  };
  this.setState({ alert });
}

export function showError(message) {
  const alert = {
    message: message,
    type: 'error',
    show: true,
  };
  this.setState({ alert });

  setTimeout(this.actions.alert.closeAlert, 10000);
}

export function showSuccess(message) {
  const alert = {
    message: message,
    type: 'success',
    show: true,
  };
  this.setState({ alert });

  setTimeout(this.actions.alert.closeAlert, 10000);
}
