import styled from 'styled-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const root = {
  borderRadius: 0,
  backgroundColor: 'white',
};

export default makeStyles(
  (theme) =>
    createStyles({
      title: {
        userSelect: 'none',
        fontWeight: 700,
        fontFamily: 'Source Sans Pro !important',
        color: theme.palette.text.main,
        fontSize: 16,
        [theme.breakpoints.up('xs')]: {
          fontSize: 16,
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: 16,
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 18,
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 20,
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: 20,
        },
      },
      titleColor: {
        color: '#908e88',
        fontWeight: 100,
      },
      titleMargin: {
        marginLeft: '5px',
      },
      notchedOutline: {
        border: '3px solid #f0f0f0',
      },
      input: {
        paddingTop: 0,
      },
      inputLightRoot: {
        ...root,
        border: '1px solid rgba(204, 204, 204, 1)',
      },
      inputDarkRoot: {
        ...root,
        border: '1px solid rgba(204, 204, 204, 1)',
      },
      inputOutlined: {
        padding: '13px 14px',
        fontFamily: 'Source Sans Pro !important',
      },
    }),
  { index: 1 }
);

export const InputTextField = styled(TextField)`
  background-color: #fff;
  color: #016fd0;
  font-weight: bold;
  box-shadow: ${(props) => (props.boxShadow ? '0 2px 2px 0 #e1e1e1' : 'none')};
  border-radius: 6px;
  border: none;
  padding: 4px;
  text-align: left;

  /* & > .MuiInputBase-root > .MuiInput-input {
    margin-top: 5px !important;
  } */

  &:focus,
  &:hover {
    border: none;
  }

  .Mui-focused {
    border: none;

    fieldset,
    fieldset:hover {
      border: none;
    }
  }

  .MuiInput-underline {
    &:hover {
      &:before,
      &:after {
        border: none;
      }
    }

    &:before,
    &:after {
      border: none;
    }
  }

  .MuiInputLabel-formControl {
    color: #6f6f6f;
    font-weight: bold;
    margin: 4px;
    margin-top: 0;
    text-transform: ${(props) => (props.uppercase ? 'uppercase' : '')};

    /*shrink*/
    width: 100%;
    bottom: 0px;
    /* end shrink */

    &.Mui-focused {
      color: #000;
      font-size: 16px;
    }
  }
`;
