import React, { useEffect, useMemo, useContext, useState } from 'react';
import {
  Collapse,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useTranslation, useQuery } from '../../shared/hooks';
import * as translations from './intl';
import { Button, RadioButton, ConfirmationModal } from '../../components';
import { PatientForm, PatientCard } from './components';
import styled from 'styled-components';
import * as Yup from 'yup';
import { GeneralContext } from '../../shared/contexts/GeneralContext';
import { Close } from '@material-ui/icons';
import { language } from '../../globalConstants';
import { partner_id } from '../../globalConstants';
import { partner_name } from '../../globalConstants';
import { country_code } from '../../globalConstants';
import { instance_env } from '../../globalConstants';

const styles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Publico Headline !important',
    fontSize: '36px !important',
    lineHeight: '60px !important',
    color: theme.palette.text.main,
    paddingBottom: 42,
    paddingTop: 42,
  },
  subTitle: {
    fontFamily: 'Publico Headline',
    fontSize: '25px',
    lineHeight: '30px',
    letterSpacing: '0.8px',
    padding: '15px 50px',
    color: theme.palette.text.main,
  },
  containerPaper: {
    [theme.breakpoints.down('md')]: {
      padding: '0px 40px !important',
    },
    padding: '0px 90px !important',
    marginBottom: 20,
  },
  paperContent: {
    width: '100%',
  },
  container: {
    marginBottom: 100,
  },
  paddingFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '25px 0px',
    borderTop: '1px solid rgba(229, 229, 229, 1)',
  },
  paddingButton: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  marginButtonNew: {
    margin: '20px 0px',
  },
  editButton: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

function PatientSelection() {
  const history = useHistory();
  const classes = styles();
  const query = useQuery();
  const {
    fetchClientAndDependents,
    client,
    dependents,
    refreshClientAndDependents,
    fetchAmosCountry,
  } = useContext(GeneralContext);
  const [addNewFM, setAddNewFM] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { locale, idiomAmos } = useTranslation({
    translationsFile: translations,
  });
  const type = query.get('type');
  const clientType = query.get('clientType') || null;
  const clientUuid = query.get('clientUuid') || null;
  const [CL, FM] = ['CL', 'FM'];
  const isVideo = useMemo(() => type === 'video', [type]);

  const {
    TITLE_VIDEO,
    TITLE_PHONE,
    TEXT,
    SUBTITLE_NEED_HELP,
    NEXT_BUTTON,
    BACK_BUTTON,
    FAMILY_MEMBER,
    PREFFIX_CLIENT,
    ADD_NEW_BUTTON,
    VALIDATION_REQUIRED,
  } = locale;

  useEffect(() => {
    fetchAmosCountry(idiomAmos);
  }, [idiomAmos]);

  useEffect(() => {
    fetchClientAndDependents();
  }, [refreshClientAndDependents]);

  const handleCLoseAddNew = () => {
    setAddNewFM(!addNewFM);
  };

  const handleSubmit = (values) => {
    if(window.location.hostname === process.env.REACT_APP_DOMAIN_AMEX){
      // alert('Access Denied!');
      setOpenConfirm(true);
    } else {
      history.push(`/request/${type}/${values.client}/${values.clientData}`);
    }
    window.scrollTo(0, 0);

    window.dataLayer.push({
        'subsidiary_name':'axa_partners',
        'business_unit':'customer_and_solutions',
        'business_line':'travel_solutions',
        'digital_business_process':'assistance_request',
        'global_digital_asset':'doctor_please',
        'partner_id':partner_id,
        'partner_name':partner_name,
        'country':country_code,
        'language':language,
        'instance_environment':instance_env,
        'user_logged':'true',
        'page_name':`${type}`+' appoinment',
        'funnel_step':'2',
        'step_name':'booking appointment',
        'appointment_type':`${type}`+' call',
        'event':'pageview',
    });
  };

  const handleBack = () => {
    history.push('/');
  };

  const validationSchema = Yup.object().shape({
    client: Yup.string().required(VALIDATION_REQUIRED),
    clientData: Yup.string().required(VALIDATION_REQUIRED),
  });

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
      <ConfirmationModal
          open={openConfirm}
          text={TEXT}
          handleAction={()=>{
            setOpenConfirm(false);
          }}
          handleClose={() => {
            setOpenConfirm(false);
          }}
          okButton
        />
        <Grid item md={12}>
          <p className={classes.title}>{isVideo ? TITLE_VIDEO : TITLE_PHONE}</p>
        </Grid>
        {client && (
          <Grid item md={12} justifyContent="center" className="d-flex">
            <Paper className={classes.paperContent}>
              <p className={classes.subTitle}>{SUBTITLE_NEED_HELP}</p>
              <Container maxWidth="lg" className={classes.containerPaper}>
                <Formik
                  initialValues={{
                    client: clientType ? clientType : '',
                    clientData: clientUuid ? clientUuid : '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <Grid item md={12}>
                        <RadioButton
                          name="client"
                          label=""
                          options={[
                            {
                              label: `${PREFFIX_CLIENT}${client?.name}`,
                              value: CL,
                            },
                            { label: FAMILY_MEMBER, value: FM },
                          ]}
                          onChange={(e) => {
                            setFieldValue('client', e.target.value);
                            setFieldValue('clientData', '');
                          }}
                        />
                      </Grid>
                      <Space />
                      <Grid item md={12}>
                        {values.client === CL && (
                          <>
                            <PatientCard payload={client} type={CL} />
                          </>
                        )}
                      </Grid>
                      <Grid item md={12}>
                        {values.client === FM && (
                          <>
                            {dependents.map((el) => (
                              <PatientCard payload={el} key={el.id} type={FM} />
                            ))}
                          </>
                        )}
                      </Grid>
                      {values.client === FM && (
                        <Collapse in={addNewFM}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className={classes.editButton}
                          >
                            <IconButton
                              aria-label="close-add-new"
                              color="secondary"
                              onClick={handleCLoseAddNew}
                            >
                              <Close />
                            </IconButton>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <PatientForm
                              payload={{
                                relationType: '',
                                clientCode: '',
                                firstName: '',
                                lastName: '',
                                day: '',
                                month: '',
                                year: '',
                                gender: '',
                                email: '',
                                countryCode: '',
                                dependentCode: '',
                                cellphone: '',
                                dependentId: null,
                                countryResidence: '',
                                language: '',
                              }}
                              type={FM}
                              handleClose={handleCLoseAddNew}
                            />
                          </Grid>
                        </Collapse>
                      )}
                      {values.client === FM && !addNewFM && (
                        <Grid item md={12}>
                          <Button
                            text={ADD_NEW_BUTTON}
                            className={classes.marginButtonNew}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              window.dataLayer.push({
                                'subsidary_name':'axa_partners',
                                'business_unit':'customer_and_solutions',
                                'business_line':'travel_solutions',
                                'digital_business_process':'assistance_request',
                                'global_digital_asset':'doctor_please',
                                'partner_id':partner_id,
                                'partner_name':partner_name,
                                'country':country_code,
                                'language':language,
                                'instance_environment':instance_env,
                                'user_logged':'true',
                                'page_name':'create dependent',
                                'funnel_step':'1',
                                'step_name':'request appointment',
                                'appointment_type:':`${type}`+' call',
                                'event':'page_view',
                              })
                              setAddNewFM(true);
                            }}
                          />
                        </Grid>
                      )}
                      <Grid container className={classes.paddingFooter}>
                        <Button
                          onClick={handleBack}
                          className={classes.paddingButton}
                          text={BACK_BUTTON}
                          variant="outlined"
                          color="primary"
                        />
                        <Button
                          type="submit"
                          className={classes.paddingButton}
                          text={NEXT_BUTTON}
                          variant="contained"
                          color="primary"
                        />
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Container>
            </Paper>
          </Grid>
        )}
      </Container>
    </>
  );
}

const Space = styled.div`
  width: 100%;
  height: 25px;
`;

export default PatientSelection;
