/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useRef } from 'react';
import {
  FormControl,
  FormHelperText,
  Typography,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useField } from 'formik';
import styled from 'styled-components';
import { KeyboardArrowDown } from '@material-ui/icons';
import { useFocusOnError } from '../../shared/hooks';
import SelectList from './components/SelectList';
import styles from './styles';
import './style.css';

const initialsNoCase = ['CPF', 'CEP', 'DDD', 'Código de área - DDI', 'DDI'];

export const changeCaseFirstLetter = (params: any) => {
  if (typeof params === 'string' && !initialsNoCase.includes(params)) {
    return params[0]?.toUpperCase() + params?.slice(1)?.toLowerCase();
  }
  return params;
};

type Options = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  options: Options[];
  placeholder?: string;
  startsWithNone?: boolean;
  inputProps?: any;
  value?: any;
  label: string;
  disabled?: boolean;
  isMulti?: boolean;
  showLabel?: boolean;
  showPlaceholder?: boolean;
  noCase?: boolean;
  labelGrey?: boolean;
  labelMargin?: boolean;
  noError?: boolean;
};

const AutoComplete = ({
  name,
  options,
  label,
  disabled,
  isMulti,
  showLabel = true,
  showPlaceholder = false,
  labelGrey = false,
  placeholder = '',
  noError = false,
  ...props
}: Props) => {
  const classes: any = styles();
  const [field, meta, fieldProps] = useField(name);
  const [valueKey, setValueKey] = useState<any>(field.value ? field.value : '');
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const setPreviousSelected = () => {
      const [selected] = options.filter((option) => {
        return option.value === field.value;
      });
      setValueKey(selected?.value);
    };

    if (field.value && options) setPreviousSelected();
  }, [options]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: any) => {
    const { value: changeValue } = event.target;

    if (changeValue && isMulti) {
      fieldProps.setValue(changeValue);
    } else if (changeValue) {
      fieldProps.setValue(changeValue);
      setValueKey(changeValue);
    }
  };

  useEffect(() => {
    if (options.length === 1 && !field.value) {
      handleChange({ target: { value: options[0].value } });
    }
  }, [options]);

  useEffect(() => {
    if (isMulti && valueKey?.length > 0) {
      handleChange({ target: { value: valueKey } });
    }
  }, [isMulti, valueKey]);

  const fieldRef = useRef();
  useFocusOnError(fieldRef, name, `form-field-${name}`);

  return (
    <FormControl fullWidth error={!!meta.error} className={classes.select}>
      <InvisibleButton
        onClick={() =>
          handleChange({
            target: { value: isMulti ? [options[0].value] : options[0].value },
          })
        }
        data-testid={`${name}-invisible`}
      />
      {showLabel && !isMulti && (
        <Typography
          className={`${classes.title} ${labelGrey && classes.titleColor}`}
        >
          {changeCaseFirstLetter(label)}
        </Typography>
      )}
      {!isMulti && (
        <Autocomplete
          {...props}
          disableClearable
          openOnFocus
          disabled={disabled}
          options={options}
          id={`form-field-${name}`}
          value={
            (options.filter(
              (el: any) => el.value === field?.value
            )[0] as any) || ''
          }
          inputValue={inputValue}
          getOptionLabel={(option: any) => option?.label || ''}
          onChange={(event: any, newValue: any) => {
            fieldProps.setValue(newValue.value);
            setValueKey(newValue);
          }}
          onInputChange={(event: any, newInputValue: any) => {
            setInputValue(newInputValue);
          }}
          getOptionSelected={(option: any, valueSelected: any) =>
            valueSelected.value === ''
              ? valueSelected?.value === option.value
              : true
          }
          forcePopupIcon
          popupIcon={<KeyboardArrowDown />}
          classes={{
            root: `${classes.selectRoot}`,
            listbox: classes.selectMenu,
            popupIndicator: classes.selectIcon,
            input: classes.inputOutlined,
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              renderIn
              placeholder={showPlaceholder ? placeholder : ''}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
              }}
              variant="outlined"
            />
          )}
        />
      )}
      {isMulti && (
        <SelectList
          className=""
          options={options}
          title={changeCaseFirstLetter(label)}
          value={valueKey}
          setValue={setValueKey}
          name={name}
        />
      )}

      {meta.touched && meta?.error && !noError && (
        <HelperTextContained>
          <FormHelperText>{meta.error}</FormHelperText>
        </HelperTextContained>
      )}
    </FormControl>
  );
};

const InvisibleButton = styled.button`
  display: none;
`;

const HelperTextContained = styled.div`
  margin-left: 14px;
  margin-right: 14px;
`;

export default AutoComplete;
