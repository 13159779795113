import React, { createContext, useState, useMemo } from 'react';
import { getCookie, setCookie,clearListCookies } from '../helpers/cookies';
import { Loading } from '../../components';
import api from '../../services';

export const GeneralContext = createContext({
  userData: null,
  client: null,
  dependents: null,
  amosToken: null,
  amosRefreshToken: null,
  amosClient: null,
  isLogged: false,
  clientCode: null,
  refreshClientAndDependents: false,
  country: [],
  fetchAmosCountry: () => {},
  setRefreshClientAndDependents: () => {},
  loginAmos: () => {},
  logoutAmos: () => {},
  setIsLoading: () => {},
  fetchClientAndDependents: () => {},
  clearAmosToken: () => {},
  setUserData: () => {},
  reFreshClientToken:()=>{},
});

const getUniqueArrayBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

const { Provider } = GeneralContext;

export const GeneralProvider = ({ children }) => {
  const [userData, setUserData] = useState(JSON.parse(getCookie('userAuth')));
  const [auth0User] = useState(
    JSON.parse(decodeURIComponent(getCookie('user')))
  );
  const [refreshClientAndDependents, setRefreshClientAndDependents] =
    useState(false);
  const [amosToken, setAmosToken] = useState(getCookie('amosToken'));
  const [amosRefreshToken, setAmosRefreshToken] = useState(
    getCookie('amosRefreshToken')
  );
  const [amosClient, setAmosClient] = useState(getCookie('amosClient'));
  const [country, setCountry] = useState([]);
  const [countryDdi, setCountryDdi] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ispopup, setIspopup] = useState(false);
  const [client, setClient] = useState(null);
  const [dependents, setDependents] = useState(null);

  const isLogged = useMemo(() => {
    return !!auth0User;
  }, [auth0User]);
  
  const clientCodeSUb = userData?.sub ? userData.sub.split('|'): null;

  const  clientCode = useMemo(
    () => ( clientCodeSUb ? clientCodeSUb[clientCodeSUb.length-1] : null ),
    [userData]
  );

  const fetchLogin = async () => {
    try {
      const { data } = await api.amosServices.amosLogin();
      return data;
    } catch (err) {
      return {
        isRegisteredAmos: false,
      };
    }
  };

  const loginAmos = async () => {
    try {
      setIsLoading(true);

      const data = await fetchLogin();
      if (data.isRegisteredAmos) {
        setCookie('amosToken', data.clientToken);
        setCookie('amosRefreshToken', data.clientRefreshToken);
        setCookie('amosClient', data.clientId);
        setAmosToken(data.clientToken);
        setAmosRefreshToken(data.clientRefreshToken);
        setAmosClient(data.clientId);
      } else {
        setCookie('amosToken', '');
        setCookie('amosRefreshToken', '');
        setCookie('amosClient', '');
        setAmosToken(null);
        setAmosRefreshToken(null);
        setAmosClient(null);
      }

      let mountUser = {
        isRegisteredAmos: data.isRegisteredAmos,
      };

      if (data.user) {
        mountUser = {
          ...data.user,
          ...mountUser,
        };
      } else {
        mountUser = {
          ...auth0User.userInfo,
          ...mountUser,
        };
      }

      setCookie('userAuth', JSON.stringify(mountUser));

      setUserData(mountUser);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const reFreshClientToken= async () => {
    try {
      setIsLoading(true);
      const { data } = await api.amosServices.amosRefreshToken(amosRefreshToken);
      setCookie('amosToken', data.clientToken);
      setCookie('amosRefreshToken', data.clientRefreshToken);
      setCookie('amosClient', data.clientId);
      setAmosToken(data.clientToken);
      setAmosRefreshToken(data.clientRefreshToken);
      setAmosClient(data.clientId);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const logoutAmos = async () => {
    try {
      setIsLoading(true);
      await api.amosServices.amosLogout(amosToken);
      setCookie('amosToken', '');
      setCookie('amosRefreshToken', '');
      setCookie('amosClient', '');
      setCookie('userAuth', '{}');
      setAmosToken('');
      setAmosRefreshToken();
      setAmosClient('');
      setUserData(null);
      setIsLoading(false);
      clearListCookies();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const clearAmosToken = async () => {
    try {
      setIsLoading(true);
      if (amosToken) await api.amosServices.amosLogout(amosToken);
      setCookie('amosToken', '');
      setCookie('amosRefreshToken', '');
      setCookie('amosClient', '');
      setAmosToken('');
      setAmosRefreshToken();
      setAmosClient('');
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const fetchClientAndDependents = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.amosServices.getClientAndDependents(
        amosToken,
        amosClient
      );

      setDependents(data.dependents);
      delete data.dependents;
      data.type = 'CL';
      setClient(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const fetchAmosCountry = async (idiom) => {
    try {
      setIsLoading(true);
      const { data } = await api.amosServices.getCountries(idiom);

      const _country = data.map((el) => ({
        label: el.value,
        value: el.key,
      }));

      // Sort and unique array itens because same phone code for countrys
      const _countryDdi = getUniqueArrayBy(
        data.map((el) => ({
          label: `+${el.phoneCode}`,
          value: el.phoneCode,
          onlySort: el.phoneCode,
        })),
        'onlySort'
      );

      setCountryDdi(_countryDdi.sort((a, b) => a.onlySort - b.onlySort));

      setCountry(_country);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <Provider
      value={{
        userData,
        amosToken,
        amosRefreshToken,
        amosClient,
        isLogged,
        clientCode,
        client,
        dependents,
        refreshClientAndDependents,
        country,
        countryDdi,
        fetchAmosCountry,
        fetchClientAndDependents,
        setRefreshClientAndDependents,
        loginAmos,
        logoutAmos,
        setIsLoading,
        clearAmosToken,
        setUserData,
        reFreshClientToken,
        ispopup,
        setIspopup,
      }}
    >
      <Loading active={isLoading} />
      {children}
    </Provider>
  );
};
