import { createStyles, makeStyles } from '@material-ui/core/styles';
// import stylesRoot from '../../../../assets/styles';

const baseButton = (theme) => ({
  borderRadius: 0,
  padding: '10px 30px',
  backgroundColor: theme.palette.primary.dark,
  color: '#fff',
  // ...stylesRoot.mt10,
  [theme.breakpoints.up('sm')]: {
    fontSize: 15,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 18,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
});

export default makeStyles(
  (theme) =>
    createStyles({
      // ...stylesRoot,
      buttonAgree: {
        ...baseButton(theme),
        width: 200,
        textTransform: 'capitalize',
      },
      displayCenter: {
        display: 'flex',
        justifyContent: 'center',
      },
      iconImage: {
        width: 80,
      },
      description: {
        fontSize: 22,
      },
    }),
  { index: 1 }
);
