import { getCookie } from '../../helpers/cookies';

export async function getUserFromCookie() {
  const userFromCookie = JSON.parse(decodeURIComponent(getCookie('user')));
  if (!userFromCookie) return;
  const user = { logged: true, data: userFromCookie };
  await this.setState({ user });
}

export function logout() {
  this.props.history.push('/signout');
}
