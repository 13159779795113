import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../../../../../shared/hooks';
import { GeneralContext } from '../../../../../../shared/contexts/GeneralContext';
import moment from 'moment';
import {
  ConfirmationModal,
  DarkTitle,
  Button,
} from '../../../../../../components';
import { Grid, Paper, Container, IconButton, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { setCookie } from '../../../../../../shared/helpers';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import DescriptionIcon from '@material-ui/icons/Description';
import JoinNow from '../TabVideo/JoinNow';
import { DialogTelemed } from '../../..';
import api from '../../../../../../services';
import * as translations from './intl';

const useStyles = makeStyles((theme) => ({
  containers: {
    padding: 0,
  },
  gridItem: {
    padding: '20px 50px',
  },
  divTopContent: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.footerbackgroundcolor,
    color: '#fff',
    padding: 20,
    marginBottom: 20,
  },
  divTopLeftContent: {
    display: 'flex',
  },
  divTopLinkBack: {
    marginLeft: 20,
    marginRight: 30,
  },
  arrowColor: {
    color: '#fff',
  },
  nameText: {
    color: 'white',
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Source Sans Pro !important',
    fontWeight: '700',
    marginBottom: 3,
  },
  ageText: {
    color: 'white',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: 3,
    fontFamily: 'Source Sans Pro !important',
  },
  subTitle: {
    fontFamily: 'Publico Headline !important',
    fontSize: '25px',
    lineHeight: '30px',
    letterSpacing: '0.8px',
    padding: '15px 0',
    fontWeight: 700,
    color: theme.palette.text.main,
  },
  cursorDownload: {
    cursor: 'pointer',
    fontSize: 14,
    textDecoration: 'none',
  },
  filePaper: {
    padding: '10px 10px 0 10px',
    display: 'flex',
    margin: 5,
  },
  fileIcon: {
    marginRight: 10,
  },
  fileLink: {
    fontSize: 14,
    textDecoration: 'none',
  },
  gridBotton: {
    paddingTop: 40,
  },
  sourceSans: {
    fontFamily: 'Source Sans Pro',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  btnsRight: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  joinBtn: {
    marginLeft: 5,
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  docGrid: {
    display: 'flex',
  },
  docName: {
    alignItems: 'center',
    display: 'flex',
    paddingLeft: 10,
  },
  gridDoctor: {
    marginTop: '2em',
    borderTop: '1px solid gray',
  },
  gridDocAnswer: {
    margin: '20px 0',
  },
}));

export default function TabDetails({
  typeAppointment,
  setValue,
  phoneCallId,
  appointmentId,
  gotTime,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { locale: intl } = useTranslation({
    translationsFile: translations,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [configDialog, setConfigDialog] = useState({
    show: false,
    message: '',
  });
  const {
    setIsLoading,
    amosToken,
    isLogged,
    client,
    refreshClientAndDependents,
    fetchClientAndDependents,
  } = useContext(GeneralContext);
  const [dataConsultation, setDataConsultation] = useState();
  const [doctorAppointment, setDoctorAppointment] = useState({
    name: '',
    surname: '',
    photo: null,
  });
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openCancelMessage, setOpenCancelMessage] = useState(false);

  const {
    PREFFIX_AGE,
    MESSAGE_CONFIRM_CANCEL_PHONE,
    MESSAGE_CONFIRM_CANCEL_VIDEO,
    BACK_BUTTON,
    TITLE_APPOINTMENT_DONE,
    TITLE_APPOINTMENT_PENDING,
    TITLE_APPOINTMENT_CANCELED,
    TITLE_APPOINTMENT_IN_PROGRESS,
    TITLE_NO_HAVE_DOCTOR_DEFAULT_ERROR,
    TITLE_NO_HAVE_DOCTOR_MSG,
    TITLE_APPOINTMENT_NOT_DUE_YET,
    MESSAGE_APPOINTMENT_IS_CANCELLED,
    LABEL_PT,
    LABEL_ES,
    LABEL_EN,
    LABEL_FR,
    LABEL_DE,
    LABEL_IT,
    LABEL_FI,
    LABEL_DA,
    LABEL_SV,
    DOCTORS_COMMENTS,
    CANCEL_APPOINTMENT,
    PHONE_CONSULTATION,
    VIDEO_CONSULTATION,
    LANGUAGE_LABEL,
    DATE_LABEL,
    DATE_TIME_LABEL,
    CONFIRMATION_TITLE,
    TYPE_CONSULTATION,
    TELEPHONE_NUMBER,
    REASON_APPOINTMENT,
    YOUR_FILES,
    STATUS,
    TITLE_TIMEZONE,
    TYPE_CONSULTATION_DATA,
  } = intl;

  const getDoctor = async (doctorId) => {
    try {
      const { data } = await api.amosServices.getDoctor(doctorId);

      setDoctorAppointment(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getConsultation = async (consultationId) => {
    try {
      setIsLoading(true);
      const { data } = await api.amosServices.getPhoneCall(
        consultationId,
        amosToken
      );

      if (data?.doctorId) {
        await getDoctor(data.doctorId);
      }

      setDataConsultation(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getVideocall = async (videocallId) => {
    try {
      setIsLoading(true);
      const { data } = await api.amosServices.getVideocall(
        videocallId,
        amosToken
      );

      if (data?.doctorId) {
        await getDoctor(data.doctorId);
      }

      setDataConsultation(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const onMakeStatus = (typeStatus) => {
    switch (typeStatus) {
      case 'done':
        return TITLE_APPOINTMENT_DONE;
      case 'pending':
        return TITLE_APPOINTMENT_PENDING;
      case 'canceled':
        return TITLE_APPOINTMENT_CANCELED;
      case 'in-progress':
        return TITLE_APPOINTMENT_IN_PROGRESS;
      default:
        return '';
    }
  };

  const onMakeTypeConsultation = (typeConsult) => {
    switch (typeConsult) {
      case 'general':
        return TYPE_CONSULTATION_DATA;
      default:
        return '';
    }
  };

  const onMakeLanguage = (keyLang) => {
    switch (keyLang) {
      case 'en':
        return LABEL_EN;
      case 'es':
        return LABEL_ES;
      case 'pt':
        return LABEL_PT;
      case 'fr':
        return LABEL_FR;
      case 'de':
        return LABEL_DE;
      case 'it':
        return LABEL_IT;
      case 'fi':
        return LABEL_FI;
      case 'da':
        return LABEL_DA;
      case 'sv':
        return LABEL_SV;
      default:
        return '';
    }
  };

  const cancelConsultation = async (consultationId) => {
    try {
      setIsLoading(true);
      const sendData = {
        clientToken: amosToken,
        email: client.email,
        language: dataConsultation.language,
        firstName: dataConsultation.patient.name,
        lastName: dataConsultation.patient.surname,
      };
      await api.amosServices.cancelPhoneCall(sendData, consultationId);
      getAppointment();
      setIsLoading(false);
      setOpenCancelMessage(true);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const cancelVideocall = async (videocallId) => {
    try {
      setIsLoading(true);
      const sendData = {
        clientToken: amosToken,
        email: client.email,
        language: dataConsultation.language,
        firstName: dataConsultation.patient.name,
        lastName: dataConsultation.patient.surname,
        date: dataConsultation.date,
        time: dataConsultation.time,
        timeZone: dataConsultation.timeZone,
        callReason: dataConsultation.callReason,
      };
      await api.amosServices.cancelVideoCall(sendData, videocallId);
      getAppointment();
      setIsLoading(false);
      setOpenCancelMessage(true);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const cancelAppointment = async () => {
    if (typeAppointment === 'phone') {
      cancelConsultation(phoneCallId);
      setOpenConfirm(false);
    } else {
      cancelVideocall(appointmentId);
      setOpenConfirm(false);
    }
  };

  const cancelAppointments = () => {
    setOpenConfirm(true);

    if (typeAppointment === 'phone') {
      window.dataLayer.push({
        'zone':'appointment details',
        'element_clicked':'cancel',
        'appointment_type':'phone call',
        'event':'cancel_appointment',
      });
    }else {
      window.dataLayer.push({
        'zone':'appointment details',
        'element_clicked':'cancel',
        'appointment_type':'video call',
        'event':'cancel_appointment',
      });
    }
  };

  const closeCancelMessage = () => {
    setOpenCancelMessage(false);
  };

  const getAppointment = async () => {
    if (typeAppointment === 'phone') {
      getConsultation(phoneCallId);
    } else {
      getVideocall(appointmentId);
    }
  };

  const endCancel = async () => {
    await getAppointment();
    setOpenConfirm(false);
  };

  let signatures = {
    JVBERi0: 'application/pdf',
    R0lGODdh: 'image/gif',
    R0lGODlh: 'image/gif',
    iVBORw0KGgo: 'image/png',
    '/9j/': 'image/jpg',
  };

  function detectMimeType(b64) {
    for (let s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  }

  function downloadFile(fileName, dataFile) {
    let contentType = detectMimeType(dataFile);
    const linkSource = `data:${contentType};base64;,${dataFile}`;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const onHandleStartVideoCall = async (videoId) => {
    try {

      window.dataLayer.push({
        'zone':'appointment details',
        'element_clicked':'start',
        'appointment_type':'video call',
        'event':'start_appointment',
      });

      const clientToken = amosToken;
      const bodyObj = { clientToken };

      const {
        data: { teladocAuthDetails },
      } = await api.amosServices.amosStartVideoCall(videoId, bodyObj);
      setCookie('amosTeladocToken', teladocAuthDetails.token);
      setCookie('amosTeladocSessionId', teladocAuthDetails.sessionId);
      setCookie('amosTeladocApiKey', teladocAuthDetails.apiKey);
      setCookie('amosTeladocId', videoId);

      history.push('?type=videoCall');
    } catch (error) {
      const { message } = error.response.data;

      let msg = TITLE_NO_HAVE_DOCTOR_DEFAULT_ERROR;
      if (message.indexOf('150') >= 0) {
        msg = TITLE_NO_HAVE_DOCTOR_MSG;
        window.dataLayer.push({
          'element_displayed':'popup',
          'element_name':'appointment yet to be associated',
          'event':'display',
        });
      }
      
      if (message.indexOf('403') >= 0) {
        msg = TITLE_APPOINTMENT_NOT_DUE_YET;
        window.dataLayer.push({
          'element_displayed':'popup',
          'element_name':'appointment is not due yet',
          'event':'display',
        });
      }
      
      setConfigDialog({
        show: true,
        title: '',
        message: msg,
      });
      setOpenDialog(true);
    }
  };

  function handleShowJoinNow(data) {
    if (!gotTime) return <></>;
    return (
      <JoinNow
        videoId={data?.id}
        gotTime={gotTime}
        appointDateTime={`${data?.date} ${data?.time}`}
        onHandleStartVideoCall={onHandleStartVideoCall}
        onDetails
      />
    );
  }

  function handleBackButton() {
    if (typeAppointment === 'video') {
      setValue(0);
    } else {
      setValue(1);
    }
  }

  useEffect(() => {
    if (amosToken && isLogged) {
      if (typeAppointment === 'phone') {
        getConsultation(phoneCallId);
      } else {
        getAppointment(appointmentId);
      }
    }

    fetchClientAndDependents();
  }, [amosToken, isLogged, refreshClientAndDependents]);

  return (
    <>
      <ConfirmationModal
        open={openConfirm}
        title={CONFIRMATION_TITLE}
        text={
          typeAppointment === 'phone'
            ? MESSAGE_CONFIRM_CANCEL_PHONE
            : MESSAGE_CONFIRM_CANCEL_VIDEO
        }
        handleAction={cancelAppointment}
        handleClose={endCancel}
      />
      <ConfirmationModal
        open={openCancelMessage}
        title={CONFIRMATION_TITLE}
        text={MESSAGE_APPOINTMENT_IS_CANCELLED}
        handleAction={closeCancelMessage}
        okButton
      />
      <Container maxWidth="lg" className={classes.containers}>
        <Grid container md={12}>
          <Grid container className={classes.divTopContent}>
            <Grid item className={classes.divTopLeftContent}>
              <Grid className={classes.divTopLinkBack}>
                <IconButton onClick={handleBackButton}>
                  <ArrowBackRoundedIcon className={classes.arrowColor} />
                </IconButton>
              </Grid>
              <Grid>
                <p className={classes.nameText}>
                  {dataConsultation?.patient.name}{' '}
                  {dataConsultation?.patient.surname2}
                </p>
                <p
                  className={classes.ageText}
                >{`${dataConsultation?.patient.age} ${PREFFIX_AGE}`}</p>
              </Grid>
            </Grid>
            <Grid className={classes.btnsRight}>
              <Grid item sm={6} md={6} xs={12}>
                {dataConsultation?.status === 'pending' && (
                  <Button
                    text={CANCEL_APPOINTMENT}
                    variant="outlined"
                    color="secundary"
                    onClick={cancelAppointments}
                  />
                )}
              </Grid>
              <Grid item sm={6} md={6} xs={12} className={classes.joinBtn}>
                {typeAppointment === 'video' &&
                  (dataConsultation?.status === 'pending' ||
                    dataConsultation?.status === 'in-progress') &&
                  handleShowJoinNow(dataConsultation)}
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.gridItem}>
            <Grid item sm={12} md={12}>
              {typeAppointment === 'phone' ? (
                <p className={classes.subTitle}>{PHONE_CONSULTATION}</p>
              ) : (
                <p className={classes.subTitle}>{VIDEO_CONSULTATION}</p>
              )}
            </Grid>
            <Grid item sm={12} md={6} xs={12}>
              <DarkTitle label={STATUS} />
              <p>
                {onMakeStatus(String(dataConsultation?.status).toLowerCase())}
              </p>
            </Grid>
            <Grid item sm={12} md={6} xs={12}>
              <DarkTitle label={LANGUAGE_LABEL} />
              <p>
                {onMakeLanguage(
                  String(dataConsultation?.language).toLowerCase()
                )}
              </p>
            </Grid>
            <Grid item sm={12} md={6} xs={12}>
              <DarkTitle
                label={
                  typeAppointment === 'video' ? DATE_TIME_LABEL : DATE_LABEL
                }
              />
              <p>
                {typeAppointment === 'video'
                  ? `${moment(dataConsultation?.date).format('DD/MM/YYYY')} ${
                      dataConsultation?.time
                    }`
                  : `${moment(dataConsultation?.date).format('DD/MM/YYYY')}`}
              </p>
            </Grid>
            <Grid item sm={12} md={6} xs={12}>
              <DarkTitle label={TITLE_TIMEZONE} />
              <p>{dataConsultation?.timeZone}</p>
            </Grid>
            <Grid item sm={12} md={6} xs={12}>
              <DarkTitle label={TYPE_CONSULTATION} />
              <p>{onMakeTypeConsultation(dataConsultation?.specialty)}</p>
            </Grid>
            <Grid item sm={12} md={6} xs={12}>
              <DarkTitle label={TELEPHONE_NUMBER} />
              <p>{`+${dataConsultation?.patient.phonePrefix} ${dataConsultation?.patient.phone}`}</p>
            </Grid>
            <Grid item sm={12} md={6} xs={12}>
              <DarkTitle label={REASON_APPOINTMENT} />
              <p>
                {typeAppointment === 'phone'
                  ? dataConsultation?.consultation
                  : dataConsultation?.callReason}
              </p>
            </Grid>
            {dataConsultation?.patientFiles.length > 0 && (
              <>
                <Grid Grid item sm={12} md={12}>
                  <p className={classes.subTitle}>{YOUR_FILES}</p>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Grid container md={12} xs={12}>
                    {dataConsultation?.patientFiles.map((fl) => (
                      <>
                        <Grid item lg={4} md={6} xs={12}>
                          <Paper className={classes.filePaper} elevation={1}>
                            <DescriptionIcon
                              color="primary"
                              className={classes.fileIcon}
                            />
                            <a
                              className={classes.cursorDownload}
                              onClick={() => downloadFile(fl.name, fl.file)}
                              aria-hidden="true"
                            >
                              <p className={classes.fileLink}>{fl.name}</p>
                            </a>
                          </Paper>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
            {dataConsultation?.status === 'done' && dataConsultation?.doctorId && (
              <Grid className={classes.gridDoctor}>
                <Grid item sm={12} md={12}>
                  <p className={classes.subTitle}>{DOCTORS_COMMENTS}</p>
                </Grid>
                <Grid className={classes.docGrid} item md={12} xs={12}>
                  <Grid>
                    <Avatar
                      alt=""
                      src={doctorAppointment?.photo}
                      className={classes.largeAvatar}
                    />
                  </Grid>
                  <Grid className={classes.docName}>
                    <DarkTitle
                      label={`Dr.(a) ${doctorAppointment?.name} ${
                        doctorAppointment?.surname
                          ? doctorAppointment?.surname
                          : ''
                      }`}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={12} md={12} className={classes.gridDocAnswer}>
                  {dataConsultation?.doctorAnswer}
                </Grid>
                {dataConsultation?.doctorFiles.length > 0 && (
                  <Grid item md={12} xs={12}>
                    <Grid container md={12} xs={12}>
                      {dataConsultation?.doctorFiles.map((fl) => (
                        <>
                          <Grid item lg={4} md={6} xs={12}>
                            <Paper className={classes.filePaper} elevation={1}>
                              <DescriptionIcon
                                color="primary"
                                className={classes.fileIcon}
                              />
                              <a
                                className={classes.cursorDownload}
                                onClick={() => downloadFile(fl.name, fl.file)}
                                aria-hidden="true"
                              >
                                <p className={classes.fileLink}>{fl.name}</p>
                              </a>
                            </Paper>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.gridBotton}>
            <Button
              onClick={handleBackButton}
              text={BACK_BUTTON}
              variant="outlined"
              color="primary"
            />
          </Grid>
        </Grid>
      </Container>
      <DialogTelemed
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        dialogConfig={configDialog}
      />
    </>
  );
}
