/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import MaskedInput from 'react-text-mask';
import InputText from '../InputText';

const TextMaskCustom = ({ inputRef, mask, maxLength = 75, name, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      name={name}
      mask={mask}
      maxLength={maxLength}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
};

const InputMask = ({
  name,
  label,
  placeholder = null,
  mask,
  maxLength = null,
  shrink = null,
  uppercase = null,
  boxShadow = null,
  labelGrey = false,
  labelMargin = false,
  ...props
}) => {
  return (
    <InputText
      name={name}
      label={label}
      placeholder={placeholder}
      maxLength={maxLength}
      shrink={shrink}
      labelGrey={labelGrey}
      labelMargin={labelMargin}
      uppercase={uppercase}
      boxShadow={boxShadow}
      // @ts-ignore
      inputComponent={TextMaskCustom}
      inputProps={{
        'data-testid': name,
        maxLength: maxLength || 75,
        mask,
        ...props,
      }}
    />
  );
};

export default InputMask;
