export function closeModal() {
  document.body.removeEventListener('click', this.actions.modal.clickOut);
  const modal = {
    ...this.state.modal,
    show: false,
  };
  this.setState({ modal });
}

export function showModal(title, close, modalInnerComponent, css = null) {
  const modal = {
    ...this.state.modal,
    title: title,
    show: true,
    close: close,
    innerComponent: modalInnerComponent,
    css: css,
  };
  this.setState({ modal });
  if (modalInnerComponent.props.closable)
    document.body.addEventListener('click', this.actions.modal.clickOut);
}

export function clickOut(e) {
  const modal = this.state.modal.modalRef.current;
  const outside = !modal.contains(e.target);
  if (outside) {
    document.body.removeEventListener('click', this.actions.modal.clickOut);
    this.actions.modal.closeModal();
  }
}
