export const MAIN_COOKIE_TEXT = {
  es_ES: '',
  en_US: 'A cookie is a small data file (text file) that a website, when visited by a user, asks your browser to store on your device to keep information about you, such as your language preferences or login information. AXA Partners collects and sets up ‘internal’ cookies during your visit of this website, pursuant to the purposes described in the present policy. ',
  pt_BR: ' ',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};

export const HOW_COLLECTS_YOUR_PERSONAL_DATA_TITLE = {
  es_ES: '',
  en_US: 'WHO COLLECTS YOUR PERSONAL DATA ON THIS WEBSITE?',
  pt_BR: ' ',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};

export const HOW_COLLECTS_YOUR_PERSONAL_DATA_TEXT = {
  es_ES: '',
  en_US: 'AXA Assistance USA, Inc. a company incorporated under the laws of the state of Illinois, United States, having its registered office at 2 Prudential Plaza, 180 North Stetson Avenue, Suite 3650, Chicago, Illinois 60601, also referred to in this policy as “AXA Partners” or “we” or “us”, collects your personal data. ',
  pt_BR: ' ',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};

export const WHAT_ARE_COOKIES_TITLE = {
  es_ES: '¿QUÉ SON LAS COOKIES Y CÓMO FUNCIONAN?',
  en_US: 'HOW TO ACCEPT OR REFUSE COOKIES?',
  pt_BR: 'O QUE SÃO COOKIES? COMO FUNCIONAM?  ',
  fr_FR: 'QUE SONT LES COOKIES ? COMMENT FONCTIONNENT-ILS ?',
  de_DE: 'WAS SIND COOKIES? WIE FUNKTIONIEREN SIE?',
  it_IT: 'COSA SONO I COOKIE? COME FUNZIONANO?',
};
export const WHAT_ARE_COOKIES_TEXT1 = {
  es_ES:
    'Una cookie es un pequeño archivo de texto almacenado por el navegador de su ordenador, tableta o teléfono móvil. Permite el correcto funcionamiento de nuestro sitio web, una experiencia de usuario más eficiente o el análisis de los datos de navegación.',
  en_US:
    'A cookie banner pops up as from your first visit of this website. This cookie banner enables you to, either: ',
  pt_BR:
    'Um cookie é um pequeno ficheiro de texto armazenado pelo navegador do seu computador, tablet ou telemóvel. Permite o funcionamento correto do nosso site, uma experiência do utilizador mais eficiente ou a análise de dados de navegação.',
  fr_FR:
    'Un cookie est un petit fichier texte stocké par le navigateur de votre ordinateur, tablette ou téléphone portable. Il permet le bon fonctionnement de notre site Web, une expérience utilisateur plus efficace ou une analyse des données de navigation.',
  de_DE:
    'Ein Cookie ist eine kleine Textdatei, die vom Browser Ihres Computers, Tablets oder Mobiltelefons gespeichert wird. Es ermöglicht den korrekten Betrieb unserer Website, eine effizientere Benutzererfahrung oder eine Analyse der Browsing-Daten.',
  it_IT:
    'Un cookie è un piccolo file di testo memorizzato dal browser del computer, tablet o telefono cellulare dell’utente. Consente il corretto funzionamento del nostro sito web, un’esperienza utente più efficiente e l’analisi dei dati.',
};
export const WHAT_ARE_COOKIES_TEXT2 = {
  es_ES:
    'Según su finalidad, existen diferentes tipos de cookies que podemos colocar nosotros, nuestros socios o proveedores externos:',
  en_US:
    '',
  pt_BR:
    'Dependendo da sua finalidade, existem diferentes tipos de cookies que podem ser colocados por nós, pelos nossos parceiros ou por fornecedores terceiros:',
  fr_FR:
    'Selon leur objectif, il existe différents types de cookies qui peuvent être placés soit par nous, soit par nos partenaires ou par des prestataires tiers:',
  de_DE:
    'Je nach Zweck gibt es verschiedene Arten von Cookies, die entweder von uns oder von unseren Partnern oder von Drittanbietern gesetzt werden können:',
  it_IT:
    'A seconda del loro scopo, esistono diversi tipi di cookie che possono essere inseriti da noi o dai nostri partner, oppure da fornitori terzi:',
};
export const WHAT_ARE_COOKIES_LIST = {
  es_ES: [
    'Cookies funcionales y técnicas (estrictamente necesarias)',
    'Cookies analíticas (tráfico del sitio web)',
    'Cookies de marketing',
    'Cookies de funcionalidad',
    'Cookie de personalización de contenido y prueba A/B',
    'Cookies para encuestas y reseñas de usuarios',
    'Cookies de interactividad en redes sociales',
  ],
  en_US: [
    'Accept all optional cookies which are not strictly necessary for the proper functioning of the website, or',
    'Refuse all cookies that are not strictly necessary to the proper functioning of the website,',
    'Access the Cookie Preference Center to set up your choices in terms of consent (meaning that you may select individually cookies you accept to drop while browsing even though they are not essential to the proper functioning of the website).',
  ],
  pt_BR: [
    'Cookies funcionais e técnicos (estritamente necessários)',
    'Cookies analíticos (tráfego de sites)',
    'Cookies de marketing',
    'Cookies de funcionalidade',
    'Teste A/B e cookie de personalização de conteúdo',
    'Cookies para inquéritos e avaliações de utilizadores',
    'Cookies de interatividade de redes sociais',
  ],
  fr_FR: [
    'Cookies fonctionnels et techniques (absolument nécessaires)',
    'Cookies analytiques (trafic du site Web)',
    'Cookies marketing',
    'Cookies de fonctionnalité',
    'Cookie de personnalisation de contenu et test A/B',
    'Cookies pour les enquêtes et les avis des utilisateurs',
    'Cookies d’interactivité des médias sociaux',
  ],
  de_DE: [
    'Funktionelle und technische Cookies (unbedingt erforderlich)',
    'Analytische Cookies (Webseitenverkehr)',
    'Marketing-Cookies',
    'Funktionale Cookies',
    'Cookie zur Anpassung des Inhalts und für A/B-Tests',
    'Cookies für Umfragen und Nutzerbewertungen',
    'Cookies für Interaktivität in sozialen Medien',
  ],
  it_IT: [
    'cookie funzionali e tecnici (strettamente necessari)',
    'cookie analitici (traffico sul sito web)',
    'cookie di marketing',
    'cookie di funzionalità',
    'cookie di personalizzazione dei contenuti e di test A/B',
    'cookie per sondaggi e recensioni degli utenti',
    'cookie di interattività sui social media',
  ],
};
export const WHAT_ARE_COOKIES_TEXT3 = {
  es_ES:
    'La ley establece que solo podemos almacenar cookies en su dispositivo si son estrictamente necesarias para el funcionamiento correcto de este sitio web. Para todos los demás tipos de cookies, necesitamos su permiso.',
  en_US:
    '',
  pt_BR:
    'A lei indica que apenas podemos armazenar cookies no seu dispositivo se forem estritamente necessários para o funcionamento adequado deste site. Para todos os outros tipos de cookies, precisamos da sua permissão.',
  fr_FR:
    'La loi stipule que nous ne pouvons stocker des cookies sur votre appareil que s’ils sont absolument nécessaires au bon fonctionnement de ce site Web. Pour tous les autres types de cookies, nous avons besoin de votre autorisation.',
  de_DE:
    'Das Gesetz besagt, dass wir Cookies nur dann auf Ihrem Gerät speichern dürfen, wenn sie für den ordnungsgemäßen Betrieb dieser Website unbedingt erforderlich sind. Für alle anderen Arten von Cookies benötigen wir Ihre Zustimmung.',
  it_IT:
    'La legge stabilisce che possiamo memorizzare i cookie sul dispositivo dell’utente solo se sono strettamente necessari per il corretto funzionamento di questo sito web. Per tutti gli altri tipi di cookie, abbiamo bisogno della sua autorizzazione.',
};
export const WHAT_ARE_COOKIES_TEXT4 = {
  es_ES:
    'Puede dar su consentimiento a través del banner que aparece en su primera visita a nuestro sitio web. Una cookie que memoriza su consentimiento o su rechazo se instala en su dispositivo. Sin embargo, puede cambiar sus preferencias de cookies en cualquier momento a través de nuestra página de configuración de cookies.',
  en_US:
    '',
  pt_BR:
    'O seu consentimento é dado através do banner que aparece na sua primeira visita ao nosso site. Um cookie que memoriza o seu consentimento ou a sua recusa é então instalado no seu dispositivo. No entanto, pode alterar as suas preferências de cookies a qualquer momento através da nossa página de definições de cookies.',
  fr_FR:
    'Votre consentement est donné via la bannière qui apparaît lors de votre première visite sur notre site Web. Un cookie mémorisant votre consentement, ou votre refus, est alors installé sur votre appareil. Cependant, vous pouvez modifier vos préférences en matière de cookies à tout moment via notre page de paramètres des cookies.',
  de_DE:
    'Ihre Zustimmung wird über das Banner erteilt, das bei Ihrem ersten Besuch auf unserer Website erscheint. Auf Ihrem Gerät wird dann ein Cookie installiert, das Ihre Zustimmung bzw. Ihre Ablehnung speichert. Sie können Ihre Cookie-Einstellungen jedoch jederzeit über unsere Cookie-Einstellungsseite ändern.',
  it_IT:
    'Il consenso dell’utente viene fornito tramite il banner che appare alla prima visita al nostro sito web. Sul dispositivo viene quindi installato un cookie che memorizza il suo consenso o rifiuto. Tuttavia, l’utente può modificare le sue preferenze sui cookie in qualsiasi momento tramite la nostra pagina delle impostazioni dei cookie.',
};
export const WHAT_ARE_COOKIES_TEXT5 = {
  es_ES:
    'Su consentimiento se guarda hasta que se actualice la presente política o hasta que se añada otra cookie que requiera su consentimiento, y en cualquier caso durante un periodo no superior a 24 meses. En ese momento reaparecerá el banner de consentimiento.',
  en_US:
    '',
  pt_BR:
    'A sua autorização é guardada até que a presente política seja atualizada ou até que seja adicionado outro cookie que requeira a sua autorização e, em qualquer caso, por um período não superior a 3 meses. O banner de consentimento será então novamente apresentado.',
  fr_FR:
    'Votre consentement est sauvegardé jusqu’à ce que la présente politique soit mise à jour ou jusqu’à ce qu’un autre cookie nécessitant votre consentement soit ajouté, et dans tous les cas pendant une période ne dépassant pas 6 mois. La bannière de consentement va alors réapparaître.',
  de_DE:
    'Ihre Zustimmung wird gespeichert, bis die vorliegende Richtlinie aktualisiert wird oder bis ein anderes Cookie hinzugefügt wird, das Ihre Zustimmung erfordert, und in jedem Fall für einen Zeitraum von höchstens 24 Monaten. Das Banner für die Zustimmung wird dann erneut angezeigt.',
  it_IT:
    'Il consenso dell’utente viene salvato fino a quando la presente politica non viene aggiornata o fino a quando non viene aggiunto un nuovo cookie che richiede il suo consenso, in ogni caso per un periodo non superiore a 13 mesi, nel qual caso riapparirà il banner di consenso.',
};
export const TYPES_OF_COOKIES_USED_TITLE = {
  es_ES: 'TIPOS DE COOKIES UTILIZADAS EN NUESTRO SITIO WEB',
  en_US: '',
  pt_BR: 'TIPOS DE COOKIES UTILIZADOS NESTE SITE DA AXA ',
  fr_FR: 'TYPES DE COOKIES UTILISÉS SUR NOTRE SITE WEB',
  de_DE: 'ARTEN VON COOKIES, DIE AUF UNSERER WEBSITE VERWENDET WERDEN',
  it_IT: 'TIPI DI COOKIE UTILIZZATI SUL NOSTRO SITO WEB',
};
export const TYPES_OF_COOKIES_USED_TEXT = {
  es_ES:
    'A continuación encontrará una lista completa de las cookies que se pueden utilizar en este sitio web, así como sus finalidades y sus fechas de caducidad, quedando constancia de lo siguiente:',
  en_US:
    '',
  pt_BR:
    'Encontrará, daqui em diante, uma lista completa dos cookies que podem ser utilizados neste site, bem como os seus fins e as suas datas de validade, sendo já declarado que:',
  fr_FR:
    'Vous trouverez ci-après une liste complète des cookies qui peuvent être utilisés sur ce site Web, ainsi que leurs objectifs et leurs dates d’expiration, étant donné que:',
  de_DE:
    'Sie finden im Folgenden eine vollständige Liste der Cookies, die auf dieser Website verwendet werden können, sowie deren Verwendungszwecke und Ablaufdaten, wobei darauf hingewiesen wird, dass:',
  it_IT:
    'Di seguito riportiamo un elenco completo dei cookie che possono essere utilizzati su questo sito, nonché delle relative finalità e delle loro date di scadenza, con la precisazione che:',
};
export const TYPES_OF_COOKIES_USED_LIST = {
  es_ES: [
    'Las cookies de sesión se almacenan mientras navega y se eliminan cuando cierra el navegador.',
    'Otras cookies (denominadas “persistentes”) se almacenan en su navegador, incluso después de cerrarlo, durante un período que puede variar de 24 horas a varios meses.',
  ],
  en_US: [
    'We will store your preferences, whether consent or refusal of cookies, for 6 months. Once this period has expired, the cookie banner will pop up for you to set up your preferences again. You can adjust the cookie settings at any time via the Cookie Preference Center.',
  ],
  pt_BR: [
    'Os cookies de sessão são armazenados durante toda a navegação e são eliminados quando fecha o seu navegador.',
    'Outros cookies (denominados “persistentes”) são armazenados no seu navegador, mesmo depois de desligados, por um período que pode variar de 24 horas a vários meses.',
  ],
  fr_FR: [
    'Les cookies de session sont stockés pendant la durée de votre navigation et sont supprimés lorsque vous fermez votre navigateur.',
    'D’autres cookies (qualifiés de « persistants ») sont stockés sur votre navigateur, même après la fermeture de celui-ci, pour une durée qui peut varier de 24 heures à plusieurs mois.',
  ],
  de_DE: [
    'Session-Cookies für die Dauer Ihrer Navigation gespeichert und beim Schließen Ihres Browsers gelöscht werden.',
    'Andere Cookies (als „dauerhaft“ bezeichnet) in Ihrem Browser gespeichert werden, selbst nach dem Ausschalten, und zwar für eine Dauer, die von 24 Stunden bis zu mehreren Monaten variieren kann.',
  ],
  it_IT: [
    'i cookie di sessione vengono memorizzati per tutta la durata della navigazione e vengono eliminati alla chiusura del browser;',
    'gli altri cookie (chiamati “persistenti”) vengono memorizzati sul browser dell’utente, anche dopo che sono stati disattivati, per una durata che può variare da 24 ore a diversi mesi.',
  ],
};
export const COOKIE_LIST_TITLE = {
  es_ES: 'Lista de cookies',
  en_US: 'IS COOKIE DEPOSIT MANDATORY?',
  pt_BR: 'Lista de cookies',
  fr_FR: 'Liste des cookies',
  de_DE: 'Cookie-Liste',
  it_IT: 'Elenco dei cookie',
};
//pending
export const COOKIE_LIST_TEXT1 = {
  es_ES:
    'Una cookie es un fragmento pequeño de datos (archivos de texto) que un sitio web, cuando es visitado por un usuario, le pregunta a su navegador para almacenarse en su dispositivo y así recordar información acerca de usted, como por ejemplo la preferencia de idioma o su información para iniciar sesión. Estas cookies son establecidas por nosotros, y se llaman cookies de primeras partes. También usamos cookies de terceras partes (que son cookies de un dominio diferente al dominio del sitio web que está visitando) para nuestros estudios de anuncios y marketing. Específicamente, usamos las cookies y otras tecnologías de seguimiento para los siguientes propósitos:',
  en_US:
    'The strictly necessary or optional character of each category of cookies is indicated at the very moment you are asked to grant (or deny) consent, either via the cookie banner at the occasion of your first visit to our website, or through the Cookie Preference Center. The Cookie Preference Center remains accessible at any time in the footer of the website but also by clicking ‘Personalize my choices’ in the cookie banner. Please note that by refusing optional cookies, some functions, pages and areas of the website may be unavailable, for which we cannot be held responsible. ',
  pt_BR:
    'A cookie is a small piece of data (text file) that a website – when visited by a user – asks your browser to store on your device in order to remember information about you, such as your language preference or login information. Those cookies are set by us and called first-party cookies. We also use third-party cookies – which are cookies from a domain different than the domain of the website you are visiting – for our advertising and marketing efforts. More specifically, we use cookies and other tracking technologies for the following purposes:',
  fr_FR:
    "Un cookie est un petit fichier de données (fichier texte) qu'un site Web, lorsqu'il est consulté par un utilisateur, demande à votre navigateur de stocker sur votre appareil afin de mémoriser des informations vous concernant, telles que vos préférences linguistiques ou informations de connexion. Nous configurons ces cookies appelés cookies internes. Nous utilisons également des cookies tiers, qui sont des cookies d'un domaine différent de celui du site Web que vous consultez, pour nos efforts de publicité et de marketing. Plus concrètement, nous utilisons des cookies et d'autres technologies de suivi selon les finalités suivantes :",
  de_DE:
    'Ein Cookie ist ein kleines Datenpaket (Textdatei), das Ihr Browser auf Anweisung einer besuchten Website auf Ihrem Gerät speichert, um sich Informationen über Sie zu „merken“, wie etwa Ihre Spracheinstellungen oder Anmeldeinformationen. Diese Cookies werden von uns gesetzt und als Erstanbieter-Cookies bezeichnet. Wir verwenden auch Drittanbieter-Cookies, welche von einer anderen Domäne als die der von Ihnen besuchten Website stammen. Wie verwenden diese Cookies zur Unterstützung unserer Werbe- und Marketingmaßnahmen. Insbesondere verwenden wir Cookies und andere Tracker-Technologien für die folgenden Zwecke:',
  it_IT:
    'Un cookie è una piccola porzione di dati (file di testo) che un sito Web, se visitato da un utente, chiede al browser di memorizzarlo sul dispositivo per ricordare le sue informazioni, quali la lingua preferita o i dati di accesso. Questi cookie sono da noi impostati e denominati cookie di prima parte. Utilizziamo inoltre cookie di terza parte - ovvero i cookie di un dominio diverso da quello del sito Web che si sta visitando - per i nostri tentativi pubblicitari e di marketing. In particolare, utilizziamo i cookie e altre tecnologie di tracciamento per i seguenti scopi:',
};

export const COOKIE_TABLES_TITLE = {
  es_ES: '',
  en_US: 'WHAT ARE THE CATEGORIES OF COOKIES DROPPED WHILE YOU ARE BROWSING OUR WEBSITE AND WHAT ARE THEIR PURPOSES?',
  pt_BR: ' ',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};

//pending
export const FUNCTIONAL_TECHNICAL_COOKIES_TITLE = {
  es_ES:
    'Cookies funcionales y técnicas, esenciales para el buen funcionamiento del sitio web (cookies estrictamente necesarias)',
  en_US: 'FUNCTIONAL AND TECHNICAL COOKIES (STRICTLY NECESSARY COOKIES)',
  pt_BR: 'Functional and technical cookies (strictly necessary cookies)',
  fr_FR: 'Cookies fonctionnels et techniques (strictement nécessaires)',
  de_DE:
    'Funktionale und technische Cookies, die für den Betrieb der Website unerlässlich sind (unbedingt notwendige Cookies)',
  it_IT: 'Cookies funzionali (essenziali per il funzionamento del website)',
};
//pending
export const FUNCTIONAL_TECHNICAL_COOKIES_TEXT = {
  es_ES:
    'Estas cookies se utilizan para memorizar sus elecciones y preferencias y para facilitar la navegación. Ayudan a que el sitio web pueda utilizarse activando funciones básicas como la autenticación o identificación de un servicio, la configuración automática del idioma, el almacenamiento de una cesta de la compra, el acceso al área de clientes. El sitio web no puede funcionar correctamente sin estas cookies. Por lo tanto, no se pueden rechazar.',
  en_US:
    'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions taken by you, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but this may impact the functionality and use of the website.',
  pt_BR:
    'These cookies are used to memorize your choices and preferences and to facilitate your navigation. They help make a website usable by activating basic functions such as authentication to a service, automatic language settings, storage of a shopping basket, access to the customer area. The website cannot operate properly without these cookies. Therefore, they cannot be refused.',
  fr_FR:
    "Ces traceurs servent à mémoriser vos choix et vos préférences ainsi qu'à faciliter votre navigation. Ils contribuent à rendre un site web utilisable en activant des fonctions de base comme l’authentification auprès d’un service, le paramétrage automatique de la langue, la conservation en mémoire d’un panier d’achat, l’accès à l’espace client. Le site web ne peut pas fonctionner correctement sans ces cookies. Pour cette raison ils ne peuvent être refusés.",
  de_DE:
    'Diese Cookies werden verwendet, um Ihre Auswahl und Präferenzen zu speichern und Ihre Navigation zu erleichtern. Sie helfen, eine Website nutzbar zu machen, indem sie grundlegende Funktionen aktivieren, wie z. B. die Authentifizierung bei einem Dienst, automatische Spracheinstellungen, Speicherung eines Warenkorbs, Zugang zum Kundenbereich. Die Website kann ohne diese Cookies nicht richtig funktionieren. Sie können daher nicht abgelehnt werden.',
  it_IT:
    'I cookie tecnici permettono un agevole utilizzo del sito ed un più facile reperimento delle informazioni, semplificando la connessione e le trasmissioni di dati tra utente e sito. In particolare, utilizziamo cookie di navigazione o di sessione per garantire la normale navigazione e fruizione del sito web permettendo, ad esempio, di salvare informazioni inerenti la navigazione. I cookie tecnici non richiedono consenso e pertanto vengono installati automaticamente al momento dell’accesso al sito, poiché sono necessari per l’esecuzione di un contratto di cui l’utente è parte o all’esecuzione di misure pre-contrattuali adottate su richiesta dello stesso (utilizzo del Sito). Questa tipologia di cookie è fornita da prima parte e/o terze parti di sotto indicate:',
};
//pending
export const TABLE_HEAD = {
  es_ES: ['Subgrupo de cookies', 'Cookies', 'Cookies utilizadas'],
  en_US: ['Cookie Subgroup', 'Cookies', 'Cookies used', 'Lifepan'],
  pt_BR: ['Cookie Subgroup', 'Cookies', 'Cookies used'],
  fr_FR: ['Sous-groupe de cookies', 'Cookies', 'Cookies utilisés'],
  de_DE: ['Cookie-Untergruppe', 'Cookies', 'Verwendete Cookies'],
  it_IT: ['Sottogruppo cookie', 'Cookies', 'Cookie usati'],
};
//pending
export const FIRST_PARTY = {
  es_ES: 'Propia',
  en_US: 'First Party',
  pt_BR: 'First Party',
  fr_FR: 'Cookies internes',
  de_DE: 'Erstanbieter',
  it_IT: 'Prima parte',
};
//pending
export const ANALYTICS_COOKIES_TITLE = {
  es_ES: 'Cookies de análisis (tráfico del sitio web)',
  en_US: '',
  pt_BR: 'Analytics cookies (website traffic)',
  fr_FR: 'Cookies d’analyse et de mesure d’audience',
  de_DE: 'Analyse -Cookies (Website-Verkehr)',
  it_IT: 'Cookies analitici (traffico website)',
};
//pending
export const ANALYTICS_COOKIES_TEXT = {
  es_ES:
    'Nuestro sitio utiliza cookies para medir nuestra audiencia (número de visitas, número de ventas, tiempo de permanencia en cada página) con el fin de mejorar el rendimiento de nuestro sitio web y adaptar nuestros servicios.',
  en_US:
    '',
  pt_BR:
    'Our site and our partners use cookies to measure our audience (number of visits, number of sales, time spend on each page) in order to improve our site performance and adapt our services.',
  fr_FR:
    'Notre site et nos partenaires utilisent des traceurs afin de mesurer notre audience (nombre de visites, de ventes, temps passé sur chaque page consultée) afin d’améliorer la performance de notre site et d’adapter nos services.',
  de_DE:
    'Unsere Website und unsere Partner verwenden Cookies, um unsere Besucher zu analysieren (Anzahl der Besuche, Anzahl der Verkäufe, Verweildauer auf jeder Seite), um die Leistung unserer Website zu verbessern und unsere Dienstleistungen anzupassen. In einigen Fällen, da sie nur zur Messung der Besucher unserer Website verwendet werden, um die Bedürfnisse der Optimierung und des ordnungsgemäßen Funktionierens unserer Website zu erfüllen, und da sie keine persönlichen Daten (wie Ihre IP-Adresse) abrufen, können einige Analyse-Cookies ohne Ihre Zustimmung hinterlegt werden.',
  it_IT:
    "I cookie di questo tipo vengono utilizzati per raccogliere informazioni sull'utilizzo del sito come ad esempio quali sono le pagine visitate con maggiore frequenza e il numero di visitatori, nonché il numero di azioni svolte sul sito. Questi cookie non memorizzano dati identificativi dei singoli visitatori, ma solo dati aggregati e in forma anonima. Tali cookie vengono utilizzati esclusivamente previa raccolta del consenso da parte dell’utente. Questa tipologia di cookie è fornita da prime parti e/o terze parti, di sotto indicate.",
};
//pending
export const MARKETING_COOKIES_TITLE = {
  es_ES: 'Cookies de márketing',
  en_US: 'AUDIENCE MEASUREMENT COOKIES',
  pt_BR: 'Marketing cookies',
  fr_FR: 'Cookies marketing',
  de_DE: 'Marketing-Cookies',
  it_IT: 'Cookies pubblicitari',
};
export const MARKETING_COOKIES_TEXT = {
  es_ES:
    'Nuestros socios utilizan cookies para medir la eficacia de nuestras campañas de márketing y también para mejorar la relevancia de los anuncios que se le muestran o para regular su exposición a nuestros mensajes publicitarios.',
  en_US:
    'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our website. They enable us to monitor which pages are the most and least popular, common actions taken and how visitors use website. All information these cookies collect is aggregated. If you do not allow these cookies we will not know when you have visited our website and will not be able to monitor its performance.',
  pt_BR:
    'Our partners use cookies to measure the effectiveness of our marketing campaigns and also to improve the relevance of the advertisements presented to you or to regulate your exposure to our advertising messages.',
  fr_FR:
    'Nos partenaires utilisent des traceurs afin de mesurer l’efficacité de nos campagnes marketing et également d’améliorer la pertinence des publicités qui vous sont présentées ou de réguler votre exposition à nos messages publicitaires.',
  de_DE:
    'Unsere Partner verwenden Cookies, um die Effektivität unserer Marketing-Kampagnen zu analysieren und auch um die Relevanz der Ihnen präsentierten Werbung zu verbessern oder um zu regulieren, wie Sie unsere Werbebotschaften erhalten.',
  it_IT:
    "I cookie di questo tipo vengono utilizzati per fornire annunci pubblicitari più pertinenti agli interessi dell’utente, attraverso la raccolta di informazioni sulle abitudini di navigazione dell'utente stesso. Tali cookie vengono inoltre utilizzati per limitare il numero di volte in cui l'utente visualizza un annuncio pubblicitario e per misurare l'efficacia delle campagne pubblicitarie (es. attività di re-targeting). Tali cookie vengono utilizzati esclusivamente previa raccolta del consenso da parte dell’utente. Questa tipologia di cookie è fornita da prime parti e/o terze parti, di sotto indicate.",
};

export const WHAT_ARE_YOUR_RIGHTS_RELATED_TITLE = {
  es_ES: '',
  en_US: 'WHAT ARE YOUR RIGHTS RELATING TO YOUR DATA?',
  pt_BR: '',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};

export const WHAT_ARE_YOUR_RIGHTS_RELATED_TEXT = {
  es_ES: '',
  en_US: 'You may grant your consent (‘opt-in’) to the deposit of some cookies and update your preferences at any time or for all or part of the optional cookies dropped while browsing this website via the Cookie Preference Center.',
  pt_BR: '',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};

export const HOW_TO_CONTACT_TITLE = {
  es_ES: '',
  en_US: 'HOW TO CONTACT AXA PARTNERS REGARDING THIS COOKIE POLICY?',
  pt_BR: '',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};

export const HOW_TO_CONTACT_TEXT = {
  es_ES: '',
  en_US: 'If you have any questions, complaints, or comments regarding this information notice, please address them by sending an email to complianceamericas@axa-assistance.us. Your request will then be reviewed by the data protection department. AXA Partners may ask you for additional information to confirm your identity and/or to assist in locating the data you are seeking.',
  pt_BR: '',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};

export const UPDATES_TITLE = {
  es_ES: 'ACTUALIZACIONES',
  en_US: 'HOW TO MAKE A COMPLAINT TO A SUPERVISORY AUTHORITY?',
  pt_BR: 'ATUALIZAÇÕES',
  fr_FR: 'MISES À JOUR',
  de_DE: 'AKTUALISIERUNGEN',
  it_IT: 'AGGIORNAMENTI',
};
export const UPDATES_TEXT = {
  es_ES:
    'Esta política de configuración de cookies se puede modificar, actualizar o corregir en cualquier momento. El banner informativo que le invita a consultarla y a personalizar sus preferencias de consentimiento a través de nuestra página de configuración de cookies aparecerá cuando visite nuestro sitio web después de cada actualización. Última actualización: 22/03/2022',
  en_US:
    'You have the right to raise concerns about how your personal data is being processed by contacting the office of the State Attorney General of the state where you reside, or directly with the Federal Trade Commission (FTC) by visiting the following page: https://reportfraud.ftc.gov/#/. You may also have the right to raise concerns about how your personal data is being processed with a competent supervisory authority in the country, place of work or place where you think an alleged infringement to your rights occurred. To do so, please refer to the website of your local data protection authority. ',
  pt_BR:
    'Esta política de definições de cookies pode ser alterada, atualizada ou corrigida a qualquer momento. Após cada atualização, o banner de informações convida-o a consultá-lo e a personalizar as suas preferências de consentimento através da nossa página de definições de cookies que aparecerá quando visitar o nosso site. Última atualização: 22/03/2022',
  fr_FR:
    'Cette politique de paramétrage des cookies peut être modifiée, mise à jour ou corrigée à tout moment. La bannière d’information vous invitant à la consulter et à personnaliser vos préférences de consentement via notre page de paramètres des cookies apparaîtra lorsque vous visitez notre site web après chaque mise à jour. Dernière mise à jour: 22/03/2022',
  de_DE:
    'Diese Richtlinie zu den Cookie-Einstellungen kann jederzeit geändert, aktualisiert oder korrigiert werden. Das Informationsbanner, das Sie zur Einsichtnahme und zur Personalisierung Ihrer Einwilligungspräferenzen über unsere Cookie-Einstellungsseite einlädt, wird nach jeder Aktualisierung beim Besuch unserer Website angezeigt. Letzte Aktualisierung: 22/03/2022',
  it_IT:
    'Questa informativa sulle impostazioni dei cookie può essere modificata, aggiornata o corretta in qualsiasi momento. Il banner informativo che invita l’utente a consultarla e a personalizzare le sue preferenze di consenso tramite la nostra pagina di impostazioni dei cookie apparirà quando visita il nostro sito web dopo ogni aggiornamento. Ultimo aggiornamento:  22/03/2022',
};

export const UPDATE_OF_PRESENT_POLICY_TITLE = {
  es_ES: '',
  en_US: 'UPDATE OF THE PRESENT POLICY',
  pt_BR: '',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};

export const UPDATE_OF_PRESENT_POLICY_TEXT = {
  es_ES: '',
  en_US: 'AXA Partners may update this cookie policy from time to time in response to changes regarding cookies dropped while browsing this website. This cookie policy was last updated on October 27, 2023.',
  pt_BR: '',
  fr_FR: '',
  de_DE: '',
  it_IT: '',
};

export const MORE_INFO_TITLE = {
  es_ES: 'MÁS INFORMACIÓN SOBRE COOKIES/RASTREADORES',
  en_US: '',
  pt_BR: 'MAIS INFORMAÇÃO SOBRE COOKIES/TRACKERS',
  fr_FR: 'PLUS D’INFORMATIONS SUR LES COOKIES/TRAQUEURS',
  de_DE: 'WEITERE INFORMATIONEN ZU COOKIES/TRACKERN',
  it_IT: 'MAGGIORI INFORMAZIONI SU COOKIE/TRACKER',
};
