import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const styles = makeStyles((theme) => ({
  title: {
    userSelect: 'none',
    fontWeight: 700,
    fontFamily: 'Source Sans Pro !important',
    fontSize: 16,
    color: theme.palette.text.main,
    [theme.breakpoints.up('xs')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 20,
    },
  },
}));

function DarkTitle({ label }) {
  const classes = styles();
  return <Typography className={classes.title}>{label}</Typography>;
}

export default DarkTitle;
