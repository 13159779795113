export const TITLE = {
  en_US: 'Confirm your email address',
  it_IT: 'Conferma il tuo indirizzo email',
  pt_BR: 'Confirme seu endereço de e-mail',
  es_ES: 'Confirme su dirección de correo electrónico',
  de_DE: 'Bitte prüfen Sie Ihren E-Mail-Posteingang.',
  fr_FR: 'Veuillez confirmer votre adresse email',
};

export const SUBTITLE = {
  en_US:
    'We have sent you an email with a link to verify your account. Please note, the link is valid 24 hours and can only be used once',
  it_IT:
    "Ti abbiamo inviato un'email con un link per verificare il tuo account. Si prega di notare che il link è valido 24 ore e può essere utilizzato solo una volta",
  pt_BR:
    'Enviamos um e-mail com um link para verificar sua conta. Observe que o link é válido por 24 horas e só pode ser usado uma vez',
  es_ES:
    'Le hemos enviado un correo electrónico con un enlace para verificar su cuenta. Tenga en cuenta que el enlace es válido durante 24 horas y solo se puede usar una vez',
  de_DE:
    'Wir haben Ihnen eine Bestätigung per E-Mail geschickt. Bitte überprüfen Sie Ihre E-Mail-Adresse und schließen Sie die Anmeldung ab.',
  fr_FR:
    "Un email contenant un lien de vérification vous a été envoyé, le lien est valide 24 heures et ne peut être utilisé qu'une seule fois",
};

export const BUTTON_GO_TO_HOME = {
  en_US: 'Go to Homepage',
  it_IT: 'Vai alla pagina principale',
  pt_BR: 'Vá para a página inicial',
  es_ES: 'Ir a la página de inicio',
  de_DE: 'Gehen Sie zur Startseite',
  fr_FR: "Aller à la page d'accueil",
};
