import React, { useEffect, useState, useContext } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
  Container,
  Paper,
  Grid,
  makeStyles,
  Tabs,
  Tab,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { GeneralContext } from '../../../../shared/contexts/GeneralContext';
import TabPanel from './TabPanel';
import TabVideo from './TabPanel/TabVideo';
import TabPhone from './TabPanel/TabPhone';
import TabDetails from './TabPanel/TabDetails';
import api from '../../../../services';
import * as translations from './intl';
import { useTranslation, useQuery } from '../../../../shared/hooks';
import { language } from '../../../../globalConstants';
import { partner_id } from '../../../../globalConstants';
import { partner_name } from '../../../../globalConstants';
import { country_code } from '../../../../globalConstants';
import { instance_env } from '../../../../globalConstants';

const styles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Publico Headline !important',
    fontSize: '48px !important',
    textAlign: 'center',
    letterSpacing: '1px',
    fontWeight: 600,
    lineHeight: '60px !important',
    color: theme.palette.text.main,
    paddingBottom: 42,
    [theme.breakpoints.down('sm')]: {
      fontSize: '38px !important',
    },
  },
  container: {
    marginTop: 60,
    marginBottom: 60,
  },
  sourceSans: {
    fontFamily: 'Source Sans Pro',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  tabTitle: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
}));

function Appointments() {
  const classes = styles();
  const theme = useTheme();
  const query = useQuery();
  const { amosToken, isLogged, setIsLoading, reFreshClientToken, userData } =
    useContext(GeneralContext);
  const type = query.get('type');
  const setTable = () => {
    if (type && type === 'phone') return 1;
    return 0;
  };
  const [value, setValue] = useState(setTable);
  const [typeAppointment, setTypeAppointment] = useState('');
  const [phoneCallId, setPhoneCallId] = useState();
  const [gotTime, setGotTime] = useState();
  const [appointmentId, setAppointmentId] = useState();
  const [listRequestsVideo, setListRequestsVideo] = useState({
    videocalls: [],
  });
  const [listRequestsPhone, setListRequestsPhone] = useState({
    consultations: [],
  });
  const { locale: intl } = useTranslation({
    translationsFile: translations,
  });

  const { APPOINTMENTS, VIDEO_CALLS, PHONE_CALLS, FULL_WIDTH_LABEL } = intl;

  const handleChange = (event, newValue) => {
    setValue(newValue);

    let type = '';
    if(document.querySelector('#video_call').value === VIDEO_CALLS){
      type='video';
    }else{
      type='phone';
    }

    window.dataLayer.push({
        'subsidiary_name':'axa_partners',
        'business_unit':'customer_and_solutions',
        'business_line':'travel_solutions',
        'digital_business_process':'assistance_request',
        'global_digital_asset':'doctor_please',
        'partner_id':partner_id,
        'partner_name':partner_name,
        'country':country_code,
        'language':language,
        'instance_environment':instance_env,
        'user_logged':'true',
        'page_name':'appointments list',
        'appointment_type': type+' call',
        'funnel_step':'3',
        'step_name':'appointments list',
        'event':'pageview',
    });
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  async function getTime(videoItem) {
    const { timeZone } = videoItem;

    return api.amosServices.getTime(timeZone).then((resp) => {
      const obj = {
        ...videoItem,
        gotTime: resp.data.time,
      };
      return obj;
    });
  }

  const getRequestsVideo = async () => {
    const promises = [];
    setIsLoading(true);

    try {
      let videocalls=[];
      try{
        videocalls=(await api.amosServices.getRequestsVideo(amosToken))?.data?.videocalls;
      }
      catch(err){
        await reFreshClientToken();
        videocalls=(await api.amosServices.getRequestsVideo(amosToken))?.data?.videocalls;
      }

      if (videocalls.length === 0) {
        setListRequestsVideo({
          videocalls: [],
        });
      }

      videocalls.forEach((item) => {
        if (
          String(item.status).toLowerCase() === 'pending' ||
          String(item.status).toLowerCase() === 'in-progress'
        ) {
          promises.push(getTime(item));
        } else {
          promises.push(item);
        }
      });
      const promisesResolved = await Promise.all(promises);

      if (promisesResolved.length > 0) {
        setListRequestsVideo({
          videocalls: promisesResolved,
        });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getRequestsPhone = async () => {
    setIsLoading(true);
    try {
      let data;
      try{
        data=await api.amosServices.getRequestsPhone(amosToken);
      }
      catch(err){
        await reFreshClientToken();
        data=await api.amosServices.getRequestsPhone(amosToken);
      }
      if (data!=undefined && data.data.consultations.length > 0) {
        setListRequestsPhone({
          consultations: data.data.consultations,
        });
      } else {
        setListRequestsPhone({
          consultations: [],
        });
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (amosToken && isLogged) {
      getRequestsPhone();
      getRequestsVideo();
    } else {
      setListRequestsVideo({
        videocalls: [],
      });
      setListRequestsPhone({
        consultations: [],
      });
    }
  }, [amosToken, userData]);

  return (
    <div id="appointments">
      <Container className={classes.container}>
        <Grid item md={12}>
          <p className={classes.title}>{APPOINTMENTS}</p>
        </Grid>
        <Paper elevation={3}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            variant="fullWidth"
            aria-label={FULL_WIDTH_LABEL}
          >
            <Tab
              className={`${classes.sourceSans} ${classes.tabTitle} ${classes.upperCase}`}
              label={VIDEO_CALLS}
            />
            <Tab
              className={`${classes.sourceSans} ${classes.tabTitle} ${classes.upperCase}`}
              label={PHONE_CALLS}
            />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <TabVideo
                videoList={listRequestsVideo.videocalls}
                setValue={setValue}
                setAppointmentId={setAppointmentId}
                setTypeAppointment={setTypeAppointment}
                setGotTime={setGotTime}
              />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <TabPhone
                phoneList={listRequestsPhone.consultations}
                setValue={setValue}
                setPhoneCallId={setPhoneCallId}
                setTypeAppointment={setTypeAppointment}
              />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <TabDetails
                setValue={setValue}
                phoneCallId={phoneCallId}
                appointmentId={appointmentId}
                typeAppointment={typeAppointment}
                gotTime={gotTime}
              />
            </TabPanel>
          </SwipeableViews>
        </Paper>
      </Container>
    </div>
  );
}
export default Appointments;
