import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FeedbackOutlined } from '@material-ui/icons';
import { useTranslation } from '../../../../shared/hooks';
import * as translations from './intl';
import { Button } from '../../../../components';
import { language } from '../../../../globalConstants';
import { partner_id, partner_name, country_code, instance_env } from '../../../../globalConstants';

const styles = makeStyles((theme) => ({
  container: {
    padding: 30,
  },
  boldTitle: {
    fontWeight: 400,
  },
  marginButton: {
    marginTop: 20,
  },
}));

function ConfirmEmailAlert() {
  const classes = styles();
  const history = useHistory();
  const { locale } = useTranslation({
    translationsFile: translations,
  });

  const { TITLE, SUBTITLE, BUTTON_GO_TO_HOME } = locale;

  useEffect(() => {
    // Check if the user is newly logged in (add your login status check here)
    const isNewUserLoggedIn = true;  // Replace with your logic

    if (isNewUserLoggedIn) {
      // Push the event only for new users
      window.dataLayer.push({
        'subsidary_name': 'axa_partners',
        'business_unit': 'customer_and_solutions',
        'business_line': 'travel_solutions',
        'digital_business_process': 'assistance_request',
        'global_digital_asset': 'doctor_please',
        'partner_id': partner_id,
        'partner_name': partner_name,
        'country': country_code,
        'language': language,
        'instance_environment': instance_env,
        'user_logged': 'true',
        'page_name': 'confirm email address',
        'event': 'page_view',
      });
    }
  }, []);  // Empty dependency array ensures the effect runs once when the component mounts

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item md={12} sm={12}>
          <FeedbackOutlined color="error" fontSize="large" />
        </Grid>
        <Grid item md={12} sm={12}>
          <Typography color="error" variant="h4" className={classes.boldTitle}>
            {TITLE}
          </Typography>
        </Grid>
        <Grid item md={12} sm={12}>
          <Typography color="text" variant="subtitle">
            {SUBTITLE}
          </Typography>
        </Grid>
        <Grid item md={12} sm={12}>
          <Button
            text={BUTTON_GO_TO_HOME}
            variant="outlined"
            color="primary"
            className={classes.marginButton}
            onClick={() => {
              history.go(0);
              window.location.reload();
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ConfirmEmailAlert;
